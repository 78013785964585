/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { Page } from '../../../shared/models/page.model';
import { AffiliationProfile } from '../../models/affiliation.model';
import { Vault, VaultEvent } from '../../models/vault.model';

// eslint-disable-next-line no-shadow
export enum AffiliationActions {
  LoadAffiliationProfile = `[Affiliation] Load Affiliation Profile`,
  SetAffiliationProfile = `[Affiliation] Set Affiliation Profile`,
  EnableAffiliationProfile = `[Affiliation] Enable Affiliation Profile`,
  LoadVault = `[Affiliation] Load Vault`,
  SetVault = `[Affiliation] Set Vault`,
  loadVaultEventPage = `[Affiliation] Load Vault Events Page`,
  SetVaultEventPage = `[Affiliation] Set Vault Events Page`,
  SetAffiliationError = `[Affiliation] Set Affiliation Error`,
}

export const loadAffiliationProfileAction = createAction(
  AffiliationActions.LoadAffiliationProfile
);

export const setAffiliationProfileAction = createAction(
  AffiliationActions.SetAffiliationProfile,
  props<{ affiliationProfile: AffiliationProfile }>()
);

export const enableAffiliationProfileAction = createAction(
  AffiliationActions.EnableAffiliationProfile
);

export const loadVaultAction = createAction(AffiliationActions.LoadVault);

export const setVaultAction = createAction(
  AffiliationActions.SetVault,
  props<{ vault: Vault }>()
);

export const loadVaultEventPageAction = createAction(
  AffiliationActions.loadVaultEventPage,
  props<{ page?: number, size?: number, sort?: string }>()
);

export const setVaultEventPageAction = createAction(
  AffiliationActions.SetVaultEventPage,
  props<{ vaultEventPage: Page<VaultEvent> }>()
);
