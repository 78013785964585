<div class="suggested-platform-importer">
  <app-account-expansion-panel
    [icon]="'/assets/img/icons/tool-primary.svg'"
    [title]="'PLATFORM_IN_SUPPORT_PROCESS' | translate"
    [content]="'PLATFORM_IN_SUPPORT_PROCESS_CONTENT' | translate"
    [showLink]="false"
  ></app-account-expansion-panel>

  @if (suggestedPlatform?.supportsWalletAddress) {
    <form [formGroup]="walletForm" (ngSubmit)="validateWalletForm()">
      <div class="form-field">
        <p class="body-14-med">
          {{ 'ACCOUNTS.ADDRESS_NS' | translate }}
        </p>

        <input
          matInput
          type="text"
          formControlName="address"
          class="custom-input"
          [placeholder]="'PLACEHOLDERS.ADDRESS_NS' | translate"
          customFocus
          defaultClass="custom-input"
        />
      </div>

      <button type="submit" mat-flat-button color="primary" [disabled]="walletForm.invalid">
        @if (loading) {
          <img class="rotating" src="/assets/img/icons/processing.svg" alt="" />
        } @else {
          {{ 'IMPORT' | translate }}
        }
      </button>
    </form>
  } @else {
    <form [formGroup]="platformForm" (ngSubmit)="validatePlatformForm()">
      @if (suggestedPlatform?.supportsApiKey) {
        <div class="form-field">
          <p class="body-14-med">
            {{ 'API' | translate }}

            @if (accountType === 'FILE') {
              <span class="body-14">({{ 'OPTIONAL' | translate }})</span>
            }
          </p>
          <input
            matInput
            type="text"
            formControlName="apiKey"
            class="custom-input"
            [placeholder]="'PLACEHOLDERS.API_KEY' | translate"
            customFocus
            defaultClass="custom-input"
          />
        </div>
      }

      @if (suggestedPlatform?.supportsApiSecret) {
        <div class="form-field">
          <p class="body-14-med">
            {{ 'SECRET_API' | translate }}

            @if (accountType === 'FILE') {
              <span class="body-14">({{ 'OPTIONAL' | translate }})</span>
            }
          </p>
          <input
            matInput
            type="text"
            formControlName="apiSecret"
            class="custom-input"
            [placeholder]="'PLACEHOLDERS.API_SECRET_KEY' | translate"
            customFocus
            defaultClass="custom-input"
          />
        </div>
      }

      @if (suggestedPlatform?.supportsApiPassphrase) {
        <div class="form-field">
          <p class="body-14-med">
            {{ 'PASSPHRASE' | translate }}

            @if (accountType === 'FILE') {
              <span class="body-14">({{ 'OPTIONAL' | translate }})</span>
            }
          </p>
          <input
            matInput
            type="text"
            formControlName="apiPassphrase"
            class="custom-input"
            [placeholder]="'PLACEHOLDERS.PASSPHRASE' | translate"
            customFocus
            defaultClass="custom-input"
          />
        </div>
      }

      @if (suggestedPlatform?.supportsFile) {
        <div class="form-field">
          <p class="body-14-med">
            {{ 'IMPORT_FILE' | translate }}

            @if (accountType === 'API') {
              <span class="body-14">({{ 'OPTIONAL' | translate }})</span>
            }
          </p>
          <app-file-importer
            [account]="account"
            [userAccounts]="userAccounts"
            [timezones]="timezones"
            [accessToken]="accessToken"
            [autoProcessQueue]="false"
            (filesAdded)="onFilesAdded($event)"
          ></app-file-importer>
        </div>
      }

      <button type="submit" mat-flat-button color="primary" [disabled]="areFilesRequired() || platformForm.invalid">
        @if (loading) {
          <img class="rotating" src="/assets/img/icons/processing.svg" alt="" />
        } @else {
          {{ 'IMPORT' | translate }}
        }
      </button>
    </form>
  }
</div>

<!-- 
  supportsApiKey?: boolean;
  supportsApiSecret?: boolean;
  supportsApiPassphrase?: boolean; -->
