<section id="main-container">
  <div id="circle-orbit-container">
    <div id="central-point">
      <img src="/assets/img/logos/_manual_ledger.svg" alt="Waltio" width="80" height="40" />
    </div>

    <!-- Circles closest to the central point -->
    <div id="inner-orbit">
      <img
        class="inner-orbit-circles inner-orbit-circles--first coin-icon"
        src="/assets/img/loader/BTC.svg"
        alt="BTC"
      />
      <img
        class="inner-orbit-circles inner-orbit-circles--second coin-icon"
        src="/assets/img/loader/ETH.svg"
        alt="ETH"
      />
      <img
        class="inner-orbit-circles inner-orbit-circles--third coin-icon"
        src="/assets/img/loader/BNB.svg"
        alt="BNB"
      />
    </div>

    <!-- Circles second closest to the central point -->
    <div id="middle-orbit">
      <img
        class="middle-orbit-circles middle-orbit-circles--first coin-icon"
        src="/assets/img/loader/MATIC.svg"
        alt="MATIC"
      />
      <img
        class="middle-orbit-circles middle-orbit-circles--second coin-icon"
        src="/assets/img/loader/DOT.svg"
        alt="DOT"
      />
      <img
        class="middle-orbit-circles middle-orbit-circles--third coin-icon"
        src="/assets/img/loader/XTZ.svg"
        alt="XTZ"
      />
    </div>

    <!-- Circles furthest away to the central point -->
    <div id="outer-orbit">
      <img
        class="outer-orbit-circles outer-orbit-circles--first coin-icon"
        src="/assets/img/loader/SOL.svg"
        alt="SOL"
      />
      <img
        class="outer-orbit-circles outer-orbit-circles--second coin-icon"
        src="/assets/img/loader/ATOM.svg"
        alt="ATOM"
      />
      <img
        class="outer-orbit-circles outer-orbit-circles--third coin-icon"
        src="/assets/img/loader/USDT.svg"
        alt="USDT"
      />
    </div>
  </div>
</section>
