import { Action, createReducer, on } from '@ngrx/store';
import { CustomError } from '../../../shared/models/error.model';
import { Page } from '../../../shared/models/page.model';
import { AffiliationProfile } from '../../models/affiliation.model';
import { Vault, VaultEvent } from '../../models/vault.model';
import * as AffiliationActions from '../actions/affiliation.action';

export interface State {
  vault: Vault;
  vaultEventPage: Page<VaultEvent>;
  affiliationProfile: AffiliationProfile;
  affiliationError: CustomError;
}

export const initialState: State = {
  vault: null,
  vaultEventPage: null,
  affiliationProfile: null,
  affiliationError: null,
};

const reducer = createReducer(
  initialState,
  on(
    AffiliationActions.setAffiliationProfileAction,
    (state: State, { affiliationProfile }: any) => ({
      ...state,
      affiliationProfile,
    })
  ),
  on(AffiliationActions.setVaultAction, (state: State, { vault }: any) => ({
    ...state,
    vault,
  })),
  on(
    AffiliationActions.setVaultEventPageAction,
    (state: State, { vaultEventPage }: any) => ({
      ...state,
      vaultEventPage,
    })
  )
);

export const affiliationReducer = (
  state: State | undefined,
  action: Action
): State => reducer(state, action);
