import { Pipe, PipeTransform } from '@angular/core';
import { Account } from '../models/account.model';

@Pipe({
  name: `accountsSorter`,
  standalone: true,
})
export class AccountsSorterPipe implements PipeTransform {
  transform(accounts: Account[], filter = ``): Account[] {
    return accounts
      .filter((account: Account) => account?.name?.toLowerCase().includes(filter.toLowerCase()))
      .sort((accountA: Account, accountB: Account) =>
        accountA?.name?.toLowerCase().localeCompare(accountB?.name?.toLowerCase()),
      );
  }
}
