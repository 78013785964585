<div
  #tooltip="matTooltip"
  [matTooltip]="tooltipLabel | translate"
  matTooltipPosition="above"
  [matTooltipDisabled]="!copiable"
  class="grid-cell"
  [ngClass]="{
    'grid-cell--copied': copied,
  }"
  (mouseenter)="tooltipLabel = 'COPY'; tooltip.show()"
  (mouseleave)="copied = false; tooltip.hide()"
  (click)="copyValue()"
>
  {{ value }}
</div>
