import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil, tap } from 'rxjs';
import { UserPreferences } from '../../../shared/models/user-preferences.model';
import { CurrencyDecimalPipe } from '../../../shared/pipes/currency-decimal.pipe';
import { TruncatePipe } from '../../../taxation/pipes/truncate.pipe';
import { DEFAULT_CURRENCIES_OPTIONS } from '../../constants/swapper.constant';

@Component({
  selector: `app-swapper`,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    TruncatePipe,
    TranslateModule,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: `./swapper.component.html`,
  styleUrl: `./swapper.component.scss`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwapperComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() label = `Acheter`;
  @Input() excludedCurrency: string;
  @Input() coins: string[];
  @Input() currency: string;
  @Input() startAmount: number | null = null;
  @Input() amount: number;
  @Input() placeholder: number;
  @Input() disabled: boolean;
  @Input() reset: boolean;

  @Input() userPreferences: UserPreferences;
  @Input() stablecoins: string[];
  @Input() fiats: string[];

  @Output() amountChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() currencyChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(`search`) searchElement: ElementRef<HTMLInputElement>;

  form: FormGroup<{
    amount: FormControl<string>;
    currency: FormControl<string>;
  }>;
  active = false;
  currencies: string[] = [];
  currenciesSearchFormControl: FormControl<string>;
  lastCurrency: string;
  amountPlaceHolder = `0`;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly currencyDecimalPipe: CurrencyDecimalPipe
  ) {
    this.currenciesSearchFormControl = this.fb.control(``);
    this.form = this.fb.group({
      amount: this.fb.control(null),
      currency: this.fb.control(``),
    });
  }

  ngOnInit(): void {
    this.form.get(`amount`).setValue(this.formatAmount(this.startAmount), { emitEvent: false });

    this.currenciesSearchFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((search: string) => {
          this.searchCoin(search);
        })
      )
      .subscribe();

    this.form
      .get(`amount`)
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((amount: string) => {
          if (amount) {
            const formattedAmount = parseFloat(amount);
            this.amountChange.emit(formattedAmount);
          } else {
            this.amountChange.emit(0);
            this.amountPlaceHolder = `0`;
          }
        })
      )
      .subscribe();

    this.form
      .get(`currency`)
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((currency: string) => {
          if (currency && currency !== this.lastCurrency) {
            this.lastCurrency = currency;
            this.currencyChange.emit(currency);
          }
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currency?.currentValue && changes.excludedCurrency?.currentValue) {
      this.lastCurrency = this.currency;
      this.form.get(`currency`).setValue(this.currency);
      this.searchCoin(this.currency);
    } else if (changes.excludedCurrency?.currentValue) {
      this.searchCoin(this.form.get(`currency`).value);
    }

    if (changes?.disabled) {
      if (changes?.disabled.currentValue) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }

    if (changes?.reset?.currentValue) {
      this.form.get(`amount`).setValue(null, { emitEvent: false });
    }

    if (changes?.amount?.currentValue) {
      const value = changes.amount.currentValue.toString();

      if (value !== this.form.get(`amount`).value) {
        const formattedAmount = this.formatAmount(changes.amount.currentValue);
        this.form.get(`amount`).setValue(formattedAmount, { emitEvent: false });
      }
    }

    if (changes?.placeholder?.currentValue) {
      this.amountPlaceHolder = this.formatAmount(changes.placeholder.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.searchElement.nativeElement.focus();
  }

  openedChange(opened: boolean): void {
    this.searchCoin(``);

    if (!opened) {
      this.currenciesSearchFormControl.setValue(``, { emitEvent: false });
      this.form.get(`currency`).setValue(this.lastCurrency);
    } else {
      this.searchElement.nativeElement.focus();
    }
  }

  searchCoin(search: string): void {
    if (search) {
      this.currencies = this.coins
        .filter((coin: string) => coin.startsWith(search.toUpperCase()))
        .filter((coin: string) => (this.excludedCurrency ? coin !== this.excludedCurrency : true))
        .sort((token1: string, token2: string) => token1.localeCompare(token2))
        .slice(0, 10);
    } else {
      this.currencies = DEFAULT_CURRENCIES_OPTIONS.filter((coin: string) =>
        this.excludedCurrency ? coin !== this.excludedCurrency : true
      );
    }
  }

  onInput(): void {
    const value = this.form.get(`amount`).value;
    const formattedAmount = value?.replace(/[^0-9.,]/g, ``).replace(`,`, `.`);
    this.form.get(`amount`).setValue(formattedAmount, { emitEvent: false });
  }

  onFocus(): void {
    this.active = true;
  }

  onBlur(): void {
    this.active = false;
    const amountValue = this.form.get(`amount`).value;

    if (amountValue !== null) {
      const value = amountValue?.replace(/[^0-9.,]/g, ``).replace(`,`, `.`);
      const formattedAmount = this.formatAmount(+value);
      this.form.get(`amount`).setValue(formattedAmount, { emitEvent: false });
    }
  }

  formatAmount(amount: number): string {
    if (amount === null || amount === undefined) {
      return null;
    } else {
      return this.currencyDecimalPipe.transform(
        amount,
        this.userPreferences,
        this.stablecoins,
        this.fiats,
        ``,
        this.form.get(`currency`).value
      );
    }
  }
}
