import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: `root`,
})
export class AppService {
  constructor(private readonly http: HttpClient) { }

  getFiats(): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.apiUrl}/v1/tax/app/fiats`
    );
  }

  /**
   *
   * @returns all stable coins
   */
  getStableCoins(): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.apiUrl}/v1/tax/app/stable-coins`
    );
  }

  getFrenchPostCodes(): Observable<Map<string, string>> {
    return this.http
      .get<Map<string, string>>(
        `${environment.apiUrl}/v1/tax/dictionary/french/postcode`
      )
      .pipe(
        map(
          (postCodes: Map<string, string>) =>
            new Map<string, string>(Object.entries(postCodes))
        )
      );
  }

  getSpanishAutonomousCommunities(): Observable<Map<string, string>> {
    return this.http
      .get<Map<string, string>>(
        `${environment.apiUrl}/v1/tax/dictionary/spanish/autonomous-communities`
      )
      .pipe(
        map(
          (autonomousCommunities: Map<string, string>) =>
            new Map<string, string>(Object.entries(autonomousCommunities))
        )
      );
  }
}
