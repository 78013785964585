import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BulkTokenToggleResponse, TokenToggleResponse } from '../models/insight.model';
import { ChartHistory } from '../models/market.model';
import { LiveBalanceResponse } from '../models/live-balance.model';

@Injectable({
  providedIn: `root`,
})
export class InsightService {
  constructor(private readonly http: HttpClient) {}

  getPortfolioChartHistory(): Observable<ChartHistory> {
    return this.http.get<ChartHistory>(`${environment.apiUrl}/v1/insight/dashboard/chart/hour`).pipe(
      map((chartHistory: ChartHistory) => {
        chartHistory.lastPriceByToken = new Map<string, number>(Object.entries(chartHistory.lastPriceByToken));
        chartHistory.stocks = new Map<string, number>(Object.entries(chartHistory.stocks));
        chartHistory.referencesPrices = new Map<string, number[]>(Object.entries(chartHistory.referencesPrices));

        return chartHistory;
      }),
    );
  }

  getPortfolioChartHistoryV2(): Observable<ChartHistory> {
    return this.http.get<ChartHistory>(`${environment.apiUrl}/v1/insight/dashboard/chart/incl-files/hour`).pipe(
      map((chartHistory: ChartHistory) => {
        chartHistory.lastPriceByToken = new Map<string, number>(Object.entries(chartHistory.lastPriceByToken));
        chartHistory.stocks = new Map<string, number>(Object.entries(chartHistory.stocks));
        chartHistory.referencesPrices = new Map<string, number[]>(Object.entries(chartHistory.referencesPrices));

        return chartHistory;
      }),
    );
  }

  getTokenToggleStatus(platform: string, token: string): Observable<TokenToggleResponse> {
    return this.http.get<TokenToggleResponse>(`${environment.apiUrl}/v1/tax/user-token-toggle/${platform}/${token}`);
  }

  updateTokenToggleStatus(platform: string, token: string, isToggled: boolean): Observable<LiveBalanceResponse> {
    return this.http.put<LiveBalanceResponse>(
      `${environment.apiUrl}/v1/tax/user-token-toggle/${platform}/${token}?isToggled=${isToggled}`,
      {},
    );
  }

  getTokensToggleStatus(requests: TokenToggleResponse[]): Observable<TokenToggleResponse[]> {
    return this.http.get<TokenToggleResponse[]>(
      `${environment.apiUrl}/v1/tax/user-token-toggle/bulk?requests=${requests}`,
    );
  }

  updateTokensToggleStatus(requests: TokenToggleResponse[]): Observable<BulkTokenToggleResponse> {
    return this.http.put<BulkTokenToggleResponse>(`${environment.apiUrl}/v1/tax/user-token-toggle/bulk`, requests);
  }
}
