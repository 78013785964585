import { CommonModule, KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store, select } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { ImagePreloadDirective } from '../../../shared/directives/image-preload.directive';
import { UtilsService } from '../../../shared/services/utils.service';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { BannerComponent } from '../../components/banner/banner.component';
import { UpgradeFishingDialogComponent } from '../../components/dialogs/upgrade-fishing-dialog/upgrade-fishing-dialog.component';
import { FrenchTaxFormComponent } from '../../components/french-tax-form/french-tax-form.component';
import { SpanishTaxFormComponent } from '../../components/spanish-tax-form/spanish-tax-form.component';
import { SpanishTaxableCessionComponent } from '../../components/spanish-taxable-cession/spanish-taxable-cession.component';
import { TaxGridCellComponent } from '../../components/tax-grid-cell/tax-grid-cell.component';
import { TAXABLE_CESSIONS_DEMO_ES, TAXABLE_CESSIONS_DEMO_FR } from '../../constants/taxable-cessions.constant';
import { Account, Details } from '../../models/account.model';
import { Assessment, AssessmentFile, AssessmentStatus } from '../../models/assessment.model';
import { PaymentEstimateV3 } from '../../models/payment.model';
import { TaxableCession } from '../../models/taxable-cession.model';
import { TransactionFilters } from '../../models/transaction.model';
import { User, UserPlan } from '../../models/user.model';
import { downloadAssessmentFileAction } from '../../store/actions/assessment.action';
import { loadFiltersAction } from '../../store/actions/transaction.action';
import * as fromAssessment from '../../store/selectors/assessment.selector';
import * as fromPayment from '../../store/selectors/payment.selector';
import * as fromTransaction from '../../store/selectors/transaction.selector';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { FeatureService } from '../../../shared/services/feature.service';
import { Modelo, ModeloPlatform } from '../../models/modelo.model';
import { TOOLTIPS } from '../../../shared/constants/tooltips.constant';
import { TAX_FORM_PLATFORMS } from '../../constants/forms.constant';
@Component({
  selector: `app-legal-forms-page`,
  templateUrl: `./legal-forms-page.component.html`,
  styleUrls: [`./legal-forms-page.component.scss`],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    TranslateModule,
    MatDividerModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    BannerComponent,
    TaxGridCellComponent,
    ImagePreloadDirective,
    MatTooltipModule,
    SpanishTaxableCessionComponent,
    FrenchTaxFormComponent,
    SpanishTaxFormComponent,
    TruncatePipe,
  ],
})
export class LegalFormsPageComponent implements OnInit, OnDestroy {
  hasPaid = false;
  hasPaidModelo = false;
  loading = false;

  accounts: Map<string, Account> = new Map<string, Account>([]);

  selectedToken: string;
  tokens: string[] = [];
  modeloTokens: Map<string, string[]> = new Map<string, string[]>([]);

  selectedPlatform: string;
  platforms: string[] = TAX_FORM_PLATFORMS;

  coins$: Observable<Map<string, string>>;

  platformSearchControl: FormControl<string> = new FormControl<string>(``);
  usedPlatforms: Map<string, Details> = new Map<string, Details>([]);
  usedPlatformsOptions$: ReplaySubject<Map<string, Details>> = new ReplaySubject(1);
  otherPlatforms: Map<string, Details> = new Map<string, Details>([]);
  platformsOptions$: ReplaySubject<Map<string, Details>> = new ReplaySubject(1);

  // ES
  modeloPlatformSearchControl: FormControl<string> = new FormControl<string>(``);
  modeloPlatformsToDeclare: Map<string, Details> = new Map<string, Details>([]);
  modeloVaspPlatforms: Map<string, Details> = new Map<string, Details>([]);
  modeloVaspPlatformsOptions$: ReplaySubject<Map<string, Details>> = new ReplaySubject(1);
  modeloPlatformsToDeclareOptions$: ReplaySubject<Map<string, Details>> = new ReplaySubject(1);

  tokenSearchControl: FormControl<string> = new FormControl<string>(``);

  currentForm = `3916-bis`;
  taxableCessions: TaxableCession[];
  displayedCessions = [];
  cessionsPageIndex = 0;
  maxCessionsPage = 1;
  taxableCessionsMsg = ``;

  assessment: Assessment;
  assessmentStatus: AssessmentStatus;
  user: User;
  paymentEstimate: PaymentEstimateV3;
  ææ;
  showDemo = false;

  upgradeFishingCTA = ``;
  upgradeFishingCTAModelo = ``;

  maxCessionsPerPage = 5;

  tooltips = TOOLTIPS;

  // ES
  selectedTaxableCession: TaxableCession;
  selectedTaxableCessionIndex = 0;
  modelo: Modelo;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly transactionStore: Store<fromTransaction.State>,
    private readonly paymentStore: Store<fromPayment.State>,
    private readonly assessmentStore: Store<fromAssessment.State>,
    private readonly authStore: Store<fromAuth.State>,
    private readonly utilsService: UtilsService,
    private readonly translateService: TranslateService,
    private readonly featureService: FeatureService,
  ) {}

  ngOnInit(): void {
    this.transactionStore.dispatch(loadFiltersAction());

    this.coins$ = this.sharedStore$.pipe(takeUntil(this.destroy$), select(fromShared.selectCoins));

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectCurrentOpenedForm),
        map((currentOpenedForm: string) => {
          this.currentForm = currentOpenedForm;
        }),
      )
      .subscribe();

    this.assessmentStore
      .pipe(
        takeUntil(this.destroy$),
        select(fromAssessment.selectTaxableCessions),
        map((taxableCessions: TaxableCession[]) => {
          if (taxableCessions && this.hasPaid) {
            this.taxableCessions = taxableCessions;
            this.selectedTaxableCession = this.taxableCessions[this.selectedTaxableCessionIndex];
            this.updateTaxableCessionsPage();
          }
        }),
      )
      .subscribe();

    combineLatest([
      this.paymentStore.select(fromPayment.selectUpgradeFishingEstimate),
      this.authStore.select(fromAuth.selectUser),
      this.assessmentStore.select(fromAssessment.selectAssessment),
      this.assessmentStore.select(fromAssessment.selectAssessmentStatus),
      this.sharedStore$.select(fromShared.selectAccounts),
      this.assessmentStore.select(fromAssessment.selectModelo),
      this.transactionStore.select(fromTransaction.selectFilters),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([paymentEstimate, user, assessment, assessmentStatus, accounts, modelo, filters]) => {
          this.paymentEstimate = paymentEstimate;
          this.user = user;
          this.assessment = assessment;
          this.assessmentStatus = assessmentStatus;
          this.accounts = accounts;
          this.modelo = modelo;

          if (this.user && this.assessment && this.assessmentStatus && this.paymentEstimate && filters) {
            this.hasPaid = this.assessmentStatus !== `NEED_PLAN_UPGRADE`;
            this.hasPaidModelo = this.user.plan === `PLN500` || this.user.plan === `PLN800`;
            this.loading = this.assessmentStatus === `IN_PROGRESS`;

            if (this.user.fiscalResidency === `FR`) {
              this.getPlatformsAndTokensForTax(filters);

              if (!this.hasPaid) {
                this.taxableCessions = TAXABLE_CESSIONS_DEMO_FR;
                this.updateTaxableCessionsPage();
              } else if (!this.taxableCessions) {
                const fiscalAnnex: AssessmentFile = this.assessment.files?.find(
                  (file: AssessmentFile) => file.fileType === `FISCAL_ANNEX_AS_JSON`,
                );

                if (fiscalAnnex) {
                  this.assessmentStore.dispatch(
                    downloadAssessmentFileAction({
                      assessmentId: this.assessment.id,
                      filename: fiscalAnnex.name,
                      fileType: fiscalAnnex.fileType,
                    }),
                  );
                }
              }
            }

            if (this.user.fiscalResidency === `ES`) {
              if (this.hasPaid) {
                if (!this.taxableCessions) {
                  const fiscalAnnex: AssessmentFile = this.assessment.files?.find(
                    (file: AssessmentFile) => file.fileType === `FISCAL_ANNEX_AS_JSON`,
                  );

                  if (fiscalAnnex) {
                    this.assessmentStore.dispatch(
                      downloadAssessmentFileAction({
                        assessmentId: this.assessment.id,
                        filename: fiscalAnnex.name,
                        fileType: fiscalAnnex.fileType,
                      }),
                    );
                  }
                }

                if (this.hasPaidModelo) {
                  if (!this.modelo) {
                    const modeloFile: AssessmentFile = this.assessment.files?.find(
                      (file: AssessmentFile) => file.fileType === `MODELO_721`,
                    );

                    if (modeloFile) {
                      this.assessmentStore.dispatch(
                        downloadAssessmentFileAction({
                          assessmentId: this.assessment.id,
                          filename: modeloFile.name,
                          fileType: modeloFile.fileType,
                        }),
                      );
                    }
                  } else {
                    this.getPlatformsAndTokensForModelo(this.modelo, filters);
                  }
                } else {
                  this.getPlatformsAndTokensForModeloDemo();
                }
              } else {
                this.taxableCessions = TAXABLE_CESSIONS_DEMO_ES;
                this.updateTaxableCessionsPage();
                this.getPlatformsAndTokensForModeloDemo();
              }
            }

            this.setUpgradeFishingCTA();
          }
        }),
      )
      .subscribe();

    this.platformSearchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((search: string) => {
          this.selectSearch(search);
        }),
      )
      .subscribe();

    this.modeloPlatformSearchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((search: string) => {
          this.selectModeloSearch(search);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // A bit dirty but it works well
  selectSearch(search: string): void {
    if (search) {
      search = search.toLowerCase();

      const filteredUsedPlatforms: Map<string, Details> = new Map<string, Details>([]);
      const filteredPlatforms: Map<string, Details> = new Map<string, Details>([]);

      if (this.usedPlatforms.size !== 0) {
        this.usedPlatforms.forEach((value: Details, key: string) => {
          const platform = this.accounts.get(key);
          if (platform.name.toLowerCase().startsWith(search)) {
            filteredUsedPlatforms.set(key, value);
          }
        });
      }

      if (this.otherPlatforms.size !== 0) {
        this.otherPlatforms.forEach((value: Details, key: string) => {
          const platform = this.accounts.get(key);
          if (platform.name.toLowerCase().startsWith(search)) {
            filteredPlatforms.set(key, value);
          }
        });
      }

      this.usedPlatformsOptions$.next(filteredUsedPlatforms);
      this.platformsOptions$.next(filteredPlatforms);
    } else {
      this.usedPlatformsOptions$.next(this.usedPlatforms);
      this.platformsOptions$.next(this.otherPlatforms);
    }
  }

  selectModeloSearch(search: string): void {
    if (search) {
      search = search.toLowerCase();

      if (this.modeloPlatformsToDeclare.size !== 0) {
        const filteredUsedPlatforms: Map<string, any> = new Map<string, any>([]);

        this.modeloPlatformsToDeclare.forEach((value: any, key: string) => {
          const platform = this.accounts.get(key);
          if (platform.name.toLowerCase().startsWith(search)) {
            filteredUsedPlatforms.set(key, value);
          }
        });
        this.modeloPlatformsToDeclareOptions$.next(filteredUsedPlatforms);
      }

      if (this.modeloVaspPlatforms.size !== 0) {
        const filteredPlatforms: Map<string, any> = new Map<string, any>([]);

        this.modeloVaspPlatforms.forEach((value: any, key: string) => {
          const platform = this.accounts.get(key);
          if (platform.name.toLowerCase().startsWith(search) && !this.modeloPlatformsToDeclare.has(key)) {
            filteredPlatforms.set(key, value);
          }
        });
        this.modeloVaspPlatformsOptions$.next(filteredPlatforms);
      }
    } else {
      this.modeloPlatformsToDeclareOptions$.next(this.modeloPlatformsToDeclare);
      this.modeloVaspPlatformsOptions$.next(this.modeloVaspPlatforms);
    }
  }

  updateCessionPage(nextPage = true): void {
    if (nextPage) {
      this.cessionsPageIndex += this.maxCessionsPerPage;
    } else {
      this.cessionsPageIndex -= this.maxCessionsPerPage;
    }

    this.displayedCessions = this.taxableCessions?.slice(
      this.cessionsPageIndex,
      this.cessionsPageIndex + this.maxCessionsPerPage,
    );
  }

  upgradeFishing(name = `upgrade-2086-panel`, title = ``, offer?: UserPlan): void {
    this.utilsService.openDialog(UpgradeFishingDialogComponent, `400px`, `auto`, {
      name,
      title,
      checkoutType: `PLAN`,
      offer: offer || this.paymentEstimate.suggestedPlan,
      recommendedPlan: this.paymentEstimate.recommendedPlan,
      updateTitle: false,
    });
  }

  showDemoCessions(): void {
    this.taxableCessions =
      this.assessment?.fiscalAuthority === `FR` ? TAXABLE_CESSIONS_DEMO_FR : TAXABLE_CESSIONS_DEMO_ES;
    this.updateTaxableCessionsPage();
    this.showDemo = true;
  }

  setUpgradeFishingCTA(): void {
    this.upgradeFishingCTA = this.translateService.instant(`SUBSCRIBE_TO_PLAN`, {
      plan: this.translateService.instant(`PLANS.${this.paymentEstimate.suggestedPlan}`),
    });

    this.upgradeFishingCTAModelo = this.translateService.instant(`SUBSCRIBE_TO_PLAN`, {
      plan: this.translateService.instant(`PLANS.PLN500`),
    });
  }

  updateTaxableCessionsPage(): void {
    this.maxCessionsPerPage = this.assessment?.fiscalAuthority === `FR` ? 5 : 15;
    this.taxableCessionsMsg = this.translateService.instant(`TAXABLE_TRANSFERS_COUNT`, {
      count: this.taxableCessions.length,
    });
    this.displayedCessions = this.taxableCessions.slice(0, this.cessionsPageIndex + this.maxCessionsPerPage);
    this.maxCessionsPage = Math.ceil(this.taxableCessions.length / this.maxCessionsPerPage);
  }

  updateSelectedTaxableCession(next = true): void {
    if (next) {
      this.selectedTaxableCessionIndex++;
    } else {
      this.selectedTaxableCessionIndex--;
    }
    this.selectedTaxableCession = this.taxableCessions[this.selectedTaxableCessionIndex];
  }

  getPlatformsAndTokensForModelo(modelo: Modelo, filters: TransactionFilters): void {
    if (modelo && filters) {
      // Tokens
      modelo.platforms.forEach((platform: ModeloPlatform, name: string) => {
        this.modeloTokens.set(name, [...platform.quantities.keys()].sort());
      });

      // Platforms
      const usedPlatforms: string[] = [...modelo.platforms.keys()].sort();
      const vaspPlatforms = modelo.vaspInES.sort();

      // Add used platforms
      for (const platform of usedPlatforms) {
        this.modeloPlatformsToDeclare.set(platform, this.accounts.get(platform)?.details);
      }

      // Add VASP platforms
      for (const platform of vaspPlatforms) {
        this.modeloVaspPlatforms.set(platform, this.accounts.get(platform)?.details);
      }

      if (this.modeloPlatformsToDeclare.size !== 0) {
        this.selectedPlatform = this.modeloPlatformsToDeclare.keys().next().value;
        this.selectedToken = this.modeloTokens.get(this.selectedPlatform)[0];
      } else {
        this.selectedPlatform = this.modeloVaspPlatforms.keys().next().value;
      }

      this.modeloPlatformsToDeclareOptions$.next(this.modeloPlatformsToDeclare);
      this.modeloVaspPlatformsOptions$.next(this.modeloVaspPlatforms);
    }
  }

  getPlatformsAndTokensForModeloDemo(): void {
    this.modelo = {
      referenceDate: 0,
      totalValue: 0,
      platforms: new Map<string, ModeloPlatform>().set(`ASCENDEX`, {
        numberOfTokens: 3,
        value: 0,
        quantities: null,
        prices: null,
        acquisitionPrice: null,
      }),
      vaspInES: [],
      availableCEX: [],
    };
    // Tokens
    this.modeloTokens.set(`ASCENDEX`, [`BTC`, `ETH`, `USDC`]);

    // Platforms
    const usedPlatforms: string[] = [`ASCENDEX`];
    const vaspPlatforms: string[] = [`BINANCE`];

    // Add used platforms
    for (const platform of usedPlatforms) {
      this.modeloPlatformsToDeclare.set(platform, this.accounts.get(platform)?.details);
    }

    // Add VASP platforms
    for (const platform of vaspPlatforms) {
      this.modeloVaspPlatforms.set(platform, this.accounts.get(platform)?.details);
    }

    if (this.modeloPlatformsToDeclare.size !== 0) {
      this.selectedPlatform = this.modeloPlatformsToDeclare.keys().next().value;
      this.selectedToken = this.modeloTokens.get(this.selectedPlatform)[0];
    } else {
      this.selectedPlatform = this.modeloVaspPlatforms.keys().next().value;
    }

    this.modeloPlatformsToDeclareOptions$.next(this.modeloPlatformsToDeclare);
    this.modeloVaspPlatformsOptions$.next(this.modeloVaspPlatforms);
  }

  getPlatformsAndTokensForTax(filters: TransactionFilters): void {
    // Add other platforms
    for (const platform of TAX_FORM_PLATFORMS) {
      this.otherPlatforms.set(platform, this.accounts.get(platform)?.details);
    }

    if (filters) {
      // Tokens
      this.tokens = [...new Set([...filters.fromCurrency, ...filters.toCurrency])];
      this.tokens.sort();
      this.selectedToken = this.tokens[0];

      // Add used platforms
      for (const platform of filters.platform) {
        if (TAX_FORM_PLATFORMS.includes(platform)) {
          this.usedPlatforms.set(platform, this.accounts.get(platform)?.details);
        }

        this.otherPlatforms.delete(platform);
      }

      if (this.usedPlatforms.size !== 0) {
        this.selectedPlatform = this.usedPlatforms.keys().next().value;
      } else {
        this.selectedPlatform = `ASCENDEX`;
      }

      this.usedPlatformsOptions$.next(this.usedPlatforms);
    } else {
      this.selectedPlatform = `ASCENDEX`;
    }

    this.platformsOptions$.next(this.otherPlatforms);
  }

  orderByName = (chartIndexA: KeyValue<string, Details>, chartIndexB: KeyValue<string, Details>): number => {
    const nameA = this.accounts.get(chartIndexA.key)?.name || ``;
    const nameB = this.accounts.get(chartIndexB.key)?.name || ``;
    return nameA.localeCompare(nameB);
  };
}
