import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFocusDirective } from '../../../shared/directives/custom-focus.directive';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { VoucherUsage } from '../../models/payment.model';

@Component({
  selector: `app-voucher`,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatTooltipModule, TranslateModule, CustomFocusDirective, MatInputModule],
  templateUrl: `./voucher.component.html`,
  styleUrls: [`./voucher.component.scss`]
})
export class VoucherComponent {
  @Input() isEnabled: boolean;

  @Input() appliedVouchers: VoucherUsage[];


  @Input() set voucherResponse(voucherResponse: GenericResponse) {
    this._voucherResponse = voucherResponse;

    if (voucherResponse?.success) {
      this.voucherControl.disable();
    }
  }

  get voucherResponse(): GenericResponse {
    return this._voucherResponse;
  }

  @Output() addVoucher: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeVoucher: EventEmitter<void> = new EventEmitter<void>();

  isActive = false;
  voucherControl: FormControl;

  private _voucherResponse: GenericResponse;

  constructor(
    private readonly fb: FormBuilder
  ) {
    this.voucherControl = this.fb.control(``);
  }

  toggle(): void {
    this.isActive = !this.isActive;
  }

  deleteVoucher(): void {
    this.voucherControl.enable();
    this.voucherControl.setValue(``);
    this.isActive = false;
    this.removeVoucher.emit();
  }
}
