<div
  class="card"
  [ngClass]="{
    'simple-card': !alternative,
    'alternative-card': alternative,
    'simple-card--selected': !alternative && selected,
    'alternative-card--selected': alternative && selected,
  }"
>
  <img
    width="50"
    height="50"
    [ngSrc]="'https://icons.waltio.com/account/' + account?.key"
    [alt]="account?.key"
    priority
  />
  <p class="body-14-med">{{ account?.name }}</p>
</div>
