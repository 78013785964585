/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { ReportedToken } from '../../../shared/models/reported-token.model';
import { Scam } from '../../../shared/models/scam.model';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { LiveBalanceResponse, LiveBalanceResponseItem } from '../../models/live-balance.model';
import { ChartHistory, MarketFeed } from '../../models/market.model';
import { Ticker, TickerFeed, TickerPair } from '../../models/ticker.model';
import { BalanceService } from '../../services/balance.service';
import { FeedService } from '../../services/feed.service';
import { InsightService } from '../../services/insight.service';
import { MarketService } from '../../services/market.service';
import {
  computePortfolioValueAction,
  loadChartHistoryAction,
  loadLiveBalancesAction,
  setChartHistoryAction,
  setLiveBalancesAction,
  setMarketFeedAction,
  setPortfolioValueAction,
  setTickerFeedAction,
  startMarketFeedStreamAction,
  startTickerFeedStreamAction,
  stopMarketFeedStreamAction,
  stopTickerFeedStreamAction,
} from '../actions/insight.action';
import * as fromInsight from '../selectors/insight.selector';

@Injectable()
export class InsightEffects {
  startMarketFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof startMarketFeedStreamAction>>(startMarketFeedStreamAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectAccessToken))),
        switchMap(([action, accessToken]: [ReturnType<typeof startMarketFeedStreamAction>, string]) => {
          const eventSource: EventSource = this.feedService.startMarketFeed(accessToken);

          eventSource.onmessage = (message: MessageEvent): void => {
            const marketFeed: MarketFeed = JSON.parse(message.data);

            this.insightStore$.dispatch(setMarketFeedAction({ marketFeed }));
          };

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  stopMarketFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof stopMarketFeedStreamAction>>(stopMarketFeedStreamAction),
        switchMap(() => {
          this.feedService.stopMarketFeed();

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  startTickerFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof startTickerFeedStreamAction>>(startTickerFeedStreamAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectAccessToken))),
        switchMap(([action, accessToken]: [ReturnType<typeof startTickerFeedStreamAction>, string]) => {
          const eventSource: EventSource = this.feedService.startTickerFeed(accessToken);

          eventSource.onmessage = (message: MessageEvent): void => {
            const tickerFeed: TickerFeed = JSON.parse(message.data);

            tickerFeed.pairs = new Map<string, TickerPair>(Object.entries(tickerFeed.pairs));

            tickerFeed.pairs.forEach((pair: TickerPair) => {
              pair.tickers = new Map<string, Ticker>(Object.entries(pair.tickers));
            });

            this.insightStore$.dispatch(setTickerFeedAction({ tickerFeed }));
          };

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  stopTickerFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof stopTickerFeedStreamAction>>(stopTickerFeedStreamAction),
        switchMap(() => {
          this.feedService.stopTickerFeed();

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  loadPortfolioChartHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadChartHistoryAction>>(loadChartHistoryAction),
      switchMap(() => {
        return this.insightService
          .getPortfolioChartHistory()
          .pipe(map((chartHistory: ChartHistory) => setChartHistoryAction({ chartHistory })));
      })
    )
  );

  loadLiveBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadLiveBalancesAction>>(loadLiveBalancesAction),
      switchMap((action: ReturnType<typeof loadLiveBalancesAction>) => {
        return this.balanceService.getLiveBalances(action.force).pipe(
          switchMap((liveBalances: LiveBalanceResponse) => {
            return [setLiveBalancesAction({ liveBalances })];
          })
        );
      })
    )
  );

  computePortfolioValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof computePortfolioValueAction>>(computePortfolioValueAction),
      withLatestFrom(
        this.insightStore$.pipe(select(fromInsight.selectLiveBalances)),
        this.insightStore$.pipe(select(fromInsight.selectMarketFeed)),
        this.insightStore$.pipe(select(fromInsight.selectChartHistory)),
        this.sharedStore$.pipe(select(fromShared.selectUserScamList)),
        this.sharedStore$.pipe(select(fromShared.selectUserReportedTokenList))
      ),
      map(
        ([action, liveBalances, marketFeed, chartHistory, userScamList, userReportedTokenList]: [
          ReturnType<typeof computePortfolioValueAction>,
          LiveBalanceResponse,
          MarketFeed,
          ChartHistory,
          Scam[],
          ReportedToken[],
        ]) => {
          const portfolioValue = liveBalances.items
            .filter(
              (balance: LiveBalanceResponseItem) =>
                !this.marketService.isScam(balance, userScamList, userReportedTokenList)
            )
            .map((balance: LiveBalanceResponseItem) => {
              const currentPrice = this.marketService.getTokenPrice(
                marketFeed.marketFeed,
                balance.token,
                action.currency || `EUR`
              );
              const historyPrice = chartHistory.lastPriceByToken.get(balance.token);

              const price = currentPrice || historyPrice || 0;

              const amount = price * balance.quantity;

              return amount;
            })
            .reduce((acc: number, amount: number) => {
              return acc + amount;
            }, 0);

          return setPortfolioValueAction({ portfolioValue });
        }
      )
    )
  );

  constructor(
    private readonly insightStore$: Store<fromInsight.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly actions$: Actions,
    private readonly feedService: FeedService,
    private readonly insightService: InsightService,
    private readonly balanceService: BalanceService,
    private readonly marketService: MarketService
  ) {}
}
