<div class="toast">
  @switch (data.type) {
    @case ('success') {
      <img src="/assets/img/icons/success.svg" alt="" />
    }

    @case ('error') {
      <img src="/assets/img/icons/error.svg" alt="" />
    }

    @case ('loading') {
      <img class="rotating" src="/assets/img/icons/loading-toast.svg" alt="" />
    }
  }

  <p class="body-14">{{ data.message }}</p>

  @if (data.type !== 'loading') {
    <img class="pointer" src="/assets/img/icons/close-white.svg" alt="" (click)="snackBarRef.closeWithAction()" />
  }
</div>
