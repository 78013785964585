/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { ReportedToken } from '../../../shared/models/reported-token.model';
import { Scam } from '../../../shared/models/scam.model';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { LiveBalanceResponse, LiveBalanceResponseItem } from '../../models/live-balance.model';
import { ManualStock } from '../../models/manual-stock.model';
import { ChartHistory, MarketFeed } from '../../models/market.model';
import { Ticker, TickerFeed, TickerPair } from '../../models/ticker.model';
import { BalanceService } from '../../services/balance.service';
import { FeedService } from '../../services/feed.service';
import { InsightService } from '../../services/insight.service';
import { ManualStockService } from '../../services/manual-stock.service';
import { MarketService } from '../../services/market.service';
import {
  addManualStockAction,
  computePortfolioValueAction,
  deleteAcquisitionPriceAction,
  deleteManualStockAction,
  getTokenToggleStatusAction,
  getTokensToggleStatusAction,
  loadAcquisitionPricesAction,
  loadChartHistoryAction,
  loadLiveBalancesAction,
  loadManualStocksAction,
  setAcquisitionPricesAction,
  setChartHistoryAction,
  setLiveBalancesAction,
  setManualStocksAction,
  setMarketFeedAction,
  setPortfolioValueAction,
  setTickerFeedAction,
  startMarketFeedStreamAction,
  startTickerFeedStreamAction,
  stopMarketFeedStreamAction,
  stopTickerFeedStreamAction,
  updateAcquisitionPriceAction,
  updateManualStockAction,
  updateTokenToggleStatusAction,
  updateTokensToggleStatusAction,
} from '../actions/insight.action';
import * as fromInsight from '../selectors/insight.selector';
import { AcquisitionPriceService } from '../../services/acquisition-price.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../shared/services/toast.service';
import { BulkTokenToggleResponse, TokenToggleResponse } from '../../models/insight.model';

@Injectable()
export class InsightEffects {
  startMarketFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof startMarketFeedStreamAction>>(startMarketFeedStreamAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectAccessToken))),
        switchMap(([action, accessToken]: [ReturnType<typeof startMarketFeedStreamAction>, string]) => {
          const eventSource: EventSource = this.feedService.startMarketFeed(accessToken);

          eventSource.onmessage = (message: MessageEvent): void => {
            const marketFeed: MarketFeed = JSON.parse(message.data);

            this.insightStore$.dispatch(setMarketFeedAction({ marketFeed }));
          };

          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  stopMarketFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof stopMarketFeedStreamAction>>(stopMarketFeedStreamAction),
        switchMap(() => {
          this.feedService.stopMarketFeed();

          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  startTickerFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof startTickerFeedStreamAction>>(startTickerFeedStreamAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectAccessToken))),
        switchMap(([action, accessToken]: [ReturnType<typeof startTickerFeedStreamAction>, string]) => {
          const eventSource: EventSource = this.feedService.startTickerFeed(accessToken);

          eventSource.onmessage = (message: MessageEvent): void => {
            const tickerFeed: TickerFeed = JSON.parse(message.data);

            tickerFeed.pairs = new Map<string, TickerPair>(Object.entries(tickerFeed.pairs));

            tickerFeed.pairs.forEach((pair: TickerPair) => {
              pair.tickers = new Map<string, Ticker>(Object.entries(pair.tickers));
            });

            this.insightStore$.dispatch(setTickerFeedAction({ tickerFeed }));
          };

          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  stopTickerFeedStream$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof stopTickerFeedStreamAction>>(stopTickerFeedStreamAction),
        switchMap(() => {
          this.feedService.stopTickerFeed();

          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  loadPortfolioChartHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadChartHistoryAction>>(loadChartHistoryAction),
      switchMap(() => {
        return this.insightService
          .getPortfolioChartHistoryV2()
          .pipe(map((chartHistory: ChartHistory) => setChartHistoryAction({ chartHistory })));
      }),
    ),
  );

  loadLiveBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadLiveBalancesAction>>(loadLiveBalancesAction),
      switchMap((action: ReturnType<typeof loadLiveBalancesAction>) => {
        return this.balanceService.getLiveBalancesV2(action.force).pipe(
          switchMap((liveBalances: LiveBalanceResponse) => {
            return [setLiveBalancesAction({ liveBalances, force: action.force })];
          }),
        );
      }),
    ),
  );

  computePortfolioValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof computePortfolioValueAction>>(computePortfolioValueAction),
      withLatestFrom(
        this.insightStore$.pipe(select(fromInsight.selectLiveBalances)),
        this.insightStore$.pipe(select(fromInsight.selectMarketFeed)),
        this.insightStore$.pipe(select(fromInsight.selectChartHistory)),
        this.sharedStore$.pipe(select(fromShared.selectUserScamList)),
        this.sharedStore$.pipe(select(fromShared.selectUserReportedTokenList)),
      ),
      map(
        ([action, liveBalances, marketFeed, chartHistory, userScamList, userReportedTokenList]: [
          ReturnType<typeof computePortfolioValueAction>,
          LiveBalanceResponse,
          MarketFeed,
          ChartHistory,
          Scam[],
          ReportedToken[],
        ]) => {
          const balancesValue = liveBalances.items
            .filter(
              (balance: LiveBalanceResponseItem) =>
                !this.marketService.isScam(balance, userScamList, userReportedTokenList) && balance.toggled,
            )
            .map((balance: LiveBalanceResponseItem) => {
              const currentPrice = this.marketService.getTokenPrice(
                marketFeed.marketFeed,
                balance.token,
                action.currency || `EUR`,
              );
              const historyPrice = chartHistory?.lastPriceByToken?.get(balance.token) || 0;
              const price = currentPrice || historyPrice || 0;

              const quantity = balance.quantity > 0 ? balance.quantity : 0;

              const amount = price * quantity;

              return amount;
            })
            .reduce((acc: number, amount: number) => {
              return acc + amount;
            }, 0);

          // Manual stocks
          const manualStocksValue = liveBalances.manualStock
            .map((manualStock: ManualStock) => {
              const currentPrice = this.marketService.getTokenPrice(
                marketFeed.marketFeed,
                manualStock.currency,
                action.currency || `EUR`,
              );
              const historyPrice = chartHistory?.lastPriceByToken?.get(manualStock.currency) || 0;
              const price = currentPrice || historyPrice || 0;

              const quantity = manualStock.amount > 0 ? manualStock.amount : 0;

              const amount = price * quantity;

              return amount;
            })
            .reduce((acc: number, amount: number) => {
              return acc + amount;
            }, 0);

          const portfolioValue = balancesValue + manualStocksValue;

          return setPortfolioValueAction({ portfolioValue });
        },
      ),
    ),
  );

  loadManualStocks$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadManualStocksAction>>(loadManualStocksAction),
      switchMap(() => {
        return this.manualStockService
          .getManualStocks()
          .pipe(map((manualStocks: ManualStock[]) => setManualStocksAction({ manualStocks })));
      }),
    ),
  );

  addManualStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof addManualStockAction>>(addManualStockAction),
      switchMap((action: ReturnType<typeof addManualStockAction>) => {
        return this.manualStockService.addManualStock(action.manualStock).pipe(
          switchMap((liveBalances: LiveBalanceResponse) => {
            const message = this.translateService.instant(`TOKEN_ADDED_PORTFOLIO`);
            this.toastService.success(message);

            liveBalances.acquisitionPrices = new Map<string, number>(Object.entries(liveBalances.acquisitionPrices));

            return [setLiveBalancesAction({ liveBalances }), loadChartHistoryAction()];
          }),
        );
      }),
    ),
  );

  updateManualStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateManualStockAction>>(updateManualStockAction),
      switchMap((action: ReturnType<typeof updateManualStockAction>) => {
        return this.manualStockService.updateManualStock(action.manualStock).pipe(
          switchMap((liveBalances: LiveBalanceResponse) => {
            const message = this.translateService.instant(`TOKEN_UPDATED`);
            this.toastService.success(message);

            liveBalances.acquisitionPrices = new Map<string, number>(Object.entries(liveBalances.acquisitionPrices));

            return [setLiveBalancesAction({ liveBalances }), loadChartHistoryAction()];
          }),
        );
      }),
    ),
  );

  deleteManualStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteManualStockAction>>(deleteManualStockAction),
      switchMap((action: ReturnType<typeof deleteManualStockAction>) => {
        return this.manualStockService.deleteManualStock(action.manualStockId).pipe(
          switchMap((liveBalances: LiveBalanceResponse) => {
            const message = this.translateService.instant(`TOKEN_DELETED`);
            this.toastService.success(message);

            liveBalances.acquisitionPrices = new Map<string, number>(Object.entries(liveBalances.acquisitionPrices));

            return [setLiveBalancesAction({ liveBalances }), loadChartHistoryAction()];
          }),
        );
      }),
    ),
  );

  loadAcquisitionPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAcquisitionPricesAction>>(loadAcquisitionPricesAction),
      switchMap(() => {
        return this.acquisitionPriceService
          .getAcquisitionPrices()
          .pipe(map((acquisitionPrices: Map<string, any>) => setAcquisitionPricesAction({ acquisitionPrices })));
      }),
    ),
  );

  updateAcquisitionPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateAcquisitionPriceAction>>(updateAcquisitionPriceAction),
      withLatestFrom(this.insightStore$.pipe(select(fromInsight.selectLiveBalances))),
      switchMap(
        ([action, currentLiveBalances]: [ReturnType<typeof updateAcquisitionPriceAction>, LiveBalanceResponse]) => {
          return this.acquisitionPriceService.updateAcquisitionPrice(action.token, action.price).pipe(
            map((updatedLiveBalances: LiveBalanceResponse) => {
              let message = ``;
              if (action.isUpdate) {
                message = this.translateService.instant(`AVERAGE_PURCHASE_PRICE_UPDATED`);
              } else {
                message = this.translateService.instant(`AVERAGE_PURCHASE_PRICE_ADDED`);
              }

              this.toastService.success(message);

              updatedLiveBalances.acquisitionPrices = new Map<string, number>(
                Object.entries(updatedLiveBalances.acquisitionPrices),
              );

              const liveBalances: LiveBalanceResponse = {
                accounts: [...currentLiveBalances.accounts, ...updatedLiveBalances.accounts],
                manualStock: [...currentLiveBalances.manualStock, ...updatedLiveBalances.manualStock],
                items: [...currentLiveBalances.items, ...updatedLiveBalances.items],
                lastUpdate: currentLiveBalances.lastUpdate,
                retryIn: updatedLiveBalances.retryIn,
                acquisitionPrices: updatedLiveBalances.acquisitionPrices,
              };

              return setLiveBalancesAction({ liveBalances });
            }),
          );
        },
      ),
    ),
  );

  deleteAcquisitionPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteAcquisitionPriceAction>>(deleteAcquisitionPriceAction),
      withLatestFrom(this.insightStore$.pipe(select(fromInsight.selectLiveBalances))),
      switchMap(
        ([action, currentLiveBalances]: [ReturnType<typeof deleteAcquisitionPriceAction>, LiveBalanceResponse]) => {
          return this.acquisitionPriceService.deleteAcquisitionPrice(action.token).pipe(
            map((updatedLiveBalances: LiveBalanceResponse) => {
              const message = this.translateService.instant(`AVERAGE_PURCHASE_PRICE_RESET`);
              this.toastService.success(message);

              updatedLiveBalances.acquisitionPrices = new Map<string, number>(
                Object.entries(updatedLiveBalances.acquisitionPrices),
              );

              const liveBalances: LiveBalanceResponse = {
                accounts: [...currentLiveBalances.accounts, ...updatedLiveBalances.accounts],
                manualStock: [...currentLiveBalances.manualStock, ...updatedLiveBalances.manualStock],
                items: [...currentLiveBalances.items, ...updatedLiveBalances.items],
                lastUpdate: currentLiveBalances.lastUpdate,
                retryIn: updatedLiveBalances.retryIn,
                acquisitionPrices: updatedLiveBalances.acquisitionPrices,
              };

              return setLiveBalancesAction({ liveBalances });
            }),
          );
        },
      ),
    ),
  );

  getTokenToggleStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof getTokenToggleStatusAction>>(getTokenToggleStatusAction),
        switchMap((action: ReturnType<typeof getTokenToggleStatusAction>) => {
          return this.insightService.getTokenToggleStatus(action.platform, action.token).pipe(
            map((tokenToggleStatus: TokenToggleResponse) => {
              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  getTokensToggleStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof getTokensToggleStatusAction>>(getTokensToggleStatusAction),
        switchMap((action: ReturnType<typeof getTokensToggleStatusAction>) => {
          return this.insightService.getTokensToggleStatus(action.requests).pipe(
            map((tokenToggleStatuses: TokenToggleResponse[]) => {
              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  updateTokenToggleStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateTokenToggleStatusAction>>(updateTokenToggleStatusAction),
      switchMap((action: ReturnType<typeof updateTokenToggleStatusAction>) => {
        return this.insightService.updateTokenToggleStatus(action.platform, action.token, action.isToggled).pipe(
          switchMap((liveBalances: LiveBalanceResponse) => {
            const message = action.isToggled
              ? this.translateService.instant(`TOKEN_REACTIVATED`)
              : this.translateService.instant(`TOKEN_DEACTIVATED`);
            this.toastService.success(message);

            liveBalances.acquisitionPrices = new Map<string, number>(Object.entries(liveBalances.acquisitionPrices));

            return [loadChartHistoryAction(), setLiveBalancesAction({ liveBalances })];
          }),
        );
      }),
    ),
  );

  updateTokensToggleStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateTokensToggleStatusAction>>(updateTokensToggleStatusAction),
      switchMap((action: ReturnType<typeof updateTokensToggleStatusAction>) => {
        return this.insightService.updateTokensToggleStatus(action.requests).pipe(
          switchMap((bulkTokenToggleResponse: BulkTokenToggleResponse) => {
            const message = action.isToggled
              ? this.translateService.instant(`TOKENS_REACTIVATED`)
              : this.translateService.instant(`TOKENS_DEACTIVATED`);
            this.toastService.success(message);

            bulkTokenToggleResponse.liveBalance.acquisitionPrices = new Map<string, number>(
              Object.entries(bulkTokenToggleResponse.liveBalance.acquisitionPrices),
            );

            return [
              loadChartHistoryAction(),
              setLiveBalancesAction({ liveBalances: bulkTokenToggleResponse.liveBalance }),
            ];
          }),
        );
      }),
    ),
  );

  constructor(
    private readonly insightStore$: Store<fromInsight.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly actions$: Actions,
    private readonly feedService: FeedService,
    private readonly insightService: InsightService,
    private readonly balanceService: BalanceService,
    private readonly marketService: MarketService,
    private readonly manualStockService: ManualStockService,
    private readonly acquisitionPriceService: AcquisitionPriceService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
  ) {}
}
