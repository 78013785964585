<div class="container">
  <section class="header">
    <h2>Bienvenue sur Waltio</h2>
  </section>
  <mat-divider></mat-divider>
  <section class="main">
    <p class="body-14">Suivez en temps réel vos cryptos et générez toujours votre rapport fiscal automatiquement.</p>

    <div class="features">
      <div class="feature">
        <div class="feature__header">
          <img src="/assets/img/icons/sliders.svg" alt="sliders" />
          <div class="beta">BETA</div>
          <p class="body-16-bold">Portefeuille en temps réel</p>
        </div>

        <img class="border-6" src="/assets/img/icons/welcome-1.svg" alt="welcome-1" />
      </div>
      <div class="feature">
        <div class="feature__header">
          <img src="/assets/img/icons/file-v2.svg" alt="file-v2" />
          <p class="body-16-bold">Rapport fiscal</p>
        </div>

        <img class="border-6" src="/assets/img/icons/welcome-2.svg" alt="welcome-2" />
      </div>
    </div>

    <button type="button" mat-flat-button color="primary" mat-dialog-close>Continuer sur Waltio</button>
  </section>
</div>
