<div class="modelo-721">
  <section class="modelo-721__header">
    <p class="body-14">{{ 'TAX_RETURN_PREVIEW' | translate }}</p>
  </section>
  <mat-divider></mat-divider>
  <section class="modelo-721__content">
    @if (firstSection) {
      <div class="modelo-721__content__item">
        <div class="form-items">
          <div class="f-c-10 f-1">
            <p class="body-14">Ejercicio</p>

            <app-tax-form-input
              class="min-width max-width"
              [value]="'CHOOSE_RIGHT_OPTION' | translate"
              [primary]="true"
            ></app-tax-form-input>
          </div>
        </div>

        <div class="form-items">
          <div class="f-c-10 f-1">
            <p class="body-14">ID Registro de Detalle</p>

            <app-tax-form-input
              class="min-width max-width"
              [value]="'YOUR_NIF_ID' | translate"
              [primary]="true"
            ></app-tax-form-input>
          </div>

          <div class="f-c-10 f-1">
            <p class="body-14">Condición del declarante</p>

            <app-tax-form-input
              class="min-width max-width"
              [value]="'Titular'"
              [selectable]="true"
            ></app-tax-form-input>
          </div>
        </div>
      </div>
      <div class="modelo-721__content__item">
        <p class="body-14-bold">Datos Declarante</p>

        <div class="form-items">
          <div class="f-c-10 f-1">
            <p class="body-14">NIF Declarante</p>

            <app-tax-form-input
              class="min-width max-width"
              [value]="'YOUR_NIF' | translate"
              [primary]="true"
            ></app-tax-form-input>
          </div>
          <div class="f-c-10 f-1">
            <p class="body-14">Nombre o Razón Social</p>

            <app-tax-form-input
              class="min-width max-width"
              [value]="'YOUR_NAME' | translate"
              [primary]="true"
            ></app-tax-form-input>
          </div>
        </div>

        <div class="form-items">
          <div class="f-c-10 f-1">
            <p class="body-14">Teléfono</p>

            <app-tax-form-input
              class="min-width max-width"
              [value]="'OPTIONNAL' | translate"
              [disabled]="true"
            ></app-tax-form-input>
          </div>
          <div class="f-c-10 f-1">
            <p class="body-14">Apellidos y Nombre</p>

            <app-tax-form-input
              class="min-width max-width"
              [value]="'OPTIONNAL' | translate"
              [disabled]="true"
            ></app-tax-form-input>
          </div>
        </div>
      </div>
    } @else {
      <div class="modelo-721__content__item">
        <p class="body-14-bold">Datos Persona Entidad Salvaguarda</p>

        <div class="f-c-10">
          <p class="body-14">Nombre o Razón Social</p>

          <app-tax-form-input
            class="min-width"
            [value]="taxFormPlatforms.get(platform)?.designation"
            [copiable]="true"
          ></app-tax-form-input>
        </div>
      </div>
      <div class="modelo-721__content__item">
        <p class="body-14-bold">Tipo de identificacion</p>

        <mat-radio-group class="custom-radio-group">
          <mat-radio-button class="custom-radio-button-tax-form" [value]="'NIF'" disableRipple disabled>
            <p class="body-14">NIF</p>
          </mat-radio-button>
          <mat-radio-button class="custom-radio-button-tax-form" [value]="'Otro'" disableRipple checked>
            <p class="body-14">Otro</p>
          </mat-radio-button>
        </mat-radio-group>

        <div class="form-items">
          <div class="f-c-10">
            <p class="body-14">Código País</p>

            <app-tax-form-input
              class="min-width"
              [value]="demo ? ('PROVIDED_BY_WALTIO' | translate) : taxFormPlatforms.get(platform)?.country"
              [selectable]="true"
            ></app-tax-form-input>
          </div>

          <div class="f-c-10">
            <p class="body-14">Typo de Identificación</p>

            <app-tax-form-input
              class="min-width"
              [value]="'Otro Documento Probatorio'"
              [selectable]="true"
            ></app-tax-form-input>
          </div>

          <div class="f-c-10">
            <p class="body-14">Identificator</p>

            <app-tax-form-input
              class="min-width"
              [value]="'PLATFORM_NIF_ID' | translate"
              [primary]="true"
            ></app-tax-form-input>
          </div>
        </div>
      </div>
      <div class="modelo-721__content__item">
        <p class="body-14-bold">Domicilio Entidad Salvaguarda</p>

        <div class="form-items">
          <div class="f-c-10">
            <p class="body-14">Vía</p>

            <app-tax-form-input
              class="min-width"
              [value]="demo ? ('PROVIDED_BY_WALTIO' | translate) : taxFormPlatforms.get(platform)?.street"
              [copiable]="true"
            ></app-tax-form-input>
          </div>

          <div class="f-c-10">
            <p class="body-14">Numeración</p>

            <app-tax-form-input
              class="min-width"
              [value]="demo ? ('PROVIDED_BY_WALTIO' | translate) : ''"
              [copiable]="true"
            ></app-tax-form-input>
          </div>

          <div class="f-c-10">
            <p class="body-14">Complemento</p>

            <app-tax-form-input
              class="min-width"
              [value]="demo ? ('PROVIDED_BY_WALTIO' | translate) : taxFormPlatforms.get(platform)?.additionalAddress"
              [copiable]="true"
            ></app-tax-form-input>
          </div>
        </div>

        <div class="form-items">
          <div class="f-c-10">
            <p class="body-14">Municipio</p>

            <app-tax-form-input
              class="min-width"
              [value]="demo ? ('PROVIDED_BY_WALTIO' | translate) : ''"
              [copiable]="true"
            ></app-tax-form-input>
          </div>

          <div class="f-c-10">
            <p class="body-14">Código Postal</p>

            <app-tax-form-input
              class="min-width"
              [value]="demo ? ('PROVIDED_BY_WALTIO' | translate) : taxFormPlatforms.get(platform)?.zipCode"
              [copiable]="true"
            ></app-tax-form-input>
          </div>

          <div class="f-c-10">
            <p class="body-14">Código País</p>

            <app-tax-form-input
              class="min-width"
              [value]="demo ? ('PROVIDED_BY_WALTIO' | translate) : taxFormPlatforms.get(platform)?.country"
              [copiable]="true"
            ></app-tax-form-input>
          </div>
        </div>
        <div class="form-items">
          <div class="f-c-10">
            <p class="body-14">Dirección del sitio web</p>

            <app-tax-form-input
              class="min-width"
              [value]="demo ? ('PROVIDED_BY_WALTIO' | translate) : taxFormPlatforms.get(platform)?.url"
              [copiable]="true"
            ></app-tax-form-input>
          </div>
        </div>
      </div>
      <div class="modelo-721__content__item">
        <p class="body-14-bold">Datos Monedas</p>
        <div class="form-items">
          <div class="f-c-10">
            <div class="f-row">
              <p class="body-14">Tipo Moneda Virtual</p>
              <div class="info-tooltip" [matTooltip]="'TIPO_MONEDA_VIRTUAL' | translate"></div>
            </div>

            <app-tax-form-input
              class="min-width"
              [value]="demo ? ('PROVIDED_BY_WALTIO' | translate) : coins?.get(token)"
              [copiable]="true"
            ></app-tax-form-input>
          </div>
          <div class="f-c-10">
            <div class="f-row">
              <p class="body-14">Siglas Moneda</p>
              <div class="info-tooltip" [matTooltip]="'SIGLAS_MONEDA' | translate"></div>
            </div>

            <app-tax-form-input
              class="min-width"
              [value]="demo ? ('PROVIDED_BY_WALTIO' | translate) : token"
              [copiable]="true"
            ></app-tax-form-input>
          </div>
        </div>
        <div class="form-items">
          <div class="f-c-10">
            <div class="f-row">
              <p class="body-14">Número de monedas virtuales</p>
              <div class="info-tooltip" [matTooltip]="'NUMERO_DE_MONEDAS_VIRTUALES' | translate"></div>
            </div>

            <app-tax-form-input
              class="min-width"
              [value]="
                demo ? ('PROVIDED_BY_WALTIO' | translate) : modelo?.platforms.get(platform)?.quantities?.get(token)
              "
              [copiable]="true"
            ></app-tax-form-input>
          </div>
          <div class="f-c-10">
            <div class="f-row">
              <p class="body-14">Valor Moneda Virtual</p>
              <div class="info-tooltip" [matTooltip]="'VALOR_MONEDA_VIRTUAL' | translate"></div>
            </div>

            <app-tax-form-input
              class="min-width"
              [value]="
                demo
                  ? ('PROVIDED_BY_WALTIO' | translate)
                  : (modelo?.platforms.get(platform)?.prices?.get(token) | currency: 'EUR' : 'symbol' : '.0-0' : 'fr')
              "
              [copiable]="true"
            ></app-tax-form-input>
          </div>
          <div class="f-c-10">
            <div class="f-row">
              <p class="body-14">Saldo Moneda Virtual</p>
              <div class="info-tooltip" [matTooltip]="'SALDO_MONEDA_VIRTUAL' | translate"></div>
            </div>

            <app-tax-form-input
              class="min-width"
              [value]="
                demo
                  ? ('PROVIDED_BY_WALTIO' | translate)
                  : (modelo?.platforms.get(platform)?.quantities?.get(token) *
                      modelo?.platforms.get(platform)?.prices?.get(token) | currency: 'EUR' : 'symbol' : '.0-0' : 'fr')
              "
              [copiable]="true"
            ></app-tax-form-input>
          </div>
        </div>
        <div class="form-items">
          <div class="f-c-10">
            <div class="f-row">
              <p class="body-14">Origen Valor/Cotización</p>
              <div class="info-tooltip" [matTooltip]="'ORIGEN_VALOR' | translate"></div>
            </div>

            <app-tax-form-input
              class="min-width"
              [value]="
                demo
                  ? ('PROVIDED_BY_WALTIO' | translate)
                  : (modelo?.platforms.get(platform)?.acquisitionPrice?.get(token)
                    | currency: 'EUR' : 'symbol' : '.0-0' : 'fr')
              "
              [copiable]="true"
            ></app-tax-form-input>
          </div>
          <div class="f-c-10">
            <div class="f-row">
              <p class="body-14">Origen Moneda Virtual</p>
              <div class="info-tooltip" [matTooltip]="'ORIGEN_MONEDA_VIRTUAL' | translate"></div>
            </div>

            <app-tax-form-input
              class="min-width"
              [value]="'CHOOSE_ORIGIN' | translate"
              [selectable]="true"
            ></app-tax-form-input>
          </div>
          <div class="f-c-10">
            <div class="f-row">
              <p class="body-14">Fecha Fin Condición</p>
              <div class="info-tooltip" [matTooltip]="'FECHA_FIN_CONDICION' | translate"></div>
            </div>

            <app-tax-form-input
              class="min-width"
              [value]="'OPTIONNAL' | translate"
              [disabled]="true"
            ></app-tax-form-input>
          </div>
        </div>
      </div>
    }
  </section>
</div>
