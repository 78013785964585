/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { AuthService, User as AuthUser, LogoutOptions } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CustomError } from '../../../shared/models/error.model';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { Page } from '../../../shared/models/page.model';
import { IntercomService } from '../../../shared/services/intercom.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserService } from '../../../shared/services/user.service';
import { goToAction, pingOutUserAction } from '../../../shared/store/actions/shared.action';
import { AuthUserEvent } from '../../../taxation/models/auth-user-event.model';
import { User } from '../../../taxation/models/user.model';
import {
  unsyncAssessmentAction,
  updateCurrentFiscalYearAction,
} from '../../../taxation/store/actions/assessment.action';
import { TwoFACheck, UserTwoFAConfiguration } from '../../models/two-fa.model';
import { TwoFaService } from '../../services/two-fa.service';
import {
  deleteUserAction,
  disable2FaAction,
  enable2FaAction,
  load2FaConfigAction,
  loadAccessTokenAction,
  loadAuthUserAction,
  loadAuthUserEventsAction,
  loadIsAuthenticatedAction,
  loadUserAction,
  resetUserAction,
  saveUserAction,
  set2FaConfigAction,
  setAccessTokenAction,
  setAuthUserAction,
  setAuthUserEventsAction,
  setIsAuthenticatedAction,
  setTwoFaAction,
  setUserAction,
  setup2FaAction,
  switchUserAssociatedFiscalYearAction,
  validate2FaCodeAction,
  validate2FaRecoveryCodeAction,
} from '../actions/authentication.action';
import * as fromAuthentication from '../selectors/authentication.selector';
import { logoutAction } from './../actions/authentication.action';
import { TwoFaDialogComponent } from '../../components/dialogs/two-fa-dialog/two-fa-dialog.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { TrackingService } from '../../../shared/services/tracking.service';

@Injectable()
export class AuthenticationEffects {
  loadIsAuthenticated$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadIsAuthenticatedAction>>(loadIsAuthenticatedAction),
      switchMap(() =>
        this.authService.isAuthenticated$.pipe(
          map((isAuthenticated: boolean) => setIsAuthenticatedAction({ isAuthenticated }))
        )
      )
    )
  );

  loadAccessToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAccessTokenAction>>(loadAccessTokenAction),
      switchMap(() =>
        this.authService
          .getAccessTokenSilently()
          .pipe(map((accessToken: string) => setAccessTokenAction({ accessToken })))
      )
    )
  );

  loadAuthUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAuthUserAction>>(loadAuthUserAction),
      switchMap(() =>
        this.authService.user$.pipe(
          map((authUser: AuthUser) => {
            if (authUser) {
              this.intercomService.updateSettings(authUser.email, authUser.sub);
            }

            return setAuthUserAction({
              authUser,
            });
          })
        )
      )
    )
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserAction>>(loadUserAction),
      switchMap(() => this.userService.getUser().pipe(map((user: User) => setUserAction({ user }))))
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof logoutAction>>(logoutAction),
        tap(() => {
          this.intercomService.shutdown();

          const options: LogoutOptions = {
            async openUrl() {
              window.location.replace(`https://waltio.com/`);
            },
          };

          this.authService.logout(options);
        })
      ),
    { dispatch: false }
  );

  resetUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof resetUserAction>>(resetUserAction),
        switchMap(() => this.userService.resetUser()),
        tap(() => window.location.reload())
      ),
    { dispatch: false }
  );

  saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof saveUserAction>>(saveUserAction),
      switchMap((action: ReturnType<typeof saveUserAction>) =>
        this.userService.saveUser(action.user).pipe(
          map((udpatedUser: User) => {
            this.toastService.success(this.translateService.instant(`PROFILE_SAVED`));
            return setUserAction({ user: udpatedUser });
          }),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setUserAction>>(setUserAction),
      switchMap((action: ReturnType<typeof setUserAction>) => {
        return [updateCurrentFiscalYearAction({ currentFiscalYear: action.user.associatedFiscalYear })];
      })
    )
  );

  switchUserAssociatedFiscalYear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof switchUserAssociatedFiscalYearAction>>(switchUserAssociatedFiscalYearAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuthentication.selectUser))),
        switchMap(([action, user]: [ReturnType<typeof switchUserAssociatedFiscalYearAction>, User]) =>
          this.userService
            .saveUser({
              ...user,
              associatedFiscalYear: action.fiscalYear,
            })
            .pipe(
              switchMap(() => {
                location.reload();

                return EMPTY;
              }),
              catchError((error: CustomError) => {
                const errorMessage: string =
                  this.translateService.instant(error.errorCode) === error.errorCode
                    ? error.message
                    : this.translateService.instant(error.errorCode);

                this.toastService.error(errorMessage);

                return EMPTY;
              })
            )
        )
      ),
    { dispatch: false }
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteUserAction>>(deleteUserAction),
      switchMap((action: ReturnType<typeof deleteUserAction>) =>
        this.userService.deleteUser().pipe(
          switchMap((genericResponse: GenericResponse) => {
            if (genericResponse.success) {
              this.toastService.success(this.translateService.instant(`ACCOUNT_DELETED`));

              return [pingOutUserAction(), unsyncAssessmentAction(), logoutAction()];
            } else {
              this.toastService.error(this.translateService.instant(`ACCOUNT_DELETION_ERROR`));
              return EMPTY;
            }
          }),

          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  load2FAConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof load2FaConfigAction>>(load2FaConfigAction),
      switchMap((action: ReturnType<typeof load2FaConfigAction>) => this.twoFAService.get2FAConfig()),
      map((config: UserTwoFAConfiguration) => set2FaConfigAction({ config }))
    )
  );

  setup2Fa$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setup2FaAction>>(setup2FaAction),
      switchMap((action: ReturnType<typeof setup2FaAction>) => this.twoFAService.setup2FA()),
      map((twoFA: TwoFACheck) => {
        this.utilsService.openDialog(TwoFaDialogComponent, `522px`, `auto`, {});

        return setTwoFaAction({ twoFA });
      })
    )
  );

  enable2Fa$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof enable2FaAction>>(enable2FaAction),
      withLatestFrom(this.authStore$.pipe(select(fromAuthentication.selectAccessToken))),
      switchMap(([action, accessToken]: [ReturnType<typeof enable2FaAction>, string]) =>
        this.twoFAService.enable2FA(action.code).pipe(
          switchMap((config: UserTwoFAConfiguration) => {
            localStorage.setItem(`id_auth`, accessToken);

            this.toastService.success(this.translateService.instant(`2FA_ENABLED`));

            return [set2FaConfigAction({ config })];
          }),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          })
        )
      )
    )
  );

  disable2Fa$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof disable2FaAction>>(disable2FaAction),
      switchMap((action: ReturnType<typeof disable2FaAction>) =>
        this.twoFAService.disable2FA().pipe(
          switchMap((config: UserTwoFAConfiguration) => {
            localStorage.removeItem(`id_auth`);
            this.toastService.success(this.translateService.instant(`2FA_DISABLED`));

            return [set2FaConfigAction({ config })];
          })
        )
      )
    )
  );

  validate2FaCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof validate2FaCodeAction>>(validate2FaCodeAction),
      withLatestFrom(this.authStore$.pipe(select(fromAuthentication.selectAccessToken))),
      switchMap(([action, accessToken]: [ReturnType<typeof validate2FaCodeAction>, string]) =>
        this.twoFAService.validate2FACode(action.code).pipe(
          switchMap((res: GenericResponse) => {
            if (res.success) {
              localStorage.setItem(`id_auth`, accessToken);
              return [goToAction({ url: `dashboard` })];
            } else {
              this.toastService.error(this.translateService.instant(`2FA_INVALID_CODE`));
              return EMPTY;
            }
          })
        )
      )
    )
  );

  validate2FaRecoveryCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof validate2FaRecoveryCodeAction>>(validate2FaRecoveryCodeAction),
      withLatestFrom(this.authStore$.pipe(select(fromAuthentication.selectAccessToken))),
      switchMap(([action, accessToken]: [ReturnType<typeof validate2FaRecoveryCodeAction>, string]) =>
        this.twoFAService.validate2FARecoveryCode(action.recoveryCode).pipe(
          switchMap((res: GenericResponse) => {
            if (res.success) {
              localStorage.setItem(`id_auth`, accessToken);
              this.toastService.success(this.translateService.instant(`2FA_VALID_CODE`));

              return [goToAction({ url: `dashboard` })];
            } else {
              this.toastService.error(this.translateService.instant(`2FA_INVALID_RECOVERY_CODE`));
              return EMPTY;
            }
          }),
          catchError((error: CustomError) => {
            const errorMessage: string =
              this.translateService.instant(error.errorCode) === error.errorCode
                ? error.message
                : this.translateService.instant(error.errorCode);

            this.toastService.error(errorMessage);

            return EMPTY;
          })
        )
      )
    )
  );

  loadAuthUserEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAuthUserEventsAction>>(loadAuthUserEventsAction),
      switchMap((action: ReturnType<typeof loadAuthUserEventsAction>) =>
        this.userService.getAuthEvents(action.page, action.size, action.sort).pipe(
          map((userAuthEventsPage: Page<AuthUserEvent>) => setAuthUserEventsAction({ userAuthEventsPage })),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly intercomService: IntercomService,
    private readonly userService: UserService,
    private readonly toastService: ToastService,
    private readonly twoFAService: TwoFaService,
    private readonly utilsService: UtilsService,
    private readonly trackingService: TrackingService,
    private readonly authStore$: Store<fromAuthentication.State>,
    private readonly translateService: TranslateService
  ) {}
}
