@if (user?.automaticAssessmentEnabled) {
  <div
    class="assessment-status"
    [ngClass]="{
      'assessment-status--finished': assessmentStatus === 'COMPLETED',
      'assessment-status--processing': assessmentStatus === 'IN_PROGRESS' || assessmentStatus === 'RETRIEVING_TX',
      'assessment-status--aborted': assessmentStatus === 'NEED_PLAN_UPGRADE',
      'assessment-status--empty': assessmentStatus === 'NEED_ACCOUNT_ADD',
      pointer: isHovered && assessmentStatus === 'IN_PROGRESS',
    }"
    (click)="statusClick.emit()"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
  >
    @switch (assessmentStatus) {
      @case ('COMPLETED') {
        <img src="/assets/img/icons/success-check.svg" alt="" />
        <div class="assessment-completed">
          <p class="body-12-med">{{ 'REPORT_UPDATED' | translate }}</p>
          @if (assessment.completed) {
            <p class="body-12">
              {{ assessment.completed | date: 'dd/MM/yyyy à HH:mm' }}
            </p>
          }
        </div>
      }

      @case ('NEED_PLAN_UPGRADE') {
        <img src="/assets/img/icons/lock.svg" alt="" />
        <p class="body-12-med">{{ 'UPGRADE_TO_NEXT_PLAN' | translate }}</p>
        <img src="/assets/img/icons/next-black.svg" alt="" />
      }

      @case ('RETRIEVING_TX') {
        <div class="icon-container">
          <img width="30" height="30" class="rotating" src="/assets/img/icons/report-processing.svg" alt="" />
        </div>
        <p class="body-12-med">{{ 'RETRIEVING_TX' | translate }}</p>
      }

      @case ('NEED_ACCOUNT_ADD') {
        <img src="/assets/img/icons/info-banner.svg" alt="" />
        <p class="body-12-med">{{ 'ADD_ACCOUNTS_TO_START' | translate }}</p>
        <img src="/assets/img/icons/next-black.svg" alt="" />
      }

      @case ('IN_PROGRESS') {
        <div
          class="icon-container"
          [ngClass]="{
            'icon-container--circle': isHovered,
          }"
        >
          @if (!isHovered) {
            <img width="30" height="30" class="rotating" src="/assets/img/icons/report-processing.svg" alt="" />
          }
          <div class="stop" [ngClass]="{ 'stop--big': isHovered }"></div>
        </div>
        <div>
          <p class="body-12-med">{{ (isHovered ? 'STOP_REPORT' : 'REPORT_IN_PROGRESS') | translate }}</p>
          @if (!isHovered && assessmentETA > 0) {
            <p class="eta body-12">{{ formatETA(assessmentETA) }} {{ 'REMAINING_F' | translate }}</p>
          }
        </div>
      }
    }
  </div>
} @else {
  <div
    class="assessment-status assessment-status--paused"
    (click)="statusClick.emit()"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
  >
    <div
      class="icon-container"
      [ngClass]="{
        'icon-container--circle-grey': !isHovered,
        'icon-container--circle-dark': isHovered,
      }"
    >
      @if (!isHovered) {
        <img src="/assets/img/icons/play.svg" alt="play" />
      } @else {
        <img src="/assets/img/icons/play-hover.svg" alt="play-hover" />
      }
    </div>
    <div>
      <p class="body-12-med">
        {{ (isHovered ? 'ENABLE_REPORT' : 'PAUSING_REPORT') | translate }}
      </p>
    </div>
  </div>
}
