<div class="container">
  <section
    class="theme"
    [ngClass]="{
      'theme--pln300': offer === 'PLN300',
      'theme--pln500': offer === 'PLN500',
      'theme--pln800': offer === 'PLN800',
      'theme--addon': data.checkoutType === 'ADDON',
    }"
  ></section>
  <section class="content">
    <div class="header">
      @if (data.checkoutType === 'PLAN') {
        <div class="header__title">
          <h2>{{ 'SWITCH_TO_PLAN' | translate }}</h2>

          <div
            class="plan"
            [ngClass]="{
              'plan--pln300': offer === 'PLN300',
              'plan--pln500': offer === 'PLN500',
              'plan--pln800': offer === 'PLN800',
            }"
          >
            <h2>
              {{ 'PLANS.' + offer | translate }}
            </h2>
          </div>
        </div>
      }

      @if (data.checkoutType === 'ADDON') {
        @if (offer === 'AOCARE001' || !offer) {
          <h2>{{ 'UPGRADE_FISHING.AOCARE001' | translate }}</h2>
        }
        @if (offer === 'AOCARE002') {
          <h2>{{ 'UPGRADE_FISHING.AOCARE002' | translate }}</h2>
        }
      }

      <img class="pointer" src="/assets/img/icons/close.svg" alt="close" mat-dialog-close />
    </div>

    @if (data.checkoutType === 'PLAN' && recommendedPlan !== 'PLN800') {
      <div class="plans">
        @if (!recommendedPlan || recommendedPlan === 'PLN300') {
          <app-offer-toggle
            [offer]="'PLN300'"
            [isChecked]="!offer || offer === 'PLN300'"
            [type]="'PLAN'"
            (click)="toggleOffer('PLN300')"
          ></app-offer-toggle>
        }

        @if (!recommendedPlan || recommendedPlan !== 'PLN800') {
          <app-offer-toggle
            [offer]="'PLN500'"
            [isChecked]="offer === 'PLN500'"
            [type]="'PLAN'"
            (click)="toggleOffer('PLN500')"
          ></app-offer-toggle>
        }

        <app-offer-toggle
          [offer]="'PLN800'"
          [isChecked]="offer === 'PLN800'"
          [type]="'PLAN'"
          (click)="toggleOffer('PLN800')"
        ></app-offer-toggle>
      </div>
    }

    @if (!data.offer && data.checkoutType === 'ADDON') {
      <div class="addons">
        <app-offer-toggle
          [offer]="'AOCARE001'"
          [isChecked]="!offer || offer === 'AOCARE001'"
          (click)="toggleOffer('AOCARE001')"
        ></app-offer-toggle>
        <app-offer-toggle
          [offer]="'AOCARE002'"
          [isChecked]="offer === 'AOCARE002'"
          (click)="toggleOffer('AOCARE002')"
        ></app-offer-toggle>
      </div>
    }

    <div class="features">
      <div class="features__title">
        @if (offer === 'PLN300' || offer === 'PLN500' || offer === 'PLN800') {
          <p class="body-14-bold">
            {{ title | translate }}
          </p>
        } @else {
          @if (offer === 'AOCARE001' || !offer) {
            <p class="body-14-bold">{{ 'AOCARE001.UF' | translate }}</p>
          }
          @if (offer === 'AOCARE002') {
            <p class="body-14-bold">{{ 'AOCARE002.UF' | translate }}</p>
          }
        }
      </div>
      <div class="features__content">
        @if (data.checkoutType === 'PLAN') {
          <!-- FEATURE 1 -->
          <div class="feature">
            @if (offer === 'PLN300') {
              <img src="/assets/img/icons/folder-files-grey.svg" alt="" />
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.PLN300' | translate }}</p>
            }
            @if (offer === 'PLN500') {
              <img src="/assets/img/icons/folder-files.svg" alt="" />
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.PLN500' | translate }}</p>
            }
            @if (offer === 'PLN800') {
              <img src="/assets/img/icons/folder-files-gold.svg" alt="" />
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.PLN800' | translate }}</p>
            }
          </div>

          <!-- FEATURE 2 -->
          <div class="feature">
            @if (offer === 'PLN300') {
              <img src="/assets/img/icons/pie-chart-grey.svg" alt="" />
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.REPORT' | translate }}</p>
            }
            @if (offer === 'PLN500') {
              <img src="/assets/img/icons/pie-chart.svg" alt="" />
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.DOCUMENTS' | translate }}</p>
            }
            @if (offer === 'PLN800') {
              <img src="/assets/img/icons/pie-chart-gold.svg" alt="" />
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.BANK' | translate }}</p>
            }
          </div>

          <!-- FEATURE 3 -->
          <div class="feature">
            @if (offer === 'PLN300') {
              <img src="/assets/img/icons/academic-cap-grey.svg" alt="" />
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.PORTFOLIO' | translate }}</p>
            }
            @if (offer === 'PLN500') {
              <img src="/assets/img/icons/academic-cap.svg" alt="" />
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.STAKING' | translate }}</p>
            }
            @if (offer === 'PLN800') {
              <img src="/assets/img/icons/academic-cap-gold.svg" alt="" />
              <p class="body-14-bold">{{ 'UPGRADE_FISHING.SUPPORT' | translate }}</p>
            }
          </div>

          @if (offer === 'PLN500' || offer === 'PLN800') {
            <div class="feature">
              <img width="16" height="16" src="/assets/img/icons/plus-grey.svg" alt="" />
              <p class="body-14-med">{{ 'PREVIOUS_PLAN_PLUS' | translate }}</p>
            </div>
          }
        }
        @if (offer === 'AOCARE001' || !offer) {
          <div class="feature">
            <img src="/assets/img/icons/folder-files-black.svg" alt="" />
            <p class="body-14-bold">{{ 'AOCARE001.UF1' | translate }}</p>
          </div>
          <div class="feature">
            <img src="/assets/img/icons/pie-chart-black.svg" alt="" />
            <p class="body-14-bold">
              {{ 'AOCARE001.UF2' | translate }}
            </p>
          </div>
          <div class="feature">
            <img src="/assets/img/icons/academic-cap-black.svg" alt="" />
            <p class="body-14-bold">{{ 'AOCARE001.UF3' | translate }}</p>
          </div>
        }
        @if (offer === 'AOCARE002') {
          <div class="feature">
            <img src="/assets/img/icons/folder-files-black.svg" alt="" />
            <p class="body-14-bold">{{ 'AOCARE002.UF1' | translate }}</p>
          </div>
          <div class="feature">
            <img src="/assets/img/icons/pie-chart-black.svg" alt="" />
            <p class="body-14-bold">{{ 'AOCARE002.UF2' | translate }}</p>
          </div>
          <div class="feature">
            <img src="/assets/img/icons/academic-cap-black.svg" alt="" />
            <p class="body-14-bold">{{ 'AOCARE002.UF3' | translate }}</p>
          </div>
        }
      </div>
    </div>

    <div class="ctas">
      @if (data.demoCTA) {
        <button type="button" mat-flat-button color="secondary" disableRipple (click)="downloadDemoFile()">
          {{ data.demoCTA | translate }}
          <img src="/assets/img/icons/download-black.svg" alt="" />
        </button>
      }

      <button
        type="button"
        mat-flat-button
        color="primary"
        disableRipple
        (click)="paymentEstimate ? openCheckoutDialog() : null"
      >
        @if (paymentEstimate) {
          {{ 'SUBSCRIBE_FOR' | translate }}
          {{ paymentEstimate.due | currencyDecimal: userPreferences : stablecoins : fiats }}
        }
        @if (!paymentEstimate) {
          <img width="20px" class="rotating" src="/assets/img/icons/processing.svg" alt="" />
        }
      </button>

      <div class="subscription">
        <p class="body-12">{{ 'FISCAL_YEAR_SUBSCRIPTION' | translate }}</p>
        <div
          class="info-button"
          (click)="openArticle('5830168')"
          [matTooltip]="'SHOW_MORE_INFOS' | translate"
          matTooltipPosition="right"
        ></div>
      </div>
    </div>
  </section>
</div>
