/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { UserOpportunities } from '../../models/user-opportunities.model';

// eslint-disable-next-line no-shadow
export enum OpportunityActions {
  LoadUserOpportunities = `[Opportunity] Load User Opportunities`,
  SetUserOpportunities = `[Opportunity] Set User Opportunities`,
  LoadUserOpportunitiesInstructions = `[Opportunity] Load User Opportunities Instructions`,
  SetUserOpportunitiesInstructions = `[Opportunity] Set User Opportunities Instructions`,
}

export const loadUserOpportunitiesAction = createAction(OpportunityActions.LoadUserOpportunities);

export const setUserOpportunitiesAction = createAction(
  OpportunityActions.SetUserOpportunities,
  props<{ userOpportunities: UserOpportunities }>()
);

export const loadUserOpportunitiesInstructionsAction = createAction(
  OpportunityActions.LoadUserOpportunitiesInstructions
);

export const setUserOpportunitiesInstructionsAction = createAction(
  OpportunityActions.SetUserOpportunitiesInstructions,
  props<{ instructions: any }>()
);
