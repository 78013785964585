import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyDecimalPipe } from '../../../shared/pipes/currency-decimal.pipe';
import { combineLatest, map, Subject, takeUntil } from 'rxjs';
import { UserPreferences } from '../../../shared/models/user-preferences.model';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: `app-allocation-toggle`,
  standalone: true,
  imports: [CommonModule, CurrencyDecimalPipe],
  templateUrl: `./allocation-toggle.component.html`,
  styleUrls: [`./allocation-toggle.component.scss`],
})
export class AllocationToggleComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() count: number;
  @Input() amount: number;
  @Input() active = false;
  @Input() static = false;

  stablecoins: string[];
  fiats: string[];
  userPreferences: UserPreferences;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(private readonly sharedStore$: Store<fromShared.State>) {}

  ngOnInit(): void {
    combineLatest([
      this.sharedStore$.select(fromShared.selectStableCoins),
      this.sharedStore$.select(fromShared.selectFiats),
      this.sharedStore$.select(fromShared.selectUserPreferences),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([stablecoins, fiats, userPreferences]) => {
          this.stablecoins = stablecoins;
          this.fiats = fiats;
          this.userPreferences = userPreferences;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
