import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { User } from '../../taxation/models/user.model';
import { environment } from '../../../environments/environment';
import { UserPreferences } from '../models/user-preferences.model';

@Injectable({
  providedIn: `root`,
})
export class OnboardingService {
  constructor(private readonly http: HttpClient) {}

  initOnboarding(user: User, preferences: UserPreferences): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/v1/tax/onboarding/init`, {
      user,
      preferences,
    });
  }

  completeOnboarding(affiliateId: string, user: User, preferences: UserPreferences): Observable<User> {
    let url = `${environment.apiUrl}/v1/tax/onboarding/full`;

    const userAndPreferences: any = {
      user,
      preferences,
    };

    if (affiliateId) {
      url = url.concat(`?affiliateId=${affiliateId}`);
    }

    return this.http.post<User>(url, userAndPreferences);
  }

  getBelgianFiscalProfiles(language?: string): Observable<Map<string, string>> {
    let url = `${environment.apiUrl}/v1/tax/onboarding/belgian-fiscal-profile/list`;

    if (language) {
      url = url.concat(`?i18n=${language}`);
    }

    return this.http.get<Map<string, string>>(url).pipe(
      map((belgianFiscalProfiles) => {
        return new Map<string, string>(Object.entries(belgianFiscalProfiles));
      }),
    );
  }
}
