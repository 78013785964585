/* eslint-disable indent */
import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import * as fromAuthentication from '../../../../authentication/store/selectors/authentication.selector';
import { CloseButtonComponent } from '../../../../shared/components/close-button/close-button.component';
import { LEGDER_LIVE_LINK } from '../../../../shared/constants/public-links.constant';
import { CustomFocusDirective } from '../../../../shared/directives/custom-focus.directive';
import { DebounceClickDirective } from '../../../../shared/directives/debounce-click.directive';
import { TimezoneDetails } from '../../../../shared/models/file.model';
import { UserPreferences } from '../../../../shared/models/user-preferences.model';
import { IntercomService } from '../../../../shared/services/intercom.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import * as fromShared from '../../../../shared/store/selectors/shared.selector';
import { REOWN_BLOCKCHAINS, REOWN_WALLETS } from '../../../constants/account.constant';
import {
  Account,
  AccountCategory,
  AccountListResponse,
  ExtendedKey,
  NameServices,
  SuggestedPlatform,
} from '../../../models/account.model';
import { Candidate } from '../../../models/candidate.model';
import { NameResolverResponse } from '../../../models/name-resolver-response.model';
import { ReownModalState } from '../../../models/reown.model';
import { APIDetails } from '../../../models/upload.model';
import { AccountType, UserAccount } from '../../../models/user-account.model';
import { AccountsSorterPipe } from '../../../pipes/accounts-sorter.pipe';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import {
  createApiAction,
  createSelectedCandidatesApisAction,
  deleteAccountAction,
  disconnectReownModalAction,
  getOAuthSyncUrlAction,
  loadTimezonesAction,
  openReownModalAction,
  resetAction,
  resolveNameAction,
  setApiErrorAction,
  setReownModalStateAction,
  updateApiAction,
  updateUserAccountAction,
} from '../../../store/actions/account.action';
import * as fromAccount from '../../../store/selectors/account.selector';
import { AccountCardComponent } from '../../account-card/account-card.component';
import { BannerComponent } from '../../banner/banner.component';
import { FileImporterComponent } from '../../file-importer/file-importer.component';
import { ImportButtonComponent } from '../../import-button/import-button.component';
import { LinkComponent } from '../../link/link.component';
import { UploadApiProgressComponent } from '../../upload-api-progress/upload-api-progress.component';
import { DeleteAccountDialogComponent } from '../delete-account-dialog/delete-account-dialog.component';
import { AccountExpansionPanelComponent } from '../../account-expansion-panel/account-expansion-panel.component';
import { SuggestedPlatformImporterComponent } from '../../suggested-platform-importer/suggested-platform-importer.component';

@Component({
  selector: `app-accounts-dialog`,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatChipsModule,
    MatDividerModule,
    MatInputModule,
    MatTooltipModule,
    TranslateModule,
    ReactiveFormsModule,
    CloseButtonComponent,
    AccountCardComponent,
    ImportButtonComponent,
    UploadApiProgressComponent,
    BannerComponent,
    LinkComponent,
    FileImporterComponent,
    CustomFocusDirective,
    AccountsSorterPipe,
    DebounceClickDirective,
    TruncatePipe,
    BannerComponent,
    AccountExpansionPanelComponent,
    SuggestedPlatformImporterComponent,
  ],
  templateUrl: `./accounts-dialog.component.html`,
  styleUrls: [`./accounts-dialog.component.scss`],
})
export class AccountsDialogComponent implements OnInit, OnDestroy {
  // Misc
  partner: string;
  accountCategory: AccountCategory = null;
  userPreferences: UserPreferences;

  // Accounts
  accounts: Map<string, Account> = new Map<string, Account>([]);
  accountsList: AccountListResponse;
  filteredAccounts: Account[] = [];
  topAccounts: Account[] = [];
  selectedAccountName: string;
  selectedAccount: Account;
  selectedSubAccount: Account;
  selectedAccountChildren: Account[];
  selectedAccountType: AccountType;
  suggestedPlatformAccountType: AccountType;
  userAccounts: UserAccount[];
  ledgerLiveAccount: Account;

  // API
  apiErrorCode: string;
  apiErrorMessage = ``;
  isApiConnecting: boolean;
  isApiConnected: boolean;

  // One for all
  availableCandidates: Candidate[];
  selectedCandidates: Candidate[] = [];
  bannerSubtitle: string;

  // NS
  resolvedName: NameResolverResponse = null;
  nsDetected = false;
  nsSuffixAndPrefix: string;

  // Form
  searchBarControl: FormControl = new FormControl(``);
  walletForm: FormGroup;
  apiForm: FormGroup;

  // File
  timezones: TimezoneDetails[] = [];
  accessToken: string;

  submitButtonLabel = `IMPORT`;

  selectedUserAccount: UserAccount;

  updateFileSubtitle = ``;
  hasIncrementalUpload = false;

  // Reown
  reownBlockchains = REOWN_BLOCKCHAINS;
  reownWallets = REOWN_WALLETS;
  reownModalState: ReownModalState;

  // Suggested Platforms
  selectedSuggestedPlatform: SuggestedPlatform;
  isSelectedAccountSuggestedPlatform = false;

  @Input() isInline = false; // Controls inline mode visibility
  @Input() showBackButton = true;
  @Input() showCloseButton = true;
  @Input() showSolutions = false;
  @Input() updateAPI = false;
  @Input() updateFile = false;
  @Input() quotaReached = false;
  @Input() apiId = ``;

  @Output() quit: EventEmitter<Account> = new EventEmitter<Account>();

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly accountStore$: Store<fromAccount.State>,
    private readonly authStore$: Store<fromAuthentication.State>,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly fb: FormBuilder,
    private readonly intercomService: IntercomService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly utilsService: UtilsService,
    @Optional() public dialogRef: MatDialogRef<AccountsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    private readonly data: {
      selectedAccount?: string;
      showBackButton?: boolean;
      showCloseButton?: boolean;
      showSolutions?: boolean;
      updateAPI?: boolean;
      updateFile?: boolean;
      quotaReached?: boolean;
      mostRecentTransactionDate?: string;
      apiId?: string;
    },
  ) {
    this.partner = sessionStorage.getItem(`partner`) || this.route.snapshot.queryParamMap.get(`account`);

    this.walletForm = new FormGroup({
      address: this.fb.control(``, [
        Validators.required,
        this.walletAddressLengthValidator,
        this.apiKeyPatternValidator,
        this.noWhiteSpaceValidator,
      ]),
    });

    this.walletForm
      .get(`address`)
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        map((address: string) => {
          this.detectNameService(address);
        }),
      )
      .subscribe();

    this.apiForm = this.fb.group({
      apiKey: [``, [Validators.required, this.apiKeyLengthValidator, this.noWhiteSpaceValidator]],
      apiSecret: [``, [this.apiSecretLengthValidator, this.noWhiteSpaceValidator]],
      passphrase: [``, [this.passphraseValidator, this.noWhiteSpaceValidator]],
    });
  }

  ngOnInit(): void {
    this.reset();

    this.showBackButton = this.data?.showBackButton || this.showBackButton;
    this.showCloseButton = this.data?.showCloseButton || this.showCloseButton;
    this.showSolutions = this.data?.showSolutions || this.showSolutions;
    this.updateAPI = this.data?.updateAPI || this.updateAPI;
    this.updateFile = this.data?.updateFile || this.updateFile;
    this.quotaReached = this.data?.quotaReached || this.quotaReached;
    this.apiId = this.data?.apiId || this.apiId;

    if (this.updateAPI) {
      this.selectAccountType(`API`);
      this.submitButtonLabel = `UPDATE`;
    }

    if (this.updateFile) {
      this.selectAccountType(`FILE`);
    }

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectAccounts),
        map((accounts: Map<string, Account>) => {
          this.accounts = accounts;

          if (this.accounts) {
            this.ledgerLiveAccount = this.accounts.get(`LEDGER_LIVE`);

            this.topAccounts = this.getTopAccounts();

            if (this.data?.selectedAccount) {
              this.selectedAccountName = this.data.selectedAccount;
              const account = this.accounts.get(this.selectedAccountName);

              if (account) {
                this.selectAccount(account);
              }
            }
          }
        }),
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectAccountsList),
        map((accountsList: AccountListResponse) => {
          this.accountsList = accountsList;

          if (this.accountsList) {
            this.filterAccounts();
          }
        }),
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectUserPreferences),
        map((userPreferences: UserPreferences) => {
          this.userPreferences = userPreferences;

          if (this.userPreferences && this.updateFile) {
            this.hasIncrementalUpload = this.userPreferences.incrementalUploadEnabled.get(this.selectedAccount?.key);
            if (!this.hasIncrementalUpload) {
              this.updateFileSubtitle = this.translateService.instant(`UPDATE_FILE_NO_INC`, {
                date: moment(this.data?.mostRecentTransactionDate).format(`DD/MM/YYYY HH:mm:ss`),
              });
            }
          }
        }),
      )
      .subscribe();

    this.accountStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAccount.selectUserAccounts),
        map((userAccounts: UserAccount[]) => {
          this.userAccounts = userAccounts;

          if (this.userAccounts && this.apiId) {
            this.selectedUserAccount = this.userAccounts.find(
              (account: UserAccount) => account.accountId === this.apiId,
            );
          }
        }),
      )
      .subscribe();

    this.accountStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAccount.selectResolvedName),
        map((resolvedName: NameResolverResponse) => {
          this.resolvedName = resolvedName;
        }),
      )
      .subscribe();

    this.accountStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAccount.selectIsApiConnected),
        map((isApiConnected: boolean) => {
          this.isApiConnected = isApiConnected;

          if (this.isApiConnected) {
            const checkAvailableChains = [...this.accountsList.blockchain, ...this.accountsList.wallet]
              .map((a: Account) => a.key)
              .includes(this.selectedSubAccount?.key || this.selectedAccount?.key);

            if (!checkAvailableChains) {
              this.isApiConnecting = false;

              this.closeDialog();
            }
          }
        }),
      )
      .subscribe();

    this.accountStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAccount.selectAvailableCandidates),
        map((availableCandidates: Candidate[]) => {
          this.availableCandidates = availableCandidates;

          if (this.isApiConnected) {
            this.isApiConnecting = false;

            if (this.availableCandidates?.length > 0) {
              this.bannerSubtitle = this.translateService.instant(`ACCOUNT.AVAILABLE_CHAINS`, {
                count: this.availableCandidates.length.toString(),
              });
              this.selectAllAvailableCandidates();

              this.updateLocation(`/accounts/add/additional-accounts`);
            } else {
              this.closeDialog();
            }
          }
        }),
      )
      .subscribe();

    this.accountStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAccount.selectAPI),
        map((api: any) => {
          this.apiErrorCode = api.errorCode;
          this.apiErrorMessage = api.errorMessage;

          if (this.apiErrorMessage) {
            this.isApiConnecting = false;
          }
        }),
      )
      .subscribe();

    this.accountStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAccount.selectTimezones),
        map((timezones: TimezoneDetails[]) => {
          this.timezones = timezones;
        }),
      )
      .subscribe();

    this.authStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAuthentication.selectAccessToken),
        map((accessToken: string) => {
          this.accessToken = accessToken;
        }),
      )
      .subscribe();

    this.accountStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAccount.selectReownModalState),
        map((reownModalState: any) => {
          this.reownModalState = reownModalState;

          if (
            this.reownModalState.network &&
            this.reownModalState.wallet &&
            this.reownModalState.isConnected &&
            this.reownModalState.isImporting
          ) {
            this.accountStore$.dispatch(setReownModalStateAction({ reownModalState: { isImporting: false } }));

            const subAccount = this.accounts.get(this.reownModalState.network);

            if (subAccount && !this.selectedSubAccount) {
              this.selectSubAccount(subAccount);
            }

            this.selectedAccountName = this.reownModalState.wallet;
            const account = this.accounts.get(this.selectedAccountName);

            if (account) {
              if (this.selectedAccount) {
                if (!this.selectedAccount.supportsSubAccount) {
                  this.selectSubAccount(this.selectedAccount, false);
                }
              }

              this.selectAccount(account, false);

              this.updateLocation(
                `/accounts/add/${this.selectedAccount?.key.toLowerCase()}/${this.selectedSubAccount?.key.toLowerCase()}`,
              );

              this.selectAccountType(`API`, false);

              this.importWallet(``, this.selectedAccount?.key);
            } else {
              this.selectAccount(this.selectedSubAccount, false);
              this.updateLocation(`/accounts/add/${this.selectedSubAccount?.key.toLowerCase()}`);

              this.selectSubAccount(null, false);

              this.selectAccountType(`API`, false);
              this.importWallet(this.selectedAccountName, this.selectedAccountName);
            }
          }

          if (this.reownModalState.address) {
            this.walletForm.get(`address`).markAsTouched();
            this.walletForm.get(`address`).setValue(this.reownModalState.address);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.reset();
    this.accountStore$.dispatch(disconnectReownModalAction());

    this.destroy$.next();
    this.destroy$.complete();
  }

  getTopAccounts(): Account[] {
    let topAccountsIds: string[] = [];

    if (this.isInline) {
      topAccountsIds = [`LEDGER`, `METAMASK`, `KRAKEN`, `PHANTOM`, `COINBASE`, `BINANCE`];
    } else {
      topAccountsIds = [`BINANCE`, `COINBASE`, `METAMASK`, `WALLET_BTC`, `KRAKEN`, `PHANTOM`];
    }

    const topAccounts: Account[] = [];

    for (const id of topAccountsIds) {
      const account = this.accounts.get(id);
      if (account) {
        topAccounts.push(account);
      }
    }

    return topAccounts;
  }

  detectNameService(address: string): void {
    this.accountStore$.dispatch(setApiErrorAction({ errorCode: null, errorMessage: `` }));

    const account = this.accounts.get((this.selectedSubAccount || this.selectedAccount)?.key);
    const nameServices: NameServices = account?.accountMetadata?.nameServices;

    if (nameServices && address?.length > 1) {
      let currentPrefix = ``;
      let currentSuffix = ``;

      nameServices.prefix?.forEach((prefix: string) => {
        if (address?.startsWith(prefix)) {
          currentPrefix = prefix;
        }
      });

      nameServices.suffix?.forEach((suffix: string) => {
        if (address?.endsWith(suffix)) {
          currentSuffix = suffix;
        }
      });

      this.nsDetected = currentPrefix !== `` || currentSuffix !== ``;

      // IBC hack
      address = address.replace(currentPrefix, ``).replace(`.cosmos`, ``);

      if (this.nsDetected) {
        this.accountStore$.dispatch(
          resolveNameAction({
            account: this.selectedSubAccount || this.selectedAccount,
            name: address,
          }),
        );
      }
    } else {
      this.nsDetected = false;
      this.resolvedName = null;
    }
  }

  selectAccount(account: Account, updateLocation = true): void {
    this.selectedAccount = account;

    // Check if the account is a suggested platform
    this.checkSuggestedPlatform(account);

    if (this.selectedSuggestedPlatform) {
      this.isSelectedAccountSuggestedPlatform = true;
    }

    this.searchBarControl.setValue(``);

    if (this.selectedAccount?.supportsSubAccount) {
      this.selectedAccountChildren = this.selectedAccount.children.map((subAccount: string) =>
        this.accounts.get(subAccount),
      );
    }

    // Little hack to avoid wrong location update
    if (!this.data?.selectedAccount && updateLocation) {
      this.updateLocation(this.location.path().concat(`/${this.selectedAccount?.key.toLowerCase()}`));
    }

    if (!this.isInline) {
      this.data.selectedAccount = ``;
    }

    this.nsSuffixAndPrefix = ``;
    this.getNSSuffixAndPrefix();
  }

  selectSubAccount(subAccount: Account, updateLocation = true): void {
    this.selectedSubAccount = subAccount;

    if (this.selectedSubAccount && updateLocation) {
      this.updateLocation(this.location.path().concat(`/${this.selectedSubAccount?.key.toLowerCase()}`));
    }

    this.nsSuffixAndPrefix = ``;
    this.getNSSuffixAndPrefix();
  }

  selectAccountType(accountType: AccountType, updateLocation = true): void {
    if (this.isSelectedAccountSuggestedPlatform) {
      this.selectedAccountType = `SUGGESTED`;
      this.suggestedPlatformAccountType = accountType;
    } else {
      this.selectedAccountType = accountType;
    }

    if (!this.updateFile && updateLocation && this.selectedAccountType !== `SUGGESTED`) {
      this.updateLocation(this.location.path().concat(`/${this.selectedAccountType.toLowerCase()}`));
    }

    if (
      this.selectedAccountType === `FILE` ||
      (this.selectedAccountType === `SUGGESTED` && this.selectedAccount.supportsFile)
    ) {
      this.accountStore$.dispatch(loadTimezonesAction());
    }
  }

  selectAvailableCandidate(candidate: Candidate): void {
    if (this.selectedCandidates.includes(candidate)) {
      this.selectedCandidates = this.selectedCandidates.filter(
        (selectedCandidate: Candidate) => selectedCandidate.platform !== candidate.platform,
      );
    } else {
      this.selectedCandidates.push(candidate);
    }
  }

  unselectAllAvailableCandidates(): void {
    this.selectedCandidates = [];
  }

  selectAllAvailableCandidates(): void {
    this.selectedCandidates = this.availableCandidates;
  }

  importSelectedCandidates(): void {
    this.accountStore$.dispatch(
      createSelectedCandidatesApisAction({
        selectedCandidates: this.selectedCandidates,
        alias: this.resolvedName?.name,
      }),
    );

    this.closeDialog();
  }

  filterAccounts(accountCategory?: AccountCategory): void {
    this.accountCategory = accountCategory;

    const suggestedPlatforms: Account[] = this.accountsList.suggestedPlatforms.map(
      (suggestedPlatform: SuggestedPlatform) => this.accounts.get(suggestedPlatform.technicalName),
    );

    if (!this.accountCategory) {
      this.filteredAccounts = [
        ...this.accountsList.platform,
        ...this.accountsList.blockchain,
        ...this.accountsList.wallet,
        ...this.accountsList.service,
        ...suggestedPlatforms,
      ];
    } else {
      this.filteredAccounts = this.accountsList[this.accountCategory];
    }
  }

  back(): void {
    this.searchBarControl.setValue(``);

    if (this.selectedAccountType) {
      this.updateLocation(this.location.path().replace(`/${this.selectedAccountType.toLowerCase()}`, ``));
      this.selectedAccountType = null;
      this.apiForm.reset();
      this.walletForm.reset();
    } else if (this.selectedSubAccount) {
      this.updateLocation(this.location.path().replace(`/${this.selectedSubAccount?.key.toLowerCase()}`, ``));
      this.selectedSubAccount = null;
    } else {
      this.filterAccounts();
      this.updateLocation(this.location.path().replace(`/${this.selectedAccount?.key.toLowerCase()}`, ``));
      this.selectedAccount = null;
    }
  }

  createOAuthSync(): void {
    this.accountStore$.dispatch(
      getOAuthSyncUrlAction({ platform: this.selectedSubAccount?.key || this.selectedAccount?.key }),
    );
  }

  isFeatureEnabled(featureId: string): Observable<boolean> {
    return this.sharedStore$.pipe(
      takeUntil(this.destroy$),
      select(fromShared.selectIsAccountFeatureEnabled(featureId)),
    );
  }

  openArticle(article: string): void {
    this.intercomService.showArticle(article);
  }

  createApi(api: APIDetails, alias?: string): void {
    this.isApiConnecting = true;

    this.accountStore$.dispatch(
      createApiAction({
        api,
        account: this.selectedAccount,
        subAccount: this.selectedSubAccount,
        alias,
      }),
    );
  }

  updateApi(api: APIDetails): void {
    this.accountStore$.dispatch(
      updateApiAction({
        apiId: this.apiId,
        api,
      }),
    );
  }

  importWallet(alias?: string, wallet?: string): void {
    if (this.walletForm.valid && !this.apiErrorMessage) {
      const walletAddress = this.nsDetected ? this.resolvedName?.address : this.walletForm.get(`address`).value;
      const api: APIDetails = {
        key: walletAddress?.trim(),
      };

      if (wallet) {
        api.wallet = wallet;
      }

      this.createApi(api, alias || this.resolvedName?.name);
    }
  }

  importAPI(): void {
    this.accountStore$.dispatch(setApiErrorAction({ errorCode: null, errorMessage: `` }));

    if (this.apiForm.valid && !this.apiErrorMessage) {
      let key: string = this.apiForm.get(`apiKey`).value;
      let secret = this.apiForm.get(`apiSecret`).value;
      let passphrase = this.apiForm.get(`passphrase`).value;

      key = key ? key.trim() : ``;
      secret = secret ? secret.trim() : ``;
      passphrase = passphrase ? passphrase.trim() : ``;

      const api: APIDetails = {
        key,
        secret,
        passphrase,
      };

      // Hack for COINBASE
      api.secret = api.secret.replace(/\\n/g, `\n`);

      if (!this.apiId) {
        this.createApi(api);
      } else {
        this.updateApi(api);
      }
    }
  }

  reset(): void {
    this.accountStore$.dispatch(resetAction());

    this.walletForm.reset();
    this.searchBarControl.setValue(``);
    this.selectedAccount = null;
    this.selectedSubAccount = null;
    this.selectedAccountChildren = null;
    this.selectedCandidates = [];
    this.resolvedName = null;
    this.nsDetected = false;
  }

  close(res?: boolean, multipleAccounts?: boolean): void {
    if (this.isInline) {
      if (multipleAccounts) {
        this.quit.emit(null);
      } else {
        this.quit.emit(this.selectedAccount);
      }
    } else {
      this.dialogRef.close(res);
    }
  }

  closeDialog(): void {
    if (!this.isInline) {
      const message = ``
        .concat(this.translateService.instant(`TOAST.ACCOUNT`))
        .concat(` "${this.selectedSubAccount?.name || this.selectedAccount?.name}" `)
        .concat(this.translateService.instant(`TOAST.ADDED`));

      this.toastService.success(message);
    }

    this.close(true);
  }

  updateLocation(locationUrl: string): void {
    if (!this.isInline) {
      this.location.go(locationUrl);
    }
  }

  // Validators
  noWhiteSpaceValidator = (control: AbstractControl): any => {
    if (/\s/g.test(control.value) && (this.selectedSubAccount || this.selectedAccount)?.key !== `COINBASE`) {
      return { hasWhiteSpace: true };
    } else {
      return null;
    }
  };

  walletAddressLengthValidator = (control: AbstractControl): any => {
    const account = this.accounts.get((this.selectedSubAccount || this.selectedAccount)?.key);
    const addressMinLength = account?.accountMetadata?.addressMinLength;
    const addressMaxLength = account?.accountMetadata?.addressMaxLength;

    const extendedKeys = account?.accountMetadata?.extendedKeys;
    const publicKey = extendedKeys?.find((extendedKey: ExtendedKey) => control.value?.startsWith(extendedKey.prefix));

    if (publicKey) {
      if (control.value.length < publicKey.minLength) {
        return {
          minlength: {
            actualLength: control.value.length,
            requiredLength: publicKey.minLength,
          },
        };
      } else if (control.value.length > publicKey.maxLength) {
        return {
          maxlength: {
            actualLength: control.value.length,
            requiredLength: publicKey.maxLength,
          },
        };
      } else {
        return null;
      }
    } else {
      if (addressMinLength) {
        return control.value?.length < addressMinLength
          ? {
              minlength: {
                actualLength: control.value.length,
                requiredLength: addressMinLength,
              },
            }
          : null;
      }

      if (addressMaxLength) {
        return control.value?.length > addressMaxLength
          ? {
              maxlength: {
                actualLength: control.value.length,
                requiredLength: addressMaxLength,
              },
            }
          : null;
      }
    }
  };

  apiKeyPatternValidator = (control: AbstractControl): any => {
    const account = this.accounts.get((this.selectedSubAccount || this.selectedAccount)?.key);
    const keyRegexPattern = account?.accountMetadata?.keyRegexPattern;

    if (keyRegexPattern) {
      return new RegExp(keyRegexPattern).test(control.value) ? null : { addressPatternInvalid: true };
    } else {
      return null;
    }
  };

  apiKeyLengthValidator = (control: AbstractControl): any => {
    const account = this.accounts.get((this.selectedSubAccount || this.selectedAccount)?.key);
    const keyMaxLength = account?.accountMetadata?.keyMaxLength;

    if (keyMaxLength && control.value?.length > keyMaxLength) {
      return {
        maxlength: {
          actualLength: control.value.length,
          requiredLength: keyMaxLength,
        },
      };
    } else {
      return null;
    }
  };

  apiSecretLengthValidator = (control: AbstractControl): any => {
    const errors: any = {};
    const account = this.accounts.get((this.selectedSubAccount || this.selectedAccount)?.key);
    const secretMaxLength = account?.accountMetadata?.secretMaxLength;

    if (secretMaxLength && control.value?.length > secretMaxLength) {
      errors.maxlength = {
        actualLength: control.value.length,
        requiredLength: secretMaxLength,
      };
    }

    if (
      control?.value === `` &&
      (this.selectedSubAccount || this.selectedAccount)?.key !== `JUST_MINING` &&
      (this.selectedSubAccount || this.selectedAccount)?.key !== `BITPANDA` &&
      (this.selectedSubAccount || this.selectedAccount)?.key !== `CELSIUS`
    ) {
      errors.required = true;
    }

    return errors;
  };

  passphraseValidator = (control: AbstractControl): any => {
    if (
      (this.selectedSubAccount || this.selectedAccount)?.key === `COINBASE_PRO` ||
      (this.selectedSubAccount || this.selectedAccount)?.key === `KUCOIN` ||
      (this.selectedSubAccount || this.selectedAccount)?.key === `OKEX` ||
      (this.selectedSubAccount || this.selectedAccount)?.key === `BITGET`
    ) {
      return control.value === `` ? { required: true } : null;
    } else {
      return null;
    }
  };

  getNSSuffixAndPrefix(): void {
    const accountKey = this.selectedSubAccount?.key || this.selectedAccount?.key;
    const nameServices: NameServices = this.accounts.get(accountKey).accountMetadata?.nameServices;

    if (nameServices) {
      const prefixes = nameServices.prefix || [];
      const suffixes = nameServices.suffix || [];

      this.nsSuffixAndPrefix = [...prefixes, ...suffixes].join(`\n`);
    }
  }

  redirectToLedgerLive(): void {
    window.open(LEGDER_LIVE_LINK, `_blank`);
  }

  redirectToFeedback(): void {
    window.open(`https://feedback.waltio.com/`, `_blank`);
  }

  isBlockchainAccount(account: Account): boolean {
    return this.utilsService.isBlockchainAccount(this.accountsList.blockchain, account?.key);
  }

  isReownCompatible(account: Account): boolean {
    const reownAccounts = [...this.reownWallets?.values(), ...this.reownBlockchains?.values()];
    return reownAccounts.includes(account?.key);
  }

  openReownModal(): void {
    this.accountStore$.dispatch(disconnectReownModalAction());

    setTimeout(() => {
      this.accountStore$.dispatch(openReownModalAction());
    }, 500);
  }

  pasteFromClipboard(control: FormControl): void {
    navigator.clipboard.readText().then((text: string) => {
      control.markAsTouched();
      control.setValue(text);
    });
  }

  getFAQArticleId(): string {
    const accountName: string = this.selectedSubAccount?.key || this.selectedAccount?.key;

    switch (this.selectedAccountType) {
      case `API`:
        return this.accounts.get(accountName).articles.apiArticleId;
      case `FILE`:
        return this.accounts.get(accountName).articles.fileArticleId;
      default:
        return ``;
    }
  }

  openConfirmDialog(): void {
    const dialogRef = this.utilsService.openDialog(DeleteAccountDialogComponent, `567px`, `auto`, {
      accounts: this.accounts,
      account: this.selectedUserAccount,
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        map((res: boolean) => {
          if (res) {
            this.deleteAccount(this.selectedUserAccount);
          }
        }),
      )
      .subscribe();
  }

  deleteAccount(account: UserAccount): void {
    account.deleting = true;
    this.accountStore$.dispatch(updateUserAccountAction({ userAccount: account }));
    this.accountStore$.dispatch(deleteAccountAction({ account }));

    this.closeDialog();
  }

  showAccountsList(): boolean {
    if (this.isInline) {
      return !!this.searchBarControl.value || (this.selectedAccount?.supportsSubAccount && !this.selectedSubAccount);
    } else {
      return (
        !this.showSolutions &&
        !this.isApiConnected &&
        (!this.selectedAccount || (this.selectedAccount.supportsSubAccount && !this.selectedSubAccount))
      );
    }
  }

  showReownBanner(): boolean {
    const isReownModalOpened = this.reownModalState?.isOpened;
    const isReownCompatible = this.isReownCompatible(this.selectedSubAccount);
    const supportsSubAccount = this.selectedAccount?.supportsSubAccount;
    const hasCandidates = this.availableCandidates?.length > 0 && this.isApiConnected;

    return !isReownModalOpened && !hasCandidates && !isReownCompatible && (!this.selectedAccount || supportsSubAccount);
  }

  checkSuggestedPlatform(account: Account): void {
    const selectedSuggestedPlatform = this.accountsList.suggestedPlatforms.find(
      (suggestedPlatform: SuggestedPlatform) => suggestedPlatform.technicalName === account.key,
    );

    this.selectedSuggestedPlatform = selectedSuggestedPlatform;
  }
}
