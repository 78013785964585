import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { IntercomService } from 'src/app/shared/services/intercom.service';
import { SupportConfiguration } from '../../models/account.model';

@Component({
  selector: `app-account-expansion-panel`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDividerModule],
  templateUrl: `./account-expansion-panel.component.html`,
  styleUrl: `./account-expansion-panel.component.scss`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountExpansionPanelComponent {
  @Input() language: string;
  @Input() supportConfiguration: SupportConfiguration;
  @Input() icon?: string;
  @Input() title?: string;
  @Input() content?: string;
  @Input() showLink = true;

  isOpen = false;

  constructor(private intercomService: IntercomService) {}

  togglePanel(): void {
    this.isOpen = !this.isOpen;
  }

  showArticle(articleId: string): void {
    this.intercomService.showArticle(articleId);
  }

  getTitle(): string {
    if (this.supportConfiguration) {
      return this.supportConfiguration[`bannerTitle` + this.language?.toUpperCase()];
    }

    return ``;
  }

  getContent(): string {
    if (this.supportConfiguration) {
      return this.supportConfiguration[`bannerContent` + this.language?.toUpperCase()];
    }

    return ``;
  }
}
