import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store, select } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import * as fromAuth from '../../../../authentication/store/selectors/authentication.selector';
import {
  BANKING_REPATRIATION_PACKAGE_DEMO_FILE,
  BOOKKEEPING_LEDGER_DEMO_FILE,
  EXPORT_TRANSACTIONS_DEMO_FILE,
  FISCAL_ANNEX_DEMO_FILE,
  FISCAL_CONTROL_PACKAGE_DEMO_FILE,
  INFORMATION_LETTER_DEMO_FILE,
  STOCK_FILE_DEMO_FILE,
  SUMMARY_FILE_DEMO_FILE,
} from '../../../../shared/constants/public-links.constant';
import { UserPreferences } from '../../../../shared/models/user-preferences.model';
import { CurrencyDecimalPipe } from '../../../../shared/pipes/currency-decimal.pipe';
import { IntercomService } from '../../../../shared/services/intercom.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { trackEventAction } from '../../../../shared/store/actions/shared.action';
import * as fromShared from '../../../../shared/store/selectors/shared.selector';
import { FileType } from '../../../models/document.model';
import { CheckoutType, PaymentEstimateV3 } from '../../../models/payment.model';
import { User, UserPlan } from '../../../models/user.model';
import { loadPaymentEstimateAction } from '../../../store/actions/payment.action';
import * as fromPayment from '../../../store/selectors/payment.selector';
import { OfferToggleComponent } from '../../offer-toggle/offer-toggle.component';
import { CheckoutDialogComponent } from '../checkout-dialog/checkout-dialog.component';

@Component({
  selector: `app-upgrade-fishing-dialog`,
  standalone: true,
  imports: [
    CommonModule,
    OfferToggleComponent,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    CurrencyDecimalPipe,
    MatTooltipModule,
  ],
  templateUrl: `./upgrade-fishing-dialog.component.html`,
  styleUrls: [`./upgrade-fishing-dialog.component.scss`],
})
export class UpgradeFishingDialogComponent implements OnInit, OnDestroy {
  fiscalYear: number;
  defaultFiscalYear: number = moment().year();
  offer: any;
  recommendedPlan: UserPlan = `PLN000`;

  paymentEstimate: PaymentEstimateV3;
  useUpgradeFishingDialog = true;

  userPreferences: UserPreferences;
  stablecoins: string[];
  fiats: string[];
  user: User;

  title = ``;
  updateTitle = true;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<UpgradeFishingDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      name: string;
      checkoutType: CheckoutType;
      fiscalYear?: number;
      recommendedPlan?: UserPlan;
      offer?: any;
      title?: string;
      demoCTA?: string;
      demoFile?: FileType;
      updateTitle?: boolean;
    },
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly paymentStore$: Store<fromPayment.State>,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly utilsService: UtilsService,
    private readonly translateService: TranslateService,
    private readonly intercomService: IntercomService,
  ) {}

  ngOnInit(): void {
    this.recommendedPlan = this.data.recommendedPlan;
    this.offer = this.data.offer;

    if (this.data.updateTitle === undefined) {
      this.updateTitle = true;
    } else {
      this.updateTitle = this.data.updateTitle;
    }

    if (!this.utilsService.isFiscalYearSwitchable(this.defaultFiscalYear)) {
      this.defaultFiscalYear -= 1;
    }

    this.fiscalYear = this.data?.fiscalYear || this.defaultFiscalYear;

    this.getEstimate();

    this.title = this.data.title || this.getDefaultTitle(this.offer);

    this.sharedStore$.dispatch(
      trackEventAction({
        event: `upgrade_fishing`,
        data: {
          upgrade_name: this.data.name,
          upgrade_type: this.data.checkoutType === `PLAN` ? `plan` : `addon`,
        },
      }),
    );

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectPaymentEstimate),
        map((paymentEstimate: PaymentEstimateV3) => {
          this.paymentEstimate = paymentEstimate;
        }),
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectUserPreferences),
        tap((userPreferences: UserPreferences) => {
          this.userPreferences = userPreferences;
        }),
      )
      .subscribe();

    this.authStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAuth.selectUser),
        tap((user: User) => {
          this.user = user;
        }),
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectStableCoins),
        tap((stablecoins: string[]) => {
          this.stablecoins = stablecoins;
        }),
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectFiats),
        tap((fiats: string[]) => {
          this.fiats = fiats;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getEstimate(): void {
    this.paymentStore$.dispatch(
      loadPaymentEstimateAction({
        fiscalYear: this.fiscalYear,
        checkoutType: this.data.checkoutType,
        requestedPlan: this.data.checkoutType === `PLAN` ? this.offer : null,
        requestedAddOn: this.data.checkoutType === `ADDON` ? this.offer || `AOCARE001` : null,
      }),
    );
  }

  openCheckoutDialog(): void {
    if (this.user.lockedToApple) {
      this.utilsService.openLockedMobileToast();
    } else {
      const checkoutDialogRef = this.utilsService.openDialog(CheckoutDialogComponent, `478px`, `auto`, {
        checkoutType: this.data.checkoutType,
      });

      checkoutDialogRef
        .afterClosed()
        .pipe(
          takeUntil(this.destroy$),
          map(() => {
            this.dialogRef.close();
          }),
        )
        .subscribe();
    }
  }

  getDefaultTitle(offer: any): string {
    switch (offer) {
      case `PLN300`:
        return this.translateService.instant(`UPGRADE_FISHING.PLN300`, {
          year: this.fiscalYear,
        });
      case `PLN500`:
        return this.translateService.instant(`UPGRADE_FISHING.PLN500`, {
          year: this.fiscalYear,
        });
      case `PLN800`:
        return this.translateService.instant(`UPGRADE_FISHING.PLN800`);
    }
  }

  downloadDemoFile(): void {
    const language = this.userPreferences?.language.toLowerCase();
    const fiscalResidency = this.user.fiscalResidency;
    let url = ``;

    switch (this.data.demoFile) {
      case `WALTIO_EXPORT_FILE`:
        url = EXPORT_TRANSACTIONS_DEMO_FILE.get(language);
        break;
      case `STOCK_FILE`:
        url = STOCK_FILE_DEMO_FILE.get(language);
        break;
      case `SUMMARY`:
        url = SUMMARY_FILE_DEMO_FILE.get(fiscalResidency)[language];
        break;
      case `BOOKKEEPING_LEDGER`:
        url = BOOKKEEPING_LEDGER_DEMO_FILE.get(fiscalResidency)[language];
        break;
      case `INFORMATION_LETTER`:
        url = INFORMATION_LETTER_DEMO_FILE.get(fiscalResidency)[language];
        break;
      case `FISCAL_ANNEX`:
        url = FISCAL_ANNEX_DEMO_FILE.get(fiscalResidency)[language];
        break;
      case `FISCAL_CONTROL_PACKAGE`:
        url = FISCAL_CONTROL_PACKAGE_DEMO_FILE.get(fiscalResidency)[language];
        break;
      case `BANKING_REPATRIATION_PACKAGE`:
        url = BANKING_REPATRIATION_PACKAGE_DEMO_FILE.get(fiscalResidency)[language];
        break;
    }

    window.open(url, `_blank`);
  }

  openArticle(article: string): void {
    this.intercomService.showArticle(article);
  }

  toggleOffer(offer: any): void {
    this.offer = offer;

    if (this.offer === this.data.offer) {
      this.title = this.data.title || this.getDefaultTitle(offer);
    } else if (this.updateTitle) {
      this.title = this.getDefaultTitle(offer);
    } else {
      this.title = this.data.title;
    }

    this.getEstimate();
  }
}
