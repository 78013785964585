import { TaxableCession } from '../models/taxable-cession.model';

export const TAXABLE_CESSIONS_DEMO_FR: TaxableCession[] = [
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 1`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 2`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 3`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 4`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 5`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 6`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 7`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 8`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 9`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 10`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 11`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 12`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 13`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 14`,
    gainOrLoss: `O`
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
    index: `0`,
    label: `Cession 15`,
    gainOrLoss: `O`
  }
];

export const TAXABLE_CESSIONS_DEMO_ES: TaxableCession[] = [
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 1`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 2`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 3`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 4`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 5`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 6`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 7`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 8`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 9`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 10`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 11`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 12`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 13`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 14`,
    pnl: `0`
  },
  {
    1631: `01/03/2021`,
    1632: `01/03/2021`,
    1633: `119760`,
    1637: `119760`,
    index: `0`,
    label: `Cession 15`,
    pnl: `0`
  }
];