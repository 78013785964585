import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: `app-tax-grid-cell`,
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule],
  templateUrl: `./tax-grid-cell.component.html`,
  styleUrls: [`./tax-grid-cell.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxGridCellComponent {
  @ViewChild(`tooltip`) tooltip: MatTooltip;

  @Input() value: string;
  @Input() copiable = true;

  tooltipLabel = `COPY`;
  copied = false;

  constructor(public readonly clipboardService: ClipboardService) {}

  copyValue(): void {
    this.copied = true;
    this.clipboardService.copyFromContent(this.value);
    this.tooltipLabel = `COPIED`;
    this.tooltip.show();
  }
}
