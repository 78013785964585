import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LiveBalanceResponse } from '../models/live-balance.model';

@Injectable({
  providedIn: `root`,
})
export class AcquisitionPriceService {
  constructor(private readonly http: HttpClient) {}

  getAcquisitionPrices(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/v1/tax/acquisition-price`);
  }

  updateAcquisitionPrice(token: string, price: number): Observable<LiveBalanceResponse> {
    return this.http.put<LiveBalanceResponse>(
      `${environment.apiUrl}/v1/tax/acquisition-price?token=${token}&price=${price}`,
      {},
    );
  }

  deleteAcquisitionPrice(token: string): Observable<LiveBalanceResponse> {
    return this.http.delete<LiveBalanceResponse>(`${environment.apiUrl}/v1/tax/acquisition-price?token=${token}`);
  }
}
