import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromShared from '../../shared/store/selectors/shared.selector';
import { filter, map, tap } from 'rxjs/operators';
import { Feature } from '../../shared/models/feature.model';
import { goToAction, loadFeaturesAction, setPartnerAction } from '../../shared/store/actions/shared.action';

export const partnerGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const sharedStore = inject(Store<fromShared.State>);
  const partnerId = route.paramMap.get(`id`);

  let partner: string;

  // Transform the partnerId to uppercase to match the PARTNERS map
  switch (partnerId) {
    case `meria`:
      partner = `JUST_MINING`;
      break;
    case `feelmining`:
      partner = `FEEL_MINING`;
      break;
    case `aktio`:
    case `rayn`:
      partner = `AKT_IO`;
      break;
    default:
      partner = partnerId.toUpperCase();
  }

  return sharedStore.pipe(
    select(fromShared.selectFeatures(`partners:${partner}`)),
    tap((partners: Feature[]) => {
      if (!partners) {
        sharedStore.dispatch(loadFeaturesAction());
      }
    }),
    filter((partners: Feature[]) => partners !== null),
    map((partners: Feature[]) => {
      const partnerFeature: Feature = partners[0];

      if (!partnerFeature?.enabled) {
        sharedStore.dispatch(goToAction({ url: `dashboard` }));
      } else {
        sharedStore.dispatch(setPartnerAction({ partner }));
      }

      return partnerFeature ? partnerFeature.enabled : false;
    })
  );
};
