
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { User } from '../../../taxation/models/user.model';

@Component({
  selector: `app-profile-sidenav`,
  standalone: true,
  imports: [TranslateModule],
  templateUrl: `./profile-sidenav.component.html`,
  styleUrls: [`./profile-sidenav.component.scss`]
})
export class ProfileSidenavComponent {
  @Input() user: User;
}
