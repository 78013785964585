import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: `truncate`,
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value = ``, limit = 25, useEllipsis = true, ellipsis = `...`): string {
    if (!value) return ``;

    const formattedValue = value.replace(`€`, ``).trim();

    if (formattedValue.length <= limit) {
      return value;
    }

    let truncated = value.substring(0, limit);

    // Exception for en formatted amounts
    if (truncated.endsWith(`.`) && ellipsis === `...`) {
      truncated = value.substring(0, limit + 1);
    }

    return useEllipsis ? `${truncated}${ellipsis}` : truncated;
  }
}
