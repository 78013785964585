import { Component, OnDestroy, OnInit } from '@angular/core';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '../../../store/selectors/authentication.selector';
import { TwoFACheck } from '../../../../authentication/models/two-fa.model';
import { ClipboardService } from 'ngx-clipboard';
import { map, takeUntil } from 'rxjs/operators';
import { enable2FaAction } from '../../../../authentication/store/actions/authentication.action';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: `app-two-fa-dialog`,
  standalone: true,
  imports: [MatDialogModule, MatDividerModule, TranslateModule, ReactiveFormsModule, MatButtonModule],
  templateUrl: `./two-fa-dialog.component.html`,
  styleUrls: [`./two-fa-dialog.component.scss`],
})
export class TwoFaDialogComponent implements OnInit, OnDestroy {
  userTwoFA: TwoFACheck;
  form: FormGroup<{
    code: FormControl<string>;
    recoveryCode: FormControl<string>;
  }>;

  is2FaCodeValidated: boolean;
  isRecover = false;
  codesCopied = false;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly authStore$: Store<fromAuth.State>,
    private readonly fb: FormBuilder,
    private readonly clipboardService: ClipboardService,
    public dialogRef: MatDialogRef<TwoFaDialogComponent>,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.authStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAuth.selectUserTwoFA),
        map((userTwoFA: TwoFACheck) => {
          this.userTwoFA = userTwoFA;
        })
      )
      .subscribe();

    this.authStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAuth.selectIs2FaCodeValidated),
        map((is2FaCodeValidated: boolean) => {
          this.is2FaCodeValidated = is2FaCodeValidated;
        })
      )
      .subscribe();

    this.form = this.fb.group({
      code: [``, [Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern(`[0-9]*`)]],
      recoveryCode: [``, Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  validate2FaCode(): void {
    this.authStore$.dispatch(enable2FaAction({ code: this.form.get(`code`).value }));
  }

  copyCodes(): void {
    let codes = ``;
    for (const code of this.userTwoFA.recoveryCodes) {
      codes += `${code} \n`;
    }
    if (this.clipboardService.copyFromContent(codes)) {
      const translation = this.translateService.instant(`2FA_CODES_COPIED`);
      const message = `${translation}`;
      this.codesCopied = true;
      this.toastService.success(message, 2000);
    }
  }
}
