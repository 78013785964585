import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: `app-upload-api-progress`,
  standalone: true,
  imports: [MatProgressBarModule, NgOptimizedImage],
  templateUrl: `./upload-api-progress.component.html`,
  styleUrls: [`./upload-api-progress.component.scss`]
})
export class UploadApiProgressComponent {

}
