import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: `date`,
  pure: true,
  standalone: true,
})
export class NgxDatePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(value: any, pattern = `fullDate`): any {
    const datePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(value, pattern);
  }
}
