<div class="covered-platforms">
  <p class="body-12">{{ 'PLATFORMS_CONSIDERED' | translate }}</p>

  <div class="covered-platforms__content">
    <div class="covered-platforms__content__item" [matTooltip]="'PLATFORMS_CONSIDERED_CEFI' | translate">
      <p class="body-12-med">CeFi</p>
      <img width="12" height="12" src="/assets/img/icons/success.svg" alt="success" />
    </div>
    <div class="covered-platforms__content__item" [matTooltip]="'PLATFORMS_CONSIDERED_DEFI' | translate">
      <p class="body-12-med">DeFi</p>
      <p class="body-12-med">⏳</p>
    </div>
  </div>
</div>
