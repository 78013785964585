<div class="container">
  <section class="main">
    <div class="main__header">
      <h2>{{ goToYearLabel }}</h2>
      <img class="pointer" src="/assets/img/icons/close.svg" alt="" (click)="dialogRef.close()" />
    </div>

    <p class="body-14">{{ description }}</p>
  </section>
  <mat-divider></mat-divider>
  <section class="ctas">
    <button mat-flat-button color="secondary" (click)="dialogRef.close()">
      {{ 'CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="dialogRef.close(true)">
      {{ moveToFiscalYearLabel }}
    </button>
  </section>
</div>
