<div class="toast" [ngClass]="{ 'toast--title': data.type === 'warning' }">
  @switch (data.type) {
    @case ('success') {
      <img src="/assets/img/icons/success.svg" alt="" />
    }

    @case ('error') {
      <img src="/assets/img/icons/error.svg" alt="" />
    }

    @case ('loading') {
      <img class="rotating" src="/assets/img/icons/loading-toast.svg" alt="" />
    }

    @case ('warning') {
      <img src="/assets/img/icons/info-banner-warning.svg" alt="" />
    }
  }

  @if (data.title) {
    <div class="toast__content">
      <p class="body-14-med">{{ data.title }}</p>
      <p class="body-14">{{ data.message }}</p>
    </div>
  } @else {
    <p class="body-14">{{ data.message }}</p>
  }

  @if (data.type !== 'loading') {
    <img class="pointer" src="/assets/img/icons/close-white.svg" alt="" (click)="snackBarRef.closeWithAction()" />
  }
</div>
