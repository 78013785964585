/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { LiveBalanceResponse } from '../../models/live-balance.model';
import { ChartHistory, MarketFeed } from '../../models/market.model';
import { TickerFeed } from '../../models/ticker.model';
import { ManualStock } from '../../models/manual-stock.model';
import { TokenToggleResponse } from '../../models/insight.model';
// eslint-disable-next-line no-shadow
export enum InsightActions {
  StartMarketFeedStream = `[Insight] Start Market Feed Stream`,
  StopMarketFeedStream = `[Insight] Stop Market Feed Stream`,
  SetMarketFeed = `[Insight] Set Market Feed`,

  StartTickerFeedStream = `[Insight] Start Ticker Feed Stream`,
  StopTickerFeedStream = `[Insight] Stop Ticker Feed Stream`,
  SetTickerFeed = `[Insight] Set Ticker Feed`,

  ComputePortfolioValue = `[Insight] Compute Portfolio Value`,
  SetPortfolioValue = `[Insight] Set Portfolio Value`,

  LoadChartHistory = `[Insight] Load Chart History`,
  SetChartHistory = `[Insight] Set Chart History`,

  LoadLiveBalances = `[Insight] Load Live Balances`,
  SetLiveBalances = `[Insight] Set Live Balances`,

  LoadManualStocks = `[Insight] Load Manual Stocks`,
  SetManualStocks = `[Insight] Set Manual Stocks`,
  AddManualStock = `[Insight] Add Manual Stock`,
  UpdateManualStock = `[Insight] Update Manual Stock`,
  DeleteManualStock = `[Insight] Delete Manual Stock`,

  LoadAcquisitionPrices = `[Insight] Load Acquisition Prices`,
  SetAcquisitionPrices = `[Insight] Set Acquisition Prices`,
  UpdateAcquisitionPrice = `[Insight] Update Acquisition Price`,
  DeleteAcquisitionPrice = `[Insight] Delete Acquisition Price`,

  GetTokenToggleStatus = `[Insight] Get Token Toggle Status`,
  UpdateTokenToggleStatus = `[Insight] Update Token Toggle Status`,
  GetTokensToggleStatus = `[Insight] Get Tokens Toggle Status`,
  UpdateTokensToggleStatus = `[Insight] Update Tokens Toggle Status`,
}

export const computePortfolioValueAction = createAction(
  InsightActions.ComputePortfolioValue,
  props<{ currency?: string }>(),
);

export const setPortfolioValueAction = createAction(
  InsightActions.SetPortfolioValue,
  props<{ portfolioValue: number }>(),
);

export const startMarketFeedStreamAction = createAction(InsightActions.StartMarketFeedStream);

export const stopMarketFeedStreamAction = createAction(InsightActions.StopMarketFeedStream);

export const setMarketFeedAction = createAction(InsightActions.SetMarketFeed, props<{ marketFeed: MarketFeed }>());

export const startTickerFeedStreamAction = createAction(InsightActions.StartTickerFeedStream);

export const stopTickerFeedStreamAction = createAction(InsightActions.StopTickerFeedStream);

export const setTickerFeedAction = createAction(InsightActions.SetTickerFeed, props<{ tickerFeed: TickerFeed }>());

export const loadChartHistoryAction = createAction(InsightActions.LoadChartHistory);

export const setChartHistoryAction = createAction(
  InsightActions.SetChartHistory,
  props<{ chartHistory: ChartHistory }>(),
);

export const loadLiveBalancesAction = createAction(InsightActions.LoadLiveBalances, props<{ force?: boolean }>());

export const setLiveBalancesAction = createAction(
  InsightActions.SetLiveBalances,
  props<{ liveBalances: LiveBalanceResponse; force?: boolean }>(),
);

export const loadManualStocksAction = createAction(InsightActions.LoadManualStocks);

export const setManualStocksAction = createAction(
  InsightActions.SetManualStocks,
  props<{ manualStocks: ManualStock[] }>(),
);

export const addManualStockAction = createAction(InsightActions.AddManualStock, props<{ manualStock: ManualStock }>());

export const updateManualStockAction = createAction(
  InsightActions.UpdateManualStock,
  props<{ manualStock: ManualStock }>(),
);

export const deleteManualStockAction = createAction(
  InsightActions.DeleteManualStock,
  props<{ manualStockId: string }>(),
);

export const loadAcquisitionPricesAction = createAction(InsightActions.LoadAcquisitionPrices);

export const setAcquisitionPricesAction = createAction(
  InsightActions.SetAcquisitionPrices,
  props<{ acquisitionPrices: Map<string, any> }>(),
);

export const updateAcquisitionPriceAction = createAction(
  InsightActions.UpdateAcquisitionPrice,
  props<{ isUpdate: boolean; token: string; price: number }>(),
);

export const deleteAcquisitionPriceAction = createAction(
  InsightActions.DeleteAcquisitionPrice,
  props<{ token: string }>(),
);

export const getTokenToggleStatusAction = createAction(
  InsightActions.GetTokenToggleStatus,
  props<{ platform: string; token: string }>(),
);

export const updateTokenToggleStatusAction = createAction(
  InsightActions.UpdateTokenToggleStatus,
  props<{ platform: string; token: string; isToggled: boolean }>(),
);

export const getTokensToggleStatusAction = createAction(
  InsightActions.GetTokensToggleStatus,
  props<{ requests: TokenToggleResponse[] }>(),
);

export const updateTokensToggleStatusAction = createAction(
  InsightActions.UpdateTokensToggleStatus,
  props<{ requests: TokenToggleResponse[]; isToggled: boolean }>(),
);
