<div class="container">
  <div
    class="toggler"
    [ngClass]="{
      pointer: isEnabled,
      'toggler--enabled': isEnabled,
      'toggler--disabled': !isEnabled,
      'toggler--active': isActive
    }"
    [matTooltip]="!isEnabled ? ('VAULT_VOUCHER_CONFLICT' | translate) : null"
    [matTooltipPosition]="'right'"
    (click)="isEnabled ? toggle() : null"
    >
    <p class="body-14">{{ 'ADD_VOUCHER' | translate }}</p>
    <div class="arrow"></div>
  </div>

  @if (isEnabled && isActive) {
    <div class="voucher">
      <input
        [formControl]="voucherControl"
        matInput
        class="custom-input"
        [ngClass]="{ 'custom-input--error': voucherResponse && !voucherResponse.success }"
        type="text"
        customFocus
        defaultClass="custom-input"
        [placeholder]="'CODE' | translate"
        />
      @if (voucherControl.value.length > 0 && !voucherResponse?.success) {
        <span
          class="body-14-med add"
          (click)="addVoucher.emit(voucherControl.value)"
          >{{ 'ADD' | translate }}</span
          >
        }
        @if (voucherControl.value.length > 0 && voucherResponse?.success) {
          <span
            class="body-14-med add"
            (click)="deleteVoucher()"
            >{{ 'WITHDRAW' | translate }}</span
            >
          }
        </div>
      }
    </div>
