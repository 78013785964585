import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RedirectionUrl } from '../models/api.model';
import { APIDetails, Upload } from '../models/upload.model';
import { Candidate } from '../models/candidate.model';

@Injectable({
  providedIn: `root`,
})
export class APIService {
  constructor(private readonly http: HttpClient) {}

  /**
   * List user APIs
   */
  getApis(): Observable<Upload[]> {
    return this.http.get<Upload[]>(`${environment.apiUrl}/v1/tax/api`);
  }

  /**
   * Create API
   *
   * @param platform
   * @param api
   */
  createAPI(account: string, api: APIDetails, alias?: string, subAccount?: string): Observable<Upload> {
    let url = `${environment.apiUrl}/v1/tax/api/${subAccount || account}?`;

    if (subAccount) {
      url = url.concat(`aggregator=${account}`);
    }

    if (alias) {
      url = url.concat(`&alias=${alias}`);
    }

    return this.http.post<Upload>(url, api);
  }

  updateAPI(apiId: string, api: APIDetails): Observable<Upload> {
    return this.http.put<Upload>(`${environment.apiUrl}/v1/tax/api/${apiId}/keys?api=${apiId}`, api, {});
  }

  /**
   * Set or update to API with ApiId an alias
   *
   * @param apiId
   * @param alias
   */
  updateApiAlias(apiId: string, alias: string): Observable<Upload> {
    return this.http.put<Upload>(`${environment.apiUrl}/v1/tax/api/${apiId}/alias?alias=${alias}`, ``);
  }

  /**
   * Get available chains for api id
   *
   * @param apiId
   * @returns
   */
  getAvailableCandidates(apiId: string, address = ``, aggregator = ``): Observable<Candidate[]> {
    return this.http.get<Candidate[]>(
      `${environment.apiUrl}/v1/tax/api/${apiId}/discover?address=${address}&aggregator=${aggregator}`
    );
  }

  /**
   * Get OAuth sync url
   *
   * @returns redirection url
   */
  getOAuthSyncUrl(account: string): Observable<RedirectionUrl> {
    return this.http.get<RedirectionUrl>(
      `${environment.apiUrl}/v1/tax/api/oauth/${account.toLowerCase()}/redirection-url`
    );
  }

  /**
   * Create OAuth API
   *
   * @param code
   * @returns created API
   */
  createOAuthAPI(account: string, code: string): Observable<Upload> {
    let url = `${environment.apiUrl}/v1/tax/api/oauth/${account.toLowerCase()}/code?code=${code}`;

    // BITSTAMP
    if (account === `BITSTAMP`) {
      const codeVerifier = localStorage.getItem(`codeVerifier`);
      url = url.concat(`&codeVerifier=${codeVerifier}`);
    }

    return this.http.post<Upload>(url, ``);
  }

  /**
   * Sync API
   *
   * @param apiId
   */
  syncAPI(apiId: string): Observable<Upload> {
    return this.http.post(`${environment.apiUrl}/v1/tax/api/sync?apiId=${apiId}`, null);
  }
}
