import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LiveBalanceResponse } from '../models/live-balance.model';

@Injectable({
  providedIn: `root`,
})
export class BalanceService {
  constructor(private readonly http: HttpClient) {}

  getLiveBalances(force = false): Observable<LiveBalanceResponse> {
    return this.http.get<LiveBalanceResponse>(`${environment.apiUrl}/v1/tax/live-balance?force=${force}`).pipe(
      map((liveBalanceResponse: LiveBalanceResponse) => {
        liveBalanceResponse.acquisitionPrices = new Map<string, number>(
          Object.entries(liveBalanceResponse.acquisitionPrices),
        );

        return liveBalanceResponse;
      }),
    );
  }

  getLiveBalancesV2(force = false): Observable<LiveBalanceResponse> {
    return this.http
      .get<LiveBalanceResponse>(`${environment.apiUrl}/v1/tax/live-balance/incl-files?force=${force}`)
      .pipe(
        map((liveBalanceResponse: LiveBalanceResponse) => {
          liveBalanceResponse.acquisitionPrices = new Map<string, number>(
            Object.entries(liveBalanceResponse.acquisitionPrices),
          );

          return liveBalanceResponse;
        }),
      );
  }
}
