import { Action, createReducer, on } from '@ngrx/store';
import { Page } from '../../../shared/models/page.model';
import { DocumentRequest } from '../../models/document.model';
import * as DocumentActions from '../actions/document.action';

export interface State {
  documentRequestPage: Page<DocumentRequest>;
}

export const initialState: State = {
  documentRequestPage: null,
};

const reducer = createReducer(
  initialState,
  on(
    DocumentActions.setDocumentRequestPageAction,
    (state: State, { documentRequestPage }: any) => ({
      ...state,
      documentRequestPage,
    })
  ),
);

export const documentReducer = (
  state: State | undefined,
  action: Action
): State => reducer(state, action);
