import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AffiliationProfile } from '../../models/affiliation.model';
import { AffiliationService } from '../../services/affiliation.service';
import {
  enableAffiliationProfileAction,
  loadAffiliationProfileAction,
  loadVaultAction,
  loadVaultEventPageAction,
  setAffiliationProfileAction,
  setVaultAction,
  setVaultEventPageAction,
} from '../actions/affiliation.action';
import { Page } from '../../../shared/models/page.model';
import { Vault, VaultEvent } from '../../models/vault.model';
import { ToastService } from '../../../shared/services/toast.service';

@Injectable()
export class AffiliationEffects {
  loadAffiliationProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAffiliationProfileAction>>(
        loadAffiliationProfileAction
      ),
      switchMap(() =>
        this.affiliationService.getAffiliationProfile().pipe(
          map((affiliationProfile: AffiliationProfile) =>
            setAffiliationProfileAction({ affiliationProfile })
          ),
          catchError((error: any) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  enableAffiliationProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof enableAffiliationProfileAction>>(
        enableAffiliationProfileAction
      ),
      switchMap(() =>
        this.affiliationService.enableAffiliationProfile().pipe(
          map((affiliationProfile: AffiliationProfile) =>
            setAffiliationProfileAction({ affiliationProfile })
          ),
          catchError((error: any) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  loadVault$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadVaultAction>>(loadVaultAction),
      switchMap(() =>
        this.affiliationService.getVault().pipe(
          map((vault: Vault) => setVaultAction({ vault })),
          catchError((error: any) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  loadVaultEventPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadVaultEventPageAction>>(
        loadVaultEventPageAction
      ),
      switchMap((action: ReturnType<typeof loadVaultEventPageAction>) =>
        this.affiliationService.getAffiliationVaultEventsPage(action.page, action.size, action.sort).pipe(
          map((vaultEventPage: Page<VaultEvent>) =>
            setVaultEventPageAction({ vaultEventPage })
          ),
          catchError((error: any) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly affiliationService: AffiliationService,
    private readonly toastService: ToastService
  ) { }
}
