<div class="container" [ngClass]="{ 'container--inline': isInline }">
  <section
    class="header"
    [ngClass]="{
      'header--inline': isInline && !selectedAccount,
      'center-title': !showBackButton || reownModalState?.isOpened,
    }"
  >
    @if (showBackButton && !reownModalState?.isOpened) {
      <img
        class="back-button"
        [ngClass]="{
          'back-button--visible': selectedAccount && !availableCandidates,
        }"
        src="/assets/img/icons/back.svg"
        alt=""
        (click)="back()"
      />
    }

    @if (showSolutions) {
      <h1>{{ 'CORRECTION_SOLUTIONS' | translate }}</h1>
    } @else if (!selectedAccount) {
      @if (isInline) {
        <h1>{{ 'ADD_MY_FIRST_ACCOUNT' | translate }}</h1>

        <div class="time-spark">
          <img src="/assets/img/icons/spark.svg" alt="spark" />

          <p class="body-14-med">1 min</p>
        </div>
      } @else {
        <h1>{{ 'ACCOUNTS.ADD_CRYPTO_ACCOUNT' | translate }}</h1>
      }
    } @else if (isSelectedAccountSuggestedPlatform) {
      <div class="suggested-platform">
        <h1>{{ selectedAccount.name }}</h1>

        <div class="suggested-platform__icon">
          <img src="/assets/img/icons/tool.svg" alt="tool" />
        </div>
      </div>
    } @else if (updateAPI) {
      <h1>{{ 'UPDATE' | translate }} {{ selectedAccount.name }} (API)</h1>
    } @else if (updateFile) {
      <h1>{{ 'UPDATE' | translate }} {{ 'MY_FILE' | translate }} {{ selectedAccount.name }}</h1>
    } @else {
      <h1>
        {{
          ('ACCOUNTS.ADD_ACCOUNT' | translate) +
            ' ' +
            selectedAccount.name +
            ' ' +
            (selectedSubAccount ? '(' + selectedSubAccount.name + ')' : '') +
            ' ' +
            (selectedAccountType && !selectedSubAccount ? '(' + (selectedAccountType | translate) + ')' : '')
            | truncate: 41
        }}
      </h1>
    }

    @if (!reownModalState?.isOpened) {
      <app-close-button
        class="close-button"
        [ngClass]="{
          'close-button--visible': showCloseButton,
        }"
        (click)="close(false)"
      ></app-close-button>
    }
  </section>

  <section
    class="content-container"
    [ngClass]="{
      'available-chains': availableCandidates?.length > 0 && isApiConnected,
    }"
  >
    @if (!reownModalState?.isOpened) {
      @if (!showSolutions && (!selectedAccount || (selectedAccount.supportsSubAccount && !selectedSubAccount))) {
        <div class="searchbar-container">
          <input
            [formControl]="searchBarControl"
            class="searchbar body-14"
            [ngClass]="{
              'searchbar--focus': !searchBarControl.value,
            }"
            type="text"
            [placeholder]="(isInline ? 'SEARCH_ACCOUNTS' : 'SEARCH') | translate"
          />

          @if (searchBarControl.value) {
            <img src="/assets/img/icons/searchbar-clear.svg" alt="clear" (click)="searchBarControl.setValue('')" />
          }
        </div>
      }

      @if (
        !isInline &&
        !showSolutions &&
        !selectedAccount &&
        (filteredAccounts | accountsSorter: searchBarControl.value)?.length > 0
      ) {
        <mat-chip-listbox
          class="custom-chip-listbox"
          (change)="filterAccounts($event.value)"
          hideSingleSelectionIndicator
        >
          <mat-chip-option
            class="custom-chip"
            [ngClass]="{
              'custom-chip--selected': !accountCategory,
            }"
            value=""
            [selected]="!accountCategory"
            disableRipple
            >{{ 'ALL' | translate }}</mat-chip-option
          >

          <mat-chip-option
            class="custom-chip"
            [ngClass]="{
              'custom-chip--selected': accountCategory === 'platform',
            }"
            value="platform"
            [selected]="accountCategory === 'platform'"
            disableRipple
            >{{ 'PLATFORMS' | translate }}</mat-chip-option
          >

          <mat-chip-option
            class="custom-chip"
            [ngClass]="{
              'custom-chip--selected': accountCategory === 'blockchain',
            }"
            value="blockchain"
            [selected]="accountCategory === 'blockchain'"
            disableRipple
            >{{ 'BLOCKCHAINS' | translate }}</mat-chip-option
          >
          <mat-chip-option
            class="custom-chip"
            [ngClass]="{
              'custom-chip--selected': accountCategory === 'wallet',
            }"
            value="wallet"
            [selected]="accountCategory === 'wallet'"
            disableRipple
            >{{ 'WALLETS' | translate }}</mat-chip-option
          >

          <mat-chip-option
            class="custom-chip"
            [ngClass]="{
              'custom-chip--selected': accountCategory === 'service',
            }"
            value="service"
            [selected]="accountCategory === 'service'"
            disableRipple
            >{{ 'SERVICES' | translate }}</mat-chip-option
          >
        </mat-chip-listbox>
      }

      @if (!accountCategory && !showSolutions && !selectedAccount && !searchBarControl.value) {
        <div class="accounts-container">
          @for (account of topAccounts; track account) {
            <app-account-card
              [account]="account"
              [selected]="selectedAccount === account"
              (click)="selectAccount(account)"
            ></app-account-card>
          }
        </div>

        @if (!isInline) {
          <mat-divider class="top-accounts-divider"></mat-divider>
        }
      }

      <!-- STEP ACCOUNT -->
      @if (showAccountsList()) {
        <div
          class="accounts-container"
          [ngClass]="{
            'accounts-container--extra': !selectedAccount && !isInline,
          }"
        >
          @if (!selectedAccount) {
            @for (account of filteredAccounts | accountsSorter: searchBarControl.value; track account) {
              <app-account-card
                [account]="account"
                [selected]="selectedAccount === account"
                (click)="selectAccount(account)"
              ></app-account-card>
            }
          }
          @if (selectedAccount?.supportsSubAccount && !selectedSubAccount) {
            @for (subAccount of selectedAccountChildren | accountsSorter: searchBarControl.value; track subAccount) {
              <app-account-card
                [account]="subAccount"
                [selected]="selectedSubAccount === subAccount"
                (click)="selectSubAccount(subAccount)"
              ></app-account-card>
            }
          }
          @if (searchBarControl.value && (filteredAccounts | accountsSorter: searchBarControl.value)?.length === 0) {
            <div class="no-accounts">
              <p class="body-14-med">
                {{ 'NO_ACCOUNT_FOUND' | translate }}
                <span class="pointer" (click)="openArticle('5042695')">{{ 'OUR_TUTORIAL' | translate }}</span
                >.
              </p>
              <div class="request-integration pointer" (click)="redirectToFeedback()">
                <img src="/assets/img/icons/write.svg" alt="write" />
                <p class="body-14-bold">
                  {{ 'REQUEST_INTEGRATION' | translate }}
                  <img src="/assets/img/icons/open-link.svg" alt="open-link" />
                </p>
              </div>
            </div>
          }
        </div>
      }

      <!-- SOLUTIONS -->
      @if (showSolutions) {
        <div class="integration-types-container">
          @if (quotaReached) {
            <app-banner
              [title]="'UNUSUAL_TX_NUMBER' | translate"
              [subtitle]="'UNUSUAL_TX_NUMBER_DESC' | translate"
            ></app-banner>

            <app-import-button
              [iconUrl]="'/assets/img/icons/document-icon.svg'"
              [label]="'DELETE_API'"
              (click)="openConfirmDialog()"
            ></app-import-button>
          } @else {
            @if (!isBlockchainAccount(selectedAccount)) {
              @if (
                selectedUserAccount?.status !== 'PLATFORM_NOT_SUPPORTED' && selectedUserAccount?.status !== 'CLOSED'
              ) {
                <app-import-button
                  [iconUrl]="'/assets/img/icons/api-icon.svg'"
                  [label]="'ADD_NEW_API'"
                  (click)="showSolutions = false; selectedAccountType = 'API'; submitButtonLabel = 'UPDATE'"
                ></app-import-button>
              } @else {
                <app-import-button
                  [iconUrl]="'/assets/img/icons/file-icon.svg'"
                  [label]="'ADD_FILES'"
                  (click)="showSolutions = false; selectedAccountType = 'FILE'"
                ></app-import-button>
              }
            }
            <app-import-button
              [iconUrl]="'/assets/img/icons/document-icon.svg'"
              [label]="'CONSULT_FAQ'"
              (click)="openArticle(getFAQArticleId())"
            ></app-import-button>
          }

          <app-import-button
            [iconUrl]="'/assets/img/icons/chat-icon.svg'"
            [label]="'CONTACT_CUSTOMER_SERVICE'"
            (click)="openArticle()"
          ></app-import-button>
        </div>
      }

      <!-- STEP INTEGRATION TYPE -->
      @if (
        !isApiConnected &&
        !selectedAccountType &&
        !showSolutions &&
        ((selectedAccount && !selectedAccount.supportsSubAccount) || selectedSubAccount)
      ) {
        <div class="integration-types-container">
          <!-- SUPPORT CONF -->
          @if (selectedAccount?.supportConfiguration?.showBanner) {
            <app-account-expansion-panel
              [language]="userPreferences?.language"
              [supportConfiguration]="selectedAccount?.supportConfiguration"
            ></app-account-expansion-panel>
          }

          <!-- LEDGER_LIVE -->
          @if (selectedAccount?.key === 'LEDGER_LIVE' || selectedSubAccount?.key === 'LEDGER_LIVE') {
            <div class="ledger-live-instructions">
              <p class="body-14-bold">
                {{ 'INSTRUCTIONS' | translate }}
              </p>
              <div class="steps">
                <div class="step">
                  <div class="step__number">
                    <p class="body-12-med">1</p>
                  </div>
                  <div class="step__text">
                    <p class="body-14">{{ 'LAUNCH_APP' | translate }}</p>
                    <div class="share pointer" (click)="redirectToLedgerLive()">
                      <p class="body-13-med">{{ ledgerLiveAccount?.name }}</p>
                      <img src="/assets/img/icons/share-primary.svg" alt="share" />
                    </div>
                  </div>
                </div>
                <div class="step">
                  <div class="step__number">
                    <p class="body-12-med">2</p>
                  </div>
                  <p class="body-14">{{ 'SEARCH_WALTIO' | translate }}</p>
                </div>
                <div class="step">
                  <div class="step__number">
                    <p class="body-12-med">3</p>
                  </div>
                  <p class="body-14">{{ 'LOGIN_WALTIO' | translate }}</p>
                </div>
              </div>
            </div>
          }

          <!-- OAUTH -->
          @if (
            (isFeatureEnabled(selectedAccount?.key + ':oauth') | async) ||
            (isFeatureEnabled(selectedSubAccount?.key + ':oauth') | async)
          ) {
            <button type="button" mat-flat-button color="sync" disableRipple (click)="createOAuthSync()">
              {{ 'CONTINUE_WITH' | translate }} {{ selectedSubAccount?.name || selectedAccount?.name }}
            </button>
          }
          <!-- SPLITTER -->
          @if (
            selectedAccount?.key !== 'FINARY' &&
            ((isFeatureEnabled(selectedAccount?.key + ':oauth') | async) ||
              (isFeatureEnabled(selectedSubAccount?.key + ':oauth') | async) ||
              selectedAccount?.key === 'LEDGER_LIVE' ||
              selectedSubAccount?.key === 'LEDGER_LIVE')
          ) {
            <div class="splitter">
              <mat-divider></mat-divider>
              <span class="or body-14">
                {{ 'OR' | translate | lowercase }}
              </span>
              <mat-divider></mat-divider>
            </div>
          }

          <!-- API -->
          @if (
            (isSelectedAccountSuggestedPlatform &&
              (selectedSuggestedPlatform?.supportsApiKey || selectedSuggestedPlatform?.supportsWalletAddress)) ||
            (isFeatureEnabled(selectedAccount?.key + ':api') | async) ||
            (isFeatureEnabled(selectedSubAccount?.key + ':api') | async)
          ) {
            <app-import-button
              [iconUrl]="'/assets/img/icons/api-icon.svg'"
              [label]="'CONNECT_API'"
              [isRecommendedImportMethod]="selectedAccount?.supportConfiguration?.recommendedImportMethod === 'API'"
              (click)="selectAccountType('API')"
            ></app-import-button>
          }

          @if (isReownCompatible(selectedSubAccount || selectedAccount)) {
            <app-import-button
              [iconUrl]="'/assets/img/icons/wallet-connect.png'"
              [label]="'CONNECT_WALLET'"
              (click)="openReownModal()"
            ></app-import-button>
          }

          <!-- FILE -->
          @if (
            (isSelectedAccountSuggestedPlatform && selectedSuggestedPlatform?.supportsFile) ||
            (isFeatureEnabled(selectedAccount?.key + ':file') | async) ||
            (isFeatureEnabled(selectedSubAccount?.key + ':file') | async)
          ) {
            <app-import-button
              [iconUrl]="'/assets/img/icons/file-icon.svg'"
              [label]="'IMPORT_FILE'"
              [isRecommendedImportMethod]="selectedAccount?.supportConfiguration?.recommendedImportMethod === 'FILE'"
              (click)="selectAccountType('FILE')"
            ></app-import-button>
          }
        </div>
      }

      <!-- STEP API -->
      @if (selectedAccountType === 'API' && !availableCandidates) {
        <!-- WALLET FORM -->
        @if (
          accountsList.wallet.includes(selectedSubAccount || selectedAccount) ||
          accountsList.blockchain.includes(selectedSubAccount || selectedAccount)
        ) {
          <form [formGroup]="walletForm" class="form">
            <app-upload-api-progress></app-upload-api-progress>

            <!-- ADDRESS FIELD -->
            <div>
              <div class="field-header">
                <div class="icon">
                  <p class="body-14-med">
                    {{ 'ACCOUNTS.ADDRESS_NS' | translate }}
                  </p>
                  @if (nsSuffixAndPrefix?.length >= 1) {
                    <div class="info-tooltip" [matTooltip]="nsSuffixAndPrefix" matTooltipPosition="right"></div>
                  }
                </div>
                <app-link [label]="'SHOW_TUTORIAL' | translate" (click)="openArticle(getFAQArticleId())"></app-link>
              </div>
              <div class="input-container input-container--paste">
                <input
                  matInput
                  formControlName="address"
                  class="custom-input body-14"
                  [ngClass]="{
                    'custom-input--error':
                      apiErrorMessage || (walletForm.get('address').invalid && walletForm.get('address').touched),
                  }"
                  [placeholder]="'PLACEHOLDERS.ADDRESS_NS' | translate"
                  type="text"
                  customFocus
                  defaultClass="custom-input"
                />
                @if (nsDetected && !apiErrorMessage) {
                  <div class="label label--success">
                    {{ 'NAME_SERVICE' | translate }}
                  </div>
                } @else {
                  <p class="body-14-med pointer" (click)="pasteFromClipboard(walletForm.get('address'))">
                    {{ 'PASTE' | translate }}
                  </p>
                }
              </div>
              @if (nsDetected && resolvedName?.address && !apiErrorMessage) {
                <p class="body-12 m-t-12">{{ 'ADDRESS' | translate }} : {{ resolvedName.address }}</p>
              }
              <!-- ERRORS -->
              @if (walletForm.get('address').touched) {
                @if (apiErrorCode === 'TAX2004') {
                  <div class="api-error-banner">
                    <img width="14" height="14" src="/assets/img/icons/alert-icon.svg" alt="alert-icon" />

                    <div class="api-error-banner__content">
                      <p class="body-13-bold">{{ 'TRANSACTION_VOLUME_HIGH' | translate }}</p>
                      <p class="body-13-med">{{ 'CONTACT_SUPPORT' | translate }}</p>
                    </div>
                  </div>
                } @else if (apiErrorMessage) {
                  <p class="input-error">{{ apiErrorMessage }}</p>
                }

                @if (walletForm.get('address').hasError('required')) {
                  <p class="input-error">
                    {{ 'ERRORS.MISSING_VALUE' | translate }}
                  </p>
                }
                @if (walletForm.get('address').hasError('maxlength')) {
                  <p class="input-error">
                    {{ 'ERRORS.TOO_LONG' | translate }} ({{
                      this.walletForm.get('address').getError('maxlength').actualLength
                    }}
                    /
                    {{ walletForm.get('address').getError('maxlength').requiredLength }}).
                  </p>
                }
                @if (walletForm.get('address').hasError('minlength')) {
                  <p class="input-error">
                    {{ 'ERRORS.TOO_SHORT' | translate }} ({{
                      this.walletForm.get('address').getError('minlength').actualLength
                    }}
                    /
                    {{ walletForm.get('address').getError('minlength').requiredLength }}).
                  </p>
                }
                @if (walletForm.get('address').hasError('addressPatternInvalid')) {
                  <p class="input-error">
                    {{ 'ERRORS.ADDRESS_PATTERN' | translate }}
                  </p>
                }
                @if (walletForm.get('address').hasError('hasWhiteSpace')) {
                  <p class="input-error">
                    {{ 'ERRORS.WHITE_SPACE' | translate }}
                  </p>
                }
              }
            </div>
            <button
              type="button"
              mat-flat-button
              color="primary"
              disableRipple
              [disabled]="walletForm.invalid || (nsDetected && !resolvedName)"
              appDebounceClick
              (click)="isApiConnecting = true"
              (debounceClick)="importWallet()"
            >
              @if (!isApiConnecting) {
                {{ submitButtonLabel | translate }}
              }
              @if (isApiConnecting && !apiErrorMessage) {
                <img class="rotating" src="/assets/img/icons/processing.svg" alt="" />
              }
            </button>
          </form>
        }
        <!-- API FORM -->
        @if (accountsList.platform.includes(selectedSubAccount || selectedAccount)) {
          <form [formGroup]="apiForm" class="form">
            @if (updateAPI) {
              <app-banner [title]="'ADD_NEW_API' | translate" [subtitle]="'CREATE_API' | translate"></app-banner>
            } @else {
              <app-upload-api-progress></app-upload-api-progress>
            }

            <!-- API KEY FIELD -->
            <div>
              <div class="field-header">
                <div>
                  <p class="body-14-med">{{ 'API' | translate }}</p>
                </div>
                <app-link [label]="'SHOW_TUTORIAL' | translate" (click)="openArticle(getFAQArticleId())"></app-link>
              </div>
              <div class="input-container input-container--paste">
                <input
                  matInput
                  formControlName="apiKey"
                  class="custom-input body-14"
                  [ngClass]="{
                    'custom-input--error':
                      apiErrorMessage || (apiForm.get('apiKey').invalid && apiForm.get('apiKey').touched),
                  }"
                  [placeholder]="'PLACEHOLDERS.API_KEY' | translate"
                  type="text"
                  customFocus
                  defaultClass="custom-input"
                />

                <p class="body-14-med pointer" (click)="pasteFromClipboard(apiForm.get('apiKey'))">
                  {{ 'PASTE' | translate }}
                </p>
              </div>
              <!-- ERRORS -->
              @if (apiForm.get('apiKey').touched) {
                @if (apiErrorCode === 'TAX2004') {
                  <div class="api-error-banner">
                    <img width="14" height="14" src="/assets/img/icons/alert-icon.svg" alt="alert-icon" />

                    <div class="api-error-banner__content">
                      <p class="body-13-bold">{{ 'TRANSACTION_VOLUME_HIGH' | translate }}</p>
                      <p class="body-13-med">{{ 'CONTACT_SUPPORT' | translate }}</p>
                    </div>
                  </div>
                } @else if (apiErrorMessage) {
                  <p class="input-error">{{ apiErrorMessage }}</p>
                }

                @if (apiForm.get('apiKey').hasError('required')) {
                  <p class="input-error">
                    {{ 'ERRORS.MISSING_VALUE' | translate }}
                  </p>
                }
                @if (apiForm.get('apiKey').hasError('maxlength')) {
                  <p class="input-error">
                    {{ 'ERRORS.TOO_LONG' | translate }} ({{
                      this.apiForm.get('apiKey').getError('maxlength').actualLength
                    }}
                    /
                    {{ apiForm.get('apiKey').getError('maxlength').requiredLength }}).
                  </p>
                }
                @if (apiForm.get('apiKey').hasError('hasWhiteSpace')) {
                  <p class="input-error">
                    {{ 'ERRORS.WHITE_SPACE' | translate }}
                  </p>
                }
              }
            </div>
            <!-- API SECRET FIELD -->
            @if (
              (selectedSubAccount || selectedAccount).key !== 'BITPANDA' &&
              (selectedSubAccount || selectedAccount).key !== 'CELSIUS' &&
              (selectedSubAccount || selectedAccount).key !== 'JUST_MINING'
            ) {
              <div>
                <div class="field-header">
                  <div>
                    <p class="body-14-med">{{ 'SECRET_API' | translate }}</p>
                  </div>
                </div>
                <div class="input-container input-container--paste">
                  <input
                    matInput
                    formControlName="apiSecret"
                    class="custom-input body-14"
                    [ngClass]="{
                      'custom-input--error': apiForm.get('apiSecret').invalid && apiForm.get('apiSecret').touched,
                    }"
                    [placeholder]="'PLACEHOLDERS.API_SECRET_KEY' | translate"
                    type="text"
                    customFocus
                    defaultClass="custom-input"
                  />

                  <p class="body-14-med pointer" (click)="pasteFromClipboard(apiForm.get('apiSecret'))">
                    {{ 'PASTE' | translate }}
                  </p>
                </div>
                <!-- ERRORS -->
                @if (apiForm.get('apiSecret').touched) {
                  @if (apiForm.get('apiSecret').hasError('required')) {
                    <p class="input-error">
                      {{ 'ERRORS.MISSING_VALUE' | translate }}
                    </p>
                  }
                  @if (apiForm.get('apiSecret').hasError('maxlength')) {
                    <p class="input-error">
                      {{ 'ERRORS.TOO_LONG' | translate }} ({{
                        this.apiForm.get('apiSecret').getError('maxlength').actualLength
                      }}
                      /
                      {{ apiForm.get('apiSecret').getError('maxlength').requiredLength }}).
                    </p>
                  }
                  @if (apiForm.get('apiSecret').hasError('hasWhiteSpace')) {
                    <p class="input-error">
                      {{ 'ERRORS.WHITE_SPACE' | translate }}
                    </p>
                  }
                }
              </div>
            }
            <!-- PASSPHRASE -->
            @if (
              (selectedSubAccount || selectedAccount).key === 'COINBASE_PRO' ||
              (selectedSubAccount || selectedAccount).key === 'KUCOIN' ||
              (selectedSubAccount || selectedAccount).key === 'KUCOIN_FUTURES' ||
              (selectedSubAccount || selectedAccount).key === 'FTX' ||
              (selectedSubAccount || selectedAccount).key === 'OKEX' ||
              (selectedSubAccount || selectedAccount).key === 'BITMART' ||
              (selectedSubAccount || selectedAccount).key === 'BITGET'
            ) {
              <div>
                <div class="field-header">
                  <div>
                    @if ((selectedSubAccount || selectedAccount).key === 'FTX') {
                      <p class="body-14-med">
                        {{ 'FTX_SUB_ACCOUNT' | translate }}
                      </p>
                    }
                    @if (
                      (selectedSubAccount || selectedAccount).key !== 'FTX' &&
                      (selectedSubAccount || selectedAccount).key !== 'BITMART'
                    ) {
                      <p class="body-14-med">
                        {{ 'PASSPHRASE' | translate }}
                      </p>
                    }
                    @if (
                      (selectedSubAccount || selectedAccount).key !== 'FTX' &&
                      (selectedSubAccount || selectedAccount).key === 'BITMART'
                    ) {
                      <p class="body-14-med">
                        {{ 'MEMO' | translate }}
                      </p>
                    }
                  </div>
                </div>
                <div class="input-container input-container--paste">
                  <input
                    matInput
                    formControlName="passphrase"
                    class="custom-input body-14"
                    [ngClass]="{
                      'custom-input--error': apiForm.get('passphrase').invalid && apiForm.get('passphrase').touched,
                    }"
                    [placeholder]="
                      ((selectedSubAccount || selectedAccount).key === 'FTX'
                        ? 'PLACEHOLDERS.FTX_SUBACCOUNT'
                        : (selectedSubAccount || selectedAccount).key !== 'FTX' &&
                            (selectedSubAccount || selectedAccount).key === 'BITMART'
                          ? 'PLACEHOLDERS.MEMO'
                          : 'PLACEHOLDERS.PASSPHRASE'
                      ) | translate
                    "
                    type="text"
                    customFocus
                    defaultClass="custom-input"
                  />

                  <p class="body-14-med pointer" (click)="pasteFromClipboard(apiForm.get('passphrase'))">
                    {{ 'PASTE' | translate }}
                  </p>
                </div>
                <!-- ERRORS -->
                @if (apiForm.get('passphrase').touched) {
                  @if (apiForm.get('passphrase').hasError('required')) {
                    <p class="input-error">
                      {{ 'ERRORS.MISSING_VALUE' | translate }}
                    </p>
                  }
                  @if (apiForm.get('passphrase').hasError('hasWhiteSpace')) {
                    <p class="input-error">
                      {{ 'ERRORS.WHITE_SPACE' | translate }}
                    </p>
                  }
                }
              </div>
            }
            <button
              type="button"
              mat-flat-button
              color="primary"
              disableRipple
              [disabled]="apiForm.invalid"
              appDebounceClick
              (click)="isApiConnecting = true"
              (debounceClick)="importAPI()"
            >
              @if (!isApiConnecting) {
                {{ submitButtonLabel | translate }}
              }
              @if (isApiConnecting && !apiErrorMessage) {
                <img class="rotating" src="/assets/img/icons/processing.svg" alt="" />
              }
            </button>
          </form>
        }
      }

      <!-- FILE FORM -->
      @if (selectedAccountType === 'FILE') {
        <div class="form">
          @if (updateFile) {
            @if (hasIncrementalUpload) {
              <app-banner
                [title]="'UPDATING_YOUR_TX' | translate"
                [subtitle]="'UPDATE_FILE_INC' | translate"
              ></app-banner>
            } @else {
              <div class="file-update-warning">
                <img src="/assets/img/icons/warning-icon.svg" alt="warning-icon" />
                <div class="file-update-warning__content">
                  <p class="body-14-bold">{{ 'UPDATING_YOUR_TX' | translate }}</p>
                  <p class="body-14">{{ updateFileSubtitle }}</p>
                </div>
              </div>
            }
          }

          <div>
            <div class="field-header">
              <div>
                <p class="body-14-med">{{ 'IMPORT_FILE' | translate }}</p>
              </div>
              <app-link [label]="'SHOW_TUTORIAL' | translate" (click)="openArticle(getFAQArticleId())"></app-link>
            </div>
            <app-file-importer
              [account]="selectedAccount"
              [userAccounts]="userAccounts"
              [subAccount]="selectedSubAccount"
              [timezones]="timezones"
              [accessToken]="accessToken"
              [autoProcessQueue]="true"
              (uploadSuccess)="closeDialog()"
            ></app-file-importer>
          </div>
        </div>
      }

      @if (selectedAccountType === 'SUGGESTED') {
        <app-suggested-platform-importer
          [accountType]="suggestedPlatformAccountType"
          [suggestedPlatform]="selectedSuggestedPlatform"
          [account]="selectedAccount"
          [userAccounts]="userAccounts"
          [timezones]="timezones"
          [accessToken]="accessToken"
        ></app-suggested-platform-importer>
      }

      @if (availableCandidates?.length > 0 && isApiConnected) {
        <app-banner
          [title]="'ACCOUNTS.SELECT_ACCOUNTS' | translate"
          [subtitle]="bannerSubtitle"
          [selectedChains]="selectedCandidates.length"
          [cta]="selectedCandidates.length === 0 ? 'SELECT_ALL' : 'CLEAR_ALL'"
          (ctaClick)="
            selectedCandidates.length === 0 ? selectAllAvailableCandidates() : unselectAllAvailableCandidates()
          "
        ></app-banner>
        <div class="accounts-container accounts-container--candidates">
          @for (availableCandidate of availableCandidates; track availableCandidate) {
            @if (availableCandidate?.platform) {
              <app-account-card
                [account]="
                  accounts.get(availableCandidate.platform) || {
                    key: availableCandidate.platform,
                    name: availableCandidate.platform,
                  }
                "
                [selected]="selectedCandidates.includes(availableCandidate)"
                [alternative]="true"
                (click)="selectAvailableCandidate(availableCandidate)"
              ></app-account-card>
            }
          }
        </div>
      }
    } @else {
      <img
        class="rotating margin-auto-0"
        src="/assets/img/icons/loader-wallet-connect.svg"
        alt="loader-wallet-connect"
      />
    }
  </section>

  @if (availableCandidates?.length > 0 && isApiConnected) {
    <section class="accounts-actions">
      <button type="button" mat-flat-button color="secondary" disableRipple (click)="closeDialog()">
        {{ 'CONTINUE_WITHOUT_ADDING' | translate }}
      </button>
      <button
        type="button"
        mat-flat-button
        color="primary"
        disableRipple
        [disabled]="selectedCandidates.length === 0"
        (click)="importSelectedCandidates()"
      >
        {{ 'ADD' | translate }}
      </button>
    </section>
  }

  @if (showReownBanner()) {
    <div class="wallet-connect-banner">
      <div class="label">
        <img src="/assets/img/icons/guard.svg" alt="guard" />
        <p class="body-14-med">{{ 'FAST_WALLET_CONNECTION' | translate }}</p>
      </div>
      <button type="button" mat-flat-button color="primary" disableRipple (click)="openReownModal()">
        {{ 'CONNECT_WALLET' | translate }}
      </button>
    </div>
  }
</div>
