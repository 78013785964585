<div class="container">
  <section class="header">
    <h2>{{ deleteAccount }}</h2>
    <img class="pointer" src="/assets/img/icons/close.svg" alt="" mat-dialog-close />
  </section>

  <p class="body-14">
    {{ deleteAccountContent }}
  </p>

  <section class="ctas">
    <button type="button" mat-flat-button color="secondary" mat-dialog-close>{{ 'CANCEL' | translate }}</button>
    <button type="button" mat-flat-button color="warn" [mat-dialog-close]="true">{{ 'DELETE' | translate }}</button>
  </section>
</div>
