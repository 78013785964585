/* eslint-disable @typescript-eslint/naming-convention */
import { Action, createReducer, on } from '@ngrx/store';
import { Account } from '../../../taxation/models/account.model';
import { AccountListResponse } from '../../../taxation/models/account.model';
import { Balance } from '../../../taxation/models/balance.model';
import { GeolocationDetails } from '../../../taxation/models/geolocation-details.model';
import { Configuration } from '../../models/configuration.model';
import { CustomError } from '../../models/error.model';
import { Feature, UserFeature } from '../../models/feature.model';
import { ReportedToken } from '../../models/reported-token.model';
import { Scam } from '../../models/scam.model';
import { TransferRequest } from '../../models/transfer-request.model';
import { UserPreferences } from '../../models/user-preferences.model';
import * as SharedActions from '../actions/shared.action';

export interface State {
  coins: any;
  stablecoins: string[];
  fiats: string[];
  configuration: Configuration;
  isConfigurationLoaded: boolean;
  userLocation: GeolocationDetails;
  userPreferences: UserPreferences;
  intercom: {
    pageName: string;
    analysisCount: number;
  };
  accounts: AccountListResponse;
  features: Feature[];
  emailTransferRequest: TransferRequest;
  hasEmailTransferError: boolean;
  frenchPostCodes: Map<string, string>;
  belgianFiscalProfiles: Map<string, string>;
  spanishAutonomousCommunities: Map<string, string>;
  userBalance: Balance;
  onboarding: {
    pending: boolean;
    completed: boolean;
    onboardingError: CustomError;
    account: Account;
  };
  isPaymentDialogOpened: boolean;
  partner: string;
  currentOpenedForm: string;
  userScamList: Scam[];
  userReportedTokenList: ReportedToken[];
  userSCC: any;
  userFeatures: UserFeature[];
}

export const initialState: State = {
  coins: null,
  stablecoins: [],
  fiats: [],
  isConfigurationLoaded: false,
  userLocation: null,
  userPreferences: null,
  intercom: {
    pageName: ``,
    analysisCount: -1,
  },
  accounts: null,
  configuration: {
    pagination: {
      pageSize: 25,
      pageSort: `transactionDate`,
      pageDirection: `desc`,
    },
  },
  features: [],
  emailTransferRequest: null,
  hasEmailTransferError: false,
  frenchPostCodes: new Map<string, string>([]),
  belgianFiscalProfiles: new Map<string, string>([]),
  spanishAutonomousCommunities: new Map<string, string>([]),
  userBalance: null,
  onboarding: {
    pending: null,
    completed: null,
    onboardingError: null,
    account: null,
  },
  isPaymentDialogOpened: false,
  partner: null,
  currentOpenedForm: `3916-bis`,
  userScamList: null,
  userReportedTokenList: null,
  userSCC: null,
  userFeatures: null,
};

const reducer = createReducer(
  initialState,
  on(SharedActions.setAccountsAction, (state: State, { accounts }: any) => ({
    ...state,
    accounts,
  })),
  on(SharedActions.setCoinsAction, (state: State, { coins }: any) => ({
    ...state,
    coins,
  })),
  on(SharedActions.setConfigurationAction, (state: State, { configuration }: any) => ({
    ...state,
    configuration,
    isConfigurationLoaded: true,
  })),
  on(SharedActions.setAnalysisCountAction, (state: State, { analysisCount }: any) => ({
    ...state,
    intercom: {
      ...state.intercom,
      analysisCount,
    },
  })),
  on(SharedActions.setUserPreferencesAction, (state: State, { userPreferences }: any) => ({
    ...state,
    userPreferences,
  })),
  on(SharedActions.setFeaturesAction, (state: State, { features }: any) => ({
    ...state,
    features,
  })),
  on(SharedActions.setStablecoinsAction, (state: State, { stablecoins }: any) => ({
    ...state,
    stablecoins,
  })),
  on(SharedActions.setFiatsAction, (state: State, { fiats }: any) => ({
    ...state,
    fiats,
  })),
  on(SharedActions.setEmailTransferRequestAction, (state: State, { emailTransferRequest }: any) => ({
    ...state,
    emailTransferRequest,
  })),
  on(SharedActions.setHasEmailTransferRequestAction, (state: State, { hasEmailTransferError }: any) => ({
    ...state,
    hasEmailTransferError,
  })),
  on(SharedActions.setUserLocationAction, (state: State, { userLocation }: any) => ({
    ...state,
    userLocation,
  })),
  on(SharedActions.setFrenchPostCodesAction, (state: State, { frenchPostCodes }: any) => ({
    ...state,
    frenchPostCodes,
  })),
  on(SharedActions.setBelgianFiscalProfilesAction, (state: State, { belgianFiscalProfiles }: any) => ({
    ...state,
    belgianFiscalProfiles,
  })),
  on(SharedActions.setSpanishAutonomousCommunitiesAction, (state: State, { spanishAutonomousCommunities }: any) => ({
    ...state,
    spanishAutonomousCommunities,
  })),
  on(SharedActions.setOnboardingPendingAction, (state: State, { account }: any) => ({
    ...state,
    onboarding: {
      ...state.onboarding,
      pending: true,
      onboardingError: null,
      account,
    },
  })),
  on(SharedActions.setOnboardingCompletedAction, (state: State) => ({
    ...state,
    onboarding: {
      ...state.onboarding,
      completed: true,
      onboardingError: null,
    },
  })),
  on(SharedActions.setOnboardingErrorAction, (state: State, { onboardingError }: any) => ({
    ...state,
    onboarding: {
      ...state.onboarding,
      completed: false,
      onboardingError,
    },
  })),
  on(SharedActions.setIsPaymentDialogOpenedAction, (state: State, { isPaymentDialogOpened }: any) => ({
    ...state,
    isPaymentDialogOpened,
  })),
  on(SharedActions.setPartnerAction, (state: State, { partner }: any) => ({
    ...state,
    partner,
  })),
  on(SharedActions.setCurrentOpenedFormAction, (state: State, { currentOpenedForm }: any) => ({
    ...state,
    currentOpenedForm,
  })),
  on(SharedActions.setUserScamListAction, (state: State, { userScamList }: any) => ({
    ...state,
    userScamList,
  })),
  on(SharedActions.setUserReportedTokenListAction, (state: State, { userReportedTokenList }: any) => ({
    ...state,
    userReportedTokenList,
  })),
  on(SharedActions.setUserSCCAction, (state: State, { userSCC }: any) => ({
    ...state,
    userSCC,
  })),
  on(SharedActions.setUserFeaturesAction, (state: State, { userFeatures }: any) => ({
    ...state,
    userFeatures,
  })),
);

export const sharedReducer = (state: State | undefined, action: Action): State => reducer(state, action);
