import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: `app-toggle-title`,
  standalone: true,
  imports: [CommonModule, CurrencyPipe],
  templateUrl: `./toggle-title.component.html`,
  styleUrls: [`./toggle-title.component.scss`],
})
export class ToggleTitleComponent {
  @Input() title: string;
  @Input() count: number;
  @Input() amount: number;
  @Input() active = false;
}
