import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: `app-considered-platforms`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatTooltipModule],
  templateUrl: `./considered-platforms.component.html`,
  styleUrl: `./considered-platforms.component.scss`,
})
export class ConsideredPlatformsComponent {}
