<div class="container">
  <div class="container__content">
    <img width="39" height="39" [ngSrc]="iconUrl" alt="" priority />
    {{ label | translate }}

    @if (isRecommendedImportMethod) {
      <div class="recommended-chip">
        <img src="/assets/img/icons/thumb-up-white.svg" alt="thumb-up-white" />
        <p class="body-14-med">
          {{ 'RECOMMENDED_ALT' | translate }}
        </p>
      </div>
    }
  </div>

  <img src="/assets/img/icons/next.svg" alt="" />
</div>
