import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomPaginator implements MatPaginatorIntl {
  itemsPerPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;
  firstPageLabel: string;
  lastPageLabel: string;
  changes = new Subject<void>();

  constructor(private readonly translateService: TranslateService) {
    this.itemsPerPageLabel = this.translateService.instant(
      `PaginatorItemsPerPageLabel`
    );
    this.nextPageLabel = this.translateService.instant(
      `PaginatorNextPageLabel`
    );
    this.previousPageLabel = this.translateService.instant(
      `PaginatorPreviousPageLabel`
    );
    this.firstPageLabel = this.translateService.instant(
      `PaginatorFirstPageLabel`
    );
    this.lastPageLabel = this.translateService.instant(
      `PaginatorLastPageLabel`
    );
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    const pageLabel = this.translateService.instant(`PAGE`);
    const on = this.translateService.instant(`ON`);

    if (length === 0) {
      return `${pageLabel} 1 ${on} 1`;
    }

    const amountPages = Math.ceil(length / pageSize);

    return `${pageLabel} ${page + 1} ${on} ${amountPages}`;
  }
}
