import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NewFeature } from '../../../models/feature.model';
import { NEW_FEATURES } from '../../../constants/new-features.constant';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: `app-new-features-dialog`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDividerModule, MatButtonModule],
  templateUrl: `./new-features-dialog.component.html`,
  styleUrl: `./new-features-dialog.component.scss`,
})
export class NewFeaturesDialogComponent implements OnInit {
  features: NewFeature[] = NEW_FEATURES;
  currentFeature: NewFeature;
  currentFeatureIndex: number;

  constructor(private readonly dialogRef: MatDialogRef<NewFeaturesDialogComponent>) {}

  ngOnInit(): void {
    this.currentFeatureIndex = 0;
    this.showFeature(this.features[this.currentFeatureIndex]);
  }

  close(): void {
    localStorage.setItem(`new-features`, `true`);
    this.dialogRef.close();
  }

  showFeature(feature: NewFeature): void {
    this.currentFeature = feature;
  }

  nextFeature(): void {
    this.currentFeatureIndex++;
    this.showFeature(this.features[this.currentFeatureIndex]);
  }

  previousFeature(): void {
    this.currentFeatureIndex--;
    this.showFeature(this.features[this.currentFeatureIndex]);
  }
}
