import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TimezoneDetails } from '../../shared/models/file.model';
import { environment } from '../../../environments/environment';
import { Upload } from '../models/upload.model';

@Injectable({
  providedIn: `root`,
})
export class FileService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get files
   */
  getFiles(): Observable<Upload[]> {
    return this.http.get<Upload[]>(`${environment.apiUrl}/v1/tax/file`);
  }

  /**
   * Delete file
   *
   * @param fileId
   */
  deleteFile(fileId: any): Observable<string> {
    return this.http.delete<string>(`${environment.apiUrl}/v1/tax/file?file=${fileId}`);
  }

  updateFileAlias(fileId: string, alias: string): Observable<Upload> {
    return this.http.put<Upload>(`${environment.apiUrl}/v1/tax/file/${fileId}/alias?alias=${alias}`, ``);
  }

  /**
   * Download files
   *
   * @param fileId
   */
  downloadFile(fileId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/v1/tax/file/dl?file=${fileId}`, { responseType: `blob` });
  }

  /**
   * Get number of transactions per uploadId
   */
  getAccountsTransactionsCount(): Observable<Map<string, number>> {
    return this.http
      .get<Record<string, number>>(`${environment.apiUrl}/v1/tax/file/statistics/transactions`)
      .pipe(
        map((transactionsCount: Record<string, number>) => new Map<string, number>(Object.entries(transactionsCount)))
      );
  }

  /**
   * Get all timezones
   *
   * @returns all timezones
   */
  getTimezones(): Observable<TimezoneDetails[]> {
    return this.http.get<TimezoneDetails[]>(`${environment.apiUrl}/v1/tax/file/timezone/list`);
  }
}
