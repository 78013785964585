import { Component, Input } from '@angular/core';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { TaxFormInputComponent } from '../tax-form-input/tax-form-input.component';
import { SPAIN_TAX_FORM_PLATFORMS } from '../../constants/forms.constant';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Modelo } from '../../models/modelo.model';

@Component({
  selector: `app-spanish-tax-form`,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDividerModule,
    MatRadioModule,
    TaxFormInputComponent,
    MatTooltipModule,
    TitleCasePipe,
  ],
  providers: [CurrencyPipe],
  templateUrl: `./spanish-tax-form.component.html`,
  styleUrl: `./spanish-tax-form.component.scss`,
})
export class SpanishTaxFormComponent {
  @Input() platform: string;
  @Input() token: string;
  @Input() coins: Map<string, string>;
  @Input() modelo: Modelo;

  @Input() firstSection = false;
  @Input() demo = false;

  taxFormPlatforms = SPAIN_TAX_FORM_PLATFORMS;
}
