import { UserAccount } from './../../models/user-account.model';
import { Action, createReducer, on } from '@ngrx/store';
import { TimezoneDetails } from '../../../shared/models/file.model';
import { NameResolverResponse } from '../../models/name-resolver-response.model';
import * as AccountActions from '../actions/account.action';
import { Upload } from '../../models/upload.model';
import { Candidate } from '../../models/candidate.model';

export interface State {
  userAccounts: UserAccount[];
  userAPIs: Upload[];
  transactionsCount: Map<string, number>;
  api: {
    isConnecting: boolean;
    isConnected: boolean;
    errorMessage: string;
  };
  availableCandidates: Candidate[];
  resolvedName: NameResolverResponse;
  timezones: TimezoneDetails[];
}

export const initialState: State = {
  userAccounts: null,
  userAPIs: null,
  transactionsCount: null,
  api: {
    isConnecting: false,
    isConnected: false,
    errorMessage: ``,
  },
  availableCandidates: null,
  resolvedName: null,
  timezones: [],
};

const reducer = createReducer(
  initialState,
  on(AccountActions.resetAction, (state: State) => ({
    ...initialState,
    userAccounts: state.userAccounts,
    userAPIs: state.userAPIs,
  })),
  on(AccountActions.setUserAccountsAction, (state: State, { userAccounts }: any) => ({
    ...state,
    userAccounts,
  })),
  on(AccountActions.updateUserAccountAction, (state: State, { userAccount }: any) => ({
    ...state,
    userAccounts: state.userAccounts.map((account: UserAccount) =>
      account.accountId === userAccount.accountId ? userAccount : account
    ),
  })),
  on(AccountActions.removeUserAccountAction, (state: State, { userAccount }: any) => ({
    ...state,
    userAccounts: state.userAccounts.filter(
      (account: UserAccount) => account.accountId !== userAccount.accountId && !account.deleting
    ),
  })),
  on(AccountActions.setTransactionsCountAction, (state: State, { transactionsCount }: any) => ({
    ...state,
    transactionsCount,
  })),
  on(AccountActions.createApiAction, (state: State) => ({
    ...state,
    api: {
      ...state.api,
      isConnecting: true,
      isConnected: false,
      errorMessage: ``,
    },
  })),
  on(AccountActions.setApiConnectedAction, (state: State) => ({
    ...state,
    api: {
      ...state.api,
      isConnecting: true,
      isConnected: true,
      errorMessage: ``,
    },
  })),
  on(AccountActions.setApiErrorMessageAction, (state: State, { apiErrorMessage }: any) => ({
    ...state,
    api: {
      ...state.api,
      isConnecting: false,
      isConnected: false,
      errorMessage: apiErrorMessage,
    },
  })),
  on(AccountActions.setAvailableCandidatesAction, (state: State, { availableCandidates }: any) => ({
    ...state,
    availableCandidates,
    api: {
      ...state.api,
      isConnecting: false,
      isConnected: true,
    },
  })),
  on(AccountActions.setResolvedNameAction, (state: State, { resolvedName }: any) => ({
    ...state,
    api: {
      ...state.api,
      isConnecting: false,
      isConnected: false,
      errorMessage: ``,
    },
    resolvedName,
  })),
  on(AccountActions.loadTimezonesAction, (state: State) => ({
    ...state,
    timezones: [],
  })),
  on(AccountActions.setTimezonesAction, (state: State, { timezones }: any) => ({
    ...state,
    timezones,
  })),
  on(AccountActions.setUserAPIsAction, (state: State, { userAPIs }: any) => ({
    ...state,
    userAPIs,
  }))
);

export const accountReducer = (state: State | undefined, action: Action): State => reducer(state, action);
