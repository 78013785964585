import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Link } from '../../models/link.model';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: `app-navigation-tile`,
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MatTooltipModule],
  templateUrl: `./navigation-tile.component.html`,
  styleUrls: [`./navigation-tile.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationTileComponent {
  @Input() link: Link;
  @Output() navigate: EventEmitter<void> = new EventEmitter<void>();
}
