import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Configuration } from '../models/configuration.model';

@Injectable({
  providedIn: `root`,
})
export class ConfigurationService {
  constructor(private readonly http: HttpClient) {}

  /**
   *  Get transactions page configuration by key
   *
   * @param key
   * @returns
   */
  getConfiguration(key: string): Observable<Configuration> {
    return this.http.get<Configuration>(
      `${environment.apiUrl}/v1/tax/configuration/${key}`
    );
  }

  /**
   * Update transactions page configuration by key
   *
   * @param key
   * @param configuration
   * @returns
   */
  updateConfiguration(
    key: string,
    configuration: Configuration
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.apiUrl}/v1/tax/configuration/${key}`,
      configuration
    );
  }
}
