import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NameResolverResponse } from '../models/name-resolver-response.model';

@Injectable({
  providedIn: `root`,
})
export class NameResolverService {
  constructor(private readonly http: HttpClient) {}

  resolveName(
    platform: string,
    name: string
  ): Observable<NameResolverResponse> {
    return this.http.get<NameResolverResponse>(
      `${environment.apiUrl}/v1/name-resolver/resolve/${platform}/${name}`
    );
  }
}
