import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { TypeformDialogComponent } from '../../../shared/components/dialogs/typeform-dialog/typeform-dialog.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { OpportunitiesHeaderComponent } from '../../components/opportunities-header/opportunities-header.component';
import { UserOpportunities } from '../../models/user-opportunities.model';
import {
  loadUserOpportunitiesAction,
  loadUserOpportunitiesInstructionsAction,
} from '../../store/actions/opportunity.action';
import * as fromOpportunity from '../../store/selectors/opportunity.selector';

@Component({
  selector: `app-opportunities-page`,
  standalone: true,
  imports: [CommonModule, RouterModule, OpportunitiesHeaderComponent],
  templateUrl: `./opportunities-page.component.html`,
})
export class OpportunitiesPageComponent implements OnInit, OnDestroy {
  userOpportunities: UserOpportunities;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly opportunityStore$: Store<fromOpportunity.State>,
    private readonly utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.opportunityStore$.dispatch(loadUserOpportunitiesAction());
    this.opportunityStore$.dispatch(loadUserOpportunitiesInstructionsAction());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openFeedbackDialog(): void {
    this.utilsService.openDialog(TypeformDialogComponent, `1050px`, `573px`, {
      typeformId: `HdV0ROXG`,
    });
  }
}
