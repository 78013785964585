import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { Assessment, AssessmentStatus } from '../../../taxation/models/assessment.model';
import { User } from '../../../taxation/models/user.model';

@Component({
  selector: `app-assessment-status`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MomentModule],
  templateUrl: `./assessment-status.component.html`,
  styleUrls: [`./assessment-status.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentStatusComponent {
  @Input() assessment: Assessment;
  @Input() assessmentStatus: AssessmentStatus;
  @Input() assessmentETA: number;
  @Input() user: User;

  @Output() statusClick: EventEmitter<void> = new EventEmitter<void>();

  isHovered = false;

  formatETA(time: number): string {
    // Hours, minutes and seconds
    const hrs = Math.floor(time / 3600);
    const mins = Math.floor((time % 3600) / 60);
    const secs = Math.floor((time % 3600) % 60);

    const hrsTxt = hrs > 0 ? `${hrs} h ` : ``;
    const minsTxt = mins > 0 ? `${mins} min ` : ``;
    const secsTxt = secs > 0 ? `${secs} s ` : ``;

    return `${hrsTxt}${minsTxt}${secsTxt}`;
  }
}
