import { Injectable } from '@angular/core';
import { ReportedToken } from '../../shared/models/reported-token.model';
import { Scam } from '../../shared/models/scam.model';
import { LiveBalanceResponseItem } from '../models/live-balance.model';
import { MarketPrice } from '../models/market.model';

@Injectable({
  providedIn: `root`,
})
export class MarketService {
  isScam(balance: LiveBalanceResponseItem, userScamList: Scam[], userReportedTokenList: ReportedToken[]): boolean {
    const scam: Scam = userScamList?.find((s: Scam) => {
      if (!s.address) {
        const symbol = balance?.token?.toLowerCase();
        const scamSymbol = s.symbol?.toLowerCase();

        return symbol === scamSymbol;
      } else {
        const address = balance?.address?.toLowerCase();
        const scamAddress = s.address?.toLowerCase();

        return address === scamAddress;
      }
    });

    const reportedToken = userReportedTokenList?.find((r: ReportedToken) => {
      if (!r.address) {
        const symbol = balance?.token?.toLowerCase();
        const reportedTokenSymbol = r.symbol?.toLowerCase();

        return symbol === reportedTokenSymbol;
      } else {
        const address = balance?.address?.toLowerCase();
        const reportedTokenAddress = r.address?.toLowerCase();

        return address === reportedTokenAddress;
      }
    });

    if (scam || reportedToken) {
      return true;
    } else {
      return balance?.possibleSpam || balance?.reported;
    }
  }

  getTokenPrice(marketPrices: MarketPrice[], token: string, currency = `EUR`): number {
    const tokenPrice = marketPrices?.find((price: MarketPrice) => price.symbol === token)?.price || 0;
    const currencyValue = marketPrices?.find((price: MarketPrice) => price.symbol === currency)?.price || 0;
    let price = 0;

    if (currency === `EUR` || currency === `USD`) {
      price = tokenPrice * currencyValue;
    } else {
      price = tokenPrice / currencyValue;
    }

    return price;
  }

  getTokenMarketHistoryPrices(
    chartHistory: Map<string, number[]>,
    marketPrices: MarketPrice[],
    token: string,
    currency = `EUR`
  ): number[] {
    const currencyValue = marketPrices?.find((price: MarketPrice) => price.symbol === currency)?.price || 0;
    const tokenPrice = chartHistory?.get(token) || [];

    return tokenPrice.map((price: number) => {
      let priceValue = 0;

      if (currency === `EUR` || currency === `USD`) {
        priceValue = price * currencyValue;
      } else {
        priceValue = price / currencyValue;
      }

      return priceValue;
    });
  }
}
