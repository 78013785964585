import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResponse } from '../../shared/models/generic-response.model';
import { environment } from '../../../environments/environment';
import { Page } from '../../shared/models/page.model';
import { DocumentRequest, DocumentType } from '../models/document.model';

@Injectable({
  providedIn: `root`,
})
export class DocumentService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get Document Request By User ID
   *
   * @returns document request
   */
  getDocumentRequests(page = 0, size = 100, sort = `desc`): Observable<Page<DocumentRequest>> {
    return this.http.get<Page<DocumentRequest>>(
      `${environment.apiUrl}/v1/tax/document-request/list?page=${page}&size=${size}&sort=${sort}`
    );
  }

  /**
   * Create Document Request
   *
   * @param documentRequestType
   * @returns document request
   */
  createDocumentRequest(documentRequestType: DocumentType, attachToAssessmentId?: string): Observable<DocumentRequest> {
    let url = `${environment.apiUrl}/v1/tax/document-request?documentRequestType=${documentRequestType}`;

    if (attachToAssessmentId) {
      url = url.concat(`&attachToAssessmentId=${attachToAssessmentId}`);
    }

    return this.http.post<DocumentRequest>(url, ``, {});
  }

  /**
   * Download document request file
   *
   * @param documentId
   * @returns file
   */
  downloadDocumentRequest(documentRequest: DocumentRequest, format = ``): Observable<any> {
    let url = `${environment.apiUrl}/v1/tax/document-request/dl`;

    if (format) {
      url = url.concat(`/${format}`);
    }

    url = url.concat(`?documentId=${documentRequest.id}`);

    return this.http.get(url, { responseType: `blob` });
  }

  /**
   * Get Document Request By Id
   *
   * @param documentRequestId
   * @returns document request
   */
  getDocumentRequestById(documentRequestId: string): Observable<DocumentRequest> {
    return this.http.get<DocumentRequest>(`${environment.apiUrl}/v1/tax/document-request/${documentRequestId}`);
  }

  /**
   * Delete Document Request
   *
   * @param documentRequestId
   * @returns document request
   */
  deleteDocumentRequest(documentRequestId: string): Observable<GenericResponse> {
    return this.http.delete<GenericResponse>(
      `${environment.apiUrl}/v1/tax/document-request?documentId=${documentRequestId}`
    );
  }
}
