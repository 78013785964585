<div class="early-access-feature">
  <section
    class="early-access-feature__banner"
    [ngClass]="{
      'early-access-feature__banner--staking': userFeature === 'STAKING',
    }"
  ></section>
  <section class="early-access-feature__header">
    <div class="early-access-chip">
      <p>{{ 'EARLY_ACCESS_UNLOCKED' | translate }}</p>
    </div>

    <h1>{{ 'YOU_SELECTED' | translate }}</h1>
  </section>
  <section class="early-access-feature__content">
    <p class="body-14" [innerHTML]="'STAKING_CONTENT' | translate"></p>

    <div class="feature-items">
      <div class="feature-item">
        <img src="/assets/img/icons/staking-active.svg" alt="staking-active" />
        <p class="body-12-bold">{{ 'STAKING' | translate | uppercase }}</p>
      </div>
      <div class="feature-item">
        <img src="/assets/img/icons/stats-icon.svg" alt="stats-icon" />
        <p class="body-12-bold">{{ 'FLEXIBLE' | translate | uppercase }}</p>
      </div>
      <div class="feature-item">
        <img src="/assets/img/icons/card-icon.svg" alt="card-icon" />
        <p class="body-12-bold">{{ 'NO_FEES' | translate | uppercase }}</p>
      </div>
    </div>
  </section>

  <button mat-flat-button color="black" (click)="closeDialog()">{{ 'ACCESS_STAKING' | translate }}</button>
</div>
