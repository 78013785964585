import { Component } from '@angular/core';


@Component({
  selector: `app-close-button`,
  standalone: true,
  imports: [],
  templateUrl: `./close-button.component.html`,
  styleUrls: [`./close-button.component.scss`]
})
export class CloseButtonComponent {

}
