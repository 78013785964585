import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store, select } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { ImagePreloadDirective } from '../../../shared/directives/image-preload.directive';
import { Form3916BisPlatformInfos } from '../../../shared/models/platform-adress.model';
import { UtilsService } from '../../../shared/services/utils.service';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { BannerComponent } from '../../components/banner/banner.component';
import { UpgradeFishingDialogComponent } from '../../components/dialogs/upgrade-fishing-dialog/upgrade-fishing-dialog.component';
import { FrenchTaxFormInputComponent } from '../../components/french-tax-form-input/french-tax-form-input.component';
import { FrenchTaxGridCellComponent } from '../../components/french-tax-grid-cell/french-tax-grid-cell.component';
import { FORM_3916_BIS_PLATFORMS } from '../../constants/forms.constant';
import { TAXABLE_CESSIONS_DEMO_ES, TAXABLE_CESSIONS_DEMO_FR } from '../../constants/taxable-cessions.constant';
import { Account } from '../../models/account.model';
import { Assessment, AssessmentFile, AssessmentStatus } from '../../models/assessment.model';
import { PaymentEstimateV3 } from '../../models/payment.model';
import { TaxableCession } from '../../models/taxable-cession.model';
import { TransactionFilters } from '../../models/transaction.model';
import { User } from '../../models/user.model';
import { downloadAssessmentFileAction } from '../../store/actions/assessment.action';
import { loadFiltersAction } from '../../store/actions/transaction.action';
import * as fromAssessment from '../../store/selectors/assessment.selector';
import * as fromPayment from '../../store/selectors/payment.selector';
import * as fromTransaction from '../../store/selectors/transaction.selector';
import { SpanishTaxableCessionComponent } from '../../components/spanish-taxable-cession/spanish-taxable-cession.component';

@Component({
  selector: `app-legal-forms-page`,
  templateUrl: `./legal-forms-page.component.html`,
  styleUrls: [`./legal-forms-page.component.scss`],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    TranslateModule,
    MatDividerModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    BannerComponent,
    FrenchTaxFormInputComponent,
    FrenchTaxGridCellComponent,
    ImagePreloadDirective,
    MatTooltipModule,
    SpanishTaxableCessionComponent,
  ],
})
export class LegalFormsPageComponent implements OnInit, OnDestroy {
  hasPaid = false;
  loading = false;
  selectedPlatform: string;
  accounts: Map<string, Account> = new Map<string, Account>([]);
  platforms: Map<string, Form3916BisPlatformInfos> = FORM_3916_BIS_PLATFORMS;
  usedPlatforms: Map<string, Form3916BisPlatformInfos> = new Map<string, Form3916BisPlatformInfos>([]);
  otherPlatforms: Map<string, Form3916BisPlatformInfos> = new Map<string, Form3916BisPlatformInfos>(this.platforms);

  usedPlatformsOptions$: ReplaySubject<Map<string, Form3916BisPlatformInfos>> = new ReplaySubject(1);
  platformsOptions$: ReplaySubject<Map<string, Form3916BisPlatformInfos>> = new ReplaySubject(1);

  platformSearchControl: FormControl<string> = new FormControl<string>(``);
  currentForm = `3916-bis`;
  taxableCessions: TaxableCession[];
  displayedCessions = [];
  cessionsPageIndex = 0;
  maxCessionsPage = 1;
  taxableCessionsMsg = ``;

  platformsMap: Map<string, Form3916BisPlatformInfos> = FORM_3916_BIS_PLATFORMS;
  assessment: Assessment;
  assessmentStatus: AssessmentStatus;
  user: User;
  paymentEstimate: PaymentEstimateV3;
  showDemo = false;

  upgradeFishingCTA = ``;

  maxCessionsPerPage = 5;

  // ES
  selectedTaxableCession: TaxableCession;
  selectedTaxableCessionIndex = 0;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly sharedStore: Store<fromShared.State>,
    private readonly transactionStore: Store<fromTransaction.State>,
    private readonly paymentStore: Store<fromPayment.State>,
    private readonly assessmentStore: Store<fromAssessment.State>,
    private readonly authStore: Store<fromAuth.State>,
    private readonly utilsService: UtilsService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.transactionStore.dispatch(loadFiltersAction());

    combineLatest([
      this.paymentStore.select(fromPayment.selectUpgradeFishingEstimate),
      this.authStore.select(fromAuth.selectUser),
      this.assessmentStore.select(fromAssessment.selectAssessment),
      this.assessmentStore.select(fromAssessment.selectAssessmentStatus),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([paymentEstimate, user, assessment, assessmentStatus]) => {
          this.paymentEstimate = paymentEstimate;

          this.user = user;
          this.assessment = assessment;
          this.assessmentStatus = assessmentStatus;

          const fiscalAnnex = this.assessment?.files?.find(
            (file: AssessmentFile) => file.fileType === `FISCAL_ANNEX_AS_JSON`
          );

          if (this.paymentEstimate && this.user && this.assessment && this.assessmentStatus) {
            this.hasPaid = this.assessmentStatus !== `NEED_PLAN_UPGRADE`;
            this.loading = this.assessmentStatus === `IN_PROGRESS`;

            if (!this.hasPaid) {
              this.taxableCessions =
                this.assessment?.fiscalAuthority === `FR` ? TAXABLE_CESSIONS_DEMO_FR : TAXABLE_CESSIONS_DEMO_ES;
              this.updateTaxableCessionsPage();
            } else if (fiscalAnnex) {
              this.assessmentStore.dispatch(
                downloadAssessmentFileAction({ assessmentId: this.assessment.id, filename: fiscalAnnex?.name })
              );
            }

            this.setUpgradeFishingCTA();
          }
        })
      )
      .subscribe();

    this.assessmentStore
      .pipe(
        takeUntil(this.destroy$),
        select(fromAssessment.selectTaxableCessions),
        map((taxableCessions: TaxableCession[]) => {
          if (taxableCessions && this.hasPaid) {
            this.taxableCessions = taxableCessions;
            this.selectedTaxableCession = this.taxableCessions[this.selectedTaxableCessionIndex];
            this.updateTaxableCessionsPage();
          }
        })
      )
      .subscribe();

    this.sharedStore
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectCurrentOpenedForm),
        map((currentOpenedForm: string) => {
          this.currentForm = currentOpenedForm;
        })
      )
      .subscribe();

    this.sharedStore
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectAccounts),
        map((accounts: Map<string, Account>) => {
          this.accounts = accounts;
        })
      )
      .subscribe();

    this.transactionStore
      .pipe(
        takeUntil(this.destroy$),
        select(fromTransaction.selectFilters),
        map((filters: TransactionFilters) => {
          if (filters) {
            for (const platform of filters.platform) {
              if (FORM_3916_BIS_PLATFORMS.get(platform)) {
                this.usedPlatforms.set(platform, FORM_3916_BIS_PLATFORMS.get(platform));
              }

              this.otherPlatforms.delete(platform);
            }

            if (this.usedPlatforms.size !== 0) {
              this.selectedPlatform = this.usedPlatforms.keys().next().value;
            } else {
              this.selectedPlatform = `ASCENDEX`;
            }

            this.usedPlatformsOptions$.next(this.usedPlatforms);
            this.platformsOptions$.next(this.otherPlatforms);
          } else {
            this.selectedPlatform = `ASCENDEX`;
            this.platformsOptions$.next(this.platforms);
          }
        })
      )
      .subscribe();

    this.platformSearchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((search: string) => {
          this.selectSearch(search);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // A bit dirty but it works well
  selectSearch(search: string): void {
    if (search) {
      search = search.toLowerCase();

      if (this.usedPlatforms.size !== 0) {
        const filteredUsedPlatforms: Map<string, Form3916BisPlatformInfos> = new Map<string, Form3916BisPlatformInfos>(
          []
        );

        this.usedPlatforms.forEach((value: Form3916BisPlatformInfos, key: string) => {
          const platform = this.accounts.get(key);
          if (platform.name.toLowerCase().startsWith(search)) {
            filteredUsedPlatforms.set(key, value);
          }
        });
        this.usedPlatformsOptions$.next(filteredUsedPlatforms);
      }

      if (this.platforms.size !== 0) {
        const filteredPlatforms: Map<string, Form3916BisPlatformInfos> = new Map<string, Form3916BisPlatformInfos>([]);

        this.platforms.forEach((value: Form3916BisPlatformInfos, key: string) => {
          const platform = this.accounts.get(key);
          if (platform?.name.toLowerCase().startsWith(search)) {
            filteredPlatforms.set(key, value);
          }
        });
        this.platformsOptions$.next(filteredPlatforms);
      }
    } else {
      this.usedPlatformsOptions$.next(this.usedPlatforms);
      this.platformsOptions$.next(this.platforms);
    }
  }

  updateCessionPage(nextPage = true): void {
    if (nextPage) {
      this.cessionsPageIndex += this.maxCessionsPerPage;
    } else {
      this.cessionsPageIndex -= this.maxCessionsPerPage;
    }

    this.displayedCessions = this.taxableCessions?.slice(
      this.cessionsPageIndex,
      this.cessionsPageIndex + this.maxCessionsPerPage
    );
  }

  upgradeFishing(): void {
    this.utilsService.openDialog(UpgradeFishingDialogComponent, `400px`, `auto`, {
      name: `upgrade-2086-panel`,
      checkoutType: `PLAN`,
      offer: this.paymentEstimate.recommendedPlan,
    });
  }

  showDemoCessions(): void {
    this.taxableCessions =
      this.assessment?.fiscalAuthority === `FR` ? TAXABLE_CESSIONS_DEMO_FR : TAXABLE_CESSIONS_DEMO_ES;
    this.updateTaxableCessionsPage();
    this.showDemo = true;
  }

  setUpgradeFishingCTA(): void {
    this.upgradeFishingCTA = this.translateService.instant(`SUBSCRIBE_TO_PLAN`, {
      plan: this.translateService.instant(`PLANS.${this.paymentEstimate.recommendedPlan}`),
    });
  }

  updateTaxableCessionsPage(): void {
    this.maxCessionsPerPage = this.assessment?.fiscalAuthority === `FR` ? 5 : 15;
    this.taxableCessionsMsg = this.translateService.instant(`TAXABLE_TRANSFERS_COUNT`, {
      count: this.taxableCessions.length,
    });
    this.displayedCessions = this.taxableCessions.slice(0, this.cessionsPageIndex + this.maxCessionsPerPage);
    this.maxCessionsPage = Math.ceil(this.taxableCessions.length / this.maxCessionsPerPage);
  }

  updateSelectedTaxableCession(next = true): void {
    if (next) {
      this.selectedTaxableCessionIndex++;
    } else {
      this.selectedTaxableCessionIndex--;
    }

    this.selectedTaxableCession = this.taxableCessions[this.selectedTaxableCessionIndex];
  }
}
