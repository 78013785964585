import { Pipe, PipeTransform } from '@angular/core';
import { UserPreferences } from '../models/user-preferences.model';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: `currencyDecimal`,
  standalone: true,
})
export class CurrencyDecimalPipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(
    amount: number,
    userPreferences: UserPreferences,
    stablecoins: string[],
    fiats: string[],
    display = `symbol`,
    currencyCode = `EUR`,
    digits?: string,
  ): string {
    if (amount === null || amount === undefined) {
      return `0`;
    }

    let decimals: number;

    if (currencyCode) {
      const isFIAT: boolean = fiats?.includes(currencyCode);

      if (!digits) {
        if (isFIAT) {
          // Avoid to display 0.00 if too small
          decimals = userPreferences?.numberOfDecimalsForFiat || 2;

          // If amount is between -0.01 and 0.01, display 8 decimals
          if (amount > -0.01 && amount < 0.01) {
            decimals = 8;
          }

          digits = `1.2-${decimals.toString()}`;
        } else {
          decimals = stablecoins?.includes(currencyCode)
            ? userPreferences?.numberOfDecimalsForStable || 8
            : userPreferences?.numberOfDecimalsForCrypto || 8;
          digits = `1.${decimals.toString()}-${decimals.toString()}`;
        }
      }
    } else {
      digits = `1.2-2`;
    }

    if (userPreferences?.language === `en` && display === `code`) {
      currencyCode = currencyCode + ` `;
    }

    const res: string = this.currencyPipe.transform(amount, currencyCode, display, digits, userPreferences?.language);

    if (res.includes(`e`)) {
      return amount.toLocaleString(`fullwide`, { useGrouping: false });
    } else {
      return res;
    }
  }
}
