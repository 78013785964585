/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Tag } from '../models/tag.model';
import { HttpClient } from '@angular/common/http';
import { GenericResponse } from '../models/generic-response.model';
import { Observable } from 'rxjs';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: `root`,
})
export class TrackingService {
  constructor(private readonly http: HttpClient) {}

  loadTrackers(): void {
    const gtmId = environment.gtmId;
    const mixpanelToken = environment.mixpanelToken;

    if (gtmId) {
      const gtmScript = document.createElement(`script`);
      const gtmNoScript = document.createElement(`noscript`);

      const gtmScript2 = document.createElement(`script`);
      const gtmNoScript2 = document.createElement(`noscript`);

      gtmScript.innerHTML = `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${gtmId}');`;

      gtmNoScript.innerHTML = ` 
        <iframe
          title="Google Tag Manager"
          src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
      ></iframe>`;

      gtmScript2.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WGV5VX5');`;

      gtmNoScript2.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WGV5VX5"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      document.head.appendChild(gtmScript);
      document.head.appendChild(gtmScript2);
      document.body.appendChild(gtmNoScript);
      document.body.appendChild(gtmNoScript2);
    }

    if (mixpanelToken) {
      mixpanel.init(mixpanelToken, {
        debug: !environment.production,
        track_pageview: false,
        persistence: `localStorage`,
      });
    }
  }

  pushTag(tag: Tag): void {
    // Send tag only in production
    if (environment.production) {
      switch (tag[`environment_market`]) {
        case `FR`:
          tag[`environment_market`] = `france`;
          break;
        case `BE`:
          tag[`environment_market`] = `belgium`;
          break;
        case `ES`:
          tag[`environment_market`] = `spain`;
          break;
      }

      window[`dataLayer`].push(tag);
    }
  }

  trackEvent(event: string, data: any = {}): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(`${environment.apiUrl}/v1/tax/track/${event}`, data);
  }
}
