import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAddon } from '../../models/user.model';
import { TranslateModule } from '@ngx-translate/core';
import { ADDONS } from '../../constants/plans.constant';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: `app-offer-toggle`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatCheckboxModule],
  templateUrl: `./offer-toggle.component.html`,
  styleUrls: [`./offer-toggle.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferToggleComponent {
  @Input() offer: UserAddon;
  @Input() isChecked: boolean;

  addons = ADDONS;
}
