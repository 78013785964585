/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { boot, Intercom, showArticle, showNewMessage, shutdown, update } from '@intercom/messenger-js-sdk';

@Injectable({
  providedIn: `root`,
})
export class IntercomService {
  constructor() {
    Intercom({
      app_id: `ea8g7nxz`,
    });
  }

  boot(): void {
    boot({
      app_id: `ea8g7nxz`,
    });
  }

  shutdown(): void {
    shutdown();
  }

  update(email: string, userId: string): void {
    const user_hash = CryptoJS.HmacSHA256(userId, environment.intercom.secret).toString(CryptoJS.enc.Hex);

    update({
      user_id: userId,
      user_hash,
      email,
      created_at: new Date().getTime(),
    });
  }

  /**
   * Update intercom language
   *
   * @param language
   */
  updateLanguage(language: string): void {
    update({
      language_override: language,
    });

    this.boot();
  }

  /**
   * Update intercom analysis count
   *
   * @param analysisCount
   */
  updateAnalysisCount(analysisCount: number): void {
    update({
      analysis_count: analysisCount,
    });
  }

  showArticle(id: string): void {
    showArticle(id);
  }

  openIntercomChat(): void {
    showNewMessage(``);
  }
}
