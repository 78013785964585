import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: `app-banner`,
  standalone: true,
  imports: [CommonModule, MatButtonModule, TranslateModule, MatTooltipModule],
  templateUrl: `./banner.component.html`,
  styleUrls: [`./banner.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() showBannerIcon = true;
  @Input() customIcon = ``;
  @Input() bannerTheme = `primary`;
  @Input() useLink = false;
  @Input() cta: string;
  @Input() ctaDisabled = false;
  @Input() ctaTooltip = ``;
  @Input() columnDisplay = false;

  @Output() ctaClick: EventEmitter<void> = new EventEmitter<void>();
}
