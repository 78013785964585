/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { TaxableCession } from '../../models/taxable-cession.model';
import { Assessment, AssessmentFileType, AssessmentStatus } from '../../models/assessment.model';
import { Modelo } from '../../models/modelo.model';

// eslint-disable-next-line no-shadow
export enum AssessmentActions {
  DownloadAssessmentFile = `[Assessment] Download Assessment File`,
  DownloadAssessmentFileAsPDF = `[Assessment] Download Assessment File As PDF`,
  SetTaxableCessions = `[Assessment] Set Taxable Cessions`,
  StartAssessment = `[Assessment] Start Assessment`,
  LoadAssessment = `[Assessment] Load Assessment`,
  UnsyncAssessment = `[Assessment] Unsync Assessment`,
  SetAssessment = `[Assessment] Set Assessment`,
  GetLatestAssessmentsByYear = `[Assessment] Get Latest Assessments By Year`,
  SetLatestAssessmentsByYear = `[Assessment] Set Latest Assessments By Year`,
  UpdateCurrentFiscalYear = `[Assessment] Update Current Fiscal Year`,
  ComputeAssessmentStatus = `[Assessment] Compute Assessment Status`,
  SetAssessmentStatus = `[Assessment] Set Assessment Status`,
  ToggleAutomaticAssessment = `[Assessment] Toggle Automatic Assessment`,
  SetModelo = `[Assessment] Set Modelo`,
}

export const downloadAssessmentFileAction = createAction(
  AssessmentActions.DownloadAssessmentFile,
  props<{ assessmentId: string; filename: string; fileType: AssessmentFileType }>()
);

export const downloadAssessmentFileAsPDFAction = createAction(
  AssessmentActions.DownloadAssessmentFileAsPDF,
  props<{ assessmentId: string; filename: string; fileType: AssessmentFileType }>()
);

export const setTaxableCessionsAction = createAction(
  AssessmentActions.SetTaxableCessions,
  props<{ taxableCessions: TaxableCession[] }>()
);

export const startAssessmentAction = createAction(AssessmentActions.StartAssessment, props<{ fiscalYear: number }>());

export const loadAssessmentAction = createAction(AssessmentActions.LoadAssessment);

export const unsyncAssessmentAction = createAction(AssessmentActions.UnsyncAssessment);

export const setAssessmentAction = createAction(AssessmentActions.SetAssessment, props<{ assessment: Assessment }>());

export const getLatestAssessmentsByYearAction = createAction(AssessmentActions.GetLatestAssessmentsByYear);

export const setLatestAssessmentsByYearAction = createAction(
  AssessmentActions.SetLatestAssessmentsByYear,
  props<{ assessmentsByYear: Map<string, Assessment> }>()
);

export const updateCurrentFiscalYearAction = createAction(
  AssessmentActions.UpdateCurrentFiscalYear,
  props<{ currentFiscalYear: number }>()
);

export const computeAssessmentStatusAction = createAction(AssessmentActions.ComputeAssessmentStatus);

export const setAssessmentStatusAction = createAction(
  AssessmentActions.SetAssessmentStatus,
  props<{ assessmentStatus: AssessmentStatus }>()
);

export const toggleAutomaticAssessmentAction = createAction(AssessmentActions.ToggleAutomaticAssessment);

export const setModeloAction = createAction(AssessmentActions.SetModelo, props<{ modelo: Modelo }>());
