import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { Page } from '../../../shared/models/page.model';
import { DocumentRequest } from '../../models/document.model';
import * as documentReducer from '../reducers/document.reducer';

export const DOCUMENT_FEATURE_NAME = `documents`;

export interface State {
  documents: documentReducer.State;
}

export const selectDocumentState: MemoizedSelector<
  State,
  documentReducer.State
> = createFeatureSelector<documentReducer.State>(DOCUMENT_FEATURE_NAME);

export const selectDocumentRequestPage: MemoizedSelector<
  State,
  Page<DocumentRequest>
> = createSelector(
  selectDocumentState,
  (state: documentReducer.State) => state.documentRequestPage
);

export const selectWaltioExportFiles: MemoizedSelector<
  State,
  DocumentRequest[]> = createSelector(
    selectDocumentState,
    (state: documentReducer.State) => state.documentRequestPage?.content.filter((documentRequest: DocumentRequest) => documentRequest.documentType === `WALTIO_EXPORT_FILE`)
  );