import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: `[customFocus]`,
})
export class CustomFocusDirective {
  @Input() defaultClass: string;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  @HostListener(`document:click`, [`$event`])
  onDocumentClick(event: Event): void {
    const isOpen = this.el.nativeElement.contains(event.target);

    if (isOpen) {
      this.renderer.addClass(this.el.nativeElement, `${this.defaultClass}--active`);
    } else {
      this.renderer.removeClass(this.el.nativeElement, `${this.defaultClass}--active`);
    }
  }
}
