/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { Page } from '../../../shared/models/page.model';
import {
  Transaction,
  TransactionFilters,
  TransactionSubType,
  TransactionWarning,
  TransactionsTab,
} from '../../models/transaction.model';
import { SearchDTO, ViewFilters } from '../../models/transactions-filters.model';
import { TransactionStatsAggregation } from '../../models/transaction-stats-aggregation.model';
import { TokenAndPlatformBalanceDetail } from '../../models/balance.model';
import { WarningOccurences } from '../../models/warning.model';

// eslint-disable-next-line no-shadow
export enum TransactionActions {
  LoadTransactions = `[Transaction] Load Transactions`,
  SetTransactions = `[Transaction] Set Transactions`,
  SetTransactionsPage = `[Transaction] Set Transactions Page`,
  LoadFilters = `[Transaction] Load Filters`,
  SetFilters = `[Transaction] Set Filters`,
  DeleteTransactions = `[Transaction] Delete Transactions`,
  SetSelectedTransaction = `[Transaction] Set Selected Transaction`,
  SetCheckedTransactions = `[Transaction] Set Checked Transactions`,
  UpdateCheckedTransactionsSubTypes = `[Transaction] Update Checked Transactions Sub Types`,
  UpdateCheckedTransactionsDescription = `[Transaction] Update Checked Transactions Description`,
  SetAppliedFilters = `[Transaction] Set Applied Filters`,
  SaveTransaction = `[Transaction] Save Transaction`,
  SaveTransactions = `[Transaction] Save Transactions`,
  UpdateTransactionPrices = `[Transaction] Update Transaction Prices`,
  LoadTransactionsStats = `[Transaction] Load Transactions Stats`,
  SetTransactionsStats = `[Transaction] Set Transactions Stats`,
  LoadNegativeBalances = `[Transaction] Load Negative Balances`,
  SetNegativeBalances = `[Transaction] Set Negative Balances`,
  GetTransaction = `[Transaction] Get Transaction`,
  MatchTransactions = `[Transaction] Match Transactions`,
  SetTransactionsTabCount = `[Transaction] Set Transactions Tab Count`,
  SetWarningsTabCount = `[Transaction] Set Warnings Tab Count`,
  SetTaxableTabCount = `[Transaction] Set Taxable Tab Count`,
  SetSelectedTransactionsTab = `[Transaction] Set Selected Transactions Tab`,
  SetSelectedNegativeBalanceWarning = `[Transaction] Set Selected Negative Balance Warning`,
  LoadTransactionsWarnings = `[Transaction] Load Transactions Warnings`,
  SetTransactionsWarnings = `[Transaction] Set Transactions Warnings`,

  MergeIntoWithdrawal = `[Transaction] Merge Into Withdrawal`,
  MergeIntoDeposit = `[Transaction] Merge Into Deposit`,
  MergeIntoTrade = `[Transaction] Merge Trade`,

  SetViewFilters = `[Transaction] Set View Filters`,
}

export const loadTransactionsAction = createAction(
  TransactionActions.LoadTransactions,
  props<{ page?: number; sort?: string; size?: number; filters?: SearchDTO }>()
);

export const setTransactionsPageAction = createAction(
  TransactionActions.SetTransactionsPage,
  props<{ transactionsPage: Page<Transaction> }>()
);

export const loadFiltersAction = createAction(TransactionActions.LoadFilters);

export const setFiltersAction = createAction(TransactionActions.SetFilters, props<{ filters: TransactionFilters }>());

export const setCheckedTransactionsAction = createAction(
  TransactionActions.SetCheckedTransactions,
  props<{ checkedTransactions: Transaction[] }>()
);

export const deleteTransactionsAction = createAction(
  TransactionActions.DeleteTransactions,
  props<{ transactions: Transaction[] }>()
);

export const setSelectedTransactionAction = createAction(
  TransactionActions.SetSelectedTransaction,
  props<{
    selectedTransaction: Transaction;
  }>()
);

export const updateCheckedTransactionsSubTypesAction = createAction(
  TransactionActions.UpdateCheckedTransactionsSubTypes,
  props<{ subType: TransactionSubType; pageIndex?: number; sort?: string }>()
);

export const updateCheckedTransactionsDescriptionAction = createAction(
  TransactionActions.UpdateCheckedTransactionsDescription,
  props<{ description: string; pageIndex?: number; sort?: string }>()
);

export const setAppliedFiltersAction = createAction(
  TransactionActions.SetAppliedFilters,
  props<{ appliedFilters: SearchDTO }>()
);

export const saveTransactionAction = createAction(
  TransactionActions.SaveTransaction,
  props<{ transaction: Transaction; pageIndex?: number; sort: string }>()
);

export const saveTransactionsAction = createAction(
  TransactionActions.SaveTransactions,
  props<{ transactions: Transaction[] }>()
);

export const loadTransactionsStatsAction = createAction(TransactionActions.LoadTransactionsStats);

export const setTransactionsStatsAction = createAction(
  TransactionActions.SetTransactionsStats,
  props<{ transactionsStats: TransactionStatsAggregation }>()
);

export const loadNegativeBalancesAction = createAction(TransactionActions.LoadNegativeBalances);

export const setNegativeBalancesAction = createAction(
  TransactionActions.SetNegativeBalances,
  props<{ negativeBalances: TokenAndPlatformBalanceDetail[] }>()
);

export const getTransactionAction = createAction(TransactionActions.GetTransaction, props<{ id: string }>());

export const updateTransactionPricesAction = createAction(
  TransactionActions.UpdateTransactionPrices,
  props<{ transaction: Transaction; token: string; price: number }>()
);

export const matchTransactionsAction = createAction(
  TransactionActions.MatchTransactions,
  props<{ transactions: Transaction[] }>()
);

export const setTransactionsTabCountAction = createAction(
  TransactionActions.SetTransactionsTabCount,
  props<{ transactionsTabCount: number }>()
);

export const setWarningsTabCountAction = createAction(
  TransactionActions.SetWarningsTabCount,
  props<{ warningsTabCount: number }>()
);

export const setTaxableTabCountAction = createAction(
  TransactionActions.SetTaxableTabCount,
  props<{ taxableTabCount: number }>()
);

export const setSelectedTransactionsTabAction = createAction(
  TransactionActions.SetSelectedTransactionsTab,
  props<{ selectedTransactionsTab: TransactionsTab }>()
);

export const setSelectedNegativeBalanceWarningAction = createAction(
  TransactionActions.SetSelectedNegativeBalanceWarning,
  props<{ selectedNegativeBalanceWarning: TransactionWarning }>()
);

export const loadTransactionsWarningsAction = createAction(TransactionActions.LoadTransactionsWarnings);

export const setTransactionsWarningsAction = createAction(
  TransactionActions.SetTransactionsWarnings,
  props<{ transactionsWarnings: WarningOccurences[] }>()
);

export const mergeIntoWithdrawalAction = createAction(
  TransactionActions.MergeIntoWithdrawal,
  props<{ withdrawals: Transaction[]; pageIndex: number; sort: string }>()
);

export const mergeIntoDepositAction = createAction(
  TransactionActions.MergeIntoDeposit,
  props<{ deposits: Transaction[]; pageIndex: number; sort: string }>()
);

export const mergeIntoTradeAction = createAction(
  TransactionActions.MergeIntoTrade,
  props<{ deposit: Transaction; withdrawal: Transaction; fee?: Transaction; pageIndex: number; sort: string }>()
);

export const setViewFiltersAction = createAction(
  TransactionActions.SetViewFilters,
  props<{ viewFilters: ViewFilters }>()
);
