<section id="main-container">
  <div id="circle-orbit-container">
    <div id="central-point">
      <img src="/assets/img/logos/_manual_ledger.svg" alt="Waltio" width="80" height="40" />
    </div>

    <!-- Circles closest to the central point -->
    <div id="inner-orbit">
      <div class="inner-orbit-circles">
        <img class="coin-icon" src="https://icons.waltio.com/token/btc" alt="BTC" />
      </div>
      <div class="inner-orbit-circles inner-orbit-circles--second">
        <img class="coin-icon" src="https://icons.waltio.com/token/eth" alt="ETH" />
      </div>
      <div class="inner-orbit-circles inner-orbit-circles--third">
        <img class="coin-icon" src="https://icons.waltio.com/token/busd" alt="BUSD" />
      </div>
    </div>

    <!-- Circles second closest to the central point -->
    <div id="middle-orbit">
      <div class="middle-orbit-circles">
        <img class="coin-icon" src="https://icons.waltio.com/token/matic" alt="MATIC" />
      </div>
      <div class="middle-orbit-circles middle-orbit-circles--second">
        <img class="coin-icon" src="https://icons.waltio.com/token/dot" alt="DOT" />
      </div>
      <div class="middle-orbit-circles middle-orbit-circles--third">
        <img class="coin-icon" src="https://icons.waltio.com/token/xtz" alt="XTZ" />
      </div>
    </div>

    <!-- Circles furthest away to the central point -->
    <div id="outer-orbit">
      <div class="outer-orbit-circles">
        <img class="coin-icon" src="https://icons.waltio.com/token/sol" alt="SOL" />
      </div>
      <div class="outer-orbit-circles outer-orbit-circles--second">
        <img class="coin-icon" src="https://icons.waltio.com/token/atom" alt="ATOM" />
      </div>
      <div class="outer-orbit-circles outer-orbit-circles--third">
        <img class="coin-icon" src="https://icons.waltio.com/token/usdt" alt="USDT" />
      </div>
    </div>
  </div>
</section>
