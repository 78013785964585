import { Injectable } from '@angular/core';
import { ReportedToken } from '../../shared/models/reported-token.model';
import { Scam } from '../../shared/models/scam.model';
import { LiveBalanceResponseItem } from '../models/live-balance.model';
import { MarketPrice } from '../models/market.model';

@Injectable({
  providedIn: `root`,
})
export class MarketService {
  isScam(balance: LiveBalanceResponseItem, userScamList: Scam[], userReportedTokenList: ReportedToken[]): boolean {
    const scam: Scam = userScamList?.find((s: Scam) => {
      if (!s.address) {
        const symbol = balance?.token?.toLowerCase();
        const scamSymbol = s.symbol?.toLowerCase();

        return symbol === scamSymbol;
      } else {
        const address = balance?.address?.toLowerCase();
        const scamAddress = s.address?.toLowerCase();

        return address === scamAddress;
      }
    });

    const reportedToken = userReportedTokenList?.find((r: ReportedToken) => {
      if (!r.address) {
        const symbol = balance?.token?.toLowerCase();
        const reportedTokenSymbol = r.symbol?.toLowerCase();

        return symbol === reportedTokenSymbol;
      } else {
        const address = balance?.address?.toLowerCase();
        const reportedTokenAddress = r.address?.toLowerCase();

        return address === reportedTokenAddress;
      }
    });

    if (scam || reportedToken) {
      return true;
    } else {
      return balance?.possibleSpam || balance?.reported;
    }
  }

  getTokenPrice(marketPrices: MarketPrice[], token: string, currency: string = `EUR`): number {
    const tokenMarket = marketPrices?.find((price: MarketPrice) => price.symbol === token);
    const tokenPriceEur = tokenMarket?.price || 0; // Token price in EUR

    if (currency !== `EUR`) {
      const currencyMarket = marketPrices?.find((price: MarketPrice) => price.symbol === currency);
      const currencyEurRate = currencyMarket?.price || 1; // 1 Currency in EUR
      return tokenPriceEur / currencyEurRate;
    }

    return tokenPriceEur;
  }
}
