import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomTitleStrategyService extends TitleStrategy {
  constructor(@Inject(DOCUMENT) private readonly document: Document, private readonly translateService: TranslateService) {
    super();
  }

  override updateTitle(state: RouterStateSnapshot): void {
    let title = `Waltio`;
    const builtTitle = this.buildTitle(state);

    if (builtTitle) {
      title = `Waltio | ${this.translateService.instant(builtTitle)}`;
    }

    this.document.title = title;
  }
}
