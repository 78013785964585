<div class="new-features">
  <img [src]="currentFeature.bannerUrl" alt="feature-banner" />

  <section class="new-features__content">
    <div class="new-features__content__tags">
      @for (tag of currentFeature.tags; track tag) {
        <div class="tag">
          <img [src]="'/assets/img/icons/' + tag.toLowerCase() + '.svg'" [alt]="tag" />
          <p>{{ tag }}</p>
        </div>
      }
      <div class="new-feature-tag">
        <p>{{ 'NEW_FEATURES' | translate | uppercase }}</p>
      </div>
    </div>

    <h1>{{ currentFeature.name | translate }}</h1>

    <p class="body-14">{{ currentFeature.description | translate }}</p>
  </section>
  <mat-divider></mat-divider>
  <section class="new-features__actions">
    <div class="new-features__actions__progress">
      @for (feature of features; track feature; let i = $index) {
        <div class="dot" [class.dot--active]="i === currentFeatureIndex"></div>
      }
    </div>

    <div class="new-features__actions__ctas">
      @if (currentFeatureIndex > 0) {
        <button type="button" mat-flat-button color="secondary" (click)="previousFeature()">
          {{ 'PREVIOUS_M' | translate }}
        </button>
      }

      <button
        type="button"
        mat-flat-button
        color="primary"
        (click)="currentFeatureIndex === features.length - 1 ? close() : nextFeature()"
      >
        {{ (currentFeatureIndex === features.length - 1 ? 'CONTINUE' : 'NEXT') | translate }}
      </button>
    </div>
  </section>
</div>
