import { FiscalResidency } from '../../taxation/models/user.model';

export const LEGDER_LIVE_LINK = `https://support.ledger.com/hc/en-us/articles/4404389606417-Download-and-install-Ledger-Live?docs=true`;

export const EXPORT_TRANSACTIONS_DEMO_FILE: Map<string, string> = new Map<string, string>([
  [`es`, `https://public.waltio.com/v3/upgrade/es/ExportWaltio_es.xlsx`],
  [`fr`, `https://public.waltio.com/v3/upgrade/fr/ExportWaltio_fr.xlsx`],
  [`en`, `https://public.waltio.com/v3/upgrade/en/WaltioExport_en.xlsx`],
]);

export const STOCK_FILE_DEMO_FILE: Map<string, string> = new Map<string, string>([
  [`es`, `https://public.waltio.com/v3/upgrade/es/Inventario.xlsx`],
  [`fr`, `https://public.waltio.com/v3/upgrade/fr/Fiche de stock.xlsx`],
  [`en`, `https://public.waltio.com/v3/upgrade/en/Stock file.xlsx`],
]);

export const SUMMARY_FILE_DEMO_FILE: Map<FiscalResidency, any> = new Map<FiscalResidency, any>([
  [
    `ES`,
    {
      en: `https://public.waltio.com/v3/upgrade/es/en/Summary.pdf`,
      es: `https://public.waltio.com/v3/upgrade/es/es/Resumen.pdf`,
    },
  ],
  [
    `BE`,
    {
      fr: `https://public.waltio.com/v3/upgrade/be/fr/Sommaire.pdf`,
      en: `https://public.waltio.com/v3/upgrade/be/en/Summary.pdf`,
    },
  ],
  [
    `FR`,
    {
      fr: `https://public.waltio.com/v3/upgrade/fr/fr/Résumé.pdf`,
      en: `https://public.waltio.com/v3/upgrade/fr/en/Summary.pdf`,
    },
  ],
]);

export const BOOKKEEPING_LEDGER_DEMO_FILE: Map<FiscalResidency, any> = new Map<FiscalResidency, any>([
  [
    `ES`,
    {
      en: `https://public.waltio.com/v3/upgrade/es/en/Bookkeeping%20ledger.xlsx`,
      es: `https://public.waltio.com/v3/upgrade/es/es/Libro%20Mayor.xlsx`,
    },
  ],
  [
    `FR`,
    {
      fr: `https://public.waltio.com/v3/upgrade/fr/fr/Grand%20livre.xlsx`,
      en: `https://public.waltio.com/v3/upgrade/fr/en/Bookkeeping%20ledger.xlsx`,
    },
  ],
  [
    `BE`,
    {
      fr: `https://public.waltio.com/v3/upgrade/be/fr/Grand%20livre.xlsx`,
      en: `https://public.waltio.com/v3/upgrade/be/en/Bookkeeping%20ledger.xlsx`,
    },
  ],
]);

export const INFORMATION_LETTER_DEMO_FILE: Map<FiscalResidency, any> = new Map<FiscalResidency, any>([
  [
    `ES`,
    {
      en: `https://public.waltio.com/v3/upgrade/es/en/Tax%20certificate.pdf`,
      es: `https://public.waltio.com/v3/upgrade/es/es/Certificados%20tributarios.pdf`,
    },
  ],
  [
    `FR`,
    {
      fr: `https://public.waltio.com/v3/upgrade/fr/fr/Certificat%20fiscal.pdf`,
      en: `https://public.waltio.com/v3/upgrade/fr/en/Tax%20certificate.pdf`,
    },
  ],
  [
    `BE`,
    {
      fr: `https://public.waltio.com/v3/upgrade/be/fr/Certificat%20fiscal.pdf`,
      en: `https://public.waltio.com/v3/upgrade/be/en/Tax%20certificate.pdf`,
    },
  ],
]);

export const FISCAL_ANNEX_DEMO_FILE = new Map<FiscalResidency, any>([
  [
    `ES`,
    {
      en: `https://public.waltio.com/v3/upgrade/es/en/Annual%20declaration%20annex.xlsx`,
      es: `https://public.waltio.com/v3/upgrade/es/es/Anexo%20de%20la%20declaración%20anual.xlsx`,
    },
  ],
  [
    `FR`,
    {
      fr: `https://public.waltio.com/v3/upgrade/fr/fr/Annexe%20de%20déclaration%20annuelle%20des%20plus-values.xlsx`,
      en: `https://public.waltio.com/v3/upgrade/fr/en/Annual%20declaration%20annex.xlsx`,
    },
  ],
]);

export const FISCAL_CONTROL_PACKAGE_DEMO_FILE = new Map<FiscalResidency, any>([
  [
    `FR`,
    {
      fr: `https://public.waltio.com/v3/upgrade/fr/fr/Controle%20fiscal.zip`,
      en: `https://public.waltio.com/v3/upgrade/fr/en/Fiscal%20control.zip`,
    },
  ],
  [
    `BE`,
    {
      fr: `https://public.waltio.com/v3/upgrade/be/fr/Controle%20fiscal.zip`,
      en: `https://public.waltio.com/v3/upgrade/be/en/Fiscal%20control.zip`,
    },
  ],
  [
    `ES`,
    {
      es: `https://public.waltio.com/v3/upgrade/es/es/Auditor%C3%ADa%20fiscal.zip`,
      en: `https://public.waltio.com/v3/upgrade/es/es/Auditor%C3%ADa%20fiscal.zip`,
    },
  ],
]);

export const BANKING_REPATRIATION_PACKAGE_DEMO_FILE = new Map<FiscalResidency, any>([
  [
    `FR`,
    {
      fr: `https://public.waltio.com/v3/upgrade/fr/fr/Rapatriement%20bancaire.zip`,
      en: `https://public.waltio.com/v3/upgrade/fr/en/Banking%20repatriation.zip`,
    },
  ],
  [
    `BE`,
    {
      fr: `https://public.waltio.com/v3/upgrade/be/fr/Rapatriement%20bancaire.zip`,
      en: `https://public.waltio.com/v3/upgrade/be/en/Banking%20repatriation.zip`,
    },
  ],
  [
    `ES`,
    {
      es: `https://public.waltio.com/v3/upgrade/es/es/Repatriaci%C3%B3n%20bancaria.zip`,
      en: `https://public.waltio.com/v3/upgrade/es/es/Repatriaci%C3%B3n%20bancaria.zip`,
    },
  ],
]);
