import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TitleStrategy, provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as echarts from 'echarts';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxEchartsModule } from 'ngx-echarts';
import { MomentModule } from 'ngx-moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { environment } from '../../environments/environment';
import { routes } from '../app.routes';
import { LoaderComponent } from '../authentication/components/loader/loader.component';
import { AuthInterceptor } from '../authentication/services/auth.interceptor';
import { AuthenticationEffects } from '../authentication/store/effects/authentication.effect';
import { authenticationReducer } from '../authentication/store/reducers/authentication.reducer';
import { AUTHENTICATION_FEATURE_NAME } from '../authentication/store/selectors/authentication.selector';
import { InsightEffects } from '../portfolio-manager/store/effects/insight.effect';
import { insightReducer } from '../portfolio-manager/store/reducers/insight.reducer';
import { INSIGHT_FEATURE_NAME } from '../portfolio-manager/store/selectors/insight.selector';
import { CustomPaginator } from '../shared/components/custom-paginator/custom-paginator';
import { CurrencyDecimalPipe } from '../shared/pipes/currency-decimal.pipe';
import { NgxDatePipe } from '../shared/pipes/ngx-date.pipe';
import { CustomTitleStrategyService } from '../shared/services/custom-title-strategy.service';
import { SharedEffects } from '../shared/store/effects/shared.effect';
import { sharedReducer } from '../shared/store/reducers/shared.reducer';
import { SHARED_FEATURE_NAME } from '../shared/store/selectors/shared.selector';
import { AccountEffects } from '../taxation/store/effects/account.effect';
import { AffiliationEffects } from '../taxation/store/effects/affiliation.effect';
import { AssessmentEffects } from '../taxation/store/effects/assessment.effect';
import { DocumentEffects } from '../taxation/store/effects/document.effect';
import { PaymentEffects } from '../taxation/store/effects/payment.effect';
import { TransactionEffects } from '../taxation/store/effects/transaction.effect';
import { accountReducer } from '../taxation/store/reducers/account.reducer';
import { affiliationReducer } from '../taxation/store/reducers/affiliation.reducer';
import { assessmentReducer } from '../taxation/store/reducers/assessment.reducer';
import { documentReducer } from '../taxation/store/reducers/document.reducer';
import { paymentReducer } from '../taxation/store/reducers/payment.reducer';
import { transactionReducer } from '../taxation/store/reducers/transaction.reducer';
import { ACCOUNTS_FEATURE_NAME } from '../taxation/store/selectors/account.selector';
import { AFFILIATION_FEATURE_NAME } from '../taxation/store/selectors/affiliation.selector';
import { ASSESSMENT_FEATURE_NAME } from '../taxation/store/selectors/assessment.selector';
import { DOCUMENT_FEATURE_NAME } from '../taxation/store/selectors/document.selector';
import { PAYMENTS_FEATURE_NAME } from '../taxation/store/selectors/payment.selector';
import { TRANSACTIONS_FEATURE_NAME } from '../taxation/store/selectors/transaction.selector';
import { cookieConfig } from './cookie';
import { httpTranslateLoader } from './translator';
import { opportunityReducer } from '../portfolio-manager/store/reducers/opportunity.reducer';
import { OPPORTUNITY_FEATURE_NAME } from '../portfolio-manager/store/selectors/opportunity.selector';
import { OpportunityEffects } from '../portfolio-manager/store/effects/opportunity.effect';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      HttpClientModule,
      MomentModule,
      DropzoneModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoader,
          deps: [HttpClient],
        },
      }),
      StoreRouterConnectingModule.forRoot(),
      StoreModule.forRoot(
        {
          router: routerReducer,
        },
        {
          runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false,
          },
        }
      ),
      StoreDevtoolsModule.instrument({
        maxAge: 100,
        logOnly: environment.production, // Restrict extension to log-only mode
        serialize: true, // Allow to serialize to JSON format Map and Set in store
      }),
      StoreModule.forFeature(SHARED_FEATURE_NAME, sharedReducer),
      StoreModule.forFeature(AUTHENTICATION_FEATURE_NAME, authenticationReducer),
      StoreModule.forFeature(PAYMENTS_FEATURE_NAME, paymentReducer),
      StoreModule.forFeature(ACCOUNTS_FEATURE_NAME, accountReducer),
      StoreModule.forFeature(TRANSACTIONS_FEATURE_NAME, transactionReducer),
      StoreModule.forFeature(DOCUMENT_FEATURE_NAME, documentReducer),
      StoreModule.forFeature(AFFILIATION_FEATURE_NAME, affiliationReducer),
      StoreModule.forFeature(ASSESSMENT_FEATURE_NAME, assessmentReducer),
      StoreModule.forFeature(INSIGHT_FEATURE_NAME, insightReducer),
      StoreModule.forFeature(OPPORTUNITY_FEATURE_NAME, opportunityReducer),
      EffectsModule.forRoot([]),
      EffectsModule.forFeature([
        AuthenticationEffects,
        SharedEffects,
        PaymentEffects,
        AccountEffects,
        TransactionEffects,
        DocumentEffects,
        AffiliationEffects,
        AssessmentEffects,
        InsightEffects,
        OpportunityEffects,
      ]),
      NgcCookieConsentModule.forRoot(cookieConfig),
      MatSnackBarModule,
      MatDialogModule,
      NgxSkeletonLoaderModule.forRoot({
        animation: `progress-dark`,
        theme: {
          extendsFromRoot: true,
          background: `#D9D9D9`,
        },
      }),
      NgxEchartsModule.forRoot({ echarts }),
      AuthModule.forRoot({
        domain: environment.auth0.domain,
        clientId: environment.auth0.clientID,
        authorizationParams: {
          redirect_uri: environment.auth0.callback,
          audience: `https://api.waltio.co/`,
          scope: `openid email`,
        },
        cacheLocation: `localstorage`,
        httpInterceptor: {
          allowedList: [
            {
              uri: `https://data.waltio.com/dev/v1/tax/app/features`,
              allowAnonymous: true,
            },
            {
              uri: `https://data.waltio.com/live/v1/tax/app/features`,
              allowAnonymous: true,
            },
            `https://data.waltio.com/*`,
          ],
        },
        skipRedirectCallback: window.location.pathname !== `/callback`,
      })
    ),
    provideRouter(routes),
    provideAnimations(),
    provideServiceWorker(`ngsw-worker.js`, {
      enabled: true, // !isDevMode(),
      registrationStrategy: `registerWhenStable:30000`,
    }),
    { provide: TitleStrategy, useClass: CustomTitleStrategyService },
    {
      provide: MAT_DATE_LOCALE,
      useValue: `en-GB`,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: CustomPaginator },
    NgxDatePipe,
    CurrencyPipe,
    CurrencyDecimalPipe,
    LoaderComponent,
  ],
};
