@if ((subAccount || account).supportsTimezone) {
  <mat-select
    [formControl]="timezoneControl"
    disableOptionCentering
    hideSingleSelectionIndicator
    class="custom-select"
    [placeholder]="'AUTO_DETECT_UTC' | translate"
    customFocus
    defaultClass="custom-select"
  >
    <mat-option value="">UTC</mat-option>
    @for (timezone of timezones; track timezone) {
      <mat-option [value]="timezone.key">
        {{ timezone.value }}
      </mat-option>
    }
  </mat-select>
}
<div
  class="dropzone-container"
  [ngClass]="{
    'invisible': filesStatus === 'LOADING',
    'dropzone-container--error': filesStatus === 'ERROR',
  }"
  [dropzone]="dropzoneConfig"
  (addedFiles)="onAddedFiles($event)"
  (error)="onUploadError($event)"
  (success)="onUploadSuccess($event)"
>
  <div id="dropzone-elements" class="dropzone-container__content">
    <div class="file-icon"></div>
    <div class="file-text">
      <p class="body-13-med" [innerHTML]="'UPLOAD_FILES' | translate"></p>
      <p class="body-12">{{ 'FORMAT' | translate }} {{ dropzoneConfig.acceptedFiles }}</p>
    </div>
  </div>
</div>

@if (filesStatus === 'ADDED') {
  <div class="added-files">
    @for (file of files; track file; let last = $last) {
      <span class="body-12">{{ file.data.name }}</span>

      @if (!last) {
        <span class="body-12">, </span>
      }
    }
  </div>
}

@if (filesStatus === 'ERROR') {
  @for (file of canceledFiles; track file) {
    <p class="body-12 file-error">{{ file.errorMessage }}</p>
  }
}

<!-- LOADING -->
@if (filesStatus === 'LOADING') {
  <div class="dropzone-container dropzone-container--loading">
    <div class="dropzone-container__content">
      <p class="body-12">{{ 'LOADING' | translate }}</p>
      <mat-progress-bar mode="determinate" [value]="filesProgress" color="primary"></mat-progress-bar>
    </div>
  </div>
}
