<div class="container">
  <div class="header">
    <h2>Instructions économiques</h2>
    <img class="pointer" src="/assets/img/icons/close-icon.svg" alt="close-icon" mat-dialog-close />
  </div>
  <mat-divider></mat-divider>
  <div class="main">
    <div class="explorer">
      <nav>
        <!-- TRADE -->
        @if (instructions?.get('TRADE')) {
          <p
            class="body-14-med pointer"
            [ngClass]="{
              active: isTradeActive,
            }"
            (click)="scrollToAnchor('trade')"
          >
            @if (isTradeActive) {
              <div class="active-pointer"></div>
            }
            Trade crypto
          </p>
        }

        <!-- BUY_CRYPTO -->
        @if (instructions?.get('BUY_CRYPTO')) {
          <p
            class="body-14-med pointer"
            [ngClass]="{
              active: isBuyActive,
            }"
            (click)="scrollToAnchor('buy')"
          >
            @if (isBuyActive) {
              <div class="active-pointer"></div>
            }
            Achat crypto en Fiat
          </p>
        }

        <!-- SELL_CRYPTO -->
        @if (instructions?.get('SELL_CRYPTO')) {
          <p
            class="body-14-med pointer"
            [ngClass]="{
              active: isSellActive,
            }"
            (click)="scrollToAnchor('sell')"
          >
            @if (isSellActive) {
              <div class="active-pointer"></div>
            }
            Vente crypto en Fiat
          </p>
        }

        <!-- WITHDRAW -->
        @if (instructions?.get('WITHDRAW')) {
          <p
            class="body-14-med pointer"
            [ngClass]="{
              active: isWithdrawActive,
            }"
            (click)="scrollToAnchor('withdraw')"
          >
            @if (isWithdrawActive) {
              <div class="active-pointer"></div>
            }
            Retirer crypto vers une adresse
          </p>
        }
      </nav>
    </div>

    <section>
      <!-- TRADE -->
      @if (instructions?.get('TRADE')) {
        <div class="instruction m-b-30" id="trade">
          <span #tradeStart></span>
          <p class="body-16-bold">Trade crypto</p>

          <div class="instruction__content">
            @for (instruction of instructions?.get('TRADE').get('POSITIVE'); track $index) {
              <div class="item">
                <div class="item__title">
                  <img src="/assets/img/icons/instruction-positive.svg" alt="instruction-positive" />
                  <p class="body-14-bold">
                    {{ instruction['tagLine' + language.toUpperCase()] }}
                  </p>
                  <div class="item__title__amount">
                    <p class="body-12-med">
                      {{ instruction['badge' + language.toUpperCase()] }}
                    </p>
                  </div>
                </div>
                <p class="body-14">
                  {{ instruction['comment' + language.toUpperCase()] }}
                </p>
              </div>
            }

            @for (instruction of instructions?.get('TRADE').get('NEGATIVE'); track $index) {
              <div class="item">
                <div class="item__title">
                  <img src="/assets/img/icons/instruction-negative.svg" alt="instruction-negative" />
                  <p class="body-14-bold">
                    {{ instruction['tagLine' + language.toUpperCase()] }}
                  </p>
                  <div class="item__title__amount">
                    <p class="body-12-med">
                      {{ instruction['badge' + language.toUpperCase()] }}
                    </p>
                  </div>
                </div>
                <p class="body-14">
                  {{ instruction['comment' + language.toUpperCase()] }}
                </p>
              </div>
            }
          </div>

          <span #tradeEnd></span>
          <span #buyStart></span>
        </div>
      }

      <!-- BUY_CRYPTO -->
      @if (instructions?.get('BUY_CRYPTO')) {
        <div class="instruction m-b-30" id="buy">
          <p class="body-16-bold">Achat crypto en Fiat</p>

          <div class="instruction__content">
            @for (instruction of instructions?.get('BUY_CRYPTO').get('POSITIVE'); track $index) {
              <div class="item">
                <div class="item__title">
                  <img src="/assets/img/icons/instruction-positive.svg" alt="instruction-positive" />
                  <p class="body-14-bold">
                    {{ instruction['tagLine' + language.toUpperCase()] }}
                  </p>
                  <div class="item__title__amount">
                    <p class="body-12-med">
                      {{ instruction['badge' + language.toUpperCase()] }}
                    </p>
                  </div>
                </div>
                <p class="body-14">
                  {{ instruction['comment' + language.toUpperCase()] }}
                </p>
              </div>
            }

            @for (instruction of instructions?.get('BUY_CRYPTO').get('NEGATIVE'); track $index) {
              <div class="item">
                <div class="item__title">
                  <img src="/assets/img/icons/instruction-negative.svg" alt="instruction-negative" />
                  <p class="body-14-bold">
                    {{ instruction['tagLine' + language.toUpperCase()] }}
                  </p>
                  <div class="item__title__amount">
                    <p class="body-12-med">
                      {{ instruction['badge' + language.toUpperCase()] }}
                    </p>
                  </div>
                </div>
                <p class="body-14">
                  {{ instruction['comment' + language.toUpperCase()] }}
                </p>
              </div>
            }
          </div>

          <span #buyEnd></span>
          <span #sellStart></span>
        </div>
      }

      <!-- SELL_CRYPTO -->
      @if (instructions?.get('SELL_CRYPTO')) {
        <div class="instruction m-b-30" id="sell">
          <p class="body-16-bold">Vente crypto en Fiat</p>

          <div class="instruction__content">
            @for (instruction of instructions?.get('SELL_CRYPTO').get('POSITIVE'); track $index) {
              <div class="item">
                <div class="item__title">
                  <img src="/assets/img/icons/instruction-positive.svg" alt="instruction-positive" />
                  <p class="body-14-bold">
                    {{ instruction['tagLine' + language.toUpperCase()] }}
                  </p>
                  <div class="item__title__amount">
                    <p class="body-12-med">
                      {{ instruction['badge' + language.toUpperCase()] }}
                    </p>
                  </div>
                </div>
                <p class="body-14">
                  {{ instruction['comment' + language.toUpperCase()] }}
                </p>
              </div>
            }

            @for (instruction of instructions?.get('SELL_CRYPTO').get('NEGATIVE'); track $index) {
              <div class="item">
                <div class="item__title">
                  <img src="/assets/img/icons/instruction-negative.svg" alt="instruction-negative" />
                  <p class="body-14-bold">
                    {{ instruction['tagLine' + language.toUpperCase()] }}
                  </p>
                  <div class="item__title__amount">
                    <p class="body-12-med">
                      {{ instruction['badge' + language.toUpperCase()] }}
                    </p>
                  </div>
                </div>
                <p class="body-14">
                  {{ instruction['comment' + language.toUpperCase()] }}
                </p>
              </div>
            }
          </div>

          <span #sellEnd></span>
          <span #withdrawStart></span>
        </div>
      }

      <!-- WITHDRAW -->
      @if (instructions?.get('WITHDRAW')) {
        <div class="instruction m-b-30" id="withdraw">
          <p class="body-16-bold">Retrait crypto vers une adresse</p>

          <div class="instruction__content">
            @for (instruction of instructions?.get('WITHDRAW').get('POSITIVE'); track $index) {
              <div class="item">
                <div class="item__title">
                  <img src="/assets/img/icons/instruction-positive.svg" alt="instruction-positive" />
                  <p class="body-14-bold">
                    {{ instruction['tagLine' + language.toUpperCase()] }}
                  </p>
                  <div class="item__title__amount">
                    <p class="body-12-med">
                      {{ instruction['badge' + language.toUpperCase()] }}
                    </p>
                  </div>
                </div>
                <p class="body-14">
                  {{ instruction['comment' + language.toUpperCase()] }}
                </p>
              </div>
            }

            @for (instruction of instructions?.get('WITHDRAW').get('NEGATIVE'); track $index) {
              <div class="item">
                <div class="item__title">
                  <img src="/assets/img/icons/instruction-negative.svg" alt="instruction-negative" />
                  <p class="body-14-bold">
                    {{ instruction['tagLine' + language.toUpperCase()] }}
                  </p>
                  <div class="item__title__amount">
                    <p class="body-12-med">
                      {{ instruction['badge' + language.toUpperCase()] }}
                    </p>
                  </div>
                </div>
                <p class="body-14">
                  {{ instruction['comment' + language.toUpperCase()] }}
                </p>
              </div>
            }
          </div>

          <span #withdrawEnd></span>
        </div>
      }

      <div class="blur-box-bottom"></div>
    </section>
  </div>
</div>
