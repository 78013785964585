import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Assessment } from '../models/assessment.model';
import { map } from 'rxjs/operators';
import { GenericResponse } from '../../shared/models/generic-response.model';

@Injectable({
  providedIn: `root`,
})
export class AssessmentService {
  constructor(private readonly http: HttpClient) {}

  downloadAssessmentFile(assessmentId: string, filename: string): Observable<any> {
    if (filename?.includes(`.json`)) {
      return this.http.get(
        `${environment.apiUrl}/v1/tax/transaction/assessment/dl?id=${assessmentId}&name=${filename}`
      );
    } else {
      return this.http.get(
        `${environment.apiUrl}/v1/tax/transaction/assessment/dl?id=${assessmentId}&name=${filename}`,
        { responseType: `blob` }
      );
    }
  }

  downloadAssessmentFileAsPDF(assessmentId: string, filename: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/v1/tax/transaction/assessment/dl/pdf?id=${assessmentId}&name=${filename}`,
      { responseType: `blob` }
    );
  }

  startAssessment(fiscalYear: number): Observable<Assessment> {
    return this.http
      .post<Assessment>(`${environment.apiUrl}/v1/tax/transaction/assessment?fiscalYear=${fiscalYear}`, {})
      .pipe(
        map((assessment: Assessment) => {
          if (assessment?.nbOfWarningsbyType) {
            assessment.nbOfWarningsbyType = new Map<string, number>(Object.entries(assessment.nbOfWarningsbyType));
          }

          return assessment;
        })
      );
  }

  getAssessment(assessmentId?: string): Observable<Assessment> {
    let url = `${environment.apiUrl}/v1/tax/transaction/assessment`;

    if (assessmentId) {
      url = url.concat(`?assessmentId=${assessmentId}`);
    }

    return this.http.get<Assessment>(url).pipe(
      map((assessment: Assessment) => {
        if (assessment?.nbOfWarningsbyType) {
          assessment.nbOfWarningsbyType = new Map<string, number>(Object.entries(assessment?.nbOfWarningsbyType));
        }

        return assessment;
      })
    );
  }

  getLatestAssessmentsByYear(): Observable<Map<string, Assessment>> {
    return this.http.get<Map<string, Assessment>>(`${environment.apiUrl}/v1/tax/transaction/assessment/list`).pipe(
      map((assessments: Map<string, Assessment>) => {
        return new Map<string, Assessment>(Object.entries(assessments));
      })
    );
  }

  toggleAutomaticAssessment(): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(
      `${environment.apiUrl}/v1/tax/transaction/assessment/automatic-assessment/toggle`,
      {}
    );
  }
}
