import { Action, createReducer, on } from '@ngrx/store';
import { Page } from '../../../shared/models/page.model';
import { Transaction, TransactionFilters, TransactionWarning, TransactionsTab } from '../../models/transaction.model';
import { SearchDTO, ViewFilters } from '../../models/transactions-filters.model';
import * as TransactionActions from '../actions/transaction.action';
import { TransactionStatsAggregation } from '../../models/transaction-stats-aggregation.model';
import { TokenAndPlatformBalanceDetail } from '../../models/balance.model';
import { DEFAULT_TRANSACTIONS_FILTERS, DEFAULT_VIEW_FILTERS } from '../../constants/transactions.constant';
import { WarningOccurences } from '../../models/warning.model';

export interface State {
  selectedTransactionsTab: TransactionsTab;
  selectedTransaction: Transaction;
  filters: TransactionFilters;
  transactionsStats: TransactionStatsAggregation;
  transactionsPage: Page<Transaction>;
  transactionsTabFilters: SearchDTO;
  warningsTabFilters: SearchDTO;
  taxableTabFilters: SearchDTO;
  negativeBalances: TokenAndPlatformBalanceDetail[];
  transactionsTabCount: number;
  warningsTabCount: number;
  taxableTabCount: number;
  selectedNegativeBalanceWarning: TransactionWarning;
  transactionsWarnings: WarningOccurences[];
  viewFilters: ViewFilters;
}

export const initialState: State = {
  selectedTransactionsTab: `transactions`,
  selectedTransaction: null,
  transactionsPage: null,
  filters: null,
  transactionsTabFilters: {
    ...DEFAULT_TRANSACTIONS_FILTERS,
  },
  warningsTabFilters: {
    ...DEFAULT_TRANSACTIONS_FILTERS,
    onlyWarnings: true,
  },
  taxableTabFilters: {
    ...DEFAULT_TRANSACTIONS_FILTERS,
    onlyTaxable: true,
  },
  transactionsStats: null,
  negativeBalances: null,
  transactionsTabCount: 0,
  warningsTabCount: 0,
  taxableTabCount: 0,
  selectedNegativeBalanceWarning: null,
  transactionsWarnings: null,
  viewFilters: DEFAULT_VIEW_FILTERS,
};

const reducer = createReducer(
  initialState,
  on(TransactionActions.setSelectedTransactionsTabAction, (state: State, { selectedTransactionsTab }: any) => ({
    ...state,
    selectedTransactionsTab,
  })),
  on(TransactionActions.setTransactionsPageAction, (state: State, { transactionsPage }: any) => ({
    ...state,
    transactionsPage,
  })),
  on(TransactionActions.setFiltersAction, (state: State, { filters }: any) => ({
    ...state,
    filters,
  })),
  on(TransactionActions.setSelectedTransactionAction, (state: State, { selectedTransaction }: any) => ({
    ...state,
    selectedTransaction,
  })),
  on(TransactionActions.setAppliedFiltersAction, (state: State, { appliedFilters }: any) => ({
    ...state,
    transactionsTabFilters:
      state.selectedTransactionsTab === `transactions` ? appliedFilters : state.transactionsTabFilters,
    warningsTabFilters: state.selectedTransactionsTab === `warnings` ? appliedFilters : state.warningsTabFilters,
    taxableTabFilters: state.selectedTransactionsTab === `taxable` ? appliedFilters : state.taxableTabFilters,
  })),
  on(TransactionActions.setTransactionsStatsAction, (state: State, { transactionsStats }: any) => ({
    ...state,
    transactionsStats,
  })),
  on(TransactionActions.setNegativeBalancesAction, (state: State, { negativeBalances }: any) => ({
    ...state,
    negativeBalances,
  })),
  on(TransactionActions.setTransactionsTabCountAction, (state: State, { transactionsTabCount }: any) => ({
    ...state,
    transactionsTabCount,
  })),
  on(TransactionActions.setWarningsTabCountAction, (state: State, { warningsTabCount }: any) => ({
    ...state,
    warningsTabCount,
  })),
  on(TransactionActions.setTaxableTabCountAction, (state: State, { taxableTabCount }: any) => ({
    ...state,
    taxableTabCount,
  })),
  on(
    TransactionActions.setSelectedNegativeBalanceWarningAction,
    (state: State, { selectedNegativeBalanceWarning }: any) => ({
      ...state,
      selectedNegativeBalanceWarning,
    }),
  ),
  on(TransactionActions.setTransactionsWarningsAction, (state: State, { transactionsWarnings }: any) => ({
    ...state,
    transactionsWarnings,
  })),
  on(TransactionActions.setViewFiltersAction, (state: State, { viewFilters }: any) => ({
    ...state,
    viewFilters,
  })),
);

export const transactionReducer = (state: State | undefined, action: Action): State => reducer(state, action);
