<form class="form" [formGroup]="form" (ngSubmit)="validate2FaCode()">
  @if (!is2FaCodeValidated) {
    <div class="form__code-not-validated">
      <h1 class="form__code-not-validated__title">
        {{ 'ENABLE_2FA' | translate }}
        <img class="pointer" src="/assets/img/icons/cross.svg" alt="" (click)="dialogRef.close()" />
      </h1>
      <div class="form__code-not-validated__content">
        <p class="body-14">{{ 'SCAN_QR' | translate }}</p>
        @if (userTwoFA.qrCode) {
          <img class="form__code-not-validated__qr-code" [src]="userTwoFA.qrCode" alt="qr-code" />
        }
        <p class="body-14-med">{{ '2FA_ENTER_CODE' | translate }}</p>
        <input
          matInput
          formControlName="code"
          type="text"
          inputmode="numeric"
          pattern="[0-9]*"
          class="custom-input width-95"
          [maxlength]="6"
          [minlength]="6"
          placeholder="000000"
          />
      </div>
      <mat-divider></mat-divider>
      <div class="form__code-not-validated__buttons">
        <button mat-stroked-button type="button" (click)="dialogRef.close()">
          {{ 'CANCEL' | translate }}
        </button>
        <button [disabled]="form.get('code').invalid" type="submit" mat-flat-button color="primary">
          {{ 'ACTIVATE' | translate }}
        </button>
      </div>
    </div>
  }

  @if (is2FaCodeValidated) {
    <div class="form__code-validated">
      <div class="form__code-validated__content">
        <h1 class="form__code-validated__title">
          {{ '2FA_ENABLED' | translate }}
          <img class="pointer" src="/assets/img/icons/cross.svg" alt="" (click)="dialogRef.close()" />
        </h1>
        <p>{{ '2FA_RECOVERY_CODES' | translate }}</p>
        <div class="form__code-validated__recovery-codes">
          @for (recoveryCode of userTwoFA.recoveryCodes; track recoveryCode) {
            <p class="form__code-validated__recovery-code">
              {{ recoveryCode }}
            </p>
          }
        </div>
      </div>
      <mat-dialog-actions class="form__code-validated__buttons">
        <button mat-stroked-button type="button" color="secondary-flash" (click)="copyCodes()">
          {{ 'COPY_CODES' | translate }}
          <img src="/assets/img/icons/copy-active.svg" alt="" />
        </button>
        <button mat-flat-button color="primary" type="button" [disabled]="!codesCopied" (click)="dialogRef.close()">
          {{ '2FA_CODES_SAVED' | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  }
</form>
