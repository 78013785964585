import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { UserFeature } from '../../../../shared/models/feature.model';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: `app-early-access-feature-dialog`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule],
  templateUrl: `./early-access-feature-dialog.component.html`,
  styleUrl: `./early-access-feature-dialog.component.scss`,
})
export class EarlyAccessFeatureDialogComponent implements OnInit {
  userFeature: UserFeature;

  constructor(
    private readonly dialogRef: MatDialogRef<EarlyAccessFeatureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { userFeature: UserFeature },
  ) {}

  ngOnInit(): void {
    this.userFeature = this.data.userFeature;
  }

  closeDialog(): void {
    const userFeatures = localStorage.getItem(`user-features`);

    if (!userFeatures) {
      localStorage.setItem(`user-features`, `${this.data.userFeature.toString()} `);
    } else {
      localStorage.setItem(`user-features`, `${userFeatures},${this.data.userFeature.toString()}`);
    }

    this.dialogRef.close();
  }
}
