import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccountExpansionPanelComponent } from '../account-expansion-panel/account-expansion-panel.component';
import { FileImporterComponent } from '../file-importer/file-importer.component';
import { TimezoneDetails } from '../../../shared/models/file.model';
import { AccountType, UserAccount } from '../../models/user-account.model';
import { Account, SuggestedPlatform } from '../../models/account.model';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CustomFocusDirective } from '../../../shared/directives/custom-focus.directive';
import { createNewIntegrationAction } from '../../store/actions/account.action';
import { Store } from '@ngrx/store';
import * as fromAccount from '../../store/selectors/account.selector';
@Component({
  selector: `app-suggested-platform-importer`,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    AccountExpansionPanelComponent,
    FileImporterComponent,
    CustomFocusDirective,
  ],
  templateUrl: `./suggested-platform-importer.component.html`,
  styleUrl: `./suggested-platform-importer.component.scss`,
})
export class SuggestedPlatformImporterComponent implements OnInit {
  @Input() accountType: AccountType;
  @Input() suggestedPlatform: SuggestedPlatform;
  @Input() account: Account;
  @Input() userAccounts: UserAccount[];
  @Input() timezones: TimezoneDetails[];
  @Input() accessToken: string;

  platformForm: FormGroup;
  walletForm: FormGroup;

  loading = false;
  files: File[] = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly accountStore$: Store<fromAccount.State>,
  ) {
    this.platformForm = this.fb.group({
      apiKey: [``],
      apiSecret: [``],
      apiPassphrase: [``],
    });

    this.walletForm = this.fb.group({
      address: [``, [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.accountType === `API`) {
      this.platformForm.get(`apiKey`).setValidators([Validators.required]);
      this.platformForm.get(`apiSecret`).setValidators([Validators.required]);
    }
  }

  areFilesRequired(): boolean {
    return this.accountType === `FILE` && this.files.length === 0;
  }

  onFilesAdded(files: FileList): void {
    this.files = Array.from(files);
  }

  validateWalletForm(): void {
    this.loading = true;

    this.accountStore$.dispatch(
      createNewIntegrationAction({
        platform: this.suggestedPlatform.technicalName,
        apiKey: this.walletForm.get(`address`).value,
      }),
    );
  }

  validatePlatformForm(): void {
    this.loading = true;

    this.accountStore$.dispatch(
      createNewIntegrationAction({
        platform: this.suggestedPlatform.technicalName,
        apiKey: this.platformForm.get(`apiKey`).value,
        apiSecret: this.platformForm.get(`apiSecret`).value,
        apiPassphrase: this.platformForm.get(`apiPassphrase`).value,
        files: this.files,
      }),
    );
  }
}
