import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: `app-switch-fiscal-year-dialog`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, MatDividerModule],
  templateUrl: `./switch-fiscal-year-dialog.component.html`,
  styleUrl: `./switch-fiscal-year-dialog.component.scss`,
})
export class SwitchFiscalYearDialogComponent implements OnInit {
  goToYearLabel = ``;
  moveToFiscalYearLabel = ``;
  description = ``;

  constructor(
    private readonly translateService: TranslateService,
    public readonly dialogRef: MatDialogRef<SwitchFiscalYearDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fiscalYear: number;
    }
  ) {}

  ngOnInit(): void {
    this.goToYearLabel = this.translateService.instant(`GO_TO_YEAR`, { year: this.data.fiscalYear });
    this.moveToFiscalYearLabel = this.translateService.instant(`MOVE_TO_YEAR`, {
      year: this.data.fiscalYear,
    });
    this.description = this.translateService.instant(`FIND_CAPITAL_GAIN_TODAY`, { year: this.data.fiscalYear });
  }
}
