import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EventSourcePolyfill } from 'event-source-polyfill';

@Injectable({
  providedIn: `root`,
})
export class FeedService implements OnDestroy {
  private readonly heartbeatTimeout = 120000; // 2 mins

  private readonly marketFeedEndpoint = `${environment.apiUrl}/v1/insight/feed/stream`; // Prices in EUR
  private readonly tickerFeedEndpoint = `${environment.apiUrl}/v1/insight/feed/ticker`; // Prices in EUR

  private marketFeedEventSource: EventSource;
  private tickerFeedEventSource: EventSource;

  startMarketFeed(accessToken: string): EventSource {
    if (!this.marketFeedEventSource) {
      const options = {
        heartbeatTimeout: this.heartbeatTimeout,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          waltio_user: sessionStorage.getItem(`waltio_user`),
        },
      };
      this.marketFeedEventSource = new EventSourcePolyfill(this.marketFeedEndpoint, options);

      return this.marketFeedEventSource;
    } else {
      return this.marketFeedEventSource;
    }
  }

  stopMarketFeed(): void {
    if (this.marketFeedEventSource) {
      this.marketFeedEventSource.close();

      this.marketFeedEventSource.onmessage = null;
      this.marketFeedEventSource.onerror = null;
      this.marketFeedEventSource.onopen = null;
    }
  }

  startTickerFeed(accessToken: string): EventSource {
    if (!this.tickerFeedEventSource) {
      const options = {
        heartbeatTimeout: this.heartbeatTimeout,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          waltio_user: sessionStorage.getItem(`waltio_user`),
        },
      };
      this.tickerFeedEventSource = new EventSourcePolyfill(this.tickerFeedEndpoint, options);

      return this.tickerFeedEventSource;
    } else {
      return this.tickerFeedEventSource;
    }
  }

  stopTickerFeed(): void {
    if (this.tickerFeedEventSource) {
      this.tickerFeedEventSource.close();

      this.tickerFeedEventSource.onmessage = null;
      this.tickerFeedEventSource.onerror = null;
      this.tickerFeedEventSource.onopen = null;
    }
  }

  ngOnDestroy(): void {
    this.stopMarketFeed();
    this.stopTickerFeed();
  }
}
