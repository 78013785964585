<div class="container">
  <div class="plan-details">
    <div [matTooltip]="planStatusExplanation" matTooltipPosition="above">
      <div class="plan">
        <p class="body-12-med">{{ planLabel | uppercase }}</p>

        <div class="year">
          <p class="body-12-med">
            {{ associatedFiscalYear }}
          </p>
        </div>
      </div>

      <p class="body-12-med">
        {{ transactionsCount }}
        {{ 'TRANSACTIONS' | translate | lowercase }}

        @if (maximumNbOfTransactions > 0) {
          {{ 'ON' | translate | lowercase }}
          {{ maximumNbOfTransactions }}
        }
      </p>
      @if (plan !== 'PLN800') {
        <div class="plan-progress">
          <div class="plan-progress__fill" [ngStyle]="{ 'width.%': progress }"></div>
        </div>
      }
    </div>

    @if (plan === 'PLN800') {
      <div class="addon pointer" (click)="upgradeAddon.emit()">
        <p class="body-12-med">{{ 'NEED_EXPERT' | translate }}</p>
        <img src="/assets/img/icons/next-white.svg" alt="" />
      </div>
    }

    @if (plan !== 'PLN800') {
      <div class="upgrade-plan" (click)="upgradePlan.emit()">
        <p class="body-12-med">{{ 'MENU.CHANGE_PLAN' | translate }}</p>
        <img src="/assets/img/icons/next.svg" alt="next" />
      </div>
    }
  </div>
</div>
