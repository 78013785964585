<div class="account-expansion-panel">
  <div class="account-expansion-panel__title pointer" (click)="togglePanel()">
    @if (icon) {
      <img [src]="icon" alt="icon" />
    } @else {
      <img src="/assets/img/icons/info-banner.svg" alt="info-banner" />
    }

    @if (title) {
      <p class="body-14-med">
        {{ title }}
      </p>
    } @else {
      <p class="body-14-med">
        {{ getTitle() || 'SUPPORT_CONFIG_TITLE' | translate }}
      </p>
    }

    <img
      class="arrow"
      [src]="isOpen ? '/assets/img/icons/select-arrow-up.svg' : '/assets/img/icons/select-arrow-down.svg'"
      alt="select-arrow"
    />
  </div>

  @if (isOpen) {
    <mat-divider></mat-divider>

    <div class="account-expansion-panel__content">
      @if (content) {
        <p class="body-14-med">
          {{ content }}
        </p>
      } @else {
        <p class="body-14-med">
          {{ getContent() }}
        </p>
      }

      @if (showLink) {
        <div
          class="account-expansion-panel__content__link pointer"
          (click)="showArticle(supportConfiguration?.recommendedImportMethodArticleId)"
        >
          <p class="body-14-bold">
            {{ 'LEARN_MORE' | translate }}
          </p>

          <img src="/assets/img/icons/select-arrow-right-active.svg" alt="select-arrow-right-active" />
        </div>
      }
    </div>
  }
</div>
