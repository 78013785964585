import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { UserPreferences } from '../../../shared/models/user-preferences.model';
import { CurrencyDecimalPipe } from '../../../shared/pipes/currency-decimal.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: `app-vault`,
  standalone: true,
  imports: [CommonModule, MatButtonModule, TranslateModule, CurrencyDecimalPipe, MatTooltipModule],
  templateUrl: `./vault.component.html`,
  styleUrls: [`./vault.component.scss`]
})
export class VaultComponent {
  @Input() vaultBalance: number;
  @Input() useVault: boolean;
  @Input() useVoucher: boolean;
  @Input() userPreferences: UserPreferences;
  @Input() stablecoins: string[];
  @Input() fiats: string[];

  @Output() useVaultChange: EventEmitter<boolean> = new EventEmitter<boolean>();
}
