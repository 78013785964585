import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GenericResponse } from '../../shared/models/generic-response.model';
import { TwoFACheck, UserTwoFAConfiguration } from '../models/two-fa.model';

@Injectable({
  providedIn: `root`,
})
export class TwoFaService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get 2FA config
   *
   * @returns 2FA config for current user
   */
  get2FAConfig(): Observable<UserTwoFAConfiguration> {
    return this.http.get<UserTwoFAConfiguration>(
      `${environment.apiUrl}/v1/tax/2fa`
    );
  }

  /**
   * Validate 2FA code
   *
   * @param code qr code
   * @returns generic response
   */
  validate2FACode(code: string): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(
      `${environment.apiUrl}/v1/tax/2fa/code?code=${code}`,
      ``
    );
  }

  /**
   * Disable 2FA
   *
   * @returns 2FA config
   */
  disable2FA(): Observable<UserTwoFAConfiguration> {
    return this.http.post<UserTwoFAConfiguration>(
      `${environment.apiUrl}/v1/tax/2fa/disable`,
      ``
    );
  }

  /**
   * Enable 2FA
   *
   * @param code qr code
   * @returns 2FA config
   */
  enable2FA(code: string): Observable<UserTwoFAConfiguration> {
    return this.http.post<UserTwoFAConfiguration>(
      `${environment.apiUrl}/v1/tax/2fa/enable?code=${code}`,
      ``
    );
  }

  /**
   * Validate 2FA recovery code
   *
   * @param code recovery code
   * @returns generic response
   */
  validate2FARecoveryCode(code: string): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(
      `${environment.apiUrl}/v1/tax/2fa/recovery-code?code=${code}`,
      ``
    );
  }

  /**
   * Setup 2FA
   *
   * @returns QR code
   */
  setup2FA(): Observable<TwoFACheck> {
    return this.http.post<TwoFACheck>(
      `${environment.apiUrl}/v1/tax/2fa/setup`,
      ``
    );
  }
}
