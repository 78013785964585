import { UserAddon, UserPlan } from '../models/user.model';

export const PLANS: Map<UserPlan, any> = new Map<UserPlan, any>([
  [
    `PLN000`,
    {
      maximumNbOfTransactions: 50,
      price: 0,
    },
  ],
  [
    `PLN300`,
    {
      maximumNbOfTransactions: 1000,
      price: 99.0,
      details: [
        `PLN300.TRANSACTIONS`,
        `DeFi, staking, farming, NFTs...`,
        `PLN300.REVENUE`,
        `PLN300.CERTIFICATE`,
        `PLN300.STEP_BY_STEP_REPORT_GUIDE`,
        `PLN300.REAL_TIME_PORTFOLIO_TRACKING`,
      ],
    },
  ],
  [
    `PLN500`,
    {
      maximumNbOfTransactions: 10000,
      price: 199.0,
      details: [
        `PLN500.PLAN`,
        `PLN500.TRANSACTIONS`,
        `PLN500.SYNC`,
        `PLN500.ITEM`,
        `PLN500.UNLIMITED_TAX_DOC_STORAGE`,
        `PLN500.STAKING_FEES_REDUCTION`,
      ],
    },
  ],
  [
    `PLN800`,
    {
      maximumNbOfTransactions: -1,
      price: 999.0,
      details: [`PLN800.PLAN`, `PLN800.TRANSACTIONS`, `PLN800.DOCS`, `PLN800.SYNC`, `PLN800.ITEM`],
    },
  ],
]);

export const ADDONS: Map<UserAddon, any> = new Map<UserAddon, any>([
  [
    `AOCARE001`,
    {
      price: 799.0,
      details: [
        `AOCARE001.ACCOUNT`,
        `AOCARE001.TURNKEY`,
        `AOCARE001.EXPERT`,
        `AOCARE001.BLOCKCHAIN`,
        `AOCARE001.PHONE`,
        `AOCARE001.SUPPORT`,
      ],
    },
  ],
  [
    `AOCARE002`,
    {
      price: 2999.0,
      details: [
        `AOCARE002.PLAN`,
        `AOCARE001.ACCOUNT`,
        `AOCARE001.TURNKEY`,
        `AOCARE001.EXPERT`,
        `AOCARE001.BLOCKCHAIN`,
        `AOCARE001.PHONE`,
        `AOCARE001.SUPPORT`,
      ],
    },
  ],
]);
