<section class="container">
  <section class="header">
    @switch (currentForm) {
      @case ('3916-bis') {
        <h1>{{ 'DECLATE_ABROAD_ACCOUNTS' | translate }}</h1>
      }

      @case ('2086') {
        <h1>{{ 'TAXABLE_TX_DECLARATION' | translate }}</h1>
      }

      @case ('es') {
        <h1>{{ 'TAXABLE_TX_DECLARATION' | translate }}</h1>
      }

      @case ('modelo-721') {
        <h1>{{ 'DECLATE_ABROAD_ACCOUNTS' | translate }}</h1>
      }
    }
    <div>
      @if (currentForm === '3916-bis' || currentForm === '2086') {
        <mat-button-toggle-group
          class="custom-button-toggle-group"
          [(ngModel)]="currentForm"
          hideSingleSelectionIndicator
        >
          <mat-button-toggle value="3916-bis" disableRipple checked>{{
            'FORM_3916_BIS' | translate
          }}</mat-button-toggle>
          <mat-button-toggle
            value="2086"
            [disabled]="loading"
            disableRipple
            [matTooltipDisabled]="!loading"
            matTooltipPosition="above"
            [matTooltip]="'FORM_AVAILABLE_SOON' | translate"
          >
            @if (!hasPaid) {
              <span class="demo">{{ 'PREVIEW' | translate }}</span>
            }
            {{ 'FORM_2086' | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      } @else {
        <mat-button-toggle-group
          class="custom-button-toggle-group"
          [(ngModel)]="currentForm"
          hideSingleSelectionIndicator
          disableRipple
          [matTooltipDisabled]="!loading"
          matTooltipPosition="above"
          [matTooltip]="'FORM_AVAILABLE_SOON' | translate"
        >
          <mat-button-toggle value="modelo-721" disableRipple checked>
            @if (!hasPaidModelo) {
              <span class="demo">{{ 'PREVIEW' | translate }}</span>
            }
            {{ 'MODELO_721' | translate }}
          </mat-button-toggle>
          <mat-button-toggle
            value="es"
            [disabled]="loading"
            disableRipple
            [matTooltipDisabled]="!loading"
            matTooltipPosition="above"
            [matTooltip]="'FORM_AVAILABLE_SOON' | translate"
          >
            @if (!hasPaid) {
              <span class="demo">{{ 'PREVIEW' | translate }}</span>
            }
            {{ 'TAXABLE_TRANSACTIONS' | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      }

      <ng-container>
        @if (currentForm === '3916-bis') {
          <app-banner
            [title]="'FILL_IN_FORM_3916_BIS' | translate"
            [subtitle]="'DECLARE_ABROAD_ACCOUNTS' | translate"
          ></app-banner>
        }
        @if (currentForm === '2086') {
          <app-banner
            [title]="'FILL_2086_FORM' | translate"
            [subtitle]="'DECLARE_TAXABLE_TRANSFERS' | translate"
          ></app-banner>
        }
        @if (currentForm === 'modelo-721') {
          <app-banner
            [title]="'FILL_IN_MODELO_721' | translate"
            [subtitle]="'FILL_IN_MODELO_721_DESC' | translate"
          ></app-banner>
        }
        @if (currentForm === 'es') {
          <app-banner
            [title]="'REPORT_TAXABLE_TX_2' | translate"
            [subtitle]="'REPORT_TAXABLE_TX_DESC' | translate"
          ></app-banner>
        }
      </ng-container>
    </div>
  </section>

  <mat-divider></mat-divider>

  @if (currentForm === '3916-bis' || currentForm === 'modelo-721') {
    <section
      class="content"
      [ngClass]="{
        'content--skeleton': !accounts || !selectedPlatform,
      }"
    >
      <!-- Upgrade fishing -->
      @if (currentForm === 'modelo-721' && !showDemo && !hasPaidModelo) {
        <div class="upgrade-fishing">
          <div class="upgrade-fishing__content">
            <h2>{{ 'UPDATE_YOUR_SUBSCRIPTION' | translate }}</h2>
            <p class="body-14">{{ 'DISCOVER_FOREIGN_PLATFORMS' | translate }}</p>
          </div>
          <div class="upgrade-fishing__ctas">
            <button
              type="button"
              mat-flat-button
              color="primary"
              (click)="upgradeFishing('upgrade-721-pannel', 'DECLARE_FOREIGN_PLATFORMS', 'PLN500')"
            >
              {{ upgradeFishingCTAModelo }}
            </button>
            <button type="button" mat-flat-button color="secondary" (click)="showDemo = true">
              {{ 'SHOW_FORM_PREVIEW' | translate }}
            </button>
          </div>
        </div>
      }

      <div
        class="modelo"
        [ngClass]="{
          'demo-filter': !showDemo && currentForm === 'modelo-721' && !hasPaidModelo,
        }"
      >
        @if (showDemo) {
          <div class="modelo--demo"></div>
        }

        @if (accounts && selectedPlatform) {
          <p class="body-14-med">{{ 'SELECT_PLATFORM' | translate }}</p>

          <div class="selectors-container">
            <div class="selectors">
              @if (accounts.size > 0) {
                <div class="platform-select-container">
                  @if (selectedPlatform) {
                    <img
                      class="platform-logo"
                      width="24"
                      height="24"
                      [src]="'/assets/img/logos/accounts/' + selectedPlatform + '.png'"
                      [default]="'/assets/img/logos/accounts/default.svg'"
                      alt=""
                    />
                  }
                  <mat-select
                    [(value)]="selectedPlatform"
                    disableOptionCentering
                    class="custom-select-variant"
                    customFocus
                    defaultClass="custom-select-variant"
                    panelClass="custom-select-variant-panel"
                  >
                    <mat-select-trigger>
                      <div class="platform-option-selected">
                        {{ accounts.get(selectedPlatform)?.name }}
                      </div>
                    </mat-select-trigger>
                    <mat-option color="primary">
                      <ngx-mat-select-search
                        [formControl]="modeloPlatformSearchControl"
                        [placeholderLabel]="'SEARCH' | translate"
                        [noEntriesFoundLabel]="''"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-divider class="option-divider"></mat-divider>
                    @if (modeloUsedPlatforms.size > 0) {
                      <p class="body-12-med">
                        {{ 'MY_ADDED_PLATFORMS' | translate }}
                        ({{ modelo?.totalValue | currency: 'EUR' : 'symbol' : '.0-0' : 'fr' }})
                      </p>
                      @for (platform of modeloUsedPlatformsOptions$ | async | keyvalue; track platform) {
                        <mat-option [value]="platform.key">
                          <div class="platform-option">
                            <img
                              class="platform-logo"
                              width="24"
                              height="24"
                              [src]="'/assets/img/logos/accounts/' + platform.key + '.png'"
                              [default]="'/assets/img/logos/accounts/default.svg'"
                              alt=""
                            />
                            {{ accounts.get(platform.key)?.name }}

                            <p class="body-12">
                              {{ modelo?.platforms.get(platform.key)?.numberOfTokens }}
                              {{ 'TOKENS' | translate | lowercase }}
                              ({{
                                modelo?.platforms.get(platform.key)?.value | currency: 'EUR' : 'symbol' : '.0-0' : 'fr'
                              }})
                            </p>
                          </div>
                        </mat-option>
                      }
                    }
                    @if (modeloPlatforms.size > 0) {
                      @if (modeloUsedPlatforms.size > 0) {
                        <mat-divider class="option-divider"></mat-divider>
                      }
                      @if (modeloUsedPlatforms.size > 0) {
                        <p class="body-12-med">{{ 'OTHER_PLATFORMS' | translate }}</p>
                      }
                      @for (platform of modeloPlatformsOptions$ | async | keyvalue; track platform) {
                        <mat-option [value]="platform.key">
                          <div class="platform-option">
                            <img
                              class="platform-logo"
                              width="24"
                              height="24"
                              [src]="'/assets/img/logos/accounts/' + platform.key + '.png'"
                              [default]="'/assets/img/logos/accounts/default.svg'"
                              alt=""
                            />
                            {{ accounts.get(platform.key)?.name }}
                          </div>
                        </mat-option>
                      }
                    }
                  </mat-select>
                </div>

                @if (assessment?.fiscalAuthority === 'ES') {
                  <mat-select
                    [(value)]="selectedToken"
                    disableOptionCentering
                    class="custom-token-select"
                    customFocus
                    defaultClass="custom-token-select"
                  >
                    <mat-select-trigger>
                      <div class="currency-option">
                        <img
                          class="coin-icon"
                          src="https://icons.waltio.com/token/{{ selectedToken | lowercase }}"
                          default="/assets/img/icons/coins/default.svg"
                          [alt]="selectedToken"
                          width="24"
                          height="24"
                        />
                        <p class="body-14-med">
                          {{ selectedToken }}
                        </p>
                      </div>
                    </mat-select-trigger>
                    @for (token of modeloTokens; track token) {
                      <mat-option [value]="token">
                        <img
                          class="coin-icon"
                          src="https://icons.waltio.com/token/{{ token | lowercase }}"
                          default="/assets/img/icons/coins/default.svg"
                          [alt]="token"
                          width="24"
                          height="24"
                        />
                        <p class="body-14-med">
                          {{ token | truncate: 15 }}
                        </p>
                      </mat-option>
                    }
                  </mat-select>
                }
              }
            </div>

            @if (!hasPaidModelo) {
              <button
                mat-flat-button
                color="primary-flash"
                disableRipple
                (click)="upgradeFishing('upgrade-721-pannel', 'DECLARE_FOREIGN_PLATFORMS', 'PLN500')"
                [disabled]="!showDemo"
              >
                {{ 'SUBSCRIBE' | translate }}
              </button>
            }
          </div>

          @if (accounts.size > 0) {
            @if (assessment?.fiscalAuthority === 'FR') {
              <app-french-tax-form [platform]="selectedPlatform"></app-french-tax-form>
            }

            @if (assessment?.fiscalAuthority === 'ES') {
              <app-spanish-tax-form
                [coins]="coins$ | async"
                [platform]="selectedPlatform"
                [token]="selectedToken"
                [modelo]="modelo"
                [demo]="!hasPaidModelo"
              ></app-spanish-tax-form>
            }
          }
        }
        @if (!accounts || !selectedPlatform) {
          <img class="rotating" width="22px" src="/assets/img/icons/loading-default.svg" alt="" />
        }
      </div>
    </section>
  }

  @if (currentForm === '2086' || currentForm === 'es') {
    <section
      class="cessions-container"
      [ngClass]="{
        'cessions-container--skeleton':
          (currentForm === '2086' || currentForm === 'es') && (loading || !taxableCessions),
      }"
    >
      @if (!loading && taxableCessions) {
        <!-- Upgrade fishing -->
        @if (!showDemo && !hasPaid) {
          <div class="upgrade-fishing">
            <div class="upgrade-fishing__content">
              <h2>{{ 'UPDATE_YOUR_SUBSCRIPTION' | translate }}</h2>
              <p class="body-14">{{ 'FIND_OUT_TAXABLE_TRANSFERS' | translate }}</p>
            </div>
            <div class="upgrade-fishing__ctas">
              <button type="button" mat-flat-button color="primary" (click)="upgradeFishing()">
                {{ upgradeFishingCTA }}
              </button>
              <button type="button" mat-flat-button color="secondary" (click)="showDemo = true">
                {{ 'SHOW_FORM_PREVIEW' | translate }}
              </button>
            </div>
          </div>
        }

        <div class="filter-container" [ngClass]="{ 'demo-filter': !hasPaid && !showDemo }">
          @if (showDemo) {
            <div class="filter-container--demo"></div>
          }

          <!-- No cessions -->
          @if (taxableCessions && taxableCessions.length === 0) {
            <div class="no-cessions">
              <img src="/assets/img/icons/no-cessions.svg" alt="no-cessions" />
              <h2>{{ 'NO_TAXABLE_TRANSFERS' | translate }}</h2>
              @if (assessment?.fiscalAuthority === 'FR') {
                <p class="body-14">
                  {{ 'NO_NEED_FILL_2086' | translate }}
                </p>
              }
              @if (assessment?.fiscalAuthority === 'ES') {
                <p class="body-14">
                  {{ 'NO_TAXABLE_TX' | translate }}
                </p>
              }
              <button type="button" mat-flat-button color="secondary" (click)="showDemoCessions()">
                {{ 'SHOW_FORM_PREVIEW' | translate }}
              </button>
            </div>
          }
          @if (taxableCessions && taxableCessions.length > 0) {
            <div class="title">
              <div class="title__infos">
                <p class="body-14-med">{{ taxableCessionsMsg }}</p>
                <p class="body-14-med">{{ 'BOX_COPY' | translate }}</p>
              </div>
              @if (!hasPaid) {
                <button
                  mat-flat-button
                  color="primary-flash"
                  disableRipple
                  (click)="upgradeFishing()"
                  [disabled]="!showDemo"
                >
                  {{ 'SUBSCRIBE' | translate }}
                </button>
              }
            </div>
          }

          <!-- FR -->
          @if (taxableCessions && taxableCessions.length > 0 && assessment.fiscalAuthority === 'FR') {
            <section
              class="cessions"
              [ngClass]="{
                'cessions--demo': showDemo,
                'demo-filter': !hasPaid && !showDemo,
              }"
            >
              <!-- First grid -->
              <div class="grid">
                <!-- First row -->
                @for (
                  cession of displayedCessions;
                  track cession;
                  let first = $first;
                  let last = $last;
                  let index = $index
                ) {
                  <div
                    class="grid-header"
                    [ngClass]="{
                      first: first,
                      'border-top-left': first,
                      'border-top-right': last,
                    }"
                  >
                    <p class="body-14-bold">{{ 'CESSION' | translate }} {{ taxableCessions?.indexOf(cession) + 1 }}</p>
                  </div>
                }
                <div class="grid-header grid-header--code border-top-left">
                  <p class="body-14-bold">211</p>
                  <div class="info-tooltip" [matTooltip]="'TRANSFER_DATE' | translate" matTooltipPosition="right"></div>
                </div>
                @for (cession of displayedCessions; track cession) {
                  <app-tax-grid-cell [value]="cession['211']" [copiable]="hasPaid || showDemo"></app-tax-grid-cell>
                }
                <div class="grid-header grid-header--code border-bottom-left">
                  <p class="body-14-bold">212</p>
                  <div
                    class="info-tooltip"
                    [matTooltip]="'PORTFOLIO_TOTAL_VALUE' | translate"
                    matTooltipPosition="right"
                  ></div>
                </div>
                @for (cession of displayedCessions; track cession; let last = $last) {
                  <app-tax-grid-cell
                    [ngClass]="{
                      'border-bottom-right': last,
                    }"
                    [value]="cession['212']"
                    [copiable]="hasPaid || showDemo"
                  >
                  </app-tax-grid-cell>
                }
              </div>
              <!-- Second grid -->
              <div class="grid">
                <div class="grid-header grid-header--code border-top-left">
                  <p class="body-14-bold">213</p>
                  <div
                    class="info-tooltip"
                    [matTooltip]="'TRANSFER_PRICE' | translate"
                    matTooltipPosition="right"
                  ></div>
                </div>
                @for (cession of displayedCessions; track cession; let last = $last) {
                  <app-tax-grid-cell
                    [ngClass]="{
                      'border-top-right': last,
                    }"
                    [value]="cession['213']"
                    [copiable]="hasPaid || showDemo"
                  >
                  </app-tax-grid-cell>
                }
                <div class="grid-header grid-header--code">
                  <p class="body-14-bold">214</p>
                  <div class="info-tooltip" [matTooltip]="'TRANSFER_FEES' | translate" matTooltipPosition="right"></div>
                </div>
                @for (cession of displayedCessions; track cession) {
                  <app-tax-grid-cell [value]="cession['214']" [copiable]="hasPaid || showDemo"> </app-tax-grid-cell>
                }
                <div class="grid-header grid-header--code">
                  <p class="body-14-bold">215</p>
                  <div
                    class="info-tooltip"
                    [matTooltip]="'NET_TRANSFER_PRICE' | translate"
                    matTooltipPosition="right"
                  ></div>
                </div>
                @for (cession of displayedCessions; track cession) {
                  <app-tax-grid-cell [value]="cession['215']" [copiable]="hasPaid || showDemo"> </app-tax-grid-cell>
                }
                <div class="grid-header grid-header--code">
                  <p class="body-14-bold">216</p>
                  <div class="info-tooltip" [matTooltip]="'COMPENSATIONS' | translate" matTooltipPosition="right"></div>
                </div>
                @for (cession of displayedCessions; track cession) {
                  <app-tax-grid-cell [value]="cession['216']" [copiable]="hasPaid || showDemo"> </app-tax-grid-cell>
                }
                <div class="grid-header grid-header--code">
                  <p class="body-14-bold">217</p>
                  <div
                    class="info-tooltip"
                    [matTooltip]="'NET_TRANSFER_PRICE_AFTER2' | translate"
                    matTooltipPosition="right"
                  ></div>
                </div>
                @for (cession of displayedCessions; track cession) {
                  <app-tax-grid-cell [value]="cession['217']" [copiable]="hasPaid || showDemo"> </app-tax-grid-cell>
                }
                <div class="grid-header grid-header--code border-bottom-left">
                  <p class="body-14-bold">218</p>
                  <div
                    class="info-tooltip"
                    [matTooltip]="'NET_TRANSFER_PRICE_AFTER' | translate"
                    matTooltipPosition="right"
                  ></div>
                </div>
                @for (cession of displayedCessions; track cession; let last = $last) {
                  <app-tax-grid-cell
                    [ngClass]="{
                      'border-bottom-right': last,
                    }"
                    [value]="cession['218']"
                    [copiable]="hasPaid || showDemo"
                  >
                  </app-tax-grid-cell>
                }
              </div>
              <!-- Last grid -->
              <div class="grid">
                <div class="grid-header grid-header--code border-top-left">
                  <p class="body-14-bold">220</p>
                  <div
                    class="info-tooltip"
                    [matTooltip]="'TOTAL_ACQUISITION_PRICE' | translate"
                    matTooltipPosition="right"
                  ></div>
                </div>
                @for (cession of displayedCessions; track cession; let last = $last) {
                  <app-tax-grid-cell
                    [ngClass]="{
                      'border-top-right': last,
                    }"
                    [value]="cession['220']"
                    [copiable]="hasPaid || showDemo"
                  >
                  </app-tax-grid-cell>
                }
                <div class="grid-header grid-header--code">
                  <p class="body-14-bold">221</p>
                  <div
                    class="info-tooltip"
                    [matTooltip]="'CAPITAL_FRACTIONS' | translate"
                    matTooltipPosition="right"
                  ></div>
                </div>
                @for (cession of displayedCessions; track cession) {
                  <app-tax-grid-cell [value]="cession['221']" [copiable]="hasPaid || showDemo"> </app-tax-grid-cell>
                }
                <div class="grid-header grid-header--code">
                  <p class="body-14-bold">222</p>
                  <div
                    class="info-tooltip"
                    [matTooltip]="'RECEIVED_COMPENSATIONS' | translate"
                    matTooltipPosition="right"
                  ></div>
                </div>
                @for (cession of displayedCessions; track cession) {
                  <app-tax-grid-cell [value]="cession['222']" [copiable]="hasPaid || showDemo"> </app-tax-grid-cell>
                }
                <div class="grid-header grid-header--code border-bottom-left">
                  <p class="body-14-bold">223</p>
                  <div
                    class="info-tooltip"
                    [matTooltip]="'TOTAL_NET_ACQUISITION_PRICE' | translate"
                    matTooltipPosition="right"
                  ></div>
                </div>
                @for (cession of displayedCessions; track cession; let last = $last) {
                  <app-tax-grid-cell
                    [ngClass]="{
                      'border-bottom-right': last,
                    }"
                    [value]="cession['223']"
                    [copiable]="hasPaid || showDemo"
                  >
                  </app-tax-grid-cell>
                }
              </div>
            </section>
          }

          <!-- ES -->
          @if (taxableCessions && taxableCessions.length > 0 && assessment.fiscalAuthority === 'ES') {
            <app-spanish-taxable-cession
              [taxableCession]="selectedTaxableCession"
              [demo]="!hasPaid"
            ></app-spanish-taxable-cession>
          }
        </div>
      }
      @if ((currentForm === '2086' || currentForm === 'es') && (loading || !taxableCessions)) {
        <img class="rotating" width="22px" src="/assets/img/icons/loading-default.svg" alt="" />
      }
    </section>
  }

  <!-- FR  -->
  @if (currentForm === '2086' && taxableCessions?.length > this.maxCessionsPerPage) {
    <section class="cessions-paginator" [ngClass]="{ 'demo-filter': !hasPaid && !showDemo }">
      <p>
        {{ 'PAGE' | translate }} {{ cessionsPageIndex / this.maxCessionsPerPage + 1 }}
        {{ 'ON' | translate | lowercase }}
        {{ maxCessionsPage }}
      </p>
      <div class="ctas">
        <button mat-flat-button color="primary" [disabled]="cessionsPageIndex === 0" (click)="updateCessionPage(false)">
          {{ 'PaginatorPreviousPageLabel' | translate }}
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="cessionsPageIndex / this.maxCessionsPerPage + 1 === maxCessionsPage"
          (click)="updateCessionPage()"
        >
          {{ 'PaginatorNextPageLabel' | translate }}
        </button>
      </div>
    </section>
  }

  <!-- ES -->
  @if (currentForm === 'es' && taxableCessions?.length > 0) {
    <section class="cessions-paginator" [ngClass]="{ 'demo-filter': !hasPaid && !showDemo }">
      <p>
        {{ 'TRANSACTION' | translate }} {{ selectedTaxableCessionIndex + 1 }} {{ 'ON' | translate | lowercase }}
        {{ taxableCessions?.length }}
      </p>
      <div class="ctas">
        <button
          mat-flat-button
          color="primary"
          [disabled]="selectedTaxableCessionIndex === 0"
          (click)="updateSelectedTaxableCession(false)"
        >
          {{ 'PREVIOUS' | translate }}
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="selectedTaxableCessionIndex === taxableCessions?.length - 1"
          (click)="updateSelectedTaxableCession()"
        >
          {{ 'NEXT' | translate }}
        </button>
      </div>
    </section>
  }
</section>
