/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { TimezoneDetails } from '../../../shared/models/file.model';
import { Account } from '../../models/account.model';
import { NameResolverResponse } from '../../models/name-resolver-response.model';
import { APIDetails, Upload } from '../../models/upload.model';
import { UserAccount } from '../../models/user-account.model';
import { Candidate } from '../../models/candidate.model';

// eslint-disable-next-line no-shadow
export enum AccountActions {
  LoadUserAPIs = `[Upload] Load User APIs`,
  SetUserAPIs = `[Upload] Set User APIs`,
  CreateApi = `[Upload] Create API`,
  UpdateApi = `[Upload] Update API`,
  Reset = `[Upload] Reset`,
  SetApiConnected = `[Upload] Set Is Api Connected`,
  SetApiErrorMessage = `[Upload] Set Api Error Message`,
  LoadAvailableCandidates = `[Upload] Load Available Candidates`,
  SetAvailableCandidates = `[Upload] Set Available Candidates`,
  CreateSelectedCandidatesApis = `[Upload] Create Selected Candidates Apis`,
  ResolveName = `[Upload] Resolve Name`,
  SetResolvedName = `[Upload] Set Resolved Name`,
  LoadUserAccounts = `[Upload] Load User Accounts`,
  SetUserAccounts = `[Upload] Set User Accounts`,
  UpdateUserAccount = `[Upload] Update User Account`,
  RemoveUserAccount = `[Upload] Remove User Account`,
  LoadTransactionsCount = `[Upload] Load Uploads Transactions Count`,
  SetTransactionsCount = `[Upload] Set Uploads Transactions Count`,
  DeleteAccount = `[Upload] Delete Account`,
  RenameAccount = `[Upload] Rename Account`,
  DownloadFile = `[Upload] Download File`,
  GetOAuthSyncUrl = `[Upload] Get OAuth Sync Url`,
  CreateOAuthAPI = `[Upload] Create OAuth API`,
  LoadTimezones = `[Upload] Load Timezones`,
  SetTimezones = `[Upload] Set Timezones`,
  GetUserAccount = `[Upload] Get User Account`,
  SyncAPI = `[Upload] Sync API`,
}

export const resetAction = createAction(AccountActions.Reset);

export const createApiAction = createAction(
  AccountActions.CreateApi,
  props<{ api: APIDetails; account: Account; subAccount?: Account; alias?: string }>()
);

export const setApiConnectedAction = createAction(AccountActions.SetApiConnected);

export const setApiErrorMessageAction = createAction(
  AccountActions.SetApiErrorMessage,
  props<{ apiErrorMessage: string }>()
);

export const loadAvailableCandidatesAction = createAction(
  AccountActions.LoadAvailableCandidates,
  props<{ apiId: string; address: string; aggregator: string }>()
);

export const setAvailableCandidatesAction = createAction(
  AccountActions.SetAvailableCandidates,
  props<{ availableCandidates: Candidate[] }>()
);

export const createSelectedCandidatesApisAction = createAction(
  AccountActions.CreateSelectedCandidatesApis,
  props<{ selectedCandidates: Candidate[]; alias?: string }>()
);

export const resolveNameAction = createAction(AccountActions.ResolveName, props<{ account: Account; name: string }>());

export const setResolvedNameAction = createAction(
  AccountActions.SetResolvedName,
  props<{ resolvedName: NameResolverResponse }>()
);

export const loadUserAccountsAction = createAction(AccountActions.LoadUserAccounts);

export const setUserAccountsAction = createAction(
  AccountActions.SetUserAccounts,
  props<{ userAccounts: UserAccount[] }>()
);

export const loadTransactionsCountAction = createAction(AccountActions.LoadTransactionsCount);

export const setTransactionsCountAction = createAction(
  AccountActions.SetTransactionsCount,
  props<{ transactionsCount: Map<string, number> }>()
);

export const downloadFileAction = createAction(AccountActions.DownloadFile, props<{ account: UserAccount }>());

export const deleteAccountAction = createAction(AccountActions.DeleteAccount, props<{ account: UserAccount }>());

export const renameAccountAction = createAction(
  AccountActions.RenameAccount,
  props<{ account: UserAccount; alias: string }>()
);

export const loadTimezonesAction = createAction(AccountActions.LoadTimezones);

export const setTimezonesAction = createAction(AccountActions.SetTimezones, props<{ timezones: TimezoneDetails[] }>());

export const getOAuthSyncUrlAction = createAction(AccountActions.GetOAuthSyncUrl, props<{ platform: string }>());

export const createOAuthAPIAction = createAction(AccountActions.CreateOAuthAPI);

export const getUserAccountAction = createAction(AccountActions.GetUserAccount, props<{ accountId: string }>());

export const syncAPIAction = createAction(AccountActions.SyncAPI, props<{ userAccount: UserAccount }>());

export const loadUserAPIsAction = createAction(AccountActions.LoadUserAPIs);

export const setUserAPIsAction = createAction(AccountActions.SetUserAPIs, props<{ userAPIs: Upload[] }>());

export const updateApiAction = createAction(AccountActions.UpdateApi, props<{ apiId: string; api: APIDetails }>());

export const updateUserAccountAction = createAction(
  AccountActions.UpdateUserAccount,
  props<{ userAccount: UserAccount }>()
);

export const removeUserAccountAction = createAction(
  AccountActions.RemoveUserAccount,
  props<{ userAccount: UserAccount }>()
);
