import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { SwapperComponent } from '../swapper/swapper.component';
import { Account } from '../../../taxation/models/account.model';
import { CurrencyDecimalPipe } from '../../../shared/pipes/currency-decimal.pipe';
import { UserPreferences } from '../../../shared/models/user-preferences.model';
import { TruncatePipe } from '../../../taxation/pipes/truncate.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Platform, Ticker, TickerFeed, TickerPair } from '../../models/ticker.model';
import { MarketService } from '../../services/market.service';
import { MarketFeed } from '../../models/market.model';
import { SwapperMode } from '../../models/swapper.model';
import { PlatformDetails, UserYieldAnalysis } from '../../models/user-opportunities.model';
import { Store, select } from '@ngrx/store';
import * as fromOpportunity from '../../store/selectors/opportunity.selector';
import { map, Subject, takeUntil } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Instruction } from '../../models/instruction.model';
import { FeesInstructionDialogComponent } from '../dialogs/fees-instruction-dialog/fees-instruction-dialog.component';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: `app-token-swapper`,
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    SwapperComponent,
    CurrencyDecimalPipe,
    TruncatePipe,
    NgxSkeletonLoaderModule,
    MatTooltipModule,
  ],
  templateUrl: `./token-swapper.component.html`,
  styleUrl: `./token-swapper.component.scss`,
})
export class TokenSwapperComponent implements OnInit, OnDestroy {
  @Input() accounts: Map<string, Account>;
  @Input() userPreferences: UserPreferences;
  @Input() stablecoins: string[];
  @Input() fiats: string[];
  @Input() set marketFeed(marketFeed: MarketFeed) {
    this._marketFeed = marketFeed;

    if (this.marketFeed) {
      this.computeBuyingCurrencyPrice(this.buyingCurrency);
      this.computeSellingCurrencyPrice(this.sellingCurrency);
    }
  }

  get marketFeed(): MarketFeed {
    return this._marketFeed;
  }

  @Input() set tickerFeed(tickerFeed: TickerFeed) {
    this._tickerFeed = tickerFeed;

    if (this.tickerFeed && this.marketFeed) {
      this.computeCoins();

      if (this.swapperMode === `BUY`) {
        this.computeBuyingAmount(this.sellingAmount, true);
      } else {
        this.computeSellingAmount(this.buyingAmount, true);
      }
    }
  }

  get tickerFeed(): TickerFeed {
    return this._tickerFeed;
  }

  @Output() closeSwapper: EventEmitter<void> = new EventEmitter<void>();

  coins: string[] = [];

  swapperMode: SwapperMode = `BUY`;

  startAmount = 1;

  sellingCurrency = `BTC`;
  sellingAmount = 1;
  sellingCurrencyPrice = 0;
  sellingAmountPlaceHolder = 0;

  buyingCurrency = `USDT`;
  buyingAmount = null;
  buyingAmountPlaceHolder = 0;
  buyingCurrencyPrice = 0;

  sellingExcludedCurrency = this.buyingCurrency;
  buyingExcludedCurrency = this.sellingCurrency;

  sellingAmountReset = false;
  buyingAmountReset = false;

  currentCurrency = this.buyingCurrency;
  platforms: Platform[] = [];
  userYieldAnalysis: UserYieldAnalysis;

  instructions: Map<string, Map<string, Map<string, Instruction[]>>>;

  private _marketFeed: MarketFeed;
  private _tickerFeed: TickerFeed;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly opportunityStore$: Store<fromOpportunity.State>,
    private readonly marketService: MarketService,
    private readonly utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.computeBuyingAmount(1, true);

    this.opportunityStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromOpportunity.selectUserYieldAnalysis),
        map((userYieldAnalysis: UserYieldAnalysis) => {
          this.userYieldAnalysis = userYieldAnalysis;
        })
      )
      .subscribe();

    this.opportunityStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromOpportunity.selectInstructions),
        map((instructions: Map<string, Map<string, Map<string, Instruction[]>>>) => {
          this.instructions = instructions;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  swap(): void {
    if (this.sellingCurrency && this.buyingCurrency && this.tickerFeed && this.marketFeed) {
      // Badass swap
      [this.sellingCurrency, this.buyingCurrency] = [this.buyingCurrency, this.sellingCurrency];

      [this.sellingCurrencyPrice, this.buyingCurrencyPrice] = [this.buyingCurrencyPrice, this.sellingCurrencyPrice];

      this.currentCurrency = this.buyingCurrency === this.currentCurrency ? this.sellingCurrency : this.buyingCurrency;

      [this.sellingExcludedCurrency, this.buyingExcludedCurrency] = [
        this.buyingExcludedCurrency,
        this.sellingExcludedCurrency,
      ];

      if (this.swapperMode === `BUY`) {
        [this.sellingAmount, this.buyingAmountPlaceHolder] = [this.buyingAmountPlaceHolder, this.sellingAmount];
        this.computeBuyingAmount(this.sellingAmount, true);
      } else {
        [this.buyingAmount, this.sellingAmountPlaceHolder] = [this.sellingAmountPlaceHolder, this.buyingAmount];
        this.computeSellingAmount(this.buyingAmount, true);
      }
    }
  }

  computeSellingCurrencyPrice(currency: string, compute = false): void {
    this.sellingCurrency = currency;

    this.buyingExcludedCurrency = this.sellingCurrency;

    if (this.fiats.includes(currency)) {
      this.sellingCurrencyPrice = 1;
    } else {
      this.sellingCurrencyPrice = this.marketService.getTokenPrice(this.marketFeed.marketFeed, currency);
    }

    if (this.swapperMode === `SELL`) {
      if (compute) {
        this.computeSellingAmount(this.buyingAmount, compute);
      }
    } else if (this.swapperMode === `BUY`) {
      if (compute) {
        this.computeBuyingAmount(this.sellingAmount, compute);
      }
    }
  }

  computeBuyingCurrencyPrice(currency: string, compute = false): void {
    this.buyingCurrency = currency;

    this.sellingExcludedCurrency = this.buyingCurrency;

    if (this.fiats.includes(currency)) {
      this.buyingCurrencyPrice = 1;
    } else {
      this.buyingCurrencyPrice = this.marketService.getTokenPrice(this.marketFeed.marketFeed, currency);
    }

    if (this.swapperMode === `BUY`) {
      this.currentCurrency = this.buyingCurrency;

      if (compute) {
        this.computeBuyingAmount(this.sellingAmount, true);
      }
    }
  }

  computeBuyingAmount(sellingAmount: number, reset?: boolean): void {
    this.sellingAmount = sellingAmount;
    this.currentCurrency = this.buyingCurrency;

    if (this.sellingAmount !== 0) {
      this.computeBestRatePlatforms();

      if (this.platforms?.length > 0) {
        if (this.swapperMode === `BUY`) {
          const isCurrenciesMatch = this.currentCurrency === this.platforms[0].quote;

          this.platforms.sort((a, b) => {
            if (!isCurrenciesMatch) {
              return a.ticker.buy - b.ticker.buy;
            } else {
              return b.ticker.buy - a.ticker.buy;
            }
          });

          const quantity = isCurrenciesMatch ? this.platforms[0].ticker.buy : 1 / this.platforms[0].ticker.buy;

          this.platforms[0].quantity = quantity * this.sellingAmount;
          this.platforms[0].amount = this.platforms[0].quantity * this.buyingCurrencyPrice;
          const bestRatePlatform = this.platforms[0];
          this.buyingAmountPlaceHolder = bestRatePlatform.quantity;

          this.platforms.forEach((platform: Platform) => {
            const platformQuantity = isCurrenciesMatch ? platform.ticker.buy : 1 / platform.ticker.buy;
            platform.quantity = platformQuantity * this.sellingAmount;
            platform.amount = platform.quantity * this.buyingCurrencyPrice;
            platform.amountGap = (bestRatePlatform.quantity - platform.quantity) * this.buyingCurrencyPrice;
            platform.amountTrendPercentage = (platform.amountGap / bestRatePlatform.amount) * 100;

            platform.amountGap = platform.amountGap * -1;
            platform.amountTrendPercentage = platform.amountTrendPercentage * -1;
          });
        }
      } else {
        this.buyingAmountPlaceHolder = null;
      }
    } else {
      this.buyingAmountPlaceHolder = null;
      this.platforms = null;
    }

    if (reset) {
      this.buyingAmountReset = true;
      this.sellingAmountReset = false;
    }
  }

  computeSellingAmount(buyingAmount: number, reset?: boolean): void {
    this.buyingAmount = buyingAmount;
    this.currentCurrency = this.sellingCurrency;

    if (this.buyingAmount !== 0) {
      this.computeBestRatePlatforms();

      if (this.platforms?.length > 0) {
        if (this.swapperMode === `SELL`) {
          const isCurrenciesMatch = this.currentCurrency === this.platforms[0].quote;

          this.platforms.sort((a, b) => {
            if (!isCurrenciesMatch) {
              return a.ticker.sell - b.ticker.sell;
            } else {
              return b.ticker.sell - a.ticker.sell;
            }
          });

          const quantity = isCurrenciesMatch ? this.platforms[0].ticker.sell : 1 / this.platforms[0].ticker.sell;

          this.platforms[0].quantity = quantity * this.buyingAmount;
          this.platforms[0].amount = this.platforms[0].quantity * this.sellingCurrencyPrice;
          const bestRatePlatform = this.platforms[0];
          this.sellingAmountPlaceHolder = bestRatePlatform.quantity;

          this.platforms.forEach((platform: Platform) => {
            const platformQuantity = isCurrenciesMatch ? platform.ticker.sell : 1 / platform.ticker.sell;
            platform.quantity = platformQuantity * this.buyingAmount;
            platform.amount = platform.quantity * this.sellingCurrencyPrice;
            platform.amountGap = (bestRatePlatform.quantity - platform.quantity) * this.sellingCurrencyPrice;
            platform.amountTrendPercentage = (platform.amountGap / bestRatePlatform.amount) * 100;

            platform.amountGap = platform.amountGap * -1;
            platform.amountTrendPercentage = platform.amountTrendPercentage * -1;
          });
        }
      } else {
        this.sellingAmountPlaceHolder = null;
      }
    } else {
      this.sellingAmountPlaceHolder = null;
      this.platforms = null;
    }

    if (reset !== undefined) {
      this.sellingAmountReset = reset;
      this.buyingAmountReset = !reset;
    }
  }

  computeBestRatePlatforms(): void {
    this.platforms = [];
    const platforms: Platform[] = [];

    const pairA = `${this.sellingCurrency}${this.buyingCurrency}`;
    const pairB = `${this.buyingCurrency}${this.sellingCurrency}`;

    const tickerPair = this.tickerFeed?.pairs.get(pairA) || this.tickerFeed?.pairs.get(pairB);

    if (tickerPair) {
      tickerPair.tickers.forEach((ticker: Ticker, name: string) => {
        const bestRatePlatform: Platform = {
          name,
          base: tickerPair.base,
          quote: tickerPair.quote,
          ticker,
        };

        platforms.push(bestRatePlatform);
      });

      this.platforms = platforms.slice(0, 5);
    } else if (this.tickerFeed) {
      this.platforms = null;
    }
  }

  changeSwapperMode(mode: SwapperMode): void {
    this.swapperMode = mode;
  }

  computeCoins(): void {
    const bases = Array.from(this.tickerFeed.pairs.values()).map((pair: TickerPair) => pair.base);
    const quotes = Array.from(this.tickerFeed.pairs.values()).map((pair: TickerPair) => pair.quote);

    this.coins = Array.from(new Set([...bases, ...quotes]));
  }

  getPlatformDetails(platform: Platform): PlatformDetails {
    return this.userYieldAnalysis?.platformDetails.get(platform.name);
  }

  openFeesInstructionDialog(platform: Platform): void {
    if (this.instructions.get(platform.name)) {
      this.utilsService.openDialog(FeesInstructionDialogComponent, `800px`, `656px`, {
        instructions: this.instructions.get(platform.name),
        language: this.userPreferences.language,
      });
    }
  }
}
