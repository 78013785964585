<div
  class="container pointer"
  [ngClass]="{
  'container--selected': isSelected,
}"
  >
  @if (paymentMethod === 'STRIPE') {
    <img src="/assets/img/icons/card.svg" alt="" />
  }
  @if (paymentMethod === 'COINBASE') {
    <img src="/assets/img/icons/crypto.svg" alt="" />
  }

  <p class="body-14-med">{{ paymentMethod | translate }}</p>
</div>
