/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { OpportunityService } from '../../services/opportunity.service';
import {
  loadUserOpportunitiesAction,
  loadUserOpportunitiesInstructionsAction,
  setUserOpportunitiesAction,
  setUserOpportunitiesInstructionsAction,
} from '../actions/opportunity.action';

@Injectable()
export class OpportunityEffects {
  loadUserOpportunities$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserOpportunitiesAction>>(loadUserOpportunitiesAction),
      switchMap(() => {
        return this.opportunityService
          .getUserOpportunities()
          .pipe(map((userOpportunities: any) => setUserOpportunitiesAction({ userOpportunities })));
      })
    )
  );

  loadUserOpportunitiesInstructions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserOpportunitiesInstructionsAction>>(loadUserOpportunitiesInstructionsAction),
      switchMap(() => {
        return this.opportunityService
          .getUserOpportunitiesInstructions()
          .pipe(map((instructions: any) => setUserOpportunitiesInstructionsAction({ instructions })));
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly opportunityService: OpportunityService
  ) {}
}
