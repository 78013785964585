import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ToggleTitleComponent } from '../toggle-title/toggle-title.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map, Subject, takeUntil } from 'rxjs';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { select, Store } from '@ngrx/store';
import { UserPreferences } from '../../../shared/models/user-preferences.model';

@Component({
  selector: `app-opportunities-header`,
  standalone: true,
  imports: [CommonModule, RouterModule, MatToolbarModule, ToggleTitleComponent, MatDividerModule, TranslateModule],
  templateUrl: `./opportunities-header.component.html`,
  styleUrl: `./opportunities-header.component.scss`,
})
export class OpportunitiesHeaderComponent implements OnInit, OnDestroy {
  currentPath = ``;
  userPreferences: UserPreferences;

  @Output() feedback: EventEmitter<void> = new EventEmitter<void>();

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.currentPath = this.router.url;

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event: any) => event instanceof NavigationEnd),
        map((event: any) => {
          this.currentPath = event.url;
        })
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        select(fromShared.selectUserPreferences),
        takeUntil(this.destroy$),
        map((userPreferences: UserPreferences) => {
          this.userPreferences = userPreferences;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
