<div
  class="tile pointer"
  [routerLink]="link.label !== 'MENU.TAX_STRATEGY' ? link.route : null"
  [routerLinkActive]=""
  #rla="routerLinkActive"
  [ngClass]="{ 'tile--active': rla.isActive }"
>
  <img
    [src]="rla.isActive ? link.routeActiveIcon : link.routeIcon"
    [alt]="rla.isActive ? link.routeActiveIcon : link.routeIcon"
  />
  <p class="body-14-med">{{ link.label | translate }}</p>

  @if (link.new) {
    <div class="route-chip">{{ 'NEW' | translate }}</div>
  }
  @if (link.beta) {
    <div class="route-chip">{{ 'BETA' | translate }}</div>
  }
</div>
