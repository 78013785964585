import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { Instruction } from '../../../models/instruction.model';

@Component({
  selector: `app-fees-instruction-dialog`,
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatDividerModule],
  templateUrl: `./fees-instruction-dialog.component.html`,
  styleUrl: `./fees-instruction-dialog.component.scss`,
})
export class FeesInstructionDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(`tradeStart`) tradeStart!: ElementRef;
  @ViewChild(`tradeEnd`) tradeEnd!: ElementRef;

  @ViewChild(`buyStart`) buyStart!: ElementRef;
  @ViewChild(`buyEnd`) buyEnd!: ElementRef;

  @ViewChild(`sellStart`) sellStart!: ElementRef;
  @ViewChild(`sellEnd`) sellEnd!: ElementRef;

  @ViewChild(`withdrawStart`) withdrawStart!: ElementRef;
  @ViewChild(`withdrawEnd`) withdrawEnd!: ElementRef;

  isTradeActive = true;
  tradeStartScrolled = false;
  tradeEndScrolled = false;

  isBuyActive = false;
  buyStartScrolled: boolean;
  buyEndScrolled: boolean;

  isSellActive = false;
  sellStartScrolled = false;
  sellEndScrolled = false;

  isWithdrawActive = false;
  withdrawStartScrolled = false;
  withdrawEndScrolled = false;

  instructions: Map<string, Map<string, Instruction[]>>;
  language: string;
  usingAutoScroll = true;
  usingAutoScrollTimeout: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {
      instructions: Map<string, Map<string, Instruction[]>>;
      language: string;
    }
  ) {}

  ngOnInit(): void {
    this.instructions = this.data.instructions;
    this.language = this.data.language;
  }

  ngAfterViewInit(): void {
    // TRADE
    this.createObserver(this.tradeStart, (entries) => {
      entries.forEach((entry) => {
        if (!this.usingAutoScroll) {
          this.tradeStartScrolled = !entry.isIntersecting;

          if (!this.tradeStartScrolled && !this.tradeEndScrolled) {
            this.isTradeActive = true;
          }

          if (this.tradeStartScrolled && this.tradeEndScrolled) {
            this.isTradeActive = false;
          }

          if (this.isTradeActive) {
            this.isBuyActive = false;
            this.isSellActive = false;
            this.isWithdrawActive = false;
          } else {
            this.isBuyActive = true;
            this.isSellActive = false;
          }
        }
      });
    });
    this.createObserver(this.tradeEnd, (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (!this.usingAutoScroll) {
          this.tradeEndScrolled = !entry.isIntersecting;

          if (!this.tradeStartScrolled && !this.tradeEndScrolled) {
            this.isTradeActive = true;
          }

          if (this.tradeStartScrolled && this.tradeEndScrolled) {
            this.isTradeActive = false;
          }

          if (this.isTradeActive) {
            this.isBuyActive = false;
            this.isSellActive = false;
            this.isWithdrawActive = false;
          } else {
            this.isBuyActive = true;
            this.isSellActive = false;
          }
        }
      });
    });

    // BUY
    this.createObserver(this.buyStart, (entries) => {
      entries.forEach((entry) => {
        if (!this.usingAutoScroll) {
          this.buyStartScrolled = !entry.isIntersecting;

          if (this.isBuyActive) {
            this.isTradeActive = false;
            this.isSellActive = false;
            this.isWithdrawActive = false;
          }
        }
      });
    });
    this.createObserver(this.buyEnd, (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (!this.usingAutoScroll) {
          this.buyEndScrolled = !entry.isIntersecting;

          if (this.isBuyActive) {
            this.isTradeActive = false;
            this.isSellActive = false;
            this.isWithdrawActive = false;
          }
        }
      });
    });

    // SELL
    this.createObserver(this.sellStart, (entries) => {
      entries.forEach((entry) => {
        if (!this.usingAutoScroll) {
          this.sellStartScrolled = !entry.isIntersecting;

          if (!this.sellStartScrolled && !this.sellEndScrolled) {
            this.isSellActive = true;
          }

          if (this.isSellActive) {
            this.isTradeActive = false;
            this.isBuyActive = false;
            this.isWithdrawActive = false;
          }
        }
      });
    });
    this.createObserver(this.sellEnd, (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (!this.usingAutoScroll) {
          this.sellEndScrolled = !entry.isIntersecting;

          if (!this.sellStartScrolled && !this.sellEndScrolled) {
            this.isSellActive = true;
          }

          if (this.isSellActive) {
            this.isTradeActive = false;
            this.isBuyActive = false;
            this.isWithdrawActive = false;
          }
        }
      });
    });

    // WITHDRAW
    this.createObserver(this.withdrawStart, (entries) => {
      entries.forEach((entry) => {
        if (!this.usingAutoScroll) {
          this.withdrawStartScrolled = !entry.isIntersecting;

          if (!this.withdrawStartScrolled && !this.withdrawEndScrolled) {
            this.isWithdrawActive = true;
          }

          if (this.withdrawStartScrolled && this.withdrawEndScrolled) {
            this.isWithdrawActive = false;
          }

          if (this.isWithdrawActive) {
            this.isTradeActive = false;
            this.isBuyActive = false;
            this.isSellActive = false;
          } else {
            this.isSellActive = true;
          }
        }
      });
    });
    this.createObserver(this.withdrawEnd, (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (!this.usingAutoScroll) {
          this.withdrawEndScrolled = !entry.isIntersecting;

          if (!this.withdrawStartScrolled && !this.withdrawEndScrolled) {
            this.isWithdrawActive = true;
          }

          if (this.withdrawStartScrolled && this.withdrawEndScrolled) {
            this.isWithdrawActive = false;
          }

          if (this.isWithdrawActive) {
            this.isTradeActive = false;
            this.isBuyActive = false;
            this.isSellActive = false;
          } else {
            this.isSellActive = true;
          }
        }
      });
    });

    clearTimeout(this.usingAutoScrollTimeout);

    this.usingAutoScrollTimeout = setTimeout(() => {
      this.usingAutoScroll = false;
    }, 1000);
  }

  scrollToAnchor(anchor: string): void {
    const element = document.getElementById(anchor);

    if (element) {
      this.usingAutoScroll = true;
      element.scrollIntoView({ behavior: `smooth` });

      switch (anchor) {
        case `trade`:
          this.isTradeActive = true;
          this.isBuyActive = false;
          this.isSellActive = false;
          this.isWithdrawActive = false;
          break;
        case `buy`:
          this.isTradeActive = false;
          this.isBuyActive = true;
          this.isSellActive = false;
          this.isWithdrawActive = false;
          break;
        case `sell`:
          this.isTradeActive = false;
          this.isBuyActive = false;
          this.isSellActive = true;
          this.isWithdrawActive = false;
          break;
        case `withdraw`:
          this.isTradeActive = false;
          this.isBuyActive = false;
          this.isSellActive = false;
          this.isWithdrawActive = true;
          break;
      }

      clearTimeout(this.usingAutoScrollTimeout);

      this.usingAutoScrollTimeout = setTimeout(() => {
        this.usingAutoScroll = false;
      }, 1000);
    }
  }

  createObserver(element: ElementRef, callback: IntersectionObserverCallback): void {
    const observer = new IntersectionObserver(
      callback,
      { threshold: 1.0 } // Trigger when the element is fully out of view
    );

    observer.observe(element.nativeElement);
  }
}
