import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import * as insightReducer from '../reducers/insight.reducer';
import { ChartHistory, MarketFeed } from '../../models/market.model';
import { LiveBalanceResponse } from '../../models/live-balance.model';
import { TickerFeed } from '../../models/ticker.model';
import { ManualStock } from '../../models/manual-stock.model';

export const INSIGHT_FEATURE_NAME = `insight`;

export interface State {
  insight: insightReducer.State;
}

export const selectInsightState: MemoizedSelector<State, insightReducer.State> =
  createFeatureSelector<insightReducer.State>(INSIGHT_FEATURE_NAME);

export const selectPortfolioValue: MemoizedSelector<State, number> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.portfolioValue,
);

export const selectChartHistory: MemoizedSelector<State, ChartHistory> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.chartHistory,
);

export const selectMarketFeed: MemoizedSelector<State, MarketFeed> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.marketFeed,
);

export const selectTickerFeed: MemoizedSelector<State, TickerFeed> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.tickerFeed,
);

export const selectLiveBalances: MemoizedSelector<State, LiveBalanceResponse> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.liveBalances,
);

export const selectManualStocks: MemoizedSelector<State, ManualStock[]> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.manualStocks,
);

export const selectAcquisitionPrices: MemoizedSelector<State, Map<string, any>> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.acquisitionPrices,
);

export const selectRefreshingBalances: MemoizedSelector<State, boolean> = createSelector(
  selectInsightState,
  (state: insightReducer.State) => state.refreshingBalances,
);
