import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavComponentType } from '../models/sidenav.model';

@Injectable({
  providedIn: `root`,
})
export class SidenavService {
  private sidenav: MatSidenav;
  private sidenavComponentType: SidenavComponentType;

  setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav;
  }

  setSidenavComponentType(sidenavComponentType: SidenavComponentType): void {
    this.sidenavComponentType = sidenavComponentType;
  }

  open(): void {
    this.sidenav.open();
  }

  close(): void {
    this.sidenav.close();
  }

  toggle(): void {
    this.sidenav.toggle();
  }

  getSidenavComponentType(): SidenavComponentType {
    return this.sidenavComponentType;
  }
}
