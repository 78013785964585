import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { CustomError } from '../../../shared/models/error.model';
import { Page } from '../../../shared/models/page.model';
import { AffiliationProfile } from '../../models/affiliation.model';
import { Vault, VaultEvent } from '../../models/vault.model';
import * as affiliationReducer from '../reducers/affiliation.reducer';

export const AFFILIATION_FEATURE_NAME = `affiliation`;

export interface State {
  affiliation: affiliationReducer.State;
}

export const selectAffiliationState: MemoizedSelector<
  State,
  affiliationReducer.State
> = createFeatureSelector<affiliationReducer.State>(AFFILIATION_FEATURE_NAME);

export const selectAffiliationProfile: MemoizedSelector<
  State,
  AffiliationProfile
> = createSelector(
  selectAffiliationState,
  (state: affiliationReducer.State) => state.affiliationProfile
);

export const selectVault: MemoizedSelector<State, Vault> = createSelector(
  selectAffiliationState,
  (state: affiliationReducer.State) => state.vault
);

export const selectVaultEventPage: MemoizedSelector<State, Page<VaultEvent>> =
  createSelector(
    selectAffiliationState,
    (state: affiliationReducer.State) => state.vaultEventPage
  );

export const selectAffiliationError: MemoizedSelector<State, CustomError> =
  createSelector(
    selectAffiliationState,
    (state: affiliationReducer.State) => state.affiliationError
  );
