import { DEFAULT_TRANSACTIONS_FILTERS } from '../../taxation/constants/transactions.constant';

export const DEFAULT_CONFIGURATION = {
  pagination: {
    pageIndex: 0,
    pageSize: 25,
    pageSort: `transactionDate`,
    pageDirection: `desc`,
  },
  filters: DEFAULT_TRANSACTIONS_FILTERS,
  showFilters: false,
};
