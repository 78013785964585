import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: `app-delete-account-dialog`,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, TranslateModule],
  templateUrl: `./delete-account-dialog.component.html`,
  styleUrls: [`./delete-account-dialog.component.scss`],
})
export class DeleteAccountDialogComponent implements OnInit {
  deleteAccount = ``;
  deleteAccountContent = ``;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.deleteAccount = this.translateService.instant(`ACCOUNTS.DELETE_ACCOUNT`, {
      account: this.data.account.alias || this.data.accounts.get(this.data.account.platform)?.name,
    });
    this.deleteAccountContent = this.translateService.instant(`ACCOUNTS.DELETE_ACCOUNT_CONTENT`, {
      account: this.data.account.alias || this.data.accounts.get(this.data.account.platform)?.name,
    });
  }
}
