import { Action, createReducer, on } from '@ngrx/store';
import { UserOpportunities } from '../../models/user-opportunities.model';
import * as OpportunityActions from '../actions/opportunity.action';
import { Instruction } from '../../models/instruction.model';

export interface State {
  userOpportunities: UserOpportunities;
  instructions: Instruction[];
}

export const initialState: State = {
  userOpportunities: null,
  instructions: [],
};

const reducer = createReducer(
  initialState,
  on(OpportunityActions.setUserOpportunitiesAction, (state: State, { userOpportunities }: any) => ({
    ...state,
    userOpportunities,
  })),
  on(OpportunityActions.setUserOpportunitiesInstructionsAction, (state: State, { instructions }: any) => ({
    ...state,
    instructions,
  }))
);

export const opportunityReducer = (state: State | undefined, action: Action): State => reducer(state, action);
