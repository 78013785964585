import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: `tax.waltio.com`,
  },
  position: `bottom-left`,
  theme: `edgeless`,
  palette: {
    popup: {
      background: `#284989`,
      text: `#ffffff`,
      link: `#ffffff`,
    },
    button: {
      background: `#f1d600`,
      text: `#000000`,
      border: `transparent`,
    },
  },
  type: `info`,
  content: {
    message: `Ce site web utilise des cookies pour vous assurer la meilleure expérience de navigation sur notre site.`,
    dismiss: `OK, j'ai compris!`,
    deny: `Refuser`,
    link: `Plus d'information`,
    href: `https://tax.waltio.co/assets/pdfs/confidentialite.pdf`,
    policy: `Cookie Policy`,
    header: `Cookies sur le site!`,
    allow: `Autoriser les cookies`,
  },
};
