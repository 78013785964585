<div class="container">
  <div class="vault">
    <img src="/assets/img/icons/vault.svg" alt="" />
    <div class="vault__amount">
      <p class="body-14-med">{{ 'WALTIO_VAULT' | translate }}</p>
      <p class="body-14-med">
        {{ vaultBalance | currencyDecimal : userPreferences : stablecoins : fiats }}
      </p>
    </div>
  </div>
  <button
    type="button"
    mat-flat-button
    color="secondary-round"
    [disabled]="useVoucher || !vaultBalance"
    [matTooltip]="useVoucher ? ('VAULT_VOUCHER_CONFLICT' | translate) : null"
    (click)="useVaultChange.emit(!useVault)"
  >
    {{ (useVault ? 'WITHDRAW' : 'USE') | translate }}
  </button>
</div>
