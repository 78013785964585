/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { LiveBalanceResponse } from '../../models/live-balance.model';
import { ChartHistory, MarketFeed } from '../../models/market.model';
import { TickerFeed } from '../../models/ticker.model';

// eslint-disable-next-line no-shadow
export enum InsightActions {
  StartMarketFeedStream = `[Insight] Start Market Feed Stream`,
  StopMarketFeedStream = `[Insight] Stop Market Feed Stream`,
  SetMarketFeed = `[Insight] Set Market Feed`,

  StartTickerFeedStream = `[Insight] Start Ticker Feed Stream`,
  StopTickerFeedStream = `[Insight] Stop Ticker Feed Stream`,
  SetTickerFeed = `[Insight] Set Ticker Feed`,

  ComputePortfolioValue = `[Insight] Compute Portfolio Value`,
  SetPortfolioValue = `[Insight] Set Portfolio Value`,

  LoadChartHistory = `[Insight] Load Chart History`,
  SetChartHistory = `[Insight] Set Chart History`,

  LoadLiveBalances = `[Insight] Load Live Balances`,
  SetLiveBalances = `[Insight] Set Live Balances`,
}

export const computePortfolioValueAction = createAction(
  InsightActions.ComputePortfolioValue,
  props<{ currency?: string }>()
);

export const setPortfolioValueAction = createAction(
  InsightActions.SetPortfolioValue,
  props<{ portfolioValue: number }>()
);

export const startMarketFeedStreamAction = createAction(InsightActions.StartMarketFeedStream);

export const stopMarketFeedStreamAction = createAction(InsightActions.StopMarketFeedStream);

export const setMarketFeedAction = createAction(InsightActions.SetMarketFeed, props<{ marketFeed: MarketFeed }>());

export const startTickerFeedStreamAction = createAction(InsightActions.StartTickerFeedStream);

export const stopTickerFeedStreamAction = createAction(InsightActions.StopTickerFeedStream);

export const setTickerFeedAction = createAction(InsightActions.SetTickerFeed, props<{ tickerFeed: TickerFeed }>());

export const loadChartHistoryAction = createAction(InsightActions.LoadChartHistory);

export const setChartHistoryAction = createAction(
  InsightActions.SetChartHistory,
  props<{ chartHistory: ChartHistory }>()
);

export const loadLiveBalancesAction = createAction(InsightActions.LoadLiveBalances, props<{ force?: boolean }>());

export const setLiveBalancesAction = createAction(
  InsightActions.SetLiveBalances,
  props<{ liveBalances: LiveBalanceResponse }>()
);
