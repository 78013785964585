import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethod } from '../../models/payment.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: `app-payment-method`,
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: `./payment-method.component.html`,
  styleUrls: [`./payment-method.component.scss`]
})
export class PaymentMethodComponent {
  @Input() paymentMethod: PaymentMethod;
  @Input() isSelected: boolean;
}
