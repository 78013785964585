<div class="container">
  <div class="header">
    <img src="assets/img/icons/success.svg" alt="success" />
    <h1>{{ 'THANK_FOR_HELP' | translate }}</h1>
  </div>
  <mat-divider></mat-divider>
  <div class="content">
    <p class="body-14-bold">{{ 'NEW_INTEGRATION_CONTENT' | translate }}</p>

    <button mat-flat-button color="primary" mat-dialog-close>{{ 'CONTINUE' | translate }}</button>
  </div>
</div>
