<div class="container">
  <section class="content">
    <div class="content__header">
      <img src="/assets/img/icons/warn-icon.svg" alt="warn-icon" />
      <h2>{{ (data.automaticAssessmentEnabled ? 'STOP_REPORT_LAUNCH' : 'REACTIVATE_REPORT') | translate }}</h2>
      <img class="pointer" src="/assets/img/icons/close-icon.svg" alt="close-icon" mat-dialog-close />
    </div>

    <p class="body-14">
      {{ (data.automaticAssessmentEnabled ? 'STOP_REPORT_LAUNCH_CONTENT' : 'REACTIVATE_REPORT_CONTENT') | translate }}
    </p>
  </section>

  <mat-divider></mat-divider>

  <section class="ctas">
    <button mat-flat-button color="secondary" disableRipple mat-dialog-close>{{ 'CANCEL' | translate }}</button>
    <button
      mat-flat-button
      [color]="data.automaticAssessmentEnabled ? 'warn' : 'primary'"
      disableRipple
      [mat-dialog-close]="true"
    >
      {{ (data.automaticAssessmentEnabled ? 'PAUSE_CALCULATION' : 'REACTIVATE_CALCULATION') | translate }}
    </button>
  </section>
</div>
