<div class="container">
  <mat-chip-listbox class="custom-chip-listbox" hideSingleSelectionIndicator>
    <mat-chip-option
      class="custom-chip"
      [ngClass]="{
        'custom-chip--selected': currentPath === '/profile',
      }"
      [routerLink]="'/profile'"
      [selected]="currentPath === '/profile'"
      value="information"
      selected
      disableRipple
      >{{ 'PROFILE' | translate }}</mat-chip-option
    >

    <mat-chip-option
      class="custom-chip"
      [ngClass]="{
        'custom-chip--selected': currentPath === '/profile/preferences',
      }"
      [routerLink]="'/profile/preferences'"
      [selected]="currentPath === '/profile/preferences'"
      value="preferences"
      disableRipple
      >{{ 'PREFERENCES' | translate }}</mat-chip-option
    >

    <mat-chip-option
      class="custom-chip"
      [ngClass]="{
        'custom-chip--selected': currentPath === '/profile/subscription',
      }"
      [routerLink]="'/profile/subscription'"
      [selected]="currentPath === '/profile/subscription'"
      value="offer"
      disableRipple
      >{{ 'SUBSCRIPTION' | translate }}</mat-chip-option
    >

    <mat-chip-option
      class="custom-chip"
      [ngClass]="{
        'custom-chip--selected': currentPath === '/profile/sponsorship',
      }"
      [routerLink]="'/profile/sponsorship'"
      [selected]="currentPath === '/profile/sponsorship'"
      value="sponsorship"
      disableRipple
      >{{ 'REFERRAL' | translate }}
      <div class="sponsorship-gain">
        <p class="body-12-med">{{ 30 | currencyDecimal: userPreferences : stablecoins : fiats }}</p>
      </div>
    </mat-chip-option>
  </mat-chip-listbox>

  @if (userPlan) {
    <div class="user-plan pointer" [matTooltip]="currentPlanTooltip" matTooltipPosition="below">
      <p class="body-14-med">{{ 'MY_SUBSCRIPTION' | translate }} {{ 'PLANS.' + userPlan | translate }}</p>
    </div>
  }
</div>
<mat-divider></mat-divider>
