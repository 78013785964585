import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PlatformDetails, UserOpportunities, YieldPosition, YieldProjection } from '../models/user-opportunities.model';

@Injectable({
  providedIn: `root`,
})
export class OpportunityService {
  constructor(private readonly http: HttpClient) {}

  getUserOpportunities(): Observable<UserOpportunities> {
    return this.http.get<UserOpportunities>(`${environment.apiUrl}/v1/tax/user-opportunities/stats`).pipe(
      map((userOpportunities: UserOpportunities) => {
        // Overview projections map convert
        userOpportunities.overview.projections = new Map<string, YieldProjection>(
          Object.entries(userOpportunities.overview.projections || [])
        );

        // Fees map convert
        userOpportunities.feeAnalysis.feeByPlatform = new Map<string, number>(
          Object.entries(userOpportunities.feeAnalysis.feeByPlatform || [])
        );

        userOpportunities.feeAnalysis.averageFeeByPlatform = new Map<string, number>(
          Object.entries(userOpportunities.feeAnalysis.averageFeeByPlatform || [])
        );

        userOpportunities.feeAnalysis.volumeByPlatform = new Map<string, number>(
          Object.entries(userOpportunities.feeAnalysis.volumeByPlatform || [])
        );

        userOpportunities.feeAnalysis.bestFeeByPlatform = new Map<string, number>(
          Object.entries(userOpportunities.feeAnalysis.bestFeeByPlatform || [])
        );

        // Current analysis map convert
        userOpportunities.feeAnalysis.currentYearAnalysis.feeByPlatform = new Map<string, number>(
          Object.entries(userOpportunities.feeAnalysis.currentYearAnalysis.feeByPlatform)
        );

        userOpportunities.feeAnalysis.currentYearAnalysis.averageFeeByPlatform = new Map<string, number>(
          Object.entries(userOpportunities.feeAnalysis.currentYearAnalysis.averageFeeByPlatform || [])
        );

        userOpportunities.feeAnalysis.currentYearAnalysis.volumeByPlatform = new Map<string, number>(
          Object.entries(userOpportunities.feeAnalysis.currentYearAnalysis.volumeByPlatform || [])
        );

        userOpportunities.feeAnalysis.currentYearAnalysis.bestFeeByPlatform = new Map<string, number>(
          Object.entries(userOpportunities.feeAnalysis.currentYearAnalysis.bestFeeByPlatform || [])
        );

        // Yield analysis map convert
        userOpportunities.yieldAnalysis.positions = new Map<string, YieldPosition[]>(
          Object.entries(userOpportunities.yieldAnalysis.positions || [])
        );

        userOpportunities.yieldAnalysis.platformDetails = new Map<string, PlatformDetails>(
          Object.entries(userOpportunities.yieldAnalysis.platformDetails || [])
        );

        return userOpportunities;
      })
    );
  }

  getUserOpportunitiesInstructions(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/v1/tax/user-opportunities/instructions`);
  }
}
