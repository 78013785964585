import { Component, OnDestroy } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { UtilsService } from '../../../services/utils.service';
import { TypeformDialogComponent } from '../typeform-dialog/typeform-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: `app-beta-program-dialog`,
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: `./beta-program-dialog.component.html`,
  styleUrls: [`./beta-program-dialog.component.scss`],
})
export class BetaProgramDialogComponent implements OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<BetaProgramDialogComponent>,
    private readonly utilsService: UtilsService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openTypeform(): void {
    this.dialogRef.close(true);

    const typeformDialogRef = this.utilsService.openDialog(TypeformDialogComponent, `1050px`, `573px`, {
      disableClose: true,
      typeformId: `Gy0rZk78`,
    });

    typeformDialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        map(() => {
          localStorage.setItem(`beta-program`, `false`);
        })
      )
      .subscribe();
  }
}
