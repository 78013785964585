/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { AppKit, EventsControllerState, UseAppKitAccountReturn, UseAppKitNetworkReturn } from '@reown/appkit';
import { CustomError } from '../../../shared/models/error.model';
import { TimezoneDetails } from '../../../shared/models/file.model';
import { Properties } from '../../../shared/models/reown.model';
import { AccountService } from '../../../shared/services/account.service';
import { ToastService } from '../../../shared/services/toast.service';
import { Web3Service } from '../../../shared/services/web3.service';
import { goToAction } from '../../../shared/store/actions/shared.action';
import { selectCurrentRoute, selectQueryParam } from '../../../shared/store/selectors/router.selector';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { REOWN_BLOCKCHAINS, REOWN_WALLETS } from '../../constants/account.constant';
import { Account, AccountListResponse, CreateIntegrationResponse } from '../../models/account.model';
import { RedirectionUrl } from '../../models/api.model';
import { AssessmentStatus } from '../../models/assessment.model';
import { Candidate } from '../../models/candidate.model';
import { NameResolverResponse } from '../../models/name-resolver-response.model';
import { APIDetails, Upload } from '../../models/upload.model';
import { UserAccount } from '../../models/user-account.model';
import { APIService } from '../../services/api.service';
import { FileService } from '../../services/file.service';
import { NameResolverService } from '../../services/name-resolver.service';
import {
  createApiAction,
  createNewIntegrationAction,
  createOAuthAPIAction,
  createSelectedCandidatesApisAction,
  deleteAccountAction,
  disconnectReownModalAction,
  downloadFileAction,
  getOAuthSyncUrlAction,
  getUserAccountAction,
  initReownModalAction,
  loadAvailableCandidatesAction,
  loadTimezonesAction,
  loadTransactionsCountAction,
  loadUserAPIsAction,
  loadUserAccountsAction,
  openReownModalAction,
  removeUserAccountAction,
  renameAccountAction,
  resolveNameAction,
  setApiConnectedAction,
  setApiErrorAction,
  setAvailableCandidatesAction,
  setReownModalAction,
  setReownModalStateAction,
  setResolvedNameAction,
  setTimezonesAction,
  setTransactionsCountAction,
  setUserAPIsAction,
  setUserAccountsAction,
  syncAPIAction,
  updateApiAction,
  updateUserAccountAction,
} from '../actions/account.action';
import { setAssessmentStatusAction } from '../actions/assessment.action';
import { loadPlansByFiscalYearsAction } from '../actions/payment.action';
import * as fromAccount from '../selectors/account.selector';
import * as fromAssessment from '../selectors/assessment.selector';
import { loadLiveBalancesAction } from '../../../portfolio-manager/store/actions/insight.action';
import { UtilsService } from '../../../shared/services/utils.service';
import { NewIntegrationSuccessDialogComponent } from '../../components/dialogs/new-integration-success-dialog/new-integration-success-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AccountEffects {
  reownBlockchains = REOWN_BLOCKCHAINS;
  reownWallets = REOWN_WALLETS;

  createApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof createApiAction>>(createApiAction),
      withLatestFrom(this.sharedStore$.pipe(select(fromShared.selectAccountsList))),
      delay(2000),
      switchMap(([action, accountsList]: [ReturnType<typeof createApiAction>, AccountListResponse]) =>
        this.apiService.createAPI(action.account.key, action.api, action.alias, action.subAccount?.key).pipe(
          switchMap((upload: Upload) => {
            const actions: any[] = [];
            actions.push(setApiConnectedAction());

            const checkAvailableChains = [...accountsList.blockchain, ...accountsList.wallet]
              .map((a: Account) => a.key)
              .includes(action.subAccount?.key || action.account.key);

            if (checkAvailableChains) {
              actions.push(
                loadAvailableCandidatesAction({
                  apiId: upload.id,
                  address: action.api.key,
                  aggregator: action.subAccount ? action.account.key : ``,
                }),
              );
            }

            return actions;
          }),
          catchError((error: CustomError) => {
            let errorMessage = error?.message;

            if (!errorMessage) {
              if (error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode) {
                errorMessage = this.translateService.instant(error.errorCode);
              }
            }

            return [setApiErrorAction({ errorCode: error.errorCode, errorMessage })];
          }),
        ),
      ),
    ),
  );

  updateApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateApiAction>>(updateApiAction),
      switchMap((action: ReturnType<typeof updateApiAction>) =>
        this.apiService.updateAPI(action.apiId, action.api).pipe(
          switchMap(() => {
            return [setApiConnectedAction(), loadUserAccountsAction()];
          }),
          catchError((error: CustomError) => {
            let errorMessage = error?.message;
            if (error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode) {
              errorMessage = this.translateService.instant(error.errorCode);
            }

            return [setApiErrorAction({ errorCode: error.errorCode, errorMessage })];
          }),
        ),
      ),
    ),
  );

  loadAvailableCandidates$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAvailableCandidatesAction>>(loadAvailableCandidatesAction),
      withLatestFrom(this.sharedStore$.pipe(select(fromShared.selectAccounts))),
      switchMap(([action, accounts]: [ReturnType<typeof loadAvailableCandidatesAction>, Map<string, Account>]) =>
        this.apiService.getAvailableCandidates(action.apiId, action.address, action.aggregator).pipe(
          map((availableCandidates: Candidate[]) => {
            return setAvailableCandidatesAction({ availableCandidates });
          }),
          catchError((error: CustomError) => EMPTY),
        ),
      ),
    ),
  );

  createSelectedCandidatesApis$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof createSelectedCandidatesApisAction>>(createSelectedCandidatesApisAction),
        withLatestFrom(this.sharedStore$.pipe(select(fromShared.selectAccounts))),
        switchMap(
          ([action, accounts]: [ReturnType<typeof createSelectedCandidatesApisAction>, Map<string, Account>]) => {
            let apis$: Observable<Upload>[] = [];

            apis$ = action.selectedCandidates.map((candidate: Candidate) => {
              const apiDetails: APIDetails = {
                key: candidate.address,
                wallet: `FEAT_ALL_IN_ONE`,
              };
              return this.apiService.createAPI(candidate.platform, apiDetails, action.alias);
            });

            return combineLatest(apis$).pipe(
              mergeMap((apis: Upload[]) => {
                const timeOut = 2000;
                this.toastService.dismiss();

                apis.forEach((api: Upload, index: number) => {
                  setTimeout(
                    () => {
                      const message = ``
                        .concat(this.translateService.instant(`TOAST.ACCOUNT`))
                        .concat(` "${api.alias || accounts.get(api.platform)?.name}" `)
                        .concat(this.translateService.instant(`TOAST.ADDED`));

                      this.toastService.success(message);
                    },
                    index * (timeOut + 500),
                  );
                });

                return EMPTY;
              }),
              catchError((error: CustomError) => {
                let errorMessage = error?.message;

                if (!errorMessage) {
                  errorMessage =
                    this.translateService.instant(error.errorCode) === error.errorCode
                      ? error.message
                      : this.translateService.instant(error.errorCode);
                }

                this.toastService.dismiss();

                this.toastService.error(errorMessage);

                return EMPTY;
              }),
            );
          },
        ),
      ),
    { dispatch: false },
  );

  resolveName$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof resolveNameAction>>(resolveNameAction),
      switchMap((action: ReturnType<typeof resolveNameAction>) =>
        this.nameResolverService.resolveName(action.account.key, action.name).pipe(
          map((resolvedName: NameResolverResponse) => setResolvedNameAction({ resolvedName })),
          catchError((error: CustomError) => {
            let errorMessage = error?.message;
            if (error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode) {
              errorMessage = this.translateService.instant(error.errorCode);
            }

            return [
              setResolvedNameAction({ resolvedName: null }),
              setApiErrorAction({
                errorCode: error.errorCode,
                errorMessage,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  loadUserAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserAccountsAction>>(loadUserAccountsAction),
      switchMap(() => {
        return this.accountService.getUserAccounts().pipe(
          map((userAccounts: UserAccount[]) => {
            return setUserAccountsAction({ userAccounts });
          }),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          }),
        );
      }),
    ),
  );

  setUserAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setUserAccountsAction>>(setUserAccountsAction),
      withLatestFrom(this.assessmentStore$.select(fromAssessment.selectAssessmentStatus)),
      switchMap(([action, assessmentStatus]: [ReturnType<typeof setUserAccountsAction>, AssessmentStatus]) => {
        if (assessmentStatus) {
          if (action.userAccounts.length === 0) {
            return [setAssessmentStatusAction({ assessmentStatus: `NEED_ACCOUNT_ADD` })];
          } else if (action.userAccounts.some((account) => account.status === `UPLOADED`)) {
            return [setAssessmentStatusAction({ assessmentStatus: `RETRIEVING_TX` })];
          } else {
            return EMPTY;
          }
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  loadUserAPIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserAPIsAction>>(loadUserAPIsAction),
      switchMap(() =>
        this.apiService.getApis().pipe(
          map((userAPIs: Upload[]) => setUserAPIsAction({ userAPIs })),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          }),
        ),
      ),
    ),
  );

  loadTransactionsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTransactionsCountAction>>(loadTransactionsCountAction),
      switchMap(() =>
        this.fileService.getAccountsTransactionsCount().pipe(
          map((transactionsCount: Map<string, number>) =>
            setTransactionsCountAction({
              transactionsCount,
            }),
          ),
        ),
      ),
    ),
  );

  downloadFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof downloadFileAction>>(downloadFileAction),
        switchMap((action: ReturnType<typeof downloadFileAction>) =>
          this.fileService.downloadFile(action.account.accountId).pipe(
            tap((data: any) => {
              // Doing it this way allows you to name the file
              const link = document.createElement(`a`);

              link.href = window.URL.createObjectURL(new Blob([data], { type: `application/zip` }));

              link.download = action.account.name + `.zip`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  deleteAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteAccountAction>>(deleteAccountAction),
      withLatestFrom(
        this.sharedStore$.pipe(select(fromShared.selectAccounts)),
        this.accountStore$.pipe(select(fromAccount.selectUserAccounts)),
      ),
      switchMap(
        ([action, accounts, userAccounts]: [
          ReturnType<typeof deleteAccountAction>,
          Map<string, Account>,
          UserAccount[],
        ]) =>
          this.fileService.deleteFile(action.account.accountId).pipe(
            switchMap(() => {
              const msg = this.translateService
                .instant(`TOAST.ACCOUNT`)
                .concat(` "${action.account.alias || accounts.get(action.account.platform)?.name}" `)
                .concat(this.translateService.instant(`TOAST.REMOVED`));

              this.toastService.success(msg);

              return [removeUserAccountAction({ userAccount: action.account }), loadPlansByFiscalYearsAction()];
            }),
            catchError((error: CustomError) => {
              this.toastService.error(error.message);

              return [setUserAccountsAction({ userAccounts }), loadPlansByFiscalYearsAction()];
            }),
          ),
      ),
    ),
  );

  renameAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof renameAccountAction>>(renameAccountAction),
      withLatestFrom(this.sharedStore$.pipe(select(fromShared.selectAccounts))),
      switchMap(([action, accounts]: [ReturnType<typeof renameAccountAction>, Map<string, Account>]) => {
        const updateAlias$: Observable<Upload> =
          action.account.type === `API`
            ? this.apiService.updateApiAlias(action.account.accountId, action.alias)
            : this.fileService.updateFileAlias(action.account.accountId, action.alias);

        return updateAlias$.pipe(
          switchMap(() => {
            const message = this.translateService.instant(`ACCOUNT_ALIAS_UPDATED`, {
              account: action.account.alias || accounts.get(action.account.platform).name,
            });

            this.toastService.success(message);

            if (action.reloadDashboard) {
              return [getUserAccountAction({ accountId: action.account.accountId }), loadLiveBalancesAction({})];
            } else {
              return [getUserAccountAction({ accountId: action.account.accountId })];
            }
          }),
        );
      }),
    ),
  );

  getOAuthSyncUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof getOAuthSyncUrlAction>>(getOAuthSyncUrlAction),
        switchMap((action: ReturnType<typeof getOAuthSyncUrlAction>) =>
          this.apiService.getOAuthSyncUrl(action.platform).pipe(
            map((redirectionUrl: RedirectionUrl) => {
              if (action.platform === `BITSTAMP`) {
                localStorage.setItem(`codeVerifier`, redirectionUrl.codeVerifier);
              }

              window.location.href = redirectionUrl.url;
            }),
            catchError((error: CustomError) => {
              this.toastService.error(error.message);

              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  createOAuthAPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof createOAuthAPIAction>>(createOAuthAPIAction),
      withLatestFrom(
        this.store$.select(selectCurrentRoute),
        this.store$.pipe(select(selectQueryParam(`code`))),
        this.sharedStore$.pipe(select(fromShared.selectAccounts)),
      ),
      switchMap(
        ([action, route, code, accounts]: [
          ReturnType<typeof createOAuthAPIAction>,
          any,
          string,
          Map<string, Account>,
        ]) => {
          const account: string = route.routeConfig.path.replace(`-sync/callback`, ``).toUpperCase();

          return this.apiService.createOAuthAPI(account, code).pipe(
            switchMap(() => {
              const message = this.translateService.instant(`ACCOUNT_SYNCED`);

              this.toastService.success(message);

              return [goToAction({ url: `/accounts` })];
            }),
          );
        },
      ),
    ),
  );

  loadTimezones$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadTimezonesAction>>(loadTimezonesAction),
      switchMap((action: ReturnType<typeof loadTimezonesAction>) =>
        this.fileService.getTimezones().pipe(
          map((timezones: TimezoneDetails[]) => setTimezonesAction({ timezones })),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          }),
        ),
      ),
    ),
  );

  getUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof getUserAccountAction>>(getUserAccountAction),
      withLatestFrom(this.accountStore$.pipe(select(fromAccount.selectUserAccounts))),
      switchMap(([action, userAccounts]: [ReturnType<typeof getUserAccountAction>, UserAccount[]]) =>
        this.accountService.getUserAccount(action.accountId).pipe(
          map((account: UserAccount) => {
            const index = userAccounts.findIndex((a: UserAccount) => a.accountId === account.accountId);
            userAccounts[index].status = account.status;
            userAccounts[index].lastUpdated = account.lastUpdated;
            userAccounts[index].nbOfTransactions = account.nbOfTransactions;
            userAccounts[index].manualSyncCredit = account.manualSyncCredit;

            return updateUserAccountAction({ userAccount: userAccounts[index] });
          }),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          }),
        ),
      ),
    ),
  );

  syncAPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof syncAPIAction>>(syncAPIAction),
      switchMap((action: ReturnType<typeof syncAPIAction>) =>
        this.apiService.syncAPI(action.userAccount.accountId).pipe(
          map(() => {
            const message = this.translateService.instant(`ACCOUNT_SYNCED`);

            this.toastService.success(message);

            return getUserAccountAction({ accountId: action.userAccount.accountId });
          }),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);
            return EMPTY;
          }),
        ),
      ),
    ),
  );

  initReownModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof initReownModalAction>>(initReownModalAction),
      withLatestFrom(this.accountStore$.pipe(select(fromAccount.selectReownModal))),
      switchMap(([action, modal]: [ReturnType<typeof initReownModalAction>, AppKit]) => {
        if (!modal) {
          const reownModal = this.web3Service.createModal();
          this.setReownModalSubscriptions(reownModal);
          return of(setReownModalAction({ reownModal }));
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  openReownModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof openReownModalAction>>(openReownModalAction),
      withLatestFrom(this.accountStore$.pipe(select(fromAccount.selectReownModal))),
      switchMap(([action, modal]: [ReturnType<typeof openReownModalAction>, AppKit]) => {
        if (modal) {
          modal.open();

          return [
            setReownModalStateAction({
              reownModalState: {
                isOpened: true,
                isImporting: false,
                isConnected: false,
                address: ``,
                network: ``,
                wallet: ``,
              },
            }),
          ];
        }

        return EMPTY;
      }),
    ),
  );

  disconnectReownModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof disconnectReownModalAction>>(disconnectReownModalAction),
      withLatestFrom(this.accountStore$.pipe(select(fromAccount.selectReownModal))),
      switchMap(([action, modal]: [ReturnType<typeof disconnectReownModalAction>, AppKit]) => {
        if (modal) {
          modal.disconnect();

          return [
            setReownModalStateAction({
              reownModalState: {
                isOpened: false,
                isImporting: false,
                isConnected: false,
                address: ``,
                network: ``,
                wallet: ``,
              },
            }),
          ];
        }

        return EMPTY;
      }),
    ),
  );

  createNewIntegration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof createNewIntegrationAction>>(createNewIntegrationAction),
        switchMap((action: ReturnType<typeof createNewIntegrationAction>) =>
          this.accountService
            .createNewIntegration(action.platform, action.apiKey, action.apiSecret, action.apiPassphrase, action.files)
            .pipe(
              switchMap((response: CreateIntegrationResponse) => {
                this.dialog.closeAll();
                this.utilsService.openDialog(NewIntegrationSuccessDialogComponent, `512px`, `auto`);

                return EMPTY;
              }),
              catchError((error: CustomError) => {
                this.toastService.error(error.message);
                return EMPTY;
              }),
            ),
        ),
      ),
    { dispatch: false },
  );

  private setReownModalSubscriptions(reownModal: AppKit): void {
    reownModal.subscribeAccount((useAppKitAccountReturn: UseAppKitAccountReturn) => {
      if (useAppKitAccountReturn.isConnected) {
        const address = useAppKitAccountReturn.address.trim();

        this.accountStore$.dispatch(
          setReownModalStateAction({
            reownModalState: {
              address,
            },
          }),
        );
      }
    });

    reownModal.subscribeNetwork((useAppKitNetworkReturn: Omit<UseAppKitNetworkReturn, `switchNetwork`>) => {
      if (useAppKitNetworkReturn.chainId) {
        const chainId = useAppKitNetworkReturn.chainId.toString();

        const network = this.reownBlockchains.get(chainId);

        this.accountStore$.dispatch(
          setReownModalStateAction({
            reownModalState: {
              network,
            },
          }),
        );
      }
    });

    reownModal.subscribeEvents((state: EventsControllerState) => {
      const event = state.data.event;
      const properties: Properties = state.data[`properties`];
      const wallet = this.reownWallets.get(properties?.name) || properties?.name;

      switch (event) {
        case `MODAL_CLOSE`:
          if (!properties?.connected) {
            this.accountStore$.dispatch(
              setReownModalStateAction({
                reownModalState: {
                  isOpened: false,
                },
              }),
            );
          }
          break;
        case `CONNECT_ERROR`:
          this.toastService.error(properties?.message);

          this.accountStore$.dispatch(
            setReownModalStateAction({
              reownModalState: {
                isOpened: false,
              },
            }),
          );
          break;
        case `CONNECT_SUCCESS`:
          reownModal.close();

          this.accountStore$.dispatch(
            setReownModalStateAction({
              reownModalState: {
                isConnected: true,
                isImporting: true,
                isOpened: false,
                wallet,
              },
            }),
          );

          break;
      }
    });
  }

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly accountStore$: Store<fromAccount.State>,
    private readonly assessmentStore$: Store<fromAssessment.State>,
    private readonly apiService: APIService,
    private readonly nameResolverService: NameResolverService,
    private readonly translateService: TranslateService,
    private readonly fileService: FileService,
    private readonly toastService: ToastService,
    private readonly accountService: AccountService,
    private readonly utilsService: UtilsService,
    private readonly dialog: MatDialog,
    private readonly web3Service: Web3Service,
  ) {}
}
