import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { User } from '../../../taxation/models/user.model';
import { TwoFACheck } from '../../models/two-fa.model';
import * as authenticationReducer from '../reducers/authentication.reducer';
import { User as AuthUser } from '@auth0/auth0-angular';
import { AuthUserEvent } from '../../../taxation/models/auth-user-event.model';
import { Page } from '../../../shared/models/page.model';

export const AUTHENTICATION_FEATURE_NAME = `authentication`;

export interface State {
  authentication: authenticationReducer.State;
}

export const selectAuthenticationState: MemoizedSelector<State, authenticationReducer.State> =
  createFeatureSelector<authenticationReducer.State>(AUTHENTICATION_FEATURE_NAME);

export const selectIsAuthenticated: MemoizedSelector<State, boolean> = createSelector(
  selectAuthenticationState,
  (state: authenticationReducer.State) => state.isAuthenticated,
);

export const selectAccessToken: MemoizedSelector<State, string> = createSelector(
  selectAuthenticationState,
  (state: authenticationReducer.State) => state.accessToken,
);

export const selectUser: MemoizedSelector<State, User> = createSelector(
  selectAuthenticationState,
  (state: authenticationReducer.State) => state.user,
);

export const selectAuthUser: MemoizedSelector<State, AuthUser> = createSelector(
  selectAuthenticationState,
  (state: authenticationReducer.State) => state.authUser,
);

export const selectUserTwoFA: MemoizedSelector<State, TwoFACheck> = createSelector(
  selectAuthenticationState,
  (state: authenticationReducer.State) => state.twoFA,
);

export const selectIs2FaCodeValidated: MemoizedSelector<State, boolean> = createSelector(
  selectAuthenticationState,
  (state: authenticationReducer.State) =>
    state.is2FaCodeValidated || localStorage.getItem(`id_auth`) === state.accessToken,
);
export const selectUserAuthEventsPage: MemoizedSelector<State, Page<AuthUserEvent>> = createSelector(
  selectAuthenticationState,
  (state: authenticationReducer.State) => state.userAuthEventsPage,
);

export const selectTwoFaCodeError: MemoizedSelector<State, boolean> = createSelector(
  selectAuthenticationState,
  (state: authenticationReducer.State) => state.twoFaCodeError,
);

export const selectTwoFaRecoveryCodeError: MemoizedSelector<State, boolean> = createSelector(
  selectAuthenticationState,
  (state: authenticationReducer.State) => state.twoFaRecoveryCodeError,
);
