<div class="container">
  <section class="header">
    <h2>{{ 'REAL_TIME_EXCHANGE_RATES' | translate }}</h2>
    <img class="pointer" src="/assets/img/icons/close-icon.svg" alt="close-icon" (click)="closeSwapper.emit()" />
  </section>

  <section class="swappers">
    <app-swapper
      [label]="'SELL_2' | translate"
      [coins]="coins"
      [startAmount]="startAmount"
      [amount]="sellingAmount"
      [placeholder]="sellingAmountPlaceHolder"
      [currency]="sellingCurrency"
      [excludedCurrency]="sellingExcludedCurrency"
      [disabled]="!tickerFeed || !marketFeed"
      [reset]="sellingAmountReset"
      [userPreferences]="userPreferences"
      [stablecoins]="stablecoins"
      [fiats]="fiats"
      (amountChange)="changeSwapperMode('BUY'); computeBuyingAmount($event, true)"
      (currencyChange)="computeSellingCurrencyPrice($event, true)"
    ></app-swapper>

    <div class="swap-switch pointer" (click)="swap()"></div>

    <app-swapper
      [label]="'BUY_2' | translate"
      [coins]="coins"
      [amount]="buyingAmount"
      [placeholder]="buyingAmountPlaceHolder"
      [currency]="buyingCurrency"
      [excludedCurrency]="buyingExcludedCurrency"
      [disabled]="!tickerFeed || !marketFeed"
      [reset]="buyingAmountReset"
      [userPreferences]="userPreferences"
      [stablecoins]="stablecoins"
      [fiats]="fiats"
      (amountChange)="changeSwapperMode('SELL'); computeSellingAmount($event, true)"
      (currencyChange)="computeBuyingCurrencyPrice($event, true)"
    ></app-swapper>
  </section>
  <mat-divider></mat-divider>
  <section class="platforms">
    <div class="platforms__header">
      <p class="body-14-bold">{{ 'BEST_RATES' | translate }}</p>

      @if (hasPaid) {
        <app-considered-platforms></app-considered-platforms>
      }
    </div>

    @if (!hasPaid) {
      <div class="best-rates">
        <div class="best-rates__content">
          <img width="15" height="15" src="/assets/img/icons/lock-white.svg" alt="lock-white" />
          <p class="body-12">{{ 'SWITCH_TO_SMART_PLAN' | translate }}</p>
        </div>

        <button mat-flat-button color="primary-revert" disableRipple (click)="upgradeFishingEvent.emit()">
          {{ 'LEARN_MORE' | translate }}
        </button>
      </div>
    }

    <div class="platforms__items">
      @if (platforms) {
        @if (platforms?.length > 0) {
          @for (platform of platforms; track platform.name; let index = $index; let first = $first) {
            <div class="platform">
              <div class="platform__name">
                @if (hasPaid) {
                  <div class="account-logo-container pointer">
                    <img
                      class="account-logo"
                      width="26"
                      [src]="'https://icons.waltio.com/account/' + platform.name"
                      [alt]="platform.name"
                    />

                    @if (getPlatformDetails(platform)?.registeredPSAN) {
                      <img class="certified" src="/assets/img/icons/certified.svg" alt="certified" />
                    }
                  </div>

                  <p class="body-14">{{ accounts?.get(platform.name)?.name }}</p>
                } @else {
                  <img
                    class="pointer"
                    src="/assets/img/icons/account-locked.svg"
                    alt="account-locked"
                    (click)="upgradeFishingEvent.emit()"
                  />

                  <p class="body-14-bold pointer" (click)="upgradeFishingEvent.emit()">
                    {{ 'PLATFORM' | translate }} {{ index + 1 }}
                  </p>
                }
              </div>

              <div class="instruction-container" (click)="openFeesInstructionDialog(platform)">
                @if (instructions?.get(platform.name)) {
                  <div class="instruction">
                    <div class="instruction__icon"></div>
                    <p class="body-12">{{ 'FEE_GUIDE' | translate }}</p>
                    <div class="instruction__next"></div>
                  </div>
                }
              </div>

              <div class="platform__amounts">
                <p class="body-13-bold">
                  {{
                    platform.quantity
                      | currencyDecimal: userPreferences : stablecoins : fiats : '' : currentCurrency
                      | truncate: 12
                  }}
                  {{ currentCurrency | truncate: 4 }}
                </p>
                <p class="body-12">
                  {{
                    platform.amount
                      | currencyDecimal: userPreferences : stablecoins : fiats
                      | truncate: 12 : true : (userPreferences?.language !== 'en' ? '... €' : '...')
                  }}
                </p>
              </div>

              <div class="platform__trends">
                @if (first) {
                  <div class="best">
                    <p class="body-12-med">Best</p>
                  </div>
                } @else {
                  <p class="body-13-med">
                    {{ platform.amountTrendPercentage | number: '.2-2' | truncate: 8 : false }} %
                  </p>
                  <p class="body-12">
                    {{
                      platform.amountGap
                        | currencyDecimal: userPreferences : stablecoins : fiats : 'symbol'
                        | truncate: 12 : true : (userPreferences?.language !== 'en' ? '... €' : '...')
                    }}
                  </p>
                }
              </div>
            </div>
          }
        } @else {
          @for (platform of ['', '', '', '', '']; track platform; let first = $first) {
            <div class="platform">
              <div class="platform__name">
                <ngx-skeleton-loader
                  appearance="circle"
                  [theme]="{
                    'border-radius': '6px',
                    'width': '24px',
                    'height': '24px',
                    'margin': '0',
                  }"
                />

                <ngx-skeleton-loader
                  appearance="line"
                  [theme]="{
                    'border-radius': '6px',
                    'width': '41px',
                    'height': '8px',
                    'margin': '0',
                  }"
                />
              </div>

              <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                  'border-radius': '21px',
                  'width': '120px',
                  'height': '10px',
                  'margin': '0',
                }"
              />

              <div class="platform__amounts m-l-0">
                <ngx-skeleton-loader
                  appearance="line"
                  [theme]="{
                    'border-radius': '6px',
                    'width': '54px',
                    'height': '10px',
                    'margin': '0',
                  }"
                />
                <ngx-skeleton-loader
                  appearance="line"
                  [theme]="{
                    'border-radius': '6px',
                    'width': '41px',
                    'height': '8px',
                    'margin': '0',
                  }"
                />
              </div>

              <div class="platform__trends">
                @if (first) {
                  <div class="best">
                    <p class="body-12-med">Best</p>
                  </div>
                } @else {
                  <ngx-skeleton-loader
                    appearance="line"
                    [theme]="{
                      'border-radius': '6px',
                      'width': '35px',
                      'height': '10px',
                      'margin': '0',
                    }"
                  />
                }
              </div>
            </div>
          }
        }
      } @else {
        <div class="no-platforms">
          <h2>Aucune possibilité trouvée</h2>

          <p class="body-14">Aucune route n’a été trouvée, veuillez essayer une autre combinaison.</p>
        </div>
      }
    </div>
  </section>
</div>
