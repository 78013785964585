import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: `app-stop-report-dialog`,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDividerModule, MatDialogModule, TranslateModule],
  templateUrl: `./stop-report-dialog.component.html`,
  styleUrl: `./stop-report-dialog.component.scss`,
})
export class StopReportDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      automaticAssessmentEnabled: boolean;
    }
  ) {}
}
