<mat-sidenav-container>
  <mat-sidenav mode="side" disableClose="true" opened>
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
  <mat-sidenav #rightSidenav mode="over" position="end">
    @if (rightSidenav.opened) {
      @switch (getSidenavComponentType()) {
        @case ('LegalForm') {
          <app-legal-forms-page></app-legal-forms-page>
        }

        @case ('TokenSwapper') {
          <app-token-swapper
            [userPreferences]="userPreferences"
            [stablecoins]="stablecoins"
            [fiats]="fiats"
            [accounts]="accounts"
            [marketFeed]="marketFeed"
            [tickerFeed]="tickerFeed"
            (closeSwapper)="closeSidenav()"
          ></app-token-swapper>
        }
      }
    }
  </mat-sidenav>
</mat-sidenav-container>
