
import { Component } from '@angular/core';
import { ProfileHeaderComponent } from '../../components/profile-header/profile-header.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: `app-profile-page`,
  templateUrl: `./profile-page.component.html`,
  standalone: true,
  imports: [
    RouterModule,
    ProfileHeaderComponent
  ],
})
export class ProfilePageComponent { }