<mat-toolbar>
  <div class="container">
    <nav>
      <app-allocation-toggle
        [title]="'DASHBOARD_2' | translate"
        [routerLink]="'/opportunities'"
        [active]="currentPath === '/opportunities'"
      ></app-allocation-toggle>
      <app-allocation-toggle
        [title]="'FEE_MANAGER' | translate"
        [routerLink]="'/opportunities/fees'"
        [active]="currentPath === '/opportunities/fees'"
      ></app-allocation-toggle>
      <app-allocation-toggle
        [title]="'YIELD_MANAGER' | translate"
        [routerLink]="'/opportunities/yields'"
        [active]="currentPath === '/opportunities/yields'"
      ></app-allocation-toggle>
    </nav>

    @if (userPreferences?.language === 'fr') {
      <div class="feedback pointer" (click)="feedback.emit()">
        <img src="/assets/img/icons/chat-bubble.svg" alt="chat" />
        <p class="body-14-med">{{ 'NEW_FEEDBACK' | translate }}</p>
      </div>
    }
  </div>
</mat-toolbar>
<mat-divider></mat-divider>
