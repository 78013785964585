import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromShared from '../../shared/store/selectors/shared.selector';
import { filter, map } from 'rxjs/operators';
import { UserFeature } from '../../shared/models/feature.model';
import { goToAction, loadUserFeaturesAction } from '../../shared/store/actions/shared.action';

export const stakingGuard: CanActivateFn = (route, state) => {
  const sharedStore$ = inject(Store<fromShared.State>);

  sharedStore$.dispatch(loadUserFeaturesAction());

  return sharedStore$.select(fromShared.selectUserFeatures).pipe(
    filter((userFeatures: UserFeature[]) => !!userFeatures),
    map((userFeatures: UserFeature[]) => {
      if (!userFeatures.includes(`STAKING`)) {
        sharedStore$.dispatch(goToAction({ url: `/dashboard` }));
        return false;
      }

      return true;
    }),
  );
};
