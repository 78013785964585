/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { User } from '../../../taxation/models/user.model';
import { TwoFACheck, UserTwoFAConfiguration } from '../../models/two-fa.model';
import { User as AuthUser } from '@auth0/auth0-angular';
import { AuthUserEvent } from '../../../taxation/models/auth-user-event.model';
import { Page } from '../../../shared/models/page.model';

// eslint-disable-next-line no-shadow
export enum AuthenticationActions {
  LoadIsAuthenticated = `[Authentication] Load Is Authenticated`,
  SetIsAuthenticated = `[Authentication] Set Is Authenticated`,
  LoadAccessToken = `[Authentication] Load Access Token`,
  SetAccessToken = `[Authentication] Set Access Token`,
  LoadAuthUser = `[Authentication] Load Auth User`,
  SetAuthUser = `[Authentication] Set Auth User`,
  Logout = `[Authentication] Log Out`,
  LoadUser = `[User] Load User`,
  SetUser = `[User] Set User`,
  SaveUser = `[User] Save User`,
  ResetUser = `[User] Reset User`,
  DeleteUser = `[User] Delete User`,
  UpdateIntercomPageName = `[User] Update Intercom Page Name`,
  UpdateIntercomAnalysisCount = `[User] Update Intercom Analysis Count`,
  Load2FaConfig = `[Authentication] Load 2FA Config`,
  Set2FaConfig = `[Authentication] Set 2FA Config`,
  Set2Fa = `[Authentication] Set 2FA`,
  Setup2Fa = `[Authentication] Setup 2FA`,
  Enable2Fa = `[Authentication] Enable 2FA`,
  Disable2Fa = `[Authentication] Disable 2FA`,
  Validate2FaCode = `[Authentication] Validate 2FA Code`,
  Validate2FaRecoveryCode = `[Authentication] Validate 2FA Recovery Code`,
  SwitchUserAssociatedFiscalYear = `[Authentication] Switch User Associated Fiscal Year`,
  LoadAuthUserEvents = `[Authentication] Load Auth User Events`,
  SetAuthUserEvents = `[Authentication] Set Auth User Events`,
}

export const logoutAction = createAction(AuthenticationActions.Logout);

export const loadIsAuthenticatedAction = createAction(
  AuthenticationActions.LoadIsAuthenticated
);

export const loadAccessTokenAction = createAction(
  AuthenticationActions.LoadAccessToken
);

export const loadAuthUserAction = createAction(
  AuthenticationActions.LoadAuthUser
);

export const setIsAuthenticatedAction = createAction(
  AuthenticationActions.SetIsAuthenticated,
  props<{ isAuthenticated: boolean }>()
);

export const setAuthUserAction = createAction(
  AuthenticationActions.SetAuthUser,
  props<{ authUser: AuthUser }>()
);

export const setAccessTokenAction = createAction(
  AuthenticationActions.SetAccessToken,
  props<{ accessToken: string }>()
);

export const setUserAction = createAction(
  AuthenticationActions.SetUser,
  props<{ user: User }>()
);

export const saveUserAction = createAction(
  AuthenticationActions.SaveUser,
  props<{ user: User }>()
);

export const switchUserAssociatedFiscalYearAction = createAction(
  AuthenticationActions.SwitchUserAssociatedFiscalYear,
  props<{ fiscalYear: number }>()
);

export const resetUserAction = createAction(AuthenticationActions.ResetUser);

export const deleteUserAction = createAction(AuthenticationActions.DeleteUser);

export const load2FaConfigAction = createAction(
  AuthenticationActions.Load2FaConfig
);

export const set2FaConfigAction = createAction(
  AuthenticationActions.Set2FaConfig,
  props<{ config: UserTwoFAConfiguration }>()
);

export const setTwoFaAction = createAction(
  AuthenticationActions.Set2Fa,
  props<{ twoFA: TwoFACheck }>()
);

export const enable2FaAction = createAction(
  AuthenticationActions.Enable2Fa,
  props<{ code: string }>()
);

export const disable2FaAction = createAction(AuthenticationActions.Disable2Fa);

export const setup2FaAction = createAction(AuthenticationActions.Setup2Fa);

export const validate2FaCodeAction = createAction(
  AuthenticationActions.Validate2FaCode,
  props<{ code: string }>()
);

export const validate2FaRecoveryCodeAction = createAction(
  AuthenticationActions.Validate2FaRecoveryCode,
  props<{ recoveryCode: string }>()
);

export const loadAuthUserEventsAction = createAction(
  AuthenticationActions.LoadAuthUserEvents,
  props<{ page: number, size: number, sort: string }>()
);

export const setAuthUserEventsAction = createAction(
  AuthenticationActions.SetAuthUserEvents,
  props<{ userAuthEventsPage: Page<AuthUserEvent> }>()
);

export const loadUserAction = createAction(AuthenticationActions.LoadUser);