import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LiveBalanceResponse } from '../models/live-balance.model';

@Injectable({
  providedIn: `root`,
})
export class BalanceService {
  constructor(private readonly http: HttpClient) {}

  getLiveBalances(force = false): Observable<LiveBalanceResponse> {
    return this.http.get<LiveBalanceResponse>(`${environment.apiUrl}/v1/tax/live-balance?force=${force}`);
  }
}
