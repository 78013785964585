import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LiveBalanceResponse } from '../models/live-balance.model';
import { ManualStock } from '../models/manual-stock.model';

@Injectable({
  providedIn: `root`,
})
export class ManualStockService {
  constructor(private readonly http: HttpClient) {}

  getManualStocks(): Observable<ManualStock[]> {
    return this.http.get<ManualStock[]>(`${environment.apiUrl}/v1/tax/manual-stock`);
  }

  addManualStock(manualStock: ManualStock): Observable<LiveBalanceResponse> {
    return this.http.post<LiveBalanceResponse>(`${environment.apiUrl}/v1/tax/manual-stock`, manualStock);
  }

  updateManualStock(manualStock: ManualStock): Observable<LiveBalanceResponse> {
    return this.http.put<LiveBalanceResponse>(
      `${environment.apiUrl}/v1/tax/manual-stock/${manualStock.id}`,
      manualStock,
    );
  }

  deleteManualStock(manualStockId: string): Observable<LiveBalanceResponse> {
    return this.http.delete<LiveBalanceResponse>(`${environment.apiUrl}/v1/tax/manual-stock/${manualStockId}`);
  }
}
