import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import { enableProdMode } from '@angular/core';
import {
  bootstrapApplication
} from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { appConfig } from './app/config/app.config';

registerLocaleData(localeFr);
registerLocaleData(localeEn);
registerLocaleData(localeEs);

if (environment.production) {
  const script = document.createElement('script');
  const hotjarId = 3451804;

  script.innerHTML = `
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: ${hotjarId}, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  `;
  document.head.appendChild(script);

  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
