import { TransactionSubType, TransactionWarningType } from '../models/transaction.model';
import { SearchDTO, ViewFilters } from '../models/transactions-filters.model';

export const TRANSACTION_SUBTYPES: Map<string, any> = new Map([
  [`DONATION`, { deposit: `SUBTYPES.DONATION.DEPOSIT`, withdrawal: `SUBTYPES.DONATION.WITHDRAWAL` }],
  [`ICO`, { deposit: `SUBTYPES.ICO.DEPOSIT`, withdrawal: `SUBTYPES.ICO.WITHDRAWAL` }],
  [
    `MASTERNODE_AND_STAKING`,
    {
      deposit: `SUBTYPES.MASTERNODE_AND_STAKING`,
    },
  ],
  [`SWAP`, { deposit: `SUBTYPES.SWAP`, withdrawal: `SUBTYPES.SWAP` }],
  [`SCAM`, { deposit: `SUBTYPES.SCAM.DEPOSIT`, withdrawal: `SUBTYPES.SCAM.WITHDRAWAL` }],
  [
    `TRANSFER_BETWEEN_ACCOUNT`,
    {
      deposit: `SUBTYPES.TRANSFER_BETWEEN_ACCOUNT.DEPOSIT`,
      withdrawal: `SUBTYPES.TRANSFER_BETWEEN_ACCOUNT.WITHDRAWAL`,
    },
  ],
  [`COLLATERAL_REIMBURSEMENT`, { deposit: `SUBTYPES.COLLATERAL_REIMBURSEMENT` }],
  [`CFD_GAIN`, { deposit: `SUBTYPES.CFD_GAIN` }],
  [`CASHBACK`, { deposit: `SUBTYPES.CASHBACK` }],
  [`UNSTAKING`, { deposit: `SUBTYPES.UNSTAKING` }],
  [`AIRDROP`, { deposit: `SUBTYPES.AIRDROP` }],
  [`FORK`, { deposit: `SUBTYPES.FORK` }],
  [`INCOME`, { deposit: `SUBTYPES.INCOME` }],
  [`BORROW`, { deposit: `SUBTYPES.BORROW` }],
  [`OTHER_GAIN`, { deposit: `SUBTYPES.OTHER_GAIN` }],
  [`MINING`, { deposit: `SUBTYPES.MINING` }],
  [`CRYPTO_BUY`, { deposit: `SUBTYPES.CRYPTO_BUY` }],
  [`PLAY2EARN_GAIN`, { deposit: `SUBTYPES.PLAY2EARN_GAIN` }],
  [`LIQUIDITY_WITHDRAWAL`, { deposit: `SUBTYPES.LIQUIDITY_WITHDRAWAL` }],
  [`PAYMENT`, { withdrawal: `SUBTYPES.PAYMENT` }],
  [`FEE`, { withdrawal: `SUBTYPES.FEE` }],
  [`ADD_STAKING`, { withdrawal: `SUBTYPES.ADD_STAKING` }],
  [`CFD_LOSS`, { withdrawal: `SUBTYPES.CFD_LOSS` }],
  [`HACK_OR_LOSS`, { withdrawal: `SUBTYPES.HACK_OR_LOSS` }],
  [`ADD_LIQUIDITY`, { withdrawal: `SUBTYPES.ADD_LIQUIDITY` }],
  [`LIQUIDATION`, { withdrawal: `SUBTYPES.LIQUIDATION` }],
  [`ADD_COLLATERAL`, { withdrawal: `SUBTYPES.ADD_COLLATERAL` }],
  [`OTHER_WITHDRAWAL`, { withdrawal: `SUBTYPES.OTHER_WITHDRAWAL` }],
  [`OTHER_LOSS`, { withdrawal: `SUBTYPES.OTHER_LOSS` }],
  [`NFT`, { withdrawal: `SUBTYPES.NFT` }],
]);

export const DEPOSIT_TRANSACTION_SUBTYPES: TransactionSubType[] = [
  `MASTERNODE_AND_STAKING`,
  `CFD_GAIN`,
  `CASHBACK`,
  `UNSTAKING`,
  `DONATION`,
  `AIRDROP`,
  `FORK`,
  `INCOME`,
  `BORROW`,
  `COLLATERAL_REIMBURSEMENT`,
  `OTHER_GAIN`,
  `MINING`,
  `ICO`,
  `SWAP`,
  `CRYPTO_BUY`,
  `SCAM`,
  `TRANSFER_BETWEEN_ACCOUNT`,
  `PLAY2EARN_GAIN`,
  `LIQUIDITY_WITHDRAWAL`,
];

export const WITHDRAWAL_TRANSACTION_SUBTYPES: TransactionSubType[] = [
  `TRANSFER_BETWEEN_ACCOUNT`,
  `PAYMENT`,
  `FEE`,
  `ADD_STAKING`,
  `CFD_LOSS`,
  `DONATION`,
  `HACK_OR_LOSS`,
  `ADD_LIQUIDITY`,
  `LIQUIDATION`,
  `ADD_COLLATERAL`,
  `OTHER_WITHDRAWAL`,
  `OTHER_LOSS`,
  `SCAM`,
  `ICO`,
  `NFT`,
  `SWAP`,
];

export const TRANSACTION_WARNINGS: TransactionWarningType[] = [
  `INSUFFICIENT_BALANCE`,
  `UNKNOWN_PRICE`,
  `WITHDRAWAL_TO_CATEGORIZE`,
];

export const TRANSACTION_WARNING_LINKS: Map<TransactionWarningType, number> = new Map([
  [`UNKNOWN_PRICE`, 4269998],
  [`INSUFFICIENT_BALANCE`, 5854121],
  [`WITHDRAWAL_TO_CATEGORIZE`, 4006824],
]);

export const DEFAULT_TRANSACTIONS_FILTERS: SearchDTO = {
  onlyTaxable: null,
  onlyWarnings: null,
  onlyModified: null,
  onlyCashin: null,
  onlyCashout: null,
  maxAmount: null,
  minAmount: null,
  startDate: null,
  endDate: null,
  platforms: {
    includes: [],
    excludes: [],
  },
  currencies: {
    includes: [],
    excludes: [],
  },
  fromCurrencies: {
    includes: [],
    excludes: [],
  },
  toCurrencies: {
    includes: [],
    excludes: [],
  },
  types: {
    includes: [],
    excludes: [],
  },
  subTypes: {
    includes: [],
    excludes: [],
  },
  warningTypes: {
    includes: [],
    excludes: [],
  },
  uploadIds: {
    includes: [],
    excludes: [],
  },
};

export const DEFAULT_VIEW_FILTERS: ViewFilters = {
  hideScams: true,
  hideDustyTransactions: true,
};
