import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TaxFormInputComponent } from '../tax-form-input/tax-form-input.component';
import { MatDividerModule } from '@angular/material/divider';
import { TaxFormPlatformInfos } from '../../../shared/models/platform-adress.model';
import { TAX_FORM_PLATFORMS } from '../../constants/forms.constant';

@Component({
  selector: `app-french-tax-form`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDividerModule, TaxFormInputComponent],
  templateUrl: `./french-tax-form.component.html`,
  styleUrl: `./french-tax-form.component.scss`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrenchTaxFormComponent {
  @Input() platform: string;

  platformsMap: Map<string, TaxFormPlatformInfos> = TAX_FORM_PLATFORMS;
}
