/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { Page } from '../../../shared/models/page.model';
import { DocumentRequest, DocumentType } from '../../models/document.model';

// eslint-disable-next-line no-shadow
export enum DocumentActions {
  LoadDocumentRequest = `[Document] Load Document Request`,
  SetDocumentRequest = `[Document] Set Document Request`,
  CreateDocumentRequest = `[Document] Create Document Request`,
  DownloadDocumentRequest = `[Document] Download Document Request`,
  DeleteDocumentRequest = `[Document] Delete Document Request`,
  GetDocumentRequest = `[Document] Get Document Request`,
  DownloadWaltioExportFile = `[Document] Download Waltio Export File`,
}

export const loadDocumentRequestAction = createAction(
  DocumentActions.LoadDocumentRequest,
  props<{ page?: number; size?: number, sort?: string }>()
);

export const createDocumentRequestAction = createAction(
  DocumentActions.CreateDocumentRequest,
  props<{ documentRequestType: DocumentType, attachToAssessmentId?: string }>()
);

export const setDocumentRequestPageAction = createAction(
  DocumentActions.SetDocumentRequest,
  props<{ documentRequestPage: Page<DocumentRequest> }>()
);

export const downloadDocumentRequestAction = createAction(
  DocumentActions.DownloadDocumentRequest,
  props<{ documentRequest: DocumentRequest; format?: string }>()
);

export const deleteDocumentRequestAction = createAction(
  DocumentActions.DeleteDocumentRequest,
  props<{ documentRequestId: string }>()
);

export const getDocumentRequestAction = createAction(
  DocumentActions.GetDocumentRequest,
  props<{ documentRequestId: string }>()
);

export const downloadWaltioExportFileAction = createAction(
  DocumentActions.DownloadWaltioExportFile
);