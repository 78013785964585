import { NewFeature } from '../models/feature.model';

export const NEW_FEATURES: NewFeature[] = [
  {
    bannerUrl: `/assets/img/banners/features/portfolio-files.svg`,
    name: `FEATURES.PORTFOLIO_FILES`,
    description: `FEATURES.PORTFOLIO_FILES_DESC`,
    tags: [`PORTFOLIO`],
  },
  {
    bannerUrl: `/assets/img/banners/features/acquisition-prices.svg`,
    name: `FEATURES.ACQUISITION_PRICES`,
    description: `FEATURES.ACQUISITION_PRICES_DESC`,
    tags: [`PORTFOLIO`],
  },
  {
    bannerUrl: `/assets/img/banners/features/staking.svg`,
    name: `FEATURES.STAKING`,
    description: `FEATURES.STAKING_DESC`,
    tags: [`PORTFOLIO`],
  },
];
