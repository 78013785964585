<div class="container" [ngClass]="{ 'container--checked': isChecked }">
  <mat-checkbox color="primary" class="custom-checkbox custom-toggle" [checked]="isChecked"></mat-checkbox>
  <div class="content">
    @if (type === 'ADDON') {
      <p class="body-14-bold">{{ 'ADDONS.' + offer | translate }}</p>
      <p class="body-12">{{ addons.get(offer).price | number: '1.2-2' }} €</p>
    } @else if (type === 'PLAN') {
      <p class="body-14-bold">{{ 'PLANS.' + offer | translate }}</p>
    }
  </div>
</div>
