<div class="container">
  <img width="124px" height="22px" src="assets/img/logos/waltio-lab.svg" alt="Beta Program Logo" class="logo" />

  <h1>Devenez beta-testeur Waltio</h1>
  <p class="body-14-med">
    Nous vous offrons la possibilité de co-construire le produit avec nous ! Soyez aux premières loges, testez nos
    nouvelles fonctionnalités et expérimentations en avant-première.
  </p>

  <div class="ctas">
    <button mat-flat-button color="black" disableRipple (click)="openTypeform()">Je rejoins Waltio Lab</button>
  </div>

  <div class="background"></div>
</div>
