/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { CurrencyPipe } from '@angular/common';
import moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { CustomError } from '../../../shared/models/error.model';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { ToastService } from '../../../shared/services/toast.service';
import { AddOnDetails } from '../../models/addon.model';
import { AvailableAddons, AvailablePlans, Payment, PaymentEstimateV3, PendingCharge } from '../../models/payment.model';
import { SubscriptionNextPayment, SubscriptionRenewalRequest } from '../../models/subscription.model';
import { User } from '../../models/user.model';
import { VaultEvent } from '../../models/vault.model';
import { PaymentService } from '../../services/payment.service';
import { loadVaultAction } from '../actions/affiliation.action';
import { computeAssessmentStatusAction } from '../actions/assessment.action';
import {
  applyVaultAction,
  checkVoucherCodeAction,
  downloadInvoiceAction,
  enableAddonAction,
  generateInvoiceAction,
  loadAddonsDetailsAction,
  loadAvailableAddonsAction,
  loadAvailablePlansAction,
  loadPaymentEstimateAction,
  loadPaymentsAction,
  loadPendingChargeAction,
  loadPlansByFiscalYearsAction,
  loadSubscriptionNextPaymentAction,
  loadUpgradeFishingEstimateAction,
  payChargeAction,
  renewSubscriptionAction,
  setAddonsDetailsAction,
  setAvailableAddonsAction,
  setAvailablePlansAction,
  setPaymentEstimateAction,
  setPaymentsAction,
  setPendingChargeAction,
  setPlansByFiscalYearsAction,
  setSubscriptionNextPaymentAction,
  setUpgradeFishingEstimateAction,
  setVoucherResponseAction,
  upgradePlanAction,
} from '../actions/payment.action';
import * as fromPayment from '../selectors/payment.selector';

declare const Stripe: any;
declare const PostAffTracker: any;

@Injectable()
export class PaymentEffects {
  upgradePlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof upgradePlanAction>>(upgradePlanAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
        switchMap(([action, user]: [ReturnType<typeof upgradePlanAction>, User]) =>
          this.paymentService
            .upgradePlan(
              action.paymentMethod,
              action.requestedPlan,
              action.fiscalYear,
              action.papCookie,
              action.tags,
              action.financeAdsTrackingID,
              action.useVault,
              action.code,
            )
            .pipe(
              tap((payment: Payment) => {
                this.pay(payment, user);
              }),
            ),
        ),
      ),
    { dispatch: false },
  );

  loadAddonsDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAddonsDetailsAction>>(loadAddonsDetailsAction),
      switchMap((action: ReturnType<typeof loadAddonsDetailsAction>) =>
        this.paymentService.getAddonsDetails().pipe(
          map((addonsDetails: AddOnDetails[]) => setAddonsDetailsAction({ addonsDetails })),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          }),
        ),
      ),
    ),
  );

  loadPaymentEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadPaymentEstimateAction>>(loadPaymentEstimateAction),
      switchMap((action: ReturnType<typeof loadPaymentEstimateAction>) => {
        let services$: Observable<PaymentEstimateV3>;

        if (action.checkoutType === `PLAN`) {
          services$ = this.paymentService.getPlanEstimate(
            action.fiscalYear,
            action.requestedPlan,
            action.useVault,
            action.code,
          );
        } else {
          services$ = this.paymentService.getAddonEstimate(
            action.fiscalYear,
            action.requestedAddOn,
            action.useVault,
            action.code,
          );
        }

        return services$.pipe(
          map((paymentEstimate: PaymentEstimateV3) => setPaymentEstimateAction({ paymentEstimate })),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          }),
        );
      }),
    ),
  );

  loadUpgradeFishingEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUpgradeFishingEstimateAction>>(loadUpgradeFishingEstimateAction),
      switchMap((action: ReturnType<typeof loadUpgradeFishingEstimateAction>) => {
        let services$: Observable<PaymentEstimateV3>;

        if (action.checkoutType === `PLAN`) {
          services$ = this.paymentService.getPlanEstimate(action.fiscalYear, action.requestedPlan);
        } else {
          services$ = this.paymentService.getAddonEstimate(action.fiscalYear, action.requestedAddOn);
        }

        return services$.pipe(
          map((upgradeFishingEstimate: PaymentEstimateV3) =>
            setUpgradeFishingEstimateAction({
              upgradeFishingEstimate,
              computeAssessmentStatus: action.computeAssessmentStatus,
            }),
          ),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          }),
        );
      }),
    ),
  );

  setUpgradeFishingEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setUpgradeFishingEstimateAction>>(setUpgradeFishingEstimateAction),
      switchMap((action: ReturnType<typeof setUpgradeFishingEstimateAction>) => {
        if (action.computeAssessmentStatus) {
          return [computeAssessmentStatusAction()];
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  enableAddon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof enableAddonAction>>(enableAddonAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
        switchMap(([action, user]: [ReturnType<typeof enableAddonAction>, User]) =>
          this.paymentService
            .enableAddon(action.paymentMethod, action.requestedAddOn, action.fiscalYear, action.useVault, action.code)
            .pipe(
              tap((payment: Payment) => {
                this.pay(payment, user);
              }),
            ),
        ),
      ),
    { dispatch: false },
  );

  loadSubscriptionNextPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadSubscriptionNextPaymentAction>>(loadSubscriptionNextPaymentAction),
      switchMap((action: ReturnType<typeof loadSubscriptionNextPaymentAction>) =>
        this.paymentService
          .getSubscriptionNextPayment()
          .pipe(
            map((subscriptionNextPayment: SubscriptionNextPayment) =>
              setSubscriptionNextPaymentAction({ subscriptionNextPayment }),
            ),
          ),
      ),
    ),
  );

  loadPendingCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadPendingChargeAction>>(loadPendingChargeAction),
      switchMap((action: ReturnType<typeof loadPendingChargeAction>) =>
        this.paymentService.getPendingCharge().pipe(
          map((pendingCharge: PendingCharge) => setPendingChargeAction({ pendingCharge })),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          }),
        ),
      ),
    ),
  );

  payCharge$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof payChargeAction>>(payChargeAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
        switchMap(([action, user]: [ReturnType<typeof payChargeAction>, User]) =>
          this.paymentService.payCharge(action.paymentMethod).pipe(
            tap((payment: Payment) => {
              this.pay(payment, user);
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  applyVault$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof applyVaultAction>>(applyVaultAction),
      withLatestFrom(this.paymentStore$.pipe(select(fromPayment.selectSubscriptionNextPayment))),
      switchMap(
        ([action, subscriptionDetailsNextPayment]: [ReturnType<typeof applyVaultAction>, SubscriptionNextPayment]) =>
          this.paymentService.applyVault().pipe(
            switchMap((event: VaultEvent) => {
              const date = moment.unix(subscriptionDetailsNextPayment.nextPaymentDate).format(`LL`);
              const amount = this.currencyPipe.transform(
                subscriptionDetailsNextPayment.amount + event.amountChange,
                `EUR`,
                `symbol`,
                `.2-2`,
                `fr`,
              );

              const message = this.translateService.instant(`REWARDS_USED`, {
                amount,
                date,
              });

              this.toastService.success(message);

              return [loadSubscriptionNextPaymentAction(), loadVaultAction()];
            }),
            catchError((error: CustomError) => {
              const message = this.translateService.instant(error.errorCode ?? error.message);

              this.toastService.error(message);

              return EMPTY;
            }),
          ),
      ),
    ),
  );

  checkVoucherCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof checkVoucherCodeAction>>(checkVoucherCodeAction),
      switchMap((action: ReturnType<typeof checkVoucherCodeAction>) =>
        this.paymentService
          .checkVoucherCode(action.voucherCode, action.fiscalYear, action.requestedPlan, action.requestedAddOn)
          .pipe(
            switchMap((res: GenericResponse) => {
              const actions = [];
              actions.push(setVoucherResponseAction({ voucherResponse: res }));

              if (res.success) {
                actions.push(
                  loadPaymentEstimateAction({
                    checkoutType: action.checkoutType,
                    requestedPlan: action.requestedPlan,
                    requestedAddOn: action.requestedAddOn,
                    fiscalYear: action.fiscalYear,
                    useVault: action.useVault,
                    code: action.voucherCode,
                  }),
                );

                const msg = this.translateService.instant(`VOUCHER_CODE_APPLIED`, {
                  code: action.voucherCode,
                });
                this.toastService.success(msg);

                return actions;
              } else {
                this.toastService.error(res.details);

                return EMPTY;
              }
            }),
            catchError((error: CustomError) => {
              const message = this.translateService.instant(error.errorCode ?? error.message);
              this.toastService.error(message);

              return [setVoucherResponseAction({ voucherResponse: { success: false, details: action.voucherCode } })];
            }),
          ),
      ),
    ),
  );

  loadAvailablePlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAvailablePlansAction>>(loadAvailablePlansAction),
      switchMap(() =>
        this.paymentService
          .getAvailablePlans()
          .pipe(map((availablePlans: AvailablePlans) => setAvailablePlansAction({ availablePlans }))),
      ),
    ),
  );

  loadPlansByFiscalYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadPlansByFiscalYearsAction>>(loadPlansByFiscalYearsAction),
      switchMap(() =>
        this.paymentService
          .getPlansByFiscalYears()
          .pipe(
            switchMap((plansByFiscalYears: any) => [
              setPlansByFiscalYearsAction({ plansByFiscalYears }),
              computeAssessmentStatusAction(),
            ]),
          ),
      ),
    ),
  );

  loadAvailableAddons$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAvailableAddonsAction>>(loadAvailableAddonsAction),
      switchMap(() =>
        this.paymentService
          .getAvailableAddons()
          .pipe(map((availableAddons: AvailableAddons) => setAvailableAddonsAction({ availableAddons }))),
      ),
    ),
  );

  loadPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadPaymentsAction>>(loadPaymentsAction),
      switchMap(() =>
        this.paymentService.getAllPayments().pipe(
          map((payments: Payment[]) => {
            payments.sort((paymentA: Payment, paymentB: Payment) => (paymentA.paid < paymentB.paid ? 1 : -1));
            return setPaymentsAction({ payments });
          }),
          catchError((error: CustomError) => {
            const message = this.translateService.instant(error.errorCode ?? error.message);

            this.toastService.error(message);

            return EMPTY;
          }),
        ),
      ),
    ),
  );

  generateInvoice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof generateInvoiceAction>>(generateInvoiceAction),
        switchMap((action: ReturnType<typeof generateInvoiceAction>) =>
          this.paymentService.generateInvoice(action.paymentId, action.invoiceDetails).pipe(
            map((data: string) => {
              const link = window.open(`about:blank`);
              link.document.open();
              link.document.write(data);
              link.document.close();
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  downloadInvoice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof downloadInvoiceAction>>(downloadInvoiceAction),
        switchMap((action: ReturnType<typeof downloadInvoiceAction>) =>
          this.paymentService.downloadInvoice(action.paymentId).pipe(
            map((data: string) => {
              const link = window.open(`about:blank`);
              link.document.open();
              link.document.write(data);
              link.document.close();
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  renewSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof renewSubscriptionAction>>(renewSubscriptionAction),
      switchMap((action: ReturnType<typeof renewSubscriptionAction>) =>
        this.paymentService.renewSubscription(action.requestedPlan).pipe(
          switchMap((subscriptionRenewalRequest: SubscriptionRenewalRequest) => {
            if (subscriptionRenewalRequest.success) {
              if (subscriptionRenewalRequest.needToRedirect) {
                window.open(subscriptionRenewalRequest.redirectUrl, `_blank`);
              } else {
                const message = this.translateService.instant(`RENEWAL_SUCCESS`);
                this.toastService.success(message);

                return [loadSubscriptionNextPaymentAction()];
              }
            }

            return EMPTY;
          }),
          catchError((error: CustomError) => {
            return [loadSubscriptionNextPaymentAction()];
          }),
        ),
      ),
    ),
  );

  pay(payment: Payment, user: User): void {
    if (payment.paidPrice === 0) {
      location.reload();
    } else if (payment.paymentMethod === `STRIPE`) {
      this.redirectToStripe(payment);
    } else {
      this.redirectToCoinbase(payment);
    }
  }

  redirectToStripe(payment: Payment): void {
    const sessionId = payment.stripeId;
    const stripe = Stripe(environment.stripe);
    stripe
      .redirectToCheckout({
        sessionId,
      })
      .then((result: any) => {
        this.toastService.error(result.error.message);
      });
  }

  redirectToCoinbase(payment: Payment): void {
    window.location.href = payment.coinbaseRedirect;
  }

  constructor(
    private readonly actions$: Actions,
    private readonly paymentService: PaymentService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly paymentStore$: Store<fromPayment.State>,
    private readonly currencyPipe: CurrencyPipe,
  ) {}
}
