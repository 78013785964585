import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import * as opportunityReducer from '../reducers/opportunity.reducer';
import { MissedOverviewOpportunity, UserFeeAnalysis, UserYieldAnalysis } from '../../models/user-opportunities.model';
import { UserScore } from '../../models/user-score.model';
import { Instruction } from '../../models/instruction.model';

export const OPPORTUNITY_FEATURE_NAME = `opportunity`;

export interface State {
  opportunity: opportunityReducer.State;
}

export const selectOpportunityState: MemoizedSelector<State, opportunityReducer.State> =
  createFeatureSelector<opportunityReducer.State>(OPPORTUNITY_FEATURE_NAME);

export const selectMissedOverviewOpportunity: MemoizedSelector<State, MissedOverviewOpportunity> = createSelector(
  selectOpportunityState,
  (state: opportunityReducer.State) => state.userOpportunities?.overview
);

export const selectUserFeeAnalysis: MemoizedSelector<State, UserFeeAnalysis> = createSelector(
  selectOpportunityState,
  (state: opportunityReducer.State) => state.userOpportunities?.feeAnalysis
);

export const selectUserYieldAnalysis: MemoizedSelector<State, UserYieldAnalysis> = createSelector(
  selectOpportunityState,
  (state: opportunityReducer.State) => state.userOpportunities?.yieldAnalysis
);

export const selectUserScore: MemoizedSelector<State, UserScore> = createSelector(
  selectOpportunityState,
  (state: opportunityReducer.State) => state.userOpportunities?.score
);

export const selectInstructions: MemoizedSelector<
  State,
  Map<string, Map<string, Map<string, Instruction[]>>>
> = createSelector(selectOpportunityState, (state: opportunityReducer.State) => {
  const platforms = [...new Set(state.instructions.map((instruction) => instruction.platform))];
  const categories = [...new Set(state.instructions.map((instruction) => instruction.category))];
  const reviews = [...new Set(state.instructions.map((instruction) => instruction.review))];

  const instructions: Map<string, Map<string, Map<string, Instruction[]>>> = new Map([]);

  platforms.forEach((platform) => {
    instructions.set(platform, new Map([]));
    categories.forEach((category) => {
      instructions.get(platform).set(category, new Map([]));
      reviews.forEach((review) => {
        instructions
          .get(platform)
          .get(category)
          .set(
            review,
            state.instructions.filter(
              (instruction) =>
                instruction.platform === platform && instruction.category === category && instruction.review === review
            )
          );
      });
    });
  });

  return instructions;
});
