import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ChartHistory } from '../models/market.model';

@Injectable({
  providedIn: `root`,
})
export class InsightService {
  constructor(private readonly http: HttpClient) {}

  getPortfolioChartHistory(): Observable<ChartHistory> {
    return this.http.get<ChartHistory>(`${environment.apiUrl}/v1/insight/dashboard/chart/hour`).pipe(
      map((chartHistory: ChartHistory) => {
        chartHistory.lastPriceByToken = new Map<string, number>(Object.entries(chartHistory.lastPriceByToken));
        chartHistory.stocks = new Map<string, number>(Object.entries(chartHistory.stocks));
        chartHistory.referencesPrices = new Map<string, number[]>(Object.entries(chartHistory.referencesPrices));

        return chartHistory;
      })
    );
  }
}
