import { Action, createReducer, on } from '@ngrx/store';
import { TwoFACheck } from '../../models/two-fa.model';
import * as AuthenticationActions from '../actions/authentication.action';
import { User as AuthUser } from '@auth0/auth0-angular';
import { AuthUserEvent } from '../../../taxation/models/auth-user-event.model';
import { Page } from '../../../shared/models/page.model';
import { User } from '../../../taxation/models/user.model';

export interface State {
  isAuthenticated: boolean;
  accessToken: string;
  authUser: AuthUser;
  user: User;
  twoFA: TwoFACheck;
  is2FaCodeValidated: boolean;
  userAuthEventsPage: Page<AuthUserEvent>;
}

export const initialState: State = {
  isAuthenticated: false,
  accessToken: ``,
  authUser: null,
  user: null,
  twoFA: {
    config: null,
    qrCode: ``,
    recoveryCodes: [],
  },
  is2FaCodeValidated: false,
  userAuthEventsPage: null,
};

const reducer = createReducer(
  initialState,
  on(
    AuthenticationActions.setIsAuthenticatedAction,
    (state: State, { isAuthenticated }: any) => ({
      ...state,
      isAuthenticated,
    })
  ),
  on(
    AuthenticationActions.setAuthUserAction,
    (state: State, { authUser }: any) => ({
      ...state,
      authUser,
    })
  ),
  on(AuthenticationActions.setUserAction, (state: State, { user }: any) => ({
    ...state,
    user,
  })),
  on(
    AuthenticationActions.setAccessTokenAction,
    (state: State, { accessToken }: any) => ({
      ...state,
      accessToken,
    })
  ),
  on(AuthenticationActions.setTwoFaAction, (state: State, { twoFA }: any) => ({
    ...state,
    twoFA,
  })),
  on(
    AuthenticationActions.set2FaConfigAction,
    (state: State, { config }: any) => ({
      ...state,
      twoFA: {
        ...state.twoFA,
        config,
      },
    })
  ),
  on(
    AuthenticationActions.setAuthUserEventsAction,
    (state: State, { userAuthEventsPage }: any) => ({
      ...state,
      userAuthEventsPage,
    })
  )
);

export const authenticationReducer = (
  state: State | undefined,
  action: Action
): State => reducer(state, action);
