
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { createOAuthAPIAction } from '../../store/actions/account.action';
import * as fromUpload from '../../store/reducers/account.reducer';

@Component({
  selector: `app-oauth-callback`,
  standalone: true,
  imports: [],
  templateUrl: `./oauth-callback.component.html`,
})
export class OauthCallbackComponent implements OnInit {
  constructor(private readonly uploadStore$: Store<fromUpload.State>) {}

  ngOnInit(): void {
    localStorage.removeItem(`auth_callback_url`);
    this.uploadStore$.dispatch(createOAuthAPIAction());
  }
}
