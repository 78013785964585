import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: `app-new-integration-success-dialog`,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDividerModule, TranslateModule, MatDialogModule],
  templateUrl: `./new-integration-success-dialog.component.html`,
  styleUrl: `./new-integration-success-dialog.component.scss`,
})
export class NewIntegrationSuccessDialogComponent {}
