<form [formGroup]="form" class="swapper" [ngClass]="{ 'swapper--active': active }">
  <div class="swapper__amount">
    <p class="body-12-bold">{{ label }}</p>
    <input
      matInput
      formControlName="amount"
      type="text"
      inputmode="numeric"
      pattern="\d*"
      class="custom-swapper-input"
      [placeholder]="amountPlaceHolder"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
  </div>

  <mat-select
    formControlName="currency"
    disableOptionCentering
    class="custom-swapper-select"
    [panelWidth]="'296px'"
    [panelClass]="'custom-swapper-select-panel'"
    (openedChange)="openedChange($event)"
  >
    <input
      #search
      [formControl]="currenciesSearchFormControl"
      class="swapper-searchbar body-14-med"
      type="text"
      [placeholder]="'SEARCH' | translate"
    />
    <mat-select-trigger>
      <div class="currency-option">
        @if (!disabled) {
          <img
            class="coin-icon"
            src="https://icons.waltio.com/token/{{ form.get('currency').value | lowercase }}"
            default="/assets/img/icons/coins/default.svg"
            [alt]="form.get('currency').value"
            width="24"
            height="24"
          />
          <p class="body-14-med">
            {{ form.get('currency').value | truncate: 4 }}
          </p>
        } @else {
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{
              width: '24px',
              height: '24px',
              margin: '0',
            }"
          />

          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '6px',
              width: '34px',
              height: '10px',
              margin: '0',
            }"
          />
        }
      </div>
    </mat-select-trigger>
    <mat-option class="invisible" [value]="form.get('currency').value">
      <img
        class="coin-icon"
        src="https://icons.waltio.com/token/{{ form.get('currency').value | lowercase }}"
        default="/assets/img/icons/coins/default.svg"
        [alt]="form.get('currency').value"
        width="24"
        height="24"
      />
      <p class="body-14-med">
        {{ form.get('currency').value | truncate: 15 }}
      </p>
    </mat-option>
    @for (currency of currencies; track currency) {
      <mat-option [value]="currency">
        <img
          class="coin-icon"
          src="https://icons.waltio.com/token/{{ currency | lowercase }}"
          default="/assets/img/icons/coins/default.svg"
          [alt]="currency"
          width="24"
          height="24"
        />
        <p class="body-14-med">
          {{ currency | truncate: 15 }}
        </p>
      </mat-option>
    }
  </mat-select>
</form>
