<div
  class="banner"
  [ngClass]="{
    'banner--cta': cta,
    'banner--column': columnDisplay,
    'banner--primary': bannerTheme === 'primary',
    'banner--warning': bannerTheme === 'warning',
    'banner--disabled': bannerTheme === 'disabled',
    'banner--black': bannerTheme === 'black',
  }"
  >
  <div
    class="content"
    [ngClass]="{
      'content--custom': customIcon !== ''
    }"
    >
    @if (showBannerIcon) {
      <div class="banner__icon"></div>
    }

    @if (customIcon && !showBannerIcon) {
      <img [src]="customIcon" alt="" />
    }

    <div class="content__label">
      @if (title) {
        <p class="body-14-bold subscribe-cta" (click)="ctaClick.emit()">
          {{ title }} @if (useLink) {
          <img width="14" height="10" src="/assets/img/icons/next-black.svg" alt="next" />
        }
      </p>
    }

    @if (subtitle) {
      <p class="body-14">{{ subtitle | translate }}</p>
    }
  </div>
</div>

@if (cta) {
  <div
    class="cta width-100"
    [matTooltip]="ctaTooltip | translate"
    matTooltipPosition="above"
    [matTooltipDisabled]="!ctaDisabled"
    >
    <button
      type="button"
      mat-flat-button
      [color]="
        cta === 'SELECT_ALL' || cta === 'CLEAR_ALL'
          ? 'secondary-round'
          : bannerTheme === 'black'
          ? 'secondary'
          : 'primary'
      "
      [disabled]="ctaDisabled"
      (click)="ctaClick.emit()"
      >
      {{ cta | translate }}
    </button>
  </div>
}
</div>
