import { Action, createReducer, on } from '@ngrx/store';
import { ChartHistory, MarketFeed, MarketPrice } from '../../models/market.model';
import * as InsightActions from '../actions/insight.action';
import { LiveBalanceResponse } from '../../models/live-balance.model';
import { TickerFeed } from '../../models/ticker.model';

export interface State {
  marketFeed: MarketFeed;
  tickerFeed: TickerFeed;
  chartHistory: ChartHistory;
  liveBalances: LiveBalanceResponse;
  portfolioValue: number;
}

export const initialState: State = {
  marketFeed: null,
  tickerFeed: null,
  chartHistory: null,
  liveBalances: null,
  portfolioValue: null,
};

const reducer = createReducer(
  initialState,
  on(InsightActions.setPortfolioValueAction, (state: State, { portfolioValue }: any) => ({
    ...state,
    portfolioValue,
  })),
  on(InsightActions.setMarketFeedAction, (state: State, { marketFeed }: any) => {
    const priceMap: Map<string, MarketPrice> = new Map(
      state.marketFeed?.marketFeed?.map((marketPrice: MarketPrice) => [marketPrice.symbol, marketPrice])
    );

    marketFeed.marketFeed.forEach((marketPrice: MarketPrice) => priceMap.set(marketPrice.symbol, marketPrice));

    return {
      ...state,
      marketFeed: {
        ...marketFeed,
        marketFeed: Array.from(priceMap.values()),
      },
    };
  }),
  on(InsightActions.setTickerFeedAction, (state: State, { tickerFeed }: any) => ({
    ...state,
    tickerFeed,
  })),
  on(InsightActions.setChartHistoryAction, (state: State, { chartHistory }: any) => ({
    ...state,
    chartHistory,
  })),
  on(InsightActions.setLiveBalancesAction, (state: State, { liveBalances }: any) => ({
    ...state,
    liveBalances,
  }))
);

export const insightReducer = (state: State | undefined, action: Action): State => reducer(state, action);
