import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: `app-import-button`,
  standalone: true,
  imports: [TranslateModule, NgOptimizedImage],
  templateUrl: `./import-button.component.html`,
  styleUrls: [`./import-button.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportButtonComponent {
  @Input() iconUrl: string;
  @Input() label: string;
  @Input() isRecommendedImportMethod = false;
}
