import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromShared from '../store/selectors/shared.selector';
import { inject } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { goToAction } from '../store/actions/shared.action';


export const mobileGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const sharedStore = inject(Store<fromShared.State>);
  const deviceService = inject(DeviceDetectorService);

  const isDesktop = deviceService.isDesktop();

  if (!isDesktop) {
    sharedStore.dispatch(goToAction({ url: `mobile-go-desktop` }));
  }

  return isDesktop;
};