export const LANGUAGES: Map<string, string> = new Map([
  [`en`, `English`],
  [`fr`, `Français`],
  [`es`, `Español`],
]);

export const FISCAL_RESIDENCIES_LANGUAGES: Map<string, string[]> = new Map([
  [`FR`, [`fr`, `en`]],
  [`BE`, [`fr`, `en`]],
  [`ES`, [`es`, `en`]],
]);