import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../shared/models/page.model';
import { environment } from '../../../environments/environment';
import { AffiliationProfile } from '../models/affiliation.model';
import { User } from '../models/user.model';
import { Vault, VaultEvent } from '../models/vault.model';

@Injectable({
  providedIn: `root`,
})
export class AffiliationService {
  constructor(private readonly http: HttpClient) { }

  /**
   * Get vault
   *
   * @returns vault
   */
  getVault(): Observable<Vault> {
    return this.http.get<Vault>(`${environment.apiUrl}/v1/tax/vault`);
  }

  /**
   * Get vault events
   *
   * @param page
   * @param size
   * @param sort
   * @returns
   */
  getAffiliationVaultEventsPage(
    page = 0,
    size = 100,
    sort = `desc`
  ): Observable<Page<VaultEvent>> {
    return this.http.get<Page<VaultEvent>>(
      `${environment.apiUrl}/v1/tax/vault/affiliation/events?page=${page}&size=${size}&sort=created,${sort}`
    );
  }

  /**
   * Get affiliation profile
   *
   * @returns
   */
  getAffiliationProfile(): Observable<AffiliationProfile> {
    return this.http.get<AffiliationProfile>(
      `${environment.apiUrl}/v1/tax/affiliation/profile`
    );
  }

  /**
   * ! Not used
   * Disable affiliation profile
   *
   * @returns
   */
  disableAffiliationProfile(): Observable<AffiliationProfile> {
    return this.http.post<AffiliationProfile>(
      `${environment.apiUrl}/v1/tax/affiliation/profile/disable`,
      ``
    );
  }

  /**
   * Enable affiliation profile
   *
   * @returns
   */
  enableAffiliationProfile(): Observable<AffiliationProfile> {
    return this.http.post<AffiliationProfile>(
      `${environment.apiUrl}/v1/tax/affiliation/profile/enable`,
      ``
    );
  }

  /**
   * Confirm a user is not linked to an affiliate
   *
   * @returns
   */
  closeAffiliation(): Observable<User> {
    return this.http.post<User>(
      `${environment.apiUrl}/v1/tax/affiliation/close-affiliation`,
      ``
    );
  }
}
