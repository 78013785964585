import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Account } from '../../models/account.model';

@Component({
  selector: `app-account-card`,
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: `./account-card.component.html`,
  styleUrls: [`./account-card.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountCardComponent {
  @Input() account: Account;
  @Input() selected = false;
  @Input() alternative = false;
}
