import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Toast } from '../../models/toast.model';

@Component({
  selector: `app-toast`,
  standalone: true,
  imports: [CommonModule],
  templateUrl: `./toast.component.html`,
  styleUrls: [`./toast.component.scss`]
})
export class ToastComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<ToastComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: Toast
  ) { }
}
