<div class="form-3916-bis">
  <div class="form-3916-bis__header">
    <p class="body-14">{{ 'TAX_RETURN_PREVIEW' | translate }}</p>
  </div>
  <mat-divider></mat-divider>
  <div class="form-3916-bis__content">
    <div class="form-3916-bis__content__title">
      <p class="body-14-bold">
        {{ '3916_BIS_FORM_STEP_3' | translate }}
      </p>
      <p class="body-14-bold">
        {{ 'DESIGNATION' | translate }}
      </p>
    </div>
    <div class="form-items">
      <div class="form-item">
        <p class="body-14">{{ 'ACCOUNT_NUMBER' | translate }}</p>
        <app-tax-form-input [value]="'ENTER_ACCOUNT_NUMBER' | translate"></app-tax-form-input>
      </div>
      <div class="form-item">
        <p class="body-14">{{ 'OPENING_DATE' | translate }}</p>
        <app-tax-form-input [value]="'ENTER_ACCOUNT_DATE_OPNENING' | translate"></app-tax-form-input>
      </div>
      <div class="form-item">
        <p class="body-14">{{ 'CLOSING_DATE' | translate }}</p>
        <app-tax-form-input [value]="'FILL_IN_ACCOUNT_CLOSE' | translate"></app-tax-form-input>
      </div>
      <div class="form-item">
        <p class="body-14">{{ 'ACCOUNT_DESIGNATION' | translate }}</p>
        <app-tax-form-input
          [copiable]="platformsMap.get(platform)?.designation"
          [value]="platformsMap.get(platform)?.designation || 'DO_NOT_FILL_IN' | translate"
        ></app-tax-form-input>
      </div>
    </div>
    <div class="form-items">
      <div class="form-item m-l-25">
        <p class="body-14">{{ 'COUNTRY' | translate }}</p>
        <app-tax-form-input
          [copiable]="platformsMap.get(platform)?.country"
          [value]="platformsMap.get(platform)?.country || 'DO_NOT_FILL_IN' | translate"
        ></app-tax-form-input>
      </div>
      <div class="form-item m-l-25">
        <p class="body-14">{{ 'ADDITIONAL_ADDRESS' | translate }}</p>
        <app-tax-form-input
          [copiable]="platformsMap.get(platform)?.additionalAddress"
          [value]="platformsMap.get(platform)?.additionalAddress || 'DO_NOT_FILL_IN' | translate"
        ></app-tax-form-input>
      </div>
      <div class="form-item m-l-25">
        <p class="body-14">{{ 'STREET' | translate }}</p>
        <app-tax-form-input
          [copiable]="platformsMap.get(platform)?.street"
          [value]="platformsMap.get(platform)?.street || 'DO_NOT_FILL_IN' | translate"
        ></app-tax-form-input>
      </div>
      <div class="form-item m-l-25">
        <p class="body-14">{{ 'COUNTRY_POSTCODE_CITY' | translate }}</p>
        <app-tax-form-input
          [copiable]="platformsMap.get(platform)?.zipCode"
          [value]="platformsMap.get(platform)?.zipCode || 'DO_NOT_FILL_IN' | translate"
        ></app-tax-form-input>
      </div>
      <div class="form-item">
        <p class="body-14">{{ 'ACCOUNT_URL' | translate }}</p>
        <app-tax-form-input
          [copiable]="platformsMap.get(platform)?.url"
          [value]="platformsMap.get(platform)?.url || 'DO_NOT_FILL_IN' | translate"
        ></app-tax-form-input>
      </div>
    </div>
  </div>
</div>
