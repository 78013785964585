import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

export const mobileGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const deviceService = inject(DeviceDetectorService);
  const isDesktop = deviceService.isDesktop();

  if (!isDesktop) {
    window.location.href = `https://waltio.app.link/waltio-smart-redirection`;
  }

  return isDesktop;
};
